import { Icon } from '@chakra-ui/icon'

const MessageBubbleCenter = props => (
  <Icon {...props}>
    <path
      d="M23 2.5A2.5 2.5 0 0020.5 0h-17A2.5 2.5 0 001 2.5v14A2.5 2.5 0 003.5 19h3.279l4.111 4.509a1.5 1.5 0 002.219 0L17.221 19H20.5a2.5 2.5 0 002.5-2.5v-14z"
      fill="currentColor"
    />
  </Icon>
)

MessageBubbleCenter.displayName = 'MessageBubbleCenterIcon'

export default MessageBubbleCenter
