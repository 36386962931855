import { Icon } from '@chakra-ui/icon'

const MenuWide = props => (
  <Icon {...props}>
    <path
      d="M.5 3.5a1 1 0 011-1h21a1 1 0 011 1v1a1 1 0 01-1 1h-21a1 1 0 01-1-1v-1zM.5 11.5a1 1 0 011-1h21a1 1 0 011 1v1a1 1 0 01-1 1h-21a1 1 0 01-1-1v-1zM.5 19.5a1 1 0 011-1h21a1 1 0 011 1v1a1 1 0 01-1 1h-21a1 1 0 01-1-1v-1z"
      fill="currentColor"
    />
  </Icon>
)

MenuWide.displayName = 'MenuWideIcon'

export default MenuWide
