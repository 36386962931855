import { Icon } from '@chakra-ui/icon'

const Location = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.634 11.366a1.25 1.25 0 01-.884 2.134h-.914a.5.5 0 00-.492.413 9.515 9.515 0 01-7.919 7.729.5.5 0 00-.425.494v.614a1.25 1.25 0 11-2.5 0v-.714a.5.5 0 00-.4-.489 9.51 9.51 0 01-7.444-7.634.5.5 0 00-.492-.413H1.25a1.25 1.25 0 110-2.5h.914a.5.5 0 00.492-.413A9.51 9.51 0 0110.1 2.953a.5.5 0 00.4-.489V1.25a1.25 1.25 0 112.5 0v1.114a.5.5 0 00.425.494 9.516 9.516 0 017.919 7.73.5.5 0 00.493.412h.913c.331 0 .65.132.884.366zM4.75 12.25A7.25 7.25 0 0012 19.5a7.258 7.258 0 007.25-7.25 7.25 7.25 0 10-14.5 0z"
      fill="currentColor"
    />
  </Icon>
)

Location.displayName = 'LocationIcon'

export default Location
