import { Icon } from '@chakra-ui/icon'

const ChatBubbleDots = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.541 10.817C.541 5.611 5.682 1.375 12 1.375s11.459 4.237 11.459 9.442S18.318 20.258 12 20.258a13.689 13.689 0 01-3.8-.531l-6.126 2.851a.5.5 0 01-.658-.678L3.7 17.333a8.567 8.567 0 01-3.159-6.516zm4.918.058a1.5 1.5 0 103 0 1.5 1.5 0 00-3 0zM12 9.375a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm3.5 1.5a1.5 1.5 0 103 0 1.5 1.5 0 00-3 0z"
      fill="currentColor"
    />
  </Icon>
)

ChatBubbleDots.displayName = 'ChatBubbleDotsIcon'

export default ChatBubbleDots
