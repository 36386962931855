import { Icon } from '@chakra-ui/icon'

const HeartOutline = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.772 1.587c1.978.337 3.685 1.631 4.6 3.488 1.218 2.496.74 5.527-1.179 7.487L12.208 22.2a.941.941 0 01-.688.303.941.941 0 01-.688-.303l-8.998-9.656c-1.91-1.96-2.38-4.982-1.162-7.469.901-1.866 2.612-3.165 4.594-3.488 1.993-.35 4.026.322 5.46 1.807l.794.754.824-.781c1.436-1.456 3.45-2.117 5.428-1.78zm-5.91 18.109l7.969-8.545c1.332-1.35 1.668-3.444.83-5.171-.627-1.295-1.814-2.195-3.189-2.418a4.255 4.255 0 00-3.804 1.26L12.17 6.245a.932.932 0 01-1.293 0l-1.469-1.4a4.273 4.273 0 00-3.834-1.283C4.197 3.784 3.008 4.684 2.38 5.98c-.838 1.72-.509 3.806.813 5.156l7.98 8.56a.47.47 0 00.688 0z"
      fill="currentColor"
    />
  </Icon>
)

HeartOutline.displayName = 'HeartOutlineIcon'

export default HeartOutline
