import { Icon } from '@chakra-ui/icon'

const Flag = props => (
  <Icon {...props}>
    <path
      d="M21.909 8.587l-14.5-6.5A1 1 0 006 3v13.5a1 1 0 001.435.9l14.5-7a1 1 0 00-.026-1.813zM3 0a1.5 1.5 0 00-1.5 1.5v21a1.5 1.5 0 003 0v-21A1.5 1.5 0 003 0z"
      fill="currentColor"
    />
  </Icon>
)

Flag.displayName = 'FlagIcon'

export default Flag
