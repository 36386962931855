import { Icon } from '@chakra-ui/icon'

const Pulse = props => (
  <Icon {...props}>
    <path
      d="M22.75 11.251h-4.879l-1.606-4.2a1.25 1.25 0 00-2.392.2l-1.458 7.116L10.6 2.314a1.25 1.25 0 00-2.438-.158l-2.614 9.095H1.25a1.25 1.25 0 000 2.5h5.239c.557 0 1.047-.37 1.2-.906l1.29-4.489 2.013 13.331a1.25 1.25 0 001.2 1.063h.033a1.25 1.25 0 001.224-1l2.009-9.8.382 1c.186.482.65.8 1.167.8h5.743a1.25 1.25 0 100-2.5v.001z"
      fill="currentColor"
    />
  </Icon>
)

Pulse.displayName = 'PulseIcon'

export default Pulse
