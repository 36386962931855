import { Question, NewQuestion } from 'types'

export enum CardType {
  Title = 'title',
  Story = 'story',
  Quiz = 'quiz',
  Video = 'video',
}

export interface NewCard {
  format: CardType
  index?: number
  question?: NewQuestion
  mediaUrl?: string
  description?: string
  title?: string
}

export interface Card extends NewCard {
  id: number
  question?: Question
}
