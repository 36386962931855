import { Icon } from '@chakra-ui/icon'

const File = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 0h11.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V22a2 2 0 01-2 2H4a2 2 0 01-2-2V2a2 2 0 012-2zm15.854 6.682l-4.536-4.536A.5.5 0 0014.965 2H4.5a.5.5 0 00-.5.5v19a.5.5 0 00.5.5h15a.5.5 0 00.5-.5V7.035a.5.5 0 00-.146-.353z"
      fill="currentColor"
    />
  </Icon>
)

File.displayName = 'FileIcon'

export default File
