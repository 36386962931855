import { Icon } from '@chakra-ui/icon'

const Play = props => (
  <Icon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="24" fill="none">
      <path
        fill="#fff"
        d="M19.5 9.402c2 1.155 2 4.041 0 5.196l-15 8.66C2.5 24.413 0 22.97 0 20.66V3.34C0 1.03 2.5-.413 4.5.742z"
      />
    </svg>
  </Icon>
)

Play.displayName = 'NAMEIcon'

export default Play
