import { Icon } from '@chakra-ui/icon'

const MilitaryRankOne = props => (
  <Icon {...props}>
    <path
      d="M20.5 18.314l-7.817-4.6a1.436 1.436 0 00-1.369 0L3.5 18.313a1 1 0 00-.493.9c.142 3.836 2.785 4.247 6.093 4.247.442 0 3.444-.032 4.445-.016 3.906.063 7.29.119 7.452-4.23a1 1 0 00-.497-.9z"
      fill="currentColor"
    />
    <path
      d="M20.322 11.708l-7.638-4.494a1.439 1.439 0 00-1.369 0l-7.637 4.494A1.46 1.46 0 003 12.893v2a.757.757 0 001.185.678l7.636-4.493a.465.465 0 01.357 0l7.637 4.494c.136.082.29.127.449.129a.718.718 0 00.358-.094.79.79 0 00.378-.714v-2a1.477 1.477 0 00-.678-1.185z"
      fill="currentColor"
    />
    <path
      d="M20.322 5.208L12.684.715a1.428 1.428 0 00-1.369 0L3.678 5.208A1.46 1.46 0 003 6.393v2a.757.757 0 001.185.678l7.637-4.494a.462.462 0 01.356 0l7.637 4.494A.756.756 0 0021 8.393v-2a1.477 1.477 0 00-.678-1.185z"
      fill="currentColor"
    />
  </Icon>
)

MilitaryRankOne.displayName = 'MilitaryRankOneIcon'

export default MilitaryRankOne
