export enum Feature {
  AnalyzeDashboard = 'analyze_dashboard',
  AtWillGameUpdates = 'at_will_game_updates',
  GameCenterEnhancements = 'game_center_enhancements',
  GameGuides = 'microlearning_modules',
  IncludeNTIDInReports = 'include_ntid_in_reports',
  PerformanceDashboard = 'performance_dashboard',
  PlayerIdentifiers = 'player_identifiers',
  RepostToAnotherFeed = 'repost_to_another_feed',
  MetricThresholds = 'metric_thresholds',
}
