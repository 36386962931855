import { FC, useState, ReactNode } from 'react'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import { Flex, InputProps } from '@chakra-ui/react'
import CbDateRangePickerTarget from './CbDateRangePickerTarget'

export type DateRange = [startDate: Date, endDate: Date]

export interface CbDateRangePickerProps {
  dateFormat?: string
  iconColor?: string
  maxDate?: Date
  minDate?: Date
  onChange?: (date: Date[]) => void
  onClear?: () => void
  showOptions?: boolean
  targetIcon?: ReactNode
  targetProps?: InputProps
  values?: Date[]
  variant?: string
  color?: string
  borderColor?: string
  width?: string
  _focus?: any
  isDisabled?: boolean
}

export const CbDateRangePicker: FC<CbDateRangePickerProps> = ({
  dateFormat,
  iconColor = 'cloud.600',
  maxDate,
  minDate,
  onChange,
  onClear,
  showOptions = false,
  targetIcon,
  targetProps,
  values = [moment().subtract(30, 'days').toDate(), moment().toDate()],
  variant = 'cb-base',
  isDisabled,
  ...props
}) => {
  const [shouldCloseOnSelect, setShouldCloseOnSelect] = useState(false)
  const [startDate, endDate] = values

  const handleChange = (dateRange: DateRange): void => {
    if (dateRange.length > 1) {
      setShouldCloseOnSelect(true)
      onChange?.(dateRange)
    }
  }

  return (
    <Flex position="relative">
      <DatePicker
        endDate={endDate}
        maxDate={maxDate}
        minDate={minDate}
        onChange={handleChange}
        onClickOutside={() => setShouldCloseOnSelect(true)}
        onInputClick={() => setShouldCloseOnSelect(false)}
        selected={startDate}
        selectsRange={true}
        shouldCloseOnSelect={shouldCloseOnSelect}
        startDate={startDate}
        customInput={
          <CbDateRangePickerTarget
            dateFormat={dateFormat}
            endDate={endDate}
            handleChange={handleChange}
            iconColor={iconColor}
            showOptions={showOptions}
            startDate={startDate}
            targetIcon={targetIcon}
            targetProps={targetProps}
            variant={variant}
            isDisabled={isDisabled}
            onClear={onClear}
            {...props}
          />
        }
      />
    </Flex>
  )
}
