import { Icon } from '@chakra-ui/icon'

const PDF = props => (
  <Icon {...props}>
    <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
      <path d="m966.9 482.6h-70.9v-146.6c0-44-25.5-105.5-56.6-136.6l-142.8-142.8c-31.1-31.1-92.6-56.6-136.6-56.6h-480c-44 0-80 36-80 80v864c0 44 36 80 80 80h736c44 0 80-36 80-80v-80.5h70.9c31.4 0 57.1-25.7 57.1-57.1v-266.7c0-31.4-25.7-57.1-57.1-57.1zm-326.8-390c4.4 3.1 8.2 6.2 11.3 9.3l142.8 142.8c3.1 3.1 6.2 6.9 9.3 11.3h-163.4zm191.9 851.4c0 8.7-7.3 16-16 16h-736c-8.7 0-16-7.3-16-16v-864c0-8.7 7.3-16 16-16h480c4.8 0 10.2.6 16 1.7v254.3h254.3c1.1 5.8 1.7 11.2 1.7 16v146.6h-601.8c-31.4 0-57.1 25.7-57.1 57.1v266.8c0 31.4 25.7 57.1 57.1 57.1h601.8zm-138.3-286.5c0 31.7-9 56-27.1 72.9s-44.1 25.3-78.2 25.3h-54.6v-192.7h60.5c31.5 0 55.9 8.3 73.3 24.9s26.1 39.8 26.1 69.6zm-222-34.4c0 20.7-6.5 36.6-19.4 47.6-13 11-31.4 16.5-55.3 16.5h-17.5v68.6h-40.9v-192.8h61.6c23.4 0 41.2 5 53.3 15.1 12.1 10 18.2 25 18.2 45zm398-26.7h-70.3v49.7h65.4v33.4h-65.4v76.2h-40.2v-192.7h110.5z" />
      <path d="m422.6 603.3c-5.2-4.6-13.4-6.9-24.6-6.9h-18.6v57.2h13.4c12.6 0 22-2.5 28.2-7.4 6.2-5 9.4-12.2 9.4-21.7 0-9.6-2.6-16.6-7.8-21.2z" />
      <path d="m574.6 722h17.5c39.4 0 59.1-21.1 59.1-63.4 0-41.4-18.3-62.1-54.8-62.1h-21.8z" />
    </svg>
  </Icon>
)

PDF.displayName = 'PDFIcon'

export default PDF
