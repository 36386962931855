import {
  TransactionTitles,
  TransactionTypes,
} from 'ui/types/RewardsActivityCard'

export const filterOptions = [...Object.keys(TransactionTitles)]

export interface TransactionFilter {
  id: number
  type: string
  name: string
}

export const filterOptionValues = filterOptions.map((value, index) => ({
  id: index + 1,
  type: TransactionTypes[value],
  name: TransactionTitles[value],
}))
