import { Icon } from '@chakra-ui/icon'

const ShieldOutline = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.73 5.19c-1.179.915-1.942 1.907-1.942 2.526 0 .652.361 1.16.999 2.001a7.149 7.149 0 011.833 4.607c0 6.977-9.912 9.535-10.334 9.641a1.185 1.185 0 01-.572 0c-.422-.106-10.334-2.664-10.334-9.641a7.149 7.149 0 011.832-4.608c.639-.84 1-1.348 1-2 0-.616-.763-1.611-1.942-2.527a1.652 1.652 0 01-.156-2.473L4.3.528A1.656 1.656 0 016.29.262l2.154 1.232a.5.5 0 00.5 0L11.18.216a1.664 1.664 0 011.64 0l2.232 1.278a.5.5 0 00.5 0L17.71.262a1.654 1.654 0 011.99.266l2.186 2.188a1.653 1.653 0 01-.156 2.474zm-9.875 16.267a.511.511 0 00.29 0c1.724-.524 8.033-2.742 8.033-7.157a4.85 4.85 0 00-1.338-3.146 5.5 5.5 0 01-1.463-3.393 5.177 5.177 0 011.843-3.5.5.5 0 00.005-.713l-.605-.605a.5.5 0 00-.6-.08L15.855 4.1c-.36.205-.8.205-1.159 0l-2.444-1.4a.5.5 0 00-.5 0L9.308 4.1c-.36.205-.8.205-1.159 0L5.983 2.866a.5.5 0 00-.6.08l-.605.6a.5.5 0 00.005.713 5.177 5.177 0 011.843 3.5 5.5 5.5 0 01-1.463 3.393 4.85 4.85 0 00-1.341 3.149c0 4.415 6.311 6.633 8.033 7.156z"
      fill="currentColor"
    />
  </Icon>
)

ShieldOutline.displayName = 'ShieldOutlineIcon'

export default ShieldOutline
