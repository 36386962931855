import { Icon } from '@chakra-ui/icon'

const GameDice = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.8.123l9.106 3.338a.544.544 0 01.016 1.017l-9.715 3.906a.55.55 0 01-.407 0L2.082 4.478a.545.545 0 01.012-1.017l9.073-3.33A2.686 2.686 0 0112.8.123zm-1.891 4.24a1.091 1.091 0 102.182 0 1.091 1.091 0 00-2.182 0zM13.16 9.639l10.091-4.057a.546.546 0 01.749.506v12.506a1.26 1.26 0 01-.756 1.151s-7.27 3.168-9.67 4.206a.542.542 0 01-.756-.5V10.145c0-.223.135-.423.342-.506zm2.113 8.783a1.091 1.091 0 102.182 0 1.091 1.091 0 00-2.182 0zm3.272-2.722a1.091 1.091 0 110-2.182 1.091 1.091 0 010 2.182zm1.174-4.491c.169.407.567.673 1.008.673v-.005c.6 0 1.088-.485 1.091-1.086a1.09 1.09 0 10-2.099.418zM10.84 9.639L.749 5.582A.545.545 0 000 6.088v12.506c0 .501.297.955.756 1.155 0 0 7.273 3.168 9.67 4.206a.542.542 0 00.756-.5v-13.31a.547.547 0 00-.342-.506zm-7.022 3.874a1.091 1.091 0 110-2.182 1.091 1.091 0 010 2.182zM6 17.331a1.091 1.091 0 102.182 0 1.091 1.091 0 00-2.182 0z"
      fill="currentColor"
    />
  </Icon>
)

GameDice.displayName = 'GameDiceIcon'

export default GameDice
