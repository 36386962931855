import { Icon } from '@chakra-ui/icon'

const VIPCircle = props => (
  <Icon {...props}>
    <path
      d="M17.023 9.646h-.5a.25.25 0 00-.25.25v1c0 .138.112.25.25.25h.5a.75.75 0 000-1.5z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.5 12C.5 5.649 5.649.5 12 .5 18.348.507 23.493 5.652 23.5 12c0 6.351-5.149 11.5-11.5 11.5S.5 18.351.5 12zm7.1 3.45a8.3 8.3 0 001.65-4.95V9a.75.75 0 00-1.5 0v1.5a6.793 6.793 0 01-.519 2.591.25.25 0 01-.462 0A6.793 6.793 0 016.25 10.5V9a.75.75 0 00-1.5 0v1.5a8.3 8.3 0 001.65 4.95.75.75 0 001.2 0zm5.4-1.2a.75.75 0 010 1.5h-2a.75.75 0 010-1.5.25.25 0 00.25-.25v-4a.25.25 0 00-.25-.25.75.75 0 010-1.5h2a.75.75 0 010 1.5.25.25 0 00-.25.25v4c0 .138.112.25.25.25zm3.25-1.5H17a2.25 2.25 0 000-4.5h-1.5a.75.75 0 00-.75.75v6a.75.75 0 001.5 0v-2.25z"
      fill="currentColor"
    />
  </Icon>
)

VIPCircle.displayName = 'VIPCircleIcon'

export default VIPCircle
