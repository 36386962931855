import { Icon } from '@chakra-ui/icon'

const StarHalf = props => (
  <Icon {...props}>
    <path
      d="M18.324 1.249a1 1 0 00-1-1c-.632.004-1.194.4-1.411.993l-2.178 6.174a.5.5 0 01-.472.333H7.174a1.5 1.5 0 00-.963 2.651l5.189 4.3a.5.5 0 01.155.543l-2.182 6.53a1.5 1.5 0 002.309 1.685l6.233-4.57a1 1 0 00.408-.807l.001-16.832z"
      fill="currentColor"
    />
  </Icon>
)

StarHalf.displayName = 'StarHalfIcon'

export default StarHalf
