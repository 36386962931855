import { Icon } from '@chakra-ui/icon'

const CursorHand = props => (
  <Icon {...props}>
    <path
      d="M21.862 11.5a3 3 0 00-3-3h-.268a2 2 0 00-1.732-1h-1.268a2 2 0 00-1.732-1h-1V3a2.5 2.5 0 00-5 0v10.3l-1.213-1.7A2.485 2.485 0 002.6 14.47l6.1 8.606a1 1 0 101.631-1.157l-6.095-8.6a.485.485 0 01.791-.563l2.12 2.976a1.5 1.5 0 002.721-.871V3a.5.5 0 011 0v7.5a1 1 0 102 0V8.747a.25.25 0 01.25-.25h.5a.25.25 0 01.25.25V10.5a1 1 0 102 0v-.75a.25.25 0 01.25-.25h.5a.25.25 0 01.25.25v1.75a1 1 0 102 0v-.71a.251.251 0 01.334-.231 1 1 0 01.666.942v2.6c0 .837-.116 1.67-.346 2.474L17.9 22.223a1 1 0 001.923.549l1.616-5.654c.28-.983.422-2 .422-3.022l.001-2.596z"
      fill="currentColor"
    />
  </Icon>
)

CursorHand.displayName = 'CursorHandIcon'

export default CursorHand
