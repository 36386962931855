import { arcadeApiClient } from '../apiClients'
import { AppContext } from '../providers'
import { v4 as randomUUID } from 'uuid'
import { useCallback, useContext, useEffect, useState } from 'react'
import useSWR from 'swr'
import { NewsfeedItem } from 'types/NewsfeedItem'

const fetcher = (query): Promise<NewsfeedItem[]> => {
  return arcadeApiClient
    .get(`/multi_platform/newsfeed/${query}`)
    .then(res => res.data.newsfeedItems)
}

export const useNewsfeedItems = ({
  feedType,
  teamId,
  userId,
  limit = 5,
}: {
  feedType: string
  teamId?: number
  userId?: number
  limit?: number
}) => {
  const { setToast } = useContext(AppContext)
  const [lastId, setLastId] = useState<number>()
  const [more, setMore] = useState(true)
  const [newsfeedItems, setNewsfeedItems] = useState<NewsfeedItem[]>([])
  // Fingerprint is a random number we append to the request URL to avoid using swr cached data
  const [fingerPrint, setFingerPrint] = useState<number>(randomUUID())
  useEffect(() => {
    setNewsfeedItems([])
    setMore(true)
    setLastId(undefined)
    setFingerPrint(randomUUID())
  }, [feedType, teamId, userId])

  const query = new URLSearchParams()
  query.append('limit', limit.toString())
  teamId && query.append('team_id', teamId.toString())
  lastId && query.append('last_id', lastId.toString())
  userId && query.append('assumed_user_id', userId.toString())
  const { data: newsfeedPage, isValidating: loading } = useSWR(
    `${feedType}?${query.toString()}#${fingerPrint}`,
    fetcher,
    { revalidateOnMount: true },
  )

  useEffect(() => {
    if (!newsfeedPage) return
    setNewsfeedItems(items => {
      const ids = items.map(item => item.id)
      const newItems = newsfeedPage.filter(item => !ids.includes(item.id))
      return [...items, ...newItems]
    })
  }, [newsfeedPage])

  const loadMore = useCallback(() => {
    if (!newsfeedPage) return
    setLastId(newsfeedItems[newsfeedItems.length - 1]?.id)
    if (newsfeedPage.length < limit) {
      setMore(false)
    }
  }, [setLastId, newsfeedItems])

  const pollingQuery = new URLSearchParams()
  pollingQuery.append('limit', limit.toString())
  teamId && pollingQuery.append('team_id', teamId.toString())
  userId && pollingQuery.append('assumed_user_id', userId.toString())
  const { data: firstPage, mutate: refresh } = useSWR(
    // Poll is appended to the end of this URL to distiguish it from the initial newsfeed request URL
    `${feedType}?${pollingQuery.toString()}#${fingerPrint}-poll`,
    fetcher,
    {
      refreshInterval: 30000,
      isPaused: () => loading || !newsfeedPage,
    },
  )

  useEffect(() => {
    if (!firstPage) return
    setNewsfeedItems(items => {
      const ids = items.map(item => item.id)
      const newItems = firstPage.filter(item => !ids.includes(item.id))
      return [...newItems, ...items]
    })
  }, [firstPage])

  const deletePost = (id: number) =>
    arcadeApiClient
      .delete(`multi_platform/newsfeed/${id}`)
      .then(() => {
        setNewsfeedItems(newsfeedItems.filter(item => item.id !== id))
      })
      .catch(e => {
        setToast({
          status: 'error',
          description: `Could not Delete Post: ${e}`,
        })
        console.error(e)
      })

  return {
    deletePost,
    hasMore: more,
    loading,
    loadMore,
    newsfeedItems,
    refresh,
  }
}
