import { Icon } from '@chakra-ui/icon'

const RibbonOne = props => (
  <Icon {...props}>
    <path
      d="M15.494 16.256a4.766 4.766 0 01-3.495 1.505 4.766 4.766 0 01-3.494-1.505 4.621 4.621 0 01-.729-.016.261.261 0 00-.194.06.237.237 0 00-.082.18v5.6c0 .349.197.67.515.84.318.169.707.159 1.015-.026L12 21.112l2.97 1.783c.308.185.697.195 1.015.025a.954.954 0 00.515-.84v-5.598a.237.237 0 00-.082-.178.258.258 0 00-.193-.06 4.529 4.529 0 01-.731.012z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.01 2.39a2.959 2.959 0 01.782 3.07c1.05.523 1.708 1.564 1.708 2.7 0 1.136-.658 2.177-1.708 2.7a2.958 2.958 0 01-.78 3.07 3.263 3.263 0 01-3.198.75c-.545 1.008-1.63 1.64-2.813 1.64-1.183 0-2.268-.632-2.813-1.64a3.264 3.264 0 01-3.198-.75 2.958 2.958 0 01-.781-3.07C4.159 10.335 3.5 9.295 3.5 8.16c0-1.136.66-2.177 1.709-2.7a2.957 2.957 0 01.78-3.07 3.262 3.262 0 013.198-.75C9.732.634 10.817 0 12 0c1.183 0 2.268.633 2.813 1.64a3.264 3.264 0 013.198.75zm-8.567 8.779a.474.474 0 00.531.05l1.906-1.026a.26.26 0 01.246 0l1.911 1.032c.17.094.384.074.531-.05a.429.429 0 00.122-.5l-.774-1.705a.233.233 0 01.054-.267l1.4-1.319a.4.4 0 00.113-.46.43.43 0 00-.413-.258h-1.591a.25.25 0 01-.226-.138L12.424 4.8a.466.466 0 00-.412-.242.466.466 0 00-.412.242l-.836 1.723a.251.251 0 01-.227.138H8.946a.429.429 0 00-.413.258.4.4 0 00.113.46l1.4 1.32c.074.07.095.175.054.266l-.779 1.704a.429.429 0 00.122.5z"
      fill="currentColor"
    />
  </Icon>
)

RibbonOne.displayName = 'RibbonOneIcon'

export default RibbonOne
