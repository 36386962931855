import { Icon } from '@chakra-ui/icon'

const MoneyBagDiamond = props => (
  <Icon {...props}>
    <path
      d="M13.311 2.14a1.39 1.39 0 00-.018-1.43C13.023.27 12.532 0 12 0H6.4c-.538 0-1.035.278-1.302.727a1.39 1.39 0 00.012 1.445l1.673 2.414c.093.134.25.214.417.214h4c.167 0 .323-.08.416-.214l1.695-2.446zM6.824 16.626a2.845 2.845 0 01-.258-3.711C6.615 12.849 9.9 9.239 9.9 9.239a3.065 3.065 0 012.345-1.079H13.7a.25.25 0 00.234-.155.234.234 0 00-.069-.265 8.596 8.596 0 00-2.311-1.425 1.036 1.036 0 00-.388-.075H6.837c-.135 0-.269.026-.393.077C3.3 7.604 0 11.749 0 16.32c0 4.27 3.28 6.72 9 6.72 1.086.009 2.17-.095 3.232-.31a.245.245 0 00.185-.163.232.232 0 00-.056-.235l-5.537-5.706z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.569 15.655c.517-.52.576-1.32.142-1.906-.02-.026-3.229-3.54-3.229-3.54a1.536 1.536 0 00-1.227-.609h-7.011a1.486 1.486 0 00-1.167.537l-3.289 3.612a1.453 1.453 0 00.132 1.899l6.756 6.961c.282.278.67.434 1.073.434.405 0 .792-.156 1.074-.434l.018-.02 6.728-6.934zm-11.176-4.053a.256.256 0 01.188-.082h6.331c.072 0 .14.03.188.082l1.752 1.92a.233.233 0 01.038.257.252.252 0 01-.227.141h-9.83a.252.252 0 01-.227-.141.233.233 0 01.038-.257l1.749-1.92zm3.356 9.182c.07 0 .136-.028.183-.077l4.332-4.464a.232.232 0 00.047-.26.25.25 0 00-.23-.143h-8.663a.25.25 0 00-.229.144.233.233 0 00.046.26l4.332 4.463a.253.253 0 00.182.077z"
      fill="currentColor"
    />
  </Icon>
)

MoneyBagDiamond.displayName = 'MoneyBagDiamondIcon'

export default MoneyBagDiamond
