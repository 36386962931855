import { Icon } from '@chakra-ui/icon'

const DirectionPadAlternate = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5 6.5H21a3 3 0 013 3v5a3 3 0 01-3 3h-3.5V21a3 3 0 01-3 3h-5a3 3 0 01-3-3v-3.5H3a3 3 0 01-3-3v-5a3 3 0 013-3h3.5V3a3 3 0 013-3h5a3 3 0 013 3v3.5zm3.5 9a1 1 0 001-1v-5a1 1 0 00-1-1h-4.5a1 1 0 01-1-1V3a1 1 0 00-1-1h-5a1 1 0 00-1 1v4.5a1 1 0 01-1 1H3a1 1 0 00-1 1v5a1 1 0 001 1h4.5a1 1 0 011 1V21a1 1 0 001 1h5a1 1 0 001-1v-4.5a1 1 0 011-1H21z"
      fill="currentColor"
    />
    <path
      d="M13 4.084a1.3 1.3 0 00-2 0L9.9 5.55a.75.75 0 00.6 1.2h3a.75.75 0 00.6-1.2L13 4.084zM13.5 17.25h-3a.75.75 0 00-.6 1.2l1.1 1.466a1.25 1.25 0 002 0l1.1-1.467a.75.75 0 00-.6-1.2v.001zM6.335 9.829a.748.748 0 00-.785.071L4.083 11a1.25 1.25 0 000 2l1.467 1.1a.75.75 0 001.2-.6v-3a.751.751 0 00-.415-.671zM19.917 11L18.45 9.9a.75.75 0 00-1.2.6v3a.75.75 0 001.2.6l1.467-1.1a1.25 1.25 0 000-2z"
      fill="currentColor"
    />
  </Icon>
)

DirectionPadAlternate.displayName = 'DirectionPadAlternateIcon'

export default DirectionPadAlternate
