import { Icon } from '@chakra-ui/icon'

const CheckboxOutline = props => (
  <Icon {...props}>
    <path
      d="M18.741 6.243a1.25 1.25 0 00-1.748.265l-6.228 8.453-3.985-3.188a1.25 1.25 0 10-1.561 1.952l5 4c.222.177.498.272.781.269.395.001.768-.184 1.006-.5l7-9.5a1.25 1.25 0 00-.265-1.751z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 0h20a2 2 0 012 2v20a2 2 0 01-2 2H2a2 2 0 01-2-2V2a2 2 0 012-2zm19.75 22a.25.25 0 00.25-.25V2.25a.25.25 0 00-.25-.25H2.25a.249.249 0 00-.25.249V21.75a.25.25 0 00.25.25h19.5z"
      fill="currentColor"
    />
  </Icon>
)

CheckboxOutline.displayName = 'CheckboxOutlineIcon'

export default CheckboxOutline
