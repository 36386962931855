export const loadTooltipIO = () => {
  const params = {
    url: 'https://cdn.tooltip.io/static/player.js',
    key: 'c425f3d2-c6bf-49ee-93fd-dd1bfa88181f',
    async: true,
  }

  window.Tooltip = {
    cs: [],
    _apiKey: params.key,
    API: {},
  }

  const applyFunction = methodName =>
    function () {
      const args = Array.prototype.slice.call(arguments)
      window.Tooltip.cs.push({ method: methodName, args: args })
    }

  const addFunctions = () => {
    ;[
      'identify',
      'goal',
      'updateUserData',
      'start',
      'stop',
      'refresh',
      'show',
      'hide',
      'on',
    ].forEach(methodName => {
      window.Tooltip.API = {
        ...window.Tooltip.API,
        [methodName]: applyFunction(methodName),
      }
    })
  }

  const addScript = () => {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.id = 'tooltip'
    script.async = params.async
    script.src = params.url
    document.head.appendChild(script)
  }

  addFunctions()
  addScript()
}
