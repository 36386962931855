import { Icon } from '@chakra-ui/icon'

const Compass = props => (
  <Icon {...props}>
    <path
      d="M12 13.75a1.75 1.75 0 100-3.5 1.75 1.75 0 000 3.5z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 12C0 5.373 5.373 0 12 0c6.624.007 11.993 5.376 12 12 0 6.627-5.373 12-12 12S0 18.627 0 12zM12 1.5a.75.75 0 00-.75.75v1.5a.75.75 0 001.5 0v-1.5A.75.75 0 0012 1.5zM3.75 12.75h-1.5a.75.75 0 010-1.5h1.5a.75.75 0 010 1.5zm.825-8.175a.75.75 0 000 1.061L5.636 6.7A.752.752 0 006.7 5.636L5.636 4.575a.75.75 0 00-1.061 0zM12.75 21.75a.75.75 0 01-1.5 0v-1.5a.75.75 0 011.5 0v1.5zm-5.5-3.693l6.679-2.782a2.484 2.484 0 001.346-1.346l2.783-6.679A1 1 0 0016.8 5.925L9.91 8.4A2.493 2.493 0 008.4 9.909L5.925 16.8a1 1 0 001.325 1.257zm12.179 1.368a.75.75 0 01-1.061 0L17.3 18.364a.75.75 0 011.06-1.06l1.061 1.06a.75.75 0 01.004 1.061h.004zm.821-6.675h1.5a.75.75 0 000-1.5h-1.5a.75.75 0 000 1.5z"
      fill="currentColor"
    />
  </Icon>
)

Compass.displayName = 'CompassIcon'

export default Compass
