import { User } from 'types'
import { arcadeApiClient } from '../apiClients'
import { AuthContext } from '../providers'
import { useContext } from 'react'
import useSWR from 'swr'

const fetcher = async (url: string): Promise<{ user: User }> =>
  await arcadeApiClient.get(url).then(res => res.data)

export const useUser = () => {
  const { auth } = useContext(AuthContext)
  const id = auth.id.toString()
  const { data, mutate } = useSWR(`/multi_platform/users/${id}`, fetcher, {
    isPaused: () => !id,
  })

  return { user: data?.user, refetch: mutate }
}
