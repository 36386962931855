import { Icon } from '@chakra-ui/icon'

const ThumbsUp = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.41 9.587a3 3 0 01.459 2.665c-.004.013-.008.026-.004.04l-1.9 6.357c-1.035 3.345-2.9 4.958-5.7 4.958-.424 0-1.286.007-1.995.013l-1.059.007c-3.469 0-4.59-.305-9.711-2.881v.627a1.5 1.5 0 01-1.5 1.5H.5a.5.5 0 01-.5-.5v-14a.5.5 0 01.5-.5H2a1.5 1.5 0 011.409 1h2.055a1 1 0 00.818-.424l4.893-6.928a2.712 2.712 0 014.839 2.253l-.881 3.343a1 1 0 00.967 1.256H21a3 3 0 012.41 1.214zm-1.459 2.095l.009-.028h-.001A1 1 0 0021 10.373h-4.9a3 3 0 01-2.9-3.766l.878-3.346a.712.712 0 00-.135-.627.739.739 0 00-1.138.039L7.916 9.6a3.006 3.006 0 01-2.452 1.273H4a.5.5 0 00-.5.5V18.2a.5.5 0 00.275.441c5.964 3.021 6.376 3.021 10.551 2.975h.065c.556-.006 1.175-.013 1.875-.013 1.886 0 3.016-1.052 3.776-3.521l1.909-6.4z"
      fill="currentColor"
    />
  </Icon>
)

ThumbsUp.displayName = 'ThumbsUpIcon'

export default ThumbsUp
