import { Icon } from '@chakra-ui/icon'

const InboxOutgoing = props => (
  <Icon {...props}>
    <path
      d="M23.884 15.276l-2.966-5.5A1.914 1.914 0 0019.24 8.75H17a1 1 0 100 2h2.009a.25.25 0 01.22.131l2.169 4a.25.25 0 01-.22.369H17.5a1.5 1.5 0 00-1.5 1.5 1.5 1.5 0 01-1.5 1.5h-5a1.5 1.5 0 01-1.5-1.5 1.5 1.5 0 00-1.5-1.5H2.8a.25.25 0 01-.221-.366l2.084-4a.25.25 0 01.221-.134H6.5a1 1 0 100-2H4.736c-.704.006-1.348.4-1.674 1.025l-2.94 5.5c-.07.15-.11.31-.122.475v6a1.5 1.5 0 001.5 1.5h21a1.5 1.5 0 001.5-1.5v-6a1.208 1.208 0 00-.116-.474z"
      fill="currentColor"
    />
    <path
      d="M15.353 4.716L12.6 1.05a.75.75 0 00-1.2 0L8.654 4.716a.75.75 0 00.6 1.2h1a.249.249 0 01.25.25v3.75a1.5 1.5 0 103 0V6.167a.249.249 0 01.25-.25h1a.751.751 0 00.6-1.2l-.001-.001z"
      fill="currentColor"
    />
  </Icon>
)

InboxOutgoing.displayName = 'InboxOutgoingIcon'

export default InboxOutgoing
