import { Icon } from '@chakra-ui/icon'

const CrownCircle = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 0c6.627 0 12 5.373 12 12a12 12 0 01-12 12C5.373 24 0 18.627 0 12S5.373 0 12 0zM5.5 14.5a1 1 0 001 1H18a1 1 0 001-1V9.1a.5.5 0 00-.829-.374l-2.791 2.441a.5.5 0 01-.709-.051L12.38 8.443a.5.5 0 00-.76 0L9.351 11.09a.5.5 0 01-.733.028L6.354 8.854a.5.5 0 00-.854.353V14.5z"
      fill="currentColor"
    />
  </Icon>
)

CrownCircle.displayName = 'CrownCircleIcon'

export default CrownCircle
