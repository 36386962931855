import { Icon } from '@chakra-ui/icon'

const PartyDecorations = props => (
  <Icon {...props}>
    <path
      d="M5.04 11.52a.72.72 0 00-.72.72v.24a.48.48 0 01-.48.48H3.6a.72.72 0 100 1.44h.24a.48.48 0 01.48.48v.24a.72.72 0 001.44 0v-.24a.48.48 0 01.48-.48h.24a.72.72 0 000-1.44h-.24a.48.48 0 01-.48-.48v-.24a.72.72 0 00-.72-.72zM20.88 20.16h-.24a.48.48 0 01-.48-.48v-.24a.72.72 0 10-1.44 0v.24a.48.48 0 01-.48.48H18a.72.72 0 100 1.44h.24a.48.48 0 01.48.48v.24a.72.72 0 001.44 0v-.24a.48.48 0 01.48-.48h.24a.72.72 0 000-1.44zM14.64 4.32h.72a.48.48 0 01.48.48v.72a.72.72 0 001.44 0V4.8a.48.48 0 01.48-.48h.72a.72.72 0 000-1.44h-.72a.48.48 0 01-.48-.48v-.72a.72.72 0 00-1.44 0v.72a.48.48 0 01-.48.48h-.72a.72.72 0 000 1.44z"
      fill="currentColor"
    />
    <path
      d="M18.144 16.01l3.456-3.456a.683.683 0 01.48-.2.96.96 0 00.136-1.91C10.636 8.777 1.67 1.264 1.582 1.187A.96.96 0 00.34 2.651c.104.089.85.719 2.132 1.624a.96.96 0 01.409.784v4.608a.96.96 0 001.49.797l3.694-2.466a.523.523 0 01.53-.03.48.48 0 01.255.335l1.072 5.36c.065.34.31.618.64.723a.966.966 0 00.947-.2l3.63-3.3a.31.31 0 01.305-.065.27.27 0 01.183.205l.899 4.494a.96.96 0 001.619.49z"
      fill="currentColor"
    />
    <path
      d="M14.112 15.234a41.008 41.008 0 01-13.056 2.283c-.03 0-.065-.014-.091 0a.96.96 0 100 1.92c.075 0 .147.035.192.096l3.028 4.083a.96.96 0 001.673-.253l1.534-4.297a.316.316 0 01.243-.204.27.27 0 01.255.096l3.13 3.812a.951.951 0 00.906.336.96.96 0 00.74-.623l1.874-5.246a.299.299 0 01.192-.184.96.96 0 00-.613-1.819h-.007zM20.64 8.64a.96.96 0 100-1.92.96.96 0 000 1.92zM22.08 18.24a.96.96 0 100-1.92.96.96 0 000 1.92z"
      fill="currentColor"
    />
  </Icon>
)

PartyDecorations.displayName = 'PartyDecorationsIcon'

export default PartyDecorations
