import { Icon } from '@chakra-ui/icon'

const SettingsCogOutline = props => (
  <Icon viewBox="0 0 16 16" {...props}>
    <g fill="none">
      <path
        d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"
        stroke="currentcolor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M13.3333 8C13.3325 7.61594 13.2905 7.23308 13.208 6.858L15.0173 5.488L13.684 3.17866L11.5887 4.06266C11.0212 3.54246 10.3478 3.15148 9.61467 2.91666L9.33333 0.666664H6.66667L6.38533 2.91666C5.65223 3.15148 4.97876 3.54246 4.41133 4.06266L2.316 3.17866L0.982666 5.488L2.792 6.858C2.62493 7.61016 2.62493 8.38983 2.792 9.142L0.982666 10.512L2.316 12.8213L4.41133 11.9373C4.97876 12.4575 5.65223 12.8485 6.38533 13.0833L6.66667 15.3333H9.33333L9.61467 13.0833C10.3478 12.8485 11.0212 12.4575 11.5887 11.9373L13.684 12.8213L15.0173 10.512L13.208 9.142C13.2905 8.76691 13.3325 8.38405 13.3333 8V8Z"
        stroke="currentcolor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </g>
  </Icon>
)

SettingsCogOutline.displayName = 'SettingsCogOutlineIcon'

export default SettingsCogOutline
