import { Icon } from '@chakra-ui/icon'

const HTML = props => (
  <Icon {...props}>
    <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
      <path d="m966.9 482.6h-70.9v-146.6c0-44-25.5-105.5-56.6-136.6l-142.8-142.8c-31.1-31.1-92.6-56.6-136.6-56.6h-480c-44 0-80 36-80 80v864c0 44 36 80 80 80h736c44 0 80-36 80-80v-80.5h70.9c31.4 0 57.1-25.7 57.1-57.1v-266.7c0-31.4-25.7-57.1-57.1-57.1zm-326.8-390c4.4 3.1 8.2 6.2 11.3 9.3l142.8 142.8c3.1 3.1 6.2 6.9 9.3 11.3h-163.4zm191.9 390h-601.8c-31.4 0-57.1 25.7-57.1 57.1v266.8c0 31.4 25.7 57.1 57.1 57.1h601.8v80.4c0 8.7-7.3 16-16 16h-736c-8.7 0-16-7.3-16-16v-864c0-8.7 7.3-16 16-16h480c4.8 0 10.2.6 16 1.7v254.3h254.3c1.1 5.8 1.7 11.2 1.7 16zm-53.6 122.1-49.7 151h-37.4l-46.4-151.2h-1.2c1.7 30.8 2.5 51.3 2.5 61.6v89.6h-36.5v-192.7h55.6l45.6 147.4h.8l48.4-147.4h55.6v192.7h-38.1v-91.2c0-4.3.1-9.3.2-14.9s.7-20.6 1.8-44.8h-1.2zm-351.5-7.7v-34h145.5v34h-52.3v158.7h-40.9v-158.7zm-154.5 75.5v83.2h-40.9v-192.7h40.9v75.5h76.3v-75.5h40.7v192.7h-40.7v-83.2zm718.4 83.2h-119v-192.7h40.9v159h78.2v33.7z" />
    </svg>
  </Icon>
)

HTML.displayName = 'HTMLIcon'

export default HTML
