import { Icon } from '@chakra-ui/icon'

const Printer = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.216 5.944l1.751 4.389c.02.073.03.149.033.225l-.894 7.128A1.5 1.5 0 0120.618 19h-1.824a.25.25 0 00-.243.314l.914 3.428A1 1 0 0118.5 24H5a1 1 0 01-.965-1.258l.914-3.428A.249.249 0 004.707 19H3.383A1.5 1.5 0 011.9 17.687l-.896-7.124a.724.724 0 01.03-.23l1.752-4.39A1.488 1.488 0 014.177 5H6.25a.25.25 0 00.25-.25V1a1 1 0 011-1h7a1 1 0 01.681.268l2 1.858a1 1 0 01.32.732V4.75a.249.249 0 00.25.25h2.073a1.49 1.49 0 011.392.944zM8.751 7h6.5a.25.25 0 00.25-.249V3.402a.252.252 0 00-.08-.184L14.18 2.066A.253.253 0 0014.01 2H8.751a.249.249 0 00-.25.25v4.5a.249.249 0 00.25.25zm11.75 6a1 1 0 11-1.998 0 1 1 0 011.997 0h.001zm-12.54 3.501a.249.249 0 00-.241.185l-1.334 5a.25.25 0 00.242.315h10.245a.25.25 0 00.242-.315l-1.334-5a.249.249 0 00-.241-.185H7.961z"
      fill="currentColor"
    />
  </Icon>
)

Printer.displayName = 'PrinterIcon'

export default Printer
