import { Icon } from '@chakra-ui/icon'

const CalendarAlternative = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 2.5a2 2 0 012 2V22a2 2 0 01-2 2H3a2 2 0 01-2-2V4.5a2 2 0 012-2h1.75A.25.25 0 005 2.25V1a1 1 0 012 0v1.25c0 .138.112.25.25.25h3.5a.25.25 0 00.25-.25V1a1 1 0 112 0v1.25c0 .138.112.25.25.25h3.5a.25.25 0 00.25-.25V1a1 1 0 112 0v1.25c0 .138.112.25.25.25H21zM20.75 22a.25.25 0 00.25-.25l-.004-17a.25.25 0 00-.25-.25h-1.5a.249.249 0 00-.249.249V5.5a1 1 0 11-2 0v-.75a.25.25 0 00-.25-.25h-3.5a.248.248 0 00-.248.248V5.5a1 1 0 11-2 0v-.75a.25.25 0 00-.25-.25h-3.5A.249.249 0 007 4.749V5.5a1 1 0 01-2 0v-.75a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25v17c0 .138.112.25.25.25h17.5z"
      fill="currentColor"
    />
  </Icon>
)

CalendarAlternative.displayName = 'CalendarAlternativeIcon'

export default CalendarAlternative
