import { Icon } from '@chakra-ui/icon'

const RockOn = props => (
  <Icon {...props}>
    <path
      d="M19.081 13.673A3.543 3.543 0 0017 13h-4.5a1.5 1.5 0 000 3H16a.5.5 0 010 1 3.5 3.5 0 00-3.5 3.5.5.5 0 01-1 0c0-1.145.436-2.246 1.221-3.079a.25.25 0 00-.182-.421H12.5a2.5 2.5 0 01-2.3-1.526c-.066 0-.132.025-.2.025A2.5 2.5 0 017.5 13v-3a.5.5 0 011 0v3a1.492 1.492 0 001.22 1.47.252.252 0 00.295-.221 2.5 2.5 0 011.351-1.972.252.252 0 00.136-.223V10a.5.5 0 011 0v1.749c0 .138.112.25.25.25h2a.25.25 0 00.25-.25V9.938a.5.5 0 011 0v1.811c0 .138.112.25.25.25H17c1.141 0 2.24.428 3.081 1.2a.25.25 0 00.419-.184V2.5a2.5 2.5 0 00-5 0v5.012a2.466 2.466 0 00-3.5.5 2.466 2.466 0 00-3.5-.5V4.5a2.5 2.5 0 00-5 0v11A8.509 8.509 0 0012 24c4.933 0 8.472-4.173 8.472-7.822a3.2 3.2 0 00-1.391-2.505z"
      fill="currentColor"
    />
  </Icon>
)

RockOn.displayName = 'RockOnIcon'

export default RockOn
