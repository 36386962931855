import { Icon } from '@chakra-ui/icon'

const Directory = props => (
  <Icon viewBox="0 0 16 16" {...props}>
    <g fill="none">
      <path
        d="M1.33325 4.66666L7.99992 0.666656L14.6666 4.66666L7.99992 8.66666L1.33325 4.66666Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M14.6666 8L7.99992 12L1.33325 8"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M14.6666 11.3333L7.99992 15.3333L1.33325 11.3333"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </g>
  </Icon>
)

Directory.displayName = 'DirectoryIcon'

export default Directory
