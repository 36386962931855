import { Icon } from '@chakra-ui/icon'

const MilitaryRankStar = props => (
  <Icon {...props}>
    <path
      d="M22 3.071a.5.5 0 00.5-.5v-.5a2 2 0 00-2-2h-17a2 2 0 00-2 2v.5a.5.5 0 00.5.5h20z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 5.071a.5.5 0 01.5-.5h20a.5.5 0 01.5.5v2.265a18.811 18.811 0 01-9.512 16.334 2.012 2.012 0 01-1.975 0A18.809 18.809 0 011.5 7.336V5.071zm5.54 4.75a.423.423 0 00-.286.737l2.311 2.06a.5.5 0 01.126.573l-1.352 3.111a.422.422 0 00.594.536l3.322-1.874a.5.5 0 01.491 0l3.322 1.871a.421.421 0 00.594-.535l-1.352-3.109a.5.5 0 01.126-.573l2.311-2.06a.422.422 0 00-.281-.737h-2.742a.5.5 0 01-.459-.3l-1.377-3.193a.422.422 0 00-.775 0l-1.377 3.193a.5.5 0 01-.459.3H7.04z"
      fill="currentColor"
    />
  </Icon>
)

MilitaryRankStar.displayName = 'MilitaryRankStarIcon'

export default MilitaryRankStar
