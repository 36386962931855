import { useState } from 'react'
import { formatRangeDate } from 'ui/helpers'

import type { ParamHookOutput } from './paramHookTypes'

export type DateRange = [startDate: Date, endDate: Date]
type DateRangeOutput = ParamHookOutput<DateRange>

export const useDateRange = (defaultRange?: DateRange): DateRangeOutput => {
  const today = new Date()
  const thirtyDaysAgo = new Date()
  thirtyDaysAgo.setDate(today.getDate() - 30)

  const defaultFrom = defaultRange?.[0] || thirtyDaysAgo
  const defaultTo = defaultRange?.[1] || today

  const [fromDate, setFromDate] = useState(defaultFrom)
  const [toDate, setToDate] = useState(defaultTo)
  const values: DateRange = [fromDate, toDate]

  const set = ([nextFrom, nextTo]: DateRange): void => {
    setFromDate(nextFrom)
    setToDate(nextTo)
  }

  const reset = (): void => {
    setFromDate(defaultFrom)
    setToDate(defaultTo)
  }

  const asParamString = toDate
    ? `from_date=${formatRangeDate(fromDate)}&to_date=${formatRangeDate(
        toDate,
      )}`
    : `from_date=${formatRangeDate(fromDate)}&to_date=${formatRangeDate(
        fromDate,
      )}`

  return { values, set, reset, asParamString }
}
