import { Icon } from '@chakra-ui/icon'

const CloverFour = props => (
  <Icon {...props}>
    <path
      d="M5.763 7.086l4.93.564h.056a.5.5 0 00.5-.55l-.5-4.982a2.347 2.347 0 00-.86-1.587A2.318 2.318 0 008.165.014a2.354 2.354 0 00-2.114 2.172c-.01.11-.1.196-.21.2a2.2 2.2 0 00-1.278.517 2.359 2.359 0 001.2 4.183zM18.238 7.086l-4.929.564h-.057a.5.5 0 01-.5-.55l.5-4.982a2.343 2.343 0 01.86-1.587 2.317 2.317 0 011.724-.517 2.354 2.354 0 012.113 2.172c.01.11.1.196.21.2a2.2 2.2 0 011.278.517c.489.387.802.953.869 1.573a2.358 2.358 0 01-2.068 2.61zM18.238 9.916l-4.929-.563a.5.5 0 00-.554.548l.5 4.98a2.358 2.358 0 002.58 2.106 2.356 2.356 0 002.113-2.159.252.252 0 01.227-.23c.46-.04.899-.214 1.262-.5.489-.386.802-.952.869-1.571a2.358 2.358 0 00-2.068-2.611zM11.1 9.495a.508.508 0 00-.407-.142l-4.93.563a2.358 2.358 0 00-2.069 2.605 2.318 2.318 0 002.135 2.074c.12.013.214.11.222.231a2.357 2.357 0 002.113 2.167c.08.007.161.01.242.007a2.358 2.358 0 002.338-2.12l.5-4.98a.5.5 0 00-.144-.405zM15.75 22a2.5 2.5 0 01-2.5-2.5V18a1 1 0 10-2 0v1.5a4.505 4.505 0 004.5 4.5 1 1 0 100-2z"
      fill="currentColor"
    />
  </Icon>
)

CloverFour.displayName = 'CloverFourIcon'

export default CloverFour
