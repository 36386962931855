import { Icon } from '@chakra-ui/icon'

const GameControllerPeople = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.5 9.5h15a4 4 0 014 4v8.878a1.627 1.627 0 01-1.64 1.62c-1.865-.004-3.86-4.516-3.86-4.516A3 3 0 0015.41 18H8.593A3.01 3.01 0 006 19.492S3.964 23.975 2.124 24A1.586 1.586 0 01.5 22.419V13.5a4 4 0 014-4zM6.75 15h.75a.75.75 0 000-1.5h-.75a.5.5 0 01-.5-.5v-.75a.75.75 0 00-1.5 0V13a.5.5 0 01-.5.5H3.5a.75.75 0 000 1.5h.75a.5.5 0 01.5.5v.75a.75.75 0 001.5 0v-.75a.5.5 0 01.5-.5zM16 15a1 1 0 110-2 1 1 0 010 2zm1.5 1a1 1 0 102 0 1 1 0 00-2 0zm1-3a1 1 0 110-2 1 1 0 010 2zm1.5 1a1 1 0 102 0 1 1 0 00-2 0z"
      fill="currentColor"
    />
    <path
      d="M3.5 4.402a2.199 2.199 0 100-4.398 2.199 2.199 0 000 4.398zM6.307 8a.5.5 0 00.453-.71 3.587 3.587 0 00-6.519 0A.5.5 0 00.7 8h5.607zM20.499 4.398a2.199 2.199 0 100-4.398 2.199 2.199 0 000 4.398zM23.759 7.285a3.588 3.588 0 00-6.519 0 .5.5 0 00.454.71h5.612a.5.5 0 00.453-.71zM12 4.398A2.199 2.199 0 1012 0a2.199 2.199 0 000 4.398zM9.194 8h5.612a.5.5 0 00.453-.71 3.588 3.588 0 00-6.519 0 .5.5 0 00.454.71z"
      fill="currentColor"
    />
  </Icon>
)

GameControllerPeople.displayName = 'GameControllerPeopleIcon'

export default GameControllerPeople
