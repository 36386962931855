import { createContext } from 'react'
import useSWR from 'swr'
import { arcadeApiClient } from '../apiClients'
import { userRoleIsAtLeast } from '../helpers/roles'
import { NewsfeedSettings } from '../types'

export const NewsfeedSettingsContext = createContext({
  canDeleteComments: false,
})

const fetcher = async (url: string): Promise<NewsfeedSettings> => {
  return await arcadeApiClient.get(url).then(res => {
    return res.data.newsfeedSettings
  })
}

export const NewsfeedSettingsProvider = ({
  children,
  userRole,
}: {
  children: React.ReactNode
  userRole: string
}) => {
  const { data: newsfeedSettings } = useSWR(
    '/multi_platform/newsfeed/settings',
    fetcher,
  )

  const canDeleteComments = userRoleIsAtLeast({
    requiredRole: newsfeedSettings?.commentModerationRole || 'champion',
    userRole,
  })

  return (
    <NewsfeedSettingsContext.Provider
      value={{
        canDeleteComments,
      }}
    >
      {children}
    </NewsfeedSettingsContext.Provider>
  )
}
