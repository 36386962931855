import { Icon } from '@chakra-ui/icon'

const FlagCheck = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.5 0h15c1.105 0 2 .86 2 1.92v19.584c.05.562-.251 1.1-.767 1.37s-1.15.223-1.615-.121L12.272 18.5a.517.517 0 00-.544 0l-6.845 4.252a1.636 1.636 0 01-1.614.12c-.504-.281-.8-.81-.769-1.368V1.92C2.5.86 3.395 0 4.5 0zm2.28 10.23l2.806 2.94c.362.397.894.613 1.444.585a1.811 1.811 0 001.37-.729l5.469-7.137a.935.935 0 00-.212-1.343 1.028 1.028 0 00-1.399.204l-5.342 6.968-2.662-2.784a1.025 1.025 0 00-.962-.307.986.986 0 00-.747.657.933.933 0 00.235.947z"
      fill="currentColor"
    />
  </Icon>
)

FlagCheck.displayName = 'FlagCheckIcon'

export default FlagCheck
