import { AxiosResponse } from 'axios'
import { camelizeKeys } from 'humps'
import { AUTH_STORAGE_KEY } from 'ui/types'
import { DateTime } from 'luxon'
import { get } from 'lodash'

export const ARCADE_API_ENDPOINT = process.env.ARCADE_API_ENDPOINT || ''
export const GENERIC_LOGIN_ERROR = 'Something went wrong'

export const b64toBlob = async (b64Data: string): Promise<Blob> => {
  const byteCharacters: Buffer = Buffer.from(b64Data, 'base64')
  const blob = new Blob([byteCharacters], { type: 'image/png' })
  return blob
}

export const normalizeAuth = async (result): Promise<AxiosResponse> => {
  result.data = camelizeKeys(result.data)
  result.data.firstName = result.data.firstname
  result.data.lastName = result.data.lastname
  result.data.fullName = result.data.fullname
  result.data.token = result.data.authenticationToken

  if (result.data.imageBlob) {
    const url = window.URL || window.webkitURL
    const blob = await b64toBlob(result.data.imageBlob)
    const blobUrl = blob ? url.createObjectURL(blob) : undefined
    result.data.imageUrl = blobUrl
    delete result.data.imageBlob
  }

  delete result.data.firstname
  delete result.data.lastname
  delete result.data.fullname
  delete result.data.authenticationToken
  return result
}

export const getErrorMsg = (type: string): string | undefined => {
  switch (type) {
    case 'company_not_registered':
      return 'Company not found, please register!'
    case 'company_already_exists':
      return 'Company already registered.'
    default:
      return GENERIC_LOGIN_ERROR
  }
}

export const getLinkLoginAuthObject = (remove = false) => {
  try {
    const url = new URL(location.toString())
    const payload = url.searchParams.get('payload')
    const decodedObject = JSON.parse(atob((payload as string) || ''))

    if (remove) {
      url.searchParams.delete('payload')
      history.pushState({}, '', url.toString())
    }

    return decodedObject
  } catch {
    return {}
  }
}

export const getTokenFromParams = (remove = false) => {
  const url = new URL(location.toString())
  const token = url.searchParams.get('token')

  if (remove) {
    url.searchParams.delete('token')
    history.pushState({}, '', url.toString())
  }

  return token
}

export const getStoredResetPasswordToken = () => {
  const { resetPasswordToken } = JSON.parse(
    localStorage.getItem(AUTH_STORAGE_KEY) || '{}',
  )

  const token = get(resetPasswordToken, 'token')
  const expiresAt = get(resetPasswordToken, 'expiresAt')

  if (!token || !expiresAt) {
    return null
  }

  if (DateTime.fromISO(expiresAt) < DateTime.now()) {
    removeStoredResetPasswordToken()
    return null
  }

  return token
}

export const removeStoredResetPasswordToken = () => {
  const currentAuth = JSON.parse(
    window.localStorage.getItem(AUTH_STORAGE_KEY) || '{}',
  )
  delete currentAuth.resetPasswordToken

  localStorage.setItem(AUTH_STORAGE_KEY, JSON.stringify(currentAuth))
}
