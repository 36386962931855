import { Icon } from '@chakra-ui/icon'

const FlagGolf = props => (
  <Icon {...props}>
    <path
      d="M18.59 6.677a.252.252 0 010-.354l2.616-2.616A1 1 0 0020.5 2h-9.25a.25.25 0 01-.25-.25V1a1 1 0 10-2 0v20a.75.75 0 01-1.5 0v-2.546a.251.251 0 00-.288-.247C4.629 18.6 2.5 19.554 2.5 21c0 1.948 3.865 3 7.5 3s7.5-1.052 7.5-3c0-1.728-3.041-2.751-6.266-2.96a.25.25 0 01-.234-.249V11.25a.25.25 0 01.25-.25h9.25a1 1 0 00.707-1.707L18.59 6.677z"
      fill="currentColor"
    />
  </Icon>
)

FlagGolf.displayName = 'FlagGolfIcon'

export default FlagGolf
