import { Icon } from '@chakra-ui/icon'

const Loading = props => (
  <Icon {...props}>
    <path
      d="M12 6.5A1.5 1.5 0 0013.5 5V2a1.5 1.5 0 00-3 0v3A1.5 1.5 0 0012 6.5zM8.271 6.106L6.149 3.985a1.54 1.54 0 00-2.121 0 1.5 1.5 0 000 2.121L6.15 8.227a1.5 1.5 0 002.121-2.121zM.5 12A1.5 1.5 0 002 13.5h3a1.5 1.5 0 000-3H2A1.5 1.5 0 00.5 12zM6.957 15.524a1.488 1.488 0 00-1.06.438l-2.122 2.122A1.5 1.5 0 005.9 20.2l2.122-2.122a1.5 1.5 0 00-1.061-2.559l-.004.005zM10.5 22a1.5 1.5 0 003 0v-3a1.5 1.5 0 00-3 0v3zM16.049 16.006a1.5 1.5 0 000 2.121l2.122 2.121a1.503 1.503 0 102.129-2.123L18.173 16a1.5 1.5 0 00-2.124.006zM23.5 12a1.5 1.5 0 00-1.5-1.5h-3a1.5 1.5 0 000 3h3a1.5 1.5 0 001.5-1.5zM19.044 3.41a1.488 1.488 0 00-1.06.438l-2.12 2.12a1.5 1.5 0 102.121 2.123l2.121-2.12a1.5 1.5 0 00-1.062-2.561z"
      fill="currentColor"
    />
  </Icon>
)

Loading.displayName = 'LoadingIcon'

export default Loading
