import { Icon } from '@chakra-ui/icon'

const GameControllerTwo = props => (
  <Icon {...props}>
    <path
      d="M5.76 3.75h2.88c1.06 0 1.92.895 1.92 2v4.5c0 .552.43 1 .96 1s.96-.448.96-1v-4.5c0-2.21-1.72-4-3.84-4H5.76c-.53 0-.96.448-.96 1s.43 1 .96 1z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.268 12.625a5.15 5.15 0 013.492-1.375c2.349 0 4.416 1.616 5.071 3.965.656 2.35-.258 4.868-2.241 6.179-1.984 1.31-4.56 1.1-6.322-.519a.47.47 0 00-.316-.125H9.09a.469.469 0 00-.318.125c-2.122 1.95-5.344 1.81-7.303-.318-1.959-2.128-1.959-5.486 0-7.614 1.96-2.128 5.18-2.268 7.303-.318.088.08.2.125.317.125h4.862a.47.47 0 00.317-.125zM6.24 17.25h.72c.398 0 .72-.336.72-.75s-.322-.75-.72-.75h-.72a.49.49 0 01-.48-.5v-.75c0-.414-.322-.75-.72-.75-.398 0-.72.336-.72.75v.75a.49.49 0 01-.48.5h-.72c-.398 0-.72.336-.72.75s.322.75.72.75h.72a.49.49 0 01.48.5v.75c0 .414.322.75.72.75.398 0 .72-.336.72-.75v-.75c0-.276.215-.5.48-.5zm9.12.5c-.53 0-.96-.448-.96-1s.43-1 .96-1 .96.448.96 1-.43 1-.96 1zm1.44 1.5c0 .552.43 1 .96 1s.96-.448.96-1-.43-1-.96-1-.96.448-.96 1zm.96-4c-.53 0-.96-.448-.96-1s.43-1 .96-1 .96.448.96 1-.43 1-.96 1zm1.44 1.5c0 .552.43 1 .96 1s.96-.448.96-1-.43-1-.96-1-.96.448-.96 1z"
      fill="currentColor"
    />
  </Icon>
)

GameControllerTwo.displayName = 'GameControllerTwoIcon'

export default GameControllerTwo
