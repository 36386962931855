import { createContext, useState } from 'react'
import { ActionMeta } from 'react-select'
import { useNewsfeedTeams } from 'hooks'
import { Team } from 'types'

export interface AdditionalFeed {
  label: string
  value: string
}

type OnAdditionalFeedSelect = (
  value: AdditionalFeed[],
  action?: ActionMeta<AdditionalFeed>,
) => void

interface NewsfeedContextProps {
  additionalFeeds: readonly AdditionalFeed[]
  currentTeam: Team | undefined
  feedType: string
  postableTeams: any[]
  setAdditionalFeeds: OnAdditionalFeedSelect
  sortedTeams: Team[]
  teamId: number | undefined
  teams: Team[]
}

export const NewsfeedContext = createContext({} as NewsfeedContextProps)

export const NewsfeedProvider = ({ children, feedType, teamId }) => {
  /* When a user is posting to a team feed we allow them to also select additional team feeds to post to */
  const [additionalFeeds, setAdditionalFeeds] = useState<
    readonly AdditionalFeed[]
  >([])

  const handleAdditionalFeedSelected: OnAdditionalFeedSelect = args => {
    setAdditionalFeeds(args)
  }

  const { teams } = useNewsfeedTeams()

  const currentTeam = teams?.find(t => t.id == teamId)
  const sortedTeams = teams?.sort((a, b) => (a.name > b.name ? 1 : -1))
  const postableTeams =
    !!teams &&
    teams
      .filter(team => team.id != teamId)
      .map(team => ({ label: team.name, value: team.id.toString() }))

  return (
    <NewsfeedContext.Provider
      value={{
        additionalFeeds,
        currentTeam,
        feedType,
        teamId,
        teams,
        postableTeams,
        setAdditionalFeeds: handleAdditionalFeedSelected,
        sortedTeams,
      }}
    >
      {children}
    </NewsfeedContext.Provider>
  )
}
