import { AxiosError, AxiosResponse } from 'axios'
import { Fetcher } from 'swr'

import { snakeCase } from '../../utils/string'
import { arcadeApiClient } from '../../apiClients'

export type FetchMethods = 'GET' | 'PUT' | 'POST' | 'PATCH' | 'DELETE'
export type QueryParams = Record<string, string | number>
export type DataNormalizer<InputData, OutputData> = (
  res: AxiosResponse<InputData>,
) => OutputData
interface FetcherProps<InputData> {
  onLoad: () => void
  onSucceed: (response: AxiosResponse<InputData>) => void
  onFail: (error: AxiosError<InputData>) => void
  method?: FetchMethods
  params?: QueryParams
}

export const makeParams = (params: QueryParams): QueryParams =>
  Object.entries(params).reduce((acc, [key, value]) => {
    acc[snakeCase(key)] = value
    return acc
  }, {})

export const makeFetcher =
  <InputData, OutputData>({
    onLoad,
    onSucceed,
    onFail,
    method = 'GET',
    params = {},
  }: FetcherProps<InputData>): Fetcher<OutputData> =>
  async (url: string): Promise<OutputData> => {
    onLoad()
    return await arcadeApiClient({ url, method, params: makeParams(params) })
      .then(response => {
        onSucceed(response)
        return response.data
      })
      .catch(onFail)
  }
