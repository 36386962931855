import { Icon } from '@chakra-ui/icon'

const RightPagination = props => (
  <Icon viewBox="0 0 6 10" {...props}>
    <path d="M0 10V0L6 5L0 10Z" fill="currentColor" />
  </Icon>
)

RightPagination.displayName = 'RightPaginationIcon'

export default RightPagination
