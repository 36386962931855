import { Icon } from '@chakra-ui/icon'

const Files = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.414 4L18 .589A2.01 2.01 0 0016.586 0H7a2 2 0 00-2 2v1H4a2 2 0 00-2 2v17a2 2 0 002 2h13a2 2 0 002-2v-1h1a2 2 0 002-2V5.414A1.99 1.99 0 0021.414 4zM17 21.5a.5.5 0 01-.5.5h-12a.5.5 0 01-.5-.5v-16a.5.5 0 011 0V19a2 2 0 002 2h9.5a.5.5 0 01.5.5zm2.5-2.5a.5.5 0 00.5-.5V5.621a.5.5 0 00-.146-.353l-3.122-3.122A.5.5 0 0016.379 2H7.5a.5.5 0 00-.5.5v16a.5.5 0 00.5.5h12z"
      fill="currentColor"
    />
  </Icon>
)

Files.displayName = 'FilesIcon'

export default Files
