import { Icon } from '@chakra-ui/icon'

const LocationFound = props => (
  <Icon {...props}>
    <path d="M12 16a4 4 0 100-8 4 4 0 000 8z" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.377 11H23a1 1 0 110 2h-1.623a.5.5 0 00-.493.421 9.017 9.017 0 01-7.463 7.463.5.5 0 00-.421.493V23a1 1 0 11-2 0v-1.623a.5.5 0 00-.421-.493 9.017 9.017 0 01-7.463-7.463.5.5 0 00-.493-.421H1a1 1 0 110-2h1.623a.5.5 0 00.493-.421 9.017 9.017 0 017.463-7.463.5.5 0 00.421-.493V1a1 1 0 112 0v1.623a.5.5 0 00.421.493 9.017 9.017 0 017.463 7.463.5.5 0 00.493.421zM5 12a7 7 0 007 7 7.008 7.008 0 007-7 7 7 0 10-14 0z"
      fill="currentColor"
    />
  </Icon>
)

LocationFound.displayName = 'LocationFoundIcon'

export default LocationFound
