import { Icon } from '@chakra-ui/icon'

const SearchCircle = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM11.2941 16.2353C8.56941 16.2353 6.35294 14.0188 6.35294 11.2941C6.35294 8.56941 8.56941 6.35294 11.2941 6.35294C14.0188 6.35294 16.2353 8.56941 16.2353 11.2941C16.2353 14.0188 14.0188 16.2353 11.2941 16.2353ZM11.2941 7.7647C9.34799 7.7647 7.7647 9.34799 7.7647 11.2941C7.7647 13.2402 9.34799 14.8235 11.2941 14.8235C13.2402 14.8235 14.8235 13.2402 14.8235 11.2941C14.8235 9.34799 13.2402 7.7647 11.2941 7.7647ZM15.7511 14.7529L17.4402 16.4421C17.7162 16.7181 17.7162 17.1642 17.4402 17.4402C17.3026 17.5779 17.1219 17.6471 16.9412 17.6471C16.7605 17.6471 16.5798 17.5779 16.4421 17.4402L14.7529 15.7511C15.1264 15.4609 15.4609 15.1264 15.7511 14.7529Z"
      fill="currentColor"
    />
  </Icon>
)

SearchCircle.displayName = 'SearchCircleIcon'

export default SearchCircle
