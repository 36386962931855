import { useCallback, useContext, useState } from 'react'
import { debounce } from 'lodash'
import axios from 'axios'
import { AppContext } from '../providers'

const LIMIT = 25

export function useGIPHY() {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [results, setResults] = useState<string[]>([])

  const [hasNoResults, setHasNoResults] = useState<boolean>(false)
  const [canShuffle, setCanShuffle] = useState<boolean>(false)
  const [nextPageOffset, setNextPageOffset] = useState<number>()

  const { setToast } = useContext(AppContext)

  const updatePaginationInfo = info => {
    if (!info || info.total_count === 0) {
      setCanShuffle(false)
      setNextPageOffset(0)
      setHasNoResults(true)
      return
    }

    const { count, offset, total_count } = info
    setCanShuffle(total_count - count - offset > LIMIT)
    setNextPageOffset(count + offset)
    setHasNoResults(false)
  }

  const search = useCallback(
    debounce((q: string, options: { offset?: number } = {}) => {
      setIsLoading(true)
      setHasNoResults(false)

      const params = {
        api_key: process.env.GIPHY_API_KEY,
        lang: 'en',
        limit: LIMIT,
        offset: 0,
        q,
        rating: 'pg',
        ...options,
      }

      axios
        .get('https://api.giphy.com/v1/gifs/search', {
          params,
        })
        .then(res => {
          const { data, pagination } = res.data
          setResults(data.map(result => result.images.fixed_height.url))
          updatePaginationInfo(pagination)
        })
        .catch(e => {
          setToast({
            status: 'error',
            description: 'Could not connect to GIPHY',
          })
          console.error(e)
        })
        .finally(() => setIsLoading(false))
    }, 500),
    [],
  )

  const shuffle = useCallback(
    (q: string) => search(q, { offset: nextPageOffset }),
    [nextPageOffset],
  )

  return { canShuffle, isLoading, hasNoResults, results, search, shuffle }
}
