import { Icon } from '@chakra-ui/icon'

const Beaker = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.5 19.345l-4.362-7.383a1 1 0 01-.138-.509V2.5a.5.5 0 01.5-.5H18a1 1 0 100-2H6a1 1 0 000 2h1.5a.5.5 0 01.5.5v8.954a1 1 0 01-.139.508l-4.323 7.319A3 3 0 005.99 24h12.016a3 3 0 002.494-4.655zM8.673 16a.5.5 0 01-.43-.754l1.617-2.737c.09-.154.14-.33.14-.509V2.5a.5.5 0 01.5-.5h3a.5.5 0 01.5.5v2.75a.5.5 0 01-.5.5h-1.32a.75.75 0 000 1.5h1.32a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-1.32a.75.75 0 000 1.5h1.32a.5.5 0 01.5.5V12a1 1 0 00.139.509l1.618 2.737a.5.5 0 01-.43.754H8.673zm-.687 4.322a1.5 1.5 0 103 0 1.5 1.5 0 00-3 0zm5.719-1.517a1 1 0 110-2 1 1 0 010 2zm1.5 2.082a1 1 0 001 1H16.2a1 1 0 10-.995-1z"
      fill="currentColor"
    />
  </Icon>
)

Beaker.displayName = 'BeakerIcon'

export default Beaker
