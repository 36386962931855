import { Icon } from '@chakra-ui/icon'

const StatsGraphAlternate = props => (
  <Icon {...props}>
    <path
      d="M1.698 20.308h3.354a.244.244 0 00.17-.068.226.226 0 00.07-.163V18.23c0-.51-.43-.923-.959-.923H2.417c-.53 0-.959.413-.959.923v1.846c0 .061.025.12.07.163a.244.244 0 00.17.068zM8.167 12.692c-.53 0-.959.414-.959.923v6.462c0 .061.025.12.07.163a.244.244 0 00.17.068h3.354a.244.244 0 00.17-.068.226.226 0 00.07-.163v-6.462c0-.51-.43-.923-.959-.923H8.167zM13.917 14.539c-.53 0-.959.413-.959.922v4.616c0 .061.025.12.07.163a.244.244 0 00.17.068h3.354a.244.244 0 00.17-.068.226.226 0 00.07-.163V15.46c0-.51-.43-.922-.959-.922h-1.916zM19.667 8.077c-.53 0-.959.413-.959.923v11.077c0 .061.025.12.07.163a.244.244 0 00.17.068h3.354a.244.244 0 00.17-.068.226.226 0 00.07-.163V9c0-.51-.43-.923-.959-.923h-1.916zM20.625.692c-.586 0-1.14.259-1.503.701a1.794 1.794 0 00-.362 1.57.228.228 0 01-.052.204l-3.122 3.508a.248.248 0 01-.241.074 1.954 1.954 0 00-1.828.49.243.243 0 01-.245.054l-2.088-.669a.235.235 0 01-.161-.185c-.142-.957-1.028-1.648-2.03-1.581-1.002.066-1.78.867-1.785 1.834 0 .145.018.288.053.429a.226.226 0 01-.08.23L4.4 9.585a.252.252 0 01-.25.034 1.966 1.966 0 00-2.236.492 1.793 1.793 0 00-.146 2.204c.463.685 1.338.995 2.152.763.813-.233 1.37-.953 1.37-1.77 0-.144-.017-.288-.051-.428a.226.226 0 01.08-.23l2.779-2.234a.247.247 0 01.25-.034 1.97 1.97 0 002.131-.39.246.246 0 01.246-.054l2.087.668c.085.028.147.099.16.184.128.97 1.02 1.677 2.033 1.61 1.013-.067 1.796-.883 1.786-1.862 0-.247-.052-.493-.154-.72a.225.225 0 01.04-.241l2.917-3.28a.246.246 0 01.276-.061 1.971 1.971 0 002.098-.382 1.797 1.797 0 00.429-2.015c-.295-.693-.995-1.146-1.773-1.147zM1.458 23.308h21.084c.529 0 .958-.414.958-.923 0-.51-.43-.924-.958-.924H1.458c-.529 0-.958.414-.958.924s.43.923.958.923z"
      fill="currentColor"
    />
  </Icon>
)

StatsGraphAlternate.displayName = 'StatsGraphAlternateIcon'

export default StatsGraphAlternate
