import { Icon } from '@chakra-ui/icon'

const CompassOutline = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 0c6.627 0 12 5.373 12 12-.007 6.624-5.376 11.993-12 12-6.627 0-12-5.373-12-12S5.373 0 12 0zM2.192 13.151a.49.49 0 00-.112.394 10.06 10.06 0 008.375 8.375.49.49 0 00.565-.484v-1.354a.98.98 0 011.96 0v1.354a.49.49 0 00.565.484 9.98 9.98 0 004.375-1.816.488.488 0 00.058-.742l-.956-.954a.98.98 0 011.386-1.386l.955.955a.49.49 0 00.742-.057 10 10 0 001.815-4.375.49.49 0 00-.484-.565h-1.354a.98.98 0 010-1.96h1.354a.49.49 0 00.484-.565 10.06 10.06 0 00-8.375-8.375.49.49 0 00-.565.484v1.354a.98.98 0 01-1.96 0V2.564a.49.49 0 00-.565-.484A9.98 9.98 0 006.08 3.896a.488.488 0 00-.058.742l.956.954a.98.98 0 11-1.381 1.386l-.955-.955a.49.49 0 00-.742.057 10 10 0 00-1.82 4.375.49.49 0 00.484.565h1.354a.98.98 0 010 1.96H2.564a.49.49 0 00-.372.171z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.7 6.049a.981.981 0 011.039.251c.268.28.345.692.197 1.05l-2.725 6.542a2.438 2.438 0 01-1.32 1.319l-6.542 2.726a.98.98 0 01-1.3-1.237l2.428-6.748a2.439 2.439 0 011.476-1.475L16.7 6.049zM10.286 12a1.714 1.714 0 103.428 0 1.714 1.714 0 00-3.428 0z"
      fill="currentColor"
    />
  </Icon>
)

CompassOutline.displayName = 'CompassOutlineIcon'

export default CompassOutline
