import { Icon } from '@chakra-ui/icon'

const ArrowLeft = props => (
  <Icon {...props}>
    <path
      d="M22.548 10.561H5.437a.25.25 0 01-.165-.438l8.637-7.6a1.44 1.44 0 00-1.9-2.162L.828 10.2a2.4 2.4 0 000 3.6l11.179 9.837a1.44 1.44 0 001.9-2.161l-8.637-7.6a.251.251 0 01.165-.438h17.113a1.44 1.44 0 000-2.879v.002z"
      fill="currentColor"
    />
  </Icon>
)

ArrowLeft.displayName = 'ArrowLeftIcon'

export default ArrowLeft
