import { Icon } from '@chakra-ui/icon'

const SpeedometerOutline = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 15.348c.007-6.624 5.376-11.993 12-12 6.624.007 11.993 5.376 12 12v2.391a2.916 2.916 0 01-2.913 2.913H2.913A2.916 2.916 0 010 17.739v-2.391zm2 2.391c.003.502.41.909.913.909h18.174v.004a.914.914 0 00.913-.915V16.4h-2.5a.75.75 0 010-1.5h2.49a9.922 9.922 0 00-.841-3.581l-2.029 1.172a.75.75 0 11-.75-1.3l2.07-1.2a10.006 10.006 0 00-7.69-4.615v2.276a.75.75 0 11-1.5 0V5.376a9.91 9.91 0 00-3.945 1.145l1.161 2.011a.75.75 0 01-.274 1.025.762.762 0 01-1.025-.275l-1.13-1.957c-.98.729-1.82 1.63-2.477 2.66l2.07 1.2a.75.75 0 11-.75 1.3l-2.029-1.174A9.922 9.922 0 002.01 14.9H4.5a.75.75 0 010 1.5H2v1.339z"
      fill="currentColor"
    />
    <path
      d="M16.565 8.125a.749.749 0 00-1.037.221l-3 4.619a2.254 2.254 0 101.257.818l3-4.62a.751.751 0 00-.22-1.038z"
      fill="currentColor"
    />
  </Icon>
)

SpeedometerOutline.displayName = 'SpeedometerOutlineIcon'

export default SpeedometerOutline
