import { Icon } from '@chakra-ui/icon'

const TrophyCheck = props => (
  <Icon {...props}>
    <path
      d="M16.5 20.053c-1.423-.141-2.3-.649-2.47-2.694a.5.5 0 00-.565-.455c-.972.128-1.956.128-2.927 0a.5.5 0 00-.565.455C9.8 19.407 8.922 19.912 7.5 20.053A1.979 1.979 0 007.7 24h8.6a1.979 1.979 0 00.194-3.947h.006z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.754 5.5H22a2 2 0 012 2V10a5.02 5.02 0 01-3.266 4.691 1 1 0 11-.693-1.877A3.01 3.01 0 0022 10V8a.5.5 0 00-.5-.5h-.343a.5.5 0 00-.482.366C19.312 12.786 16.3 15.5 12 15.5c-4.3 0-7.312-2.714-8.675-7.634a.5.5 0 00-.482-.366H2.5A.5.5 0 002 8v2a3.01 3.01 0 001.959 2.814 1 1 0 01-.693 1.877A5.019 5.019 0 010 10V7.5a2 2 0 012-2h.238a.5.5 0 00.5-.565A29.826 29.826 0 012.5 1.523 1.5 1.5 0 014 0h16a1.5 1.5 0 011.5 1.522 29.856 29.856 0 01-.246 3.413.5.5 0 00.5.565zm-9.023 4.24l4.053-5.119-.004-.002a1 1 0 10-1.568-1.242l-3.7 4.68a.5.5 0 01-.746.043L9.457 6.793a1 1 0 10-1.414 1.414l1.706 1.706a2 2 0 002.982-.173z"
      fill="currentColor"
    />
  </Icon>
)

TrophyCheck.displayName = 'TrophyCheckIcon'

export default TrophyCheck
