import { Icon } from '@chakra-ui/icon'

const ChatBubbleDotsOutline = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.041 10.815C.041 5.333 5.406.874 12 .874c6.594 0 11.959 4.46 11.959 9.942S18.594 20.757 12 20.757a14.222 14.222 0 01-3.758-.5L2.285 23.03a1 1 0 01-1.316-1.355l2.117-4.226a8.978 8.978 0 01-3.045-6.634zm8.411 7.42c1.15.349 2.346.524 3.548.521 5.491 0 9.959-3.561 9.959-7.94 0-4.379-4.468-7.941-9.959-7.941s-9.959 3.562-9.959 7.941a7.182 7.182 0 002.912 5.6 1 1 0 01.27 1.229l-.86 1.716a.25.25 0 00.329.339l3.047-1.415a1 1 0 01.713-.05z"
      fill="currentColor"
    />
    <path
      d="M6.96 12.374a1.5 1.5 0 10-.001-3 1.5 1.5 0 000 3zM12 12.374a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM17 12.374a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
      fill="currentColor"
    />
  </Icon>
)

ChatBubbleDotsOutline.displayName = 'ChatBubbleDotsOutlineIcon'

export default ChatBubbleDotsOutline
