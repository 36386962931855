import { Icon } from '@chakra-ui/icon'

const ListNumbers = props => (
  <Icon {...props}>
    <path
      d="M7.75 4.5h15a1 1 0 100-2h-15a1 1 0 000 2zM22.75 11h-15a1 1 0 100 2h15a1 1 0 100-2zM22.75 19.5h-15a1 1 0 100 2h15a1 1 0 100-2zM2.212 17.248a2 2 0 00-1.933 1.484.75.75 0 001.45.386.5.5 0 11.483.63.75.75 0 000 1.5.5.5 0 11-.482.635.75.75 0 10-1.445.4 2 2 0 103.589-1.648.251.251 0 010-.278 2 2 0 00-1.662-3.111v.002zM4.25 10.748a2 2 0 10-4 0 .75.75 0 001.5 0 .5.5 0 111 0c0 .235-.08.462-.227.645L.414 14.029A.75.75 0 001 15.248h2.5a.75.75 0 000-1.5h-.419a.249.249 0 01-.195-.406L3.7 12.33c.357-.45.55-1.008.55-1.582zM4 5.248h-.25A.25.25 0 013.5 5V1.623A1.377 1.377 0 002.125.248H1.5a.75.75 0 000 1.5h.25A.25.25 0 012 2v3a.25.25 0 01-.25.25H1.5a.75.75 0 100 1.5H4a.75.75 0 000-1.5v-.002z"
      fill="currentColor"
    />
  </Icon>
)

ListNumbers.displayName = 'ListNumbersIcon'

export default ListNumbers
