import { FC, useCallback, useState } from 'react'
import Select from 'react-select'
import { debounce } from 'lodash'

export const CbUserSelect: FC<any> = ({ fetchUsers, onChange, value }) => {
  const [options, setOptions] = useState<UserWithSelectionOptionProps[]>([])
  const [searching, setSearching] = useState<boolean>(false)

  const getUsersByInput = useCallback((inputValue: string) => {
    setSearching(true)

    fetchUsers(inputValue)
      .then(userArray =>
        userArray.map(user => ({ ...user, label: user.name, value: user.id })),
      )
      .then(setOptions)
      .then(() => setSearching(false))
  }, [])
  const debouncedSearch = useCallback(debounce(getUsersByInput, 375), [])

  const handleInputChange = (inputValue: string, { action }) => {
    switch (action) {
      case InputAction.InputChange:
        return debouncedSearch(inputValue)
    }
  }

  const customStyles = {
    control: baseStyles => ({
      ...baseStyles,
      borderRadius: '99px',
    }),
  }

  const noOptionsMessage = ({ inputValue }) => {
    switch (true) {
      case searching:
        return 'Searching...'
      case !!inputValue:
        return 'No users found'
      default:
        return 'Search by name...'
    }
  }

  return (
    <div>
      <Select
        noOptionsMessage={noOptionsMessage}
        onChange={onChange}
        onInputChange={handleInputChange}
        options={options}
        placeholder="Select a player..."
        styles={customStyles}
        value={value}
      />
    </div>
  )
}

enum InputAction {
  InputChange = 'input-change',
}

type UserWithSelectionOptionProps = {
  label: string
  value: string
}
