import chakra from '../input'

import {
  NumberInputStepperProps,
  NumberInputFieldProps,
  BoxProps,
} from '@chakra-ui/react'

export const chromePrefillColorOverride = {
  transition: 'background-color 150ms ease -in -out 0s',
  transitionDelay: '5000s',
}
export const baseStyle = {
  field: {
    width: '100%',
    minWidth: 0,
    outline: 0,
    position: 'relative',
    appearance: 'none',
    transitionProperty: 'common',
    transitionDuration: 'normal',
    background: 'transparent',
    borderRadius: '99px',
    border: 'solid 1.5px',
    '&:-webkit-autofill': {
      ...chromePrefillColorOverride,
    },
    '&:-webkit-autofill:hover': {
      ...chromePrefillColorOverride,
      _hover: {
        border: '1.5px solid',
        borderColor: 'gray.500',
      },
    },
    '&:-webkit-autofill:focus': {
      ...chromePrefillColorOverride,
    },
    '&:-webkit-autofill:active': {
      ...chromePrefillColorOverride,
    },
    _blur: {
      borderColor: 'kirby.200',
    },
  },
  addon: {
    border: '2px solid',
    borderColor: 'transparent',
  },
}
const variantOutlined = {
  field: () =>
    ({
      ...chakra.baseStyle.field,
      borderColor: 'border.primary',
      background: 'white',
      height: '42px',
    } as NumberInputFieldProps),
  stepperGroup: () => {
    return {
      flexDirection: 'row',
    } as NumberInputStepperProps
  },
  stepper: () => ({} as BoxProps),
}

const numberInput = {
  parts: ['stepper'],
  baseStyle: {},
  sizes: {},
  variants: {
    horizontal: {
      field: {
        ...variantOutlined.field(),
        ...chakra.baseStyle.field,
        ...baseStyle.field,
        color: 'text.primary',
        borderColor: 'border.primary',
      },
      stepperGroup: {
        ...variantOutlined.stepperGroup(),
        minWidth: '50px',
        flexDirection: 'row-reverse',
        height: 'calc(100% - 4px)',
        alignItems: 'center',
      } as NumberInputStepperProps,
      stepper: {
        ...variantOutlined.stepper(),
        backgroundColor: 'transparent',
        paddingX: '2px',
        _first: {
          borderTopRightRadius: 3,
          borderBottomRightRadius: 3,
          height: '100%',
        },
        _last: {
          borderRadius: 0,
          height: '100%',
        },
      } as BoxProps,
    },
    vertical: {
      field: {
        ...variantOutlined.field(),
      },
      stepperGroup: {
        ...variantOutlined.stepperGroup(),
        minWidth: '32px',
        height: 'calc(100% - 4px)',
        flexDirection: 'column',
      },
      stepper: {
        ...variantOutlined.stepper(),
        backgroundColor: 'transparent',
        height: '100%',
      },
    },
  },
  defaultProps: {
    size: 'md',
    focusBorderColor: 'blue.500',
    errorBorderColor: 'red.200',
    variant: 'vertical',
  },
}

export const variantChonky = (props): Record<string, any> => {
  return {
    field: {
      ...baseStyle.field,
      color: 'cloud.800',
      borderColor: 'cloud.300',
      backgroundColor: 'cloud.100',
      _placeholder: {
        color: 'text.tertiary',
      },
      _hover: {
        borderColor: 'border.hover',
      },
      _focus: {
        border: '2px solid',
        borderColor: 'border.active',
      },
      _active: {
        border: '2px solid',
        borderColor: 'border.active',
      },
      _invalid: {
        border: '2px solid',
        borderColor: 'kirby.300',
        _hover: {
          borderColor: 'kirby.500',
        },
      },
      _disabled: {
        opacity: 0.5,
        cursor: 'not-allowed',
        color: 'blackAlpha.500',
        _hover: {
          border: 'solid 1.5px',
        },
      },
    },
  }
}

export default {
  parts: [...chakra.parts, ...numberInput.parts],
  baseStyle: {
    ...chakra.baseStyle,
    ...numberInput.baseStyle,
  },
  sizes: {
    ...chakra.sizes,
  },
  variants: {
    ...chakra.variants,
    ...numberInput.variants,
    'cb-base': variantChonky,
    'cb-chonky': variantChonky,
  },
  defaultProps: {
    ...numberInput.defaultProps,
    ...chakra.defaultProps,
  },
}
