import { Icon } from '@chakra-ui/icon'

const LogoutWide = props => (
  <Icon viewBox="0 0 16 16" {...props}>
    <g fill="none">
      <path
        d="M11.3333 8H1.33325"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M4.66658 11.3333L1.33325 8L4.66658 4.66667"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M6.6665 0.666668H14.6665V15.3333H6.6665"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </g>
  </Icon>
)

LogoutWide.displayName = 'LogoutWideIcon'

export default LogoutWide
