export const snakeCase = (raw: string): string => {
  const parts = raw.match(/([a-z]+)|([A-Z][a-z]+)/g)
  return (
    parts?.map(part => part.toLowerCase()).join('_') ||
    raw
      .toLowerCase()
      .split(/[\s-_]/)
      .join('_')
  )
}

export const snakeCaseKeys = (obj: any): any => {
  if (['string', 'number', 'boolean'].includes(typeof obj)) {
    return obj
  }
  if (obj === null || obj === undefined) {
    return obj
  }
  if (Array.isArray(obj)) {
    return obj.map(v => snakeCaseKeys(v))
  }

  const returnValue = {}
  Object.keys(obj).map(key => {
    returnValue[snakeCase(key)] = snakeCaseKeys(obj[key])
  })

  return returnValue
}

export const simplifyNumber = (num: number, decimals = 2): string => {
  if (Number.isInteger(num)) return num.toString()
  if (num < 1e-15 && num > -1e-15) return '0'
  return num.toFixed(decimals)
}
