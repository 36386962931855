import { Icon } from '@chakra-ui/icon'

const Confetti = props => (
  <Icon {...props}>
    <path
      d="M23.21 11.807a.748.748 0 01-.53-.22 3.152 3.152 0 00-4.352 0 .75.75 0 11-1.061-1.061 4.579 4.579 0 016.473 0 .751.751 0 01-.53 1.281zM20.764 15.372a.809.809 0 100-1.618.809.809 0 000 1.618zM12.943 6.953a.75.75 0 01-.53-1.28 3.084 3.084 0 000-4.353.75.75 0 011.06-1.06 4.583 4.583 0 010 6.473.744.744 0 01-.53.22zM9.437 4.045a.809.809 0 100-1.618.809.809 0 000 1.618zM23.191 1.618a.809.809 0 100-1.618.809.809 0 000 1.618zM22.921 7.281a.809.809 0 100-1.618.809.809 0 000 1.618zM16.179 8.571a.75.75 0 01-.53-1.28l5.124-5.124a.75.75 0 011.06 1.061l-5.124 5.124a.747.747 0 01-.53.219z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.016 9.979c-2.227-2.216-5.322-3.619-6.93-2.013a1.9 1.9 0 00-.358.485C6.691 8.514.28 20.519.28 20.519a2.364 2.364 0 003.194 3.2l12-6.417c.212-.096.403-.232.564-.4 1.602-1.602.198-4.692-2.022-6.923zm.492 5.409l.003-.003c.229-.23-.121-1.972-2.02-3.885a6.312 6.312 0 00-3.625-2.08.37.37 0 00-.248.062c-.345.346.509 2.348 2.028 3.868 1.014 1.017 2.706 2.1 3.614 2.1.18.009.23-.043.248-.062z"
      fill="currentColor"
    />
  </Icon>
)

Confetti.displayName = 'ConfettiIcon'

export default Confetti
