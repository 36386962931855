import { Icon } from '@chakra-ui/icon'

const AlarmBellSnooze = props => (
  <Icon {...props}>
    <path
      d="M22.08 15h-1.396l2.155-2.886c.226-.302.266-.71.105-1.054a.957.957 0 00-.864-.56h-3.36c-.53 0-.96.448-.96 1s.43 1 .96 1h1.398l-2.155 2.886c-.226.301-.266.71-.105 1.053.16.343.495.56.862.561h3.36c.53 0 .96-.448.96-1s-.43-1-.96-1zM14.88 2h2.294l-3.036 3.868a1.034 1.034 0 00-.124 1.06.958.958 0 00.866.572h4.32c.53 0 .96-.448.96-1s-.43-1-.96-1h-2.294l3.037-3.867c.235-.3.283-.712.124-1.061A.958.958 0 0019.2 0h-4.32c-.53 0-.96.448-.96 1s.43 1 .96 1zM9.12 21.5H6.24a.49.49 0 00-.48.5c0 1.105.86 2 1.92 2s1.92-.895 1.92-2a.49.49 0 00-.48-.5zM14.4 18.5c-.53 0-.96-.448-.96-1V13c0-3.451-2.08-6.317-4.8-6.874V4.5c0-.552-.43-1-.96-1s-.96.448-.96 1v1.627C4.001 6.684 1.92 9.55 1.92 13v4.5c0 .552-.43 1-.96 1s-.96.448-.96 1 .43 1 .96 1H14.4c.53 0 .96-.448.96-1s-.43-1-.96-1z"
      fill="currentColor"
    />
  </Icon>
)

AlarmBellSnooze.displayName = 'AlarmBellSnoozeIcon'

export default AlarmBellSnooze
