import { Icon } from '@chakra-ui/icon'

const Store = props => (
  <Icon viewBox="0 0 16 16" {...props}>
    <g fill="none">
      <path
        d="M2.66663 9.02029V15.0725H13.3333V9.02029"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M2.66663 1.62318L0.666631 4.9855C0.665624 5.43899 0.816524 5.87952 1.09486 6.23565C1.37319 6.59178 1.76264 6.84262 2.20003 6.94749C2.63742 7.05235 3.09711 7.0051 3.50453 6.81339C3.91196 6.62168 4.24324 6.29675 4.44463 5.89131C4.60895 6.22519 4.86242 6.50615 5.17649 6.70257C5.49056 6.89899 5.85276 7.00306 6.2223 7.00306C6.59184 7.00306 6.95404 6.89899 7.26811 6.70257C7.58218 6.50615 7.83565 6.22519 7.99996 5.89131C8.16428 6.22519 8.41775 6.50615 8.73182 6.70257C9.04589 6.89899 9.40809 7.00306 9.77763 7.00306C10.1472 7.00306 10.5094 6.89899 10.8234 6.70257C11.1375 6.50615 11.391 6.22519 11.5553 5.89131C11.7567 6.29675 12.088 6.62168 12.4954 6.81339C12.9028 7.0051 13.3625 7.05235 13.7999 6.94749C14.2373 6.84262 14.6267 6.59178 14.9051 6.23565C15.1834 5.87952 15.3343 5.43899 15.3333 4.9855L13.3333 1.62318H2.66663Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M6.66663 15.0725V11.0377H9.33329V15.0725"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </g>
  </Icon>
)

Store.displayName = 'StoreIcon'

export default Store
