import { Icon } from '@chakra-ui/icon'

const ListBullets = props => (
  <Icon {...props}>
    <path
      d="M2.5 6.498a2.5 2.5 0 100-5 2.5 2.5 0 000 5zM8.5 5H23a1 1 0 100-2H8.5a1 1 0 000 2zM2.5 14.498a2.5 2.5 0 100-5 2.5 2.5 0 000 5zM23 11H8.5a1 1 0 100 2H23a1 1 0 100-2zM2.5 22.498a2.5 2.5 0 100-5 2.5 2.5 0 000 5zM23 19H8.5a1 1 0 100 2H23a1 1 0 100-2z"
      fill="currentColor"
    />
  </Icon>
)

ListBullets.displayName = 'ListBulletsIcon'

export default ListBullets
