import { Icon } from '@chakra-ui/icon'

const SearchPerson = props => (
  <Icon {...props}>
    <svg
      width="24"
      height="24"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M7.33325 8.66667C5.45059 8.66667 3.78059 9.092 2.68325 9.462C1.87325 9.736 1.33325 10.4993 1.33325 11.3547V14C1.33325 14 7.97192 14 7.99992 14"
          stroke="#8959C3"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M7.33333 8.66667C5.49267 8.66667 4 6.50734 4 4.66667V4.00001C4 2.15934 5.49267 0.666672 7.33333 0.666672C9.174 0.666672 10.6667 2.15934 10.6667 4.00001V4.66667C10.6667 6.50734 9.174 8.66667 7.33333 8.66667Z"
          stroke="#8959C3"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M15.3332 15.3333L13.4138 13.414"
          stroke="#8959C3"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z"
          stroke="#8959C3"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </Icon>
)

SearchPerson.displayName = 'SearchPersonIcon'

export default SearchPerson
