import { Icon } from '@chakra-ui/icon'

const Timer = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.16 7.924l1.547-1.484a.935.935 0 00.273-.934.98.98 0 00-.715-.686 1.028 1.028 0 00-.972.262l-1.6 1.536A9.677 9.677 0 0013 4.851V1.92h2c.552 0 1-.43 1-.96S15.552 0 15 0H9c-.552 0-1 .43-1 .96s.448.96 1 .96h2v2.93c-4.354.444-7.828 3.686-8.413 7.851s1.87 8.175 5.946 9.71c4.076 1.534 8.716.196 11.237-3.24 2.52-3.436 2.268-8.08-.61-11.247zM4.5 13.92c0 3.976 3.358 7.2 7.5 7.2 4.14-.005 7.495-3.226 7.5-7.2 0-3.976-3.358-7.2-7.5-7.2-4.142 0-7.5 3.224-7.5 7.2z"
      fill="currentColor"
    />
    <path
      d="M9.207 9.882a1.03 1.03 0 00-1.402.012.933.933 0 00-.012 1.346l3.5 3.36c.394.367 1.02.367 1.414 0a.933.933 0 000-1.358l-3.5-3.36z"
      fill="currentColor"
    />
  </Icon>
)

Timer.displayName = 'TimerIcon'

export default Timer
