import { Icon } from '@chakra-ui/icon'

const LocationCrossed = props => (
  <Icon {...props}>
    <path
      d="M14.126 18.26a6.606 6.606 0 01-8.385-8.383 1.225 1.225 0 00-2.317-.794c-.143.42-.254.852-.331 1.29a.49.49 0 01-.482.4H1.224a1.224 1.224 0 100 2.448h1.39c.236 0 .438.168.481.4a9.07 9.07 0 007.278 7.279.49.49 0 01.4.482v1.39a1.224 1.224 0 102.448 0V21.39c0-.236.168-.439.4-.482a9.017 9.017 0 001.289-.33 1.224 1.224 0 10-.79-2.318h.006z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.451 17.152a.488.488 0 00.057.624l4.133 4.134a1.224 1.224 0 11-1.731 1.731L.359 2.09A1.224 1.224 0 012.09.359L6.227 4.5a.489.489 0 00.625.052 8.972 8.972 0 013.527-1.458.489.489 0 00.4-.481V1.224a1.224 1.224 0 112.448 0v1.394c0 .236.168.438.4.482a9.07 9.07 0 017.278 7.279c.043.232.245.4.481.4h1.39a1.224 1.224 0 110 2.448h-1.389a.49.49 0 00-.482.4 8.988 8.988 0 01-1.454 3.525zm-1.757-8.526A6.6 6.6 0 0012 5.388a6.558 6.558 0 00-3.178.817.489.489 0 00-.11.775l8.306 8.306a.489.489 0 00.775-.11 6.6 6.6 0 00-.1-6.55z"
      fill="currentColor"
    />
  </Icon>
)

LocationCrossed.displayName = 'LocationCrossedIcon'

export default LocationCrossed
