import { Icon } from '@chakra-ui/icon'

const ChatBubbleLinesOutline = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.041 10.815C.041 5.333 5.406.874 12 .874c6.594 0 11.959 4.459 11.959 9.941 0 5.482-5.365 9.941-11.959 9.941a14.18 14.18 0 01-3.758-.5L2.285 23.03a1 1 0 01-1.316-1.355l2.117-4.226a8.98 8.98 0 01-3.045-6.634zm8.412 7.42c1.15.349 2.345.524 3.547.521 5.491 0 9.959-3.56 9.959-7.939S17.491 2.876 12 2.876s-9.959 3.562-9.959 7.941a7.186 7.186 0 002.913 5.6 1 1 0 01.269 1.229l-.86 1.716a.25.25 0 00.329.338l3.046-1.415a1 1 0 01.715-.05z"
      fill="currentColor"
    />
    <path
      d="M7.5 9.374H14a1 1 0 100-2H7.5a1 1 0 000 2zM17 11.874H7.5a1 1 0 100 2H17a1 1 0 100-2z"
      fill="currentColor"
    />
  </Icon>
)

ChatBubbleLinesOutline.displayName = 'ChatBubbleLinesOutlineIcon'

export default ChatBubbleLinesOutline
