export enum Routes {
  Error = '/_error',
  GameDetails = '/games/[id]',
  GameLeaderboard = '/games/[id]/leaderboard',
  Games = '/games',
  Guides = '/guides',
  Login = '/login',
  LoginGroupMe = '/login-groupme',
  LoginLink = '/login-via-link',
  LoginMagicLink = '/login-via-magic-link',
  LoginSlack = '/login-slack',
  Manage = '/manage',
  ManagePlayers = '/manage/players',
  Newsfeed = '/newsfeed/[feedType]',
  NotFound = '/404',
  Players = '/players',
  Profile = '/profile',
  ProfileId = '/profile/[id]',
  RenderedCards = '/_rendered-cards',
  ROIDashboard = '/results',
  Settings = '?drawer=manage-settings',
  Rewards = '/rewards',
  RewardsActivity = '/rewards/activity',
  RewardsSearch = '/rewards/search',
  Stats = '/stats',
}

export enum PageTitle {
  Arcade = 'Arcade',
}
