import { Icon } from '@chakra-ui/icon'

const TourchTwo = props => (
  <Icon {...props}>
    <path
      d="M5.429 10.56a.503.503 0 00-.44.254.464.464 0 00.025.492l1.4 2.025A2.523 2.523 0 008.5 14.4h.1l1.7 7.328c.177.767.886 1.313 1.704 1.313.819 0 1.528-.546 1.705-1.313L15.4 14.4h.1c.836 0 1.616-.4 2.08-1.068l1.407-2.025a.464.464 0 00.025-.493.503.503 0 00-.441-.254H5.429zM10.369 9.12c.074 0 .144-.03.191-.085a.233.233 0 00.055-.195 1.42 1.42 0 01.725-1.504 3.146 3.146 0 001.473-1.46 4.914 4.914 0 011.063 2.992.23.23 0 00.068.177.254.254 0 00.181.075h2.917a.246.246 0 00.246-.2c.782-4.566-3.645-8.152-4.17-8.557-.357-.276-.594-.455-.9-.314a.446.446 0 00-.283.409 10.848 10.848 0 01-3.318 3.49c-2.372 1.824-2.354 3.648-1.967 5 .03.103.129.175.241.175l3.478-.003z"
      fill="currentColor"
    />
  </Icon>
)

TourchTwo.displayName = 'TourchTwoIcon'

export default TourchTwo
