import { Icon } from '@chakra-ui/icon'

const Hot = props => (
  <Icon {...props}>
    <path
      d="M12.747 14.5a.75.75 0 00.75-.75v-2.5a.75.75 0 00-1.5 0v2.5c0 .414.336.75.75.75z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 12C0 5.373 5.373 0 12 0c6.624.007 11.993 5.376 12 12 0 6.627-5.373 12-12 12S0 18.627 0 12zm5.5 1.5a.25.25 0 01.25-.25h1a.25.25 0 01.25.25v1.75a.75.75 0 001.5 0v-5.5a.75.75 0 00-1.5 0v1.75a.25.25 0 01-.25.25h-1a.25.25 0 01-.25-.25V9.75a.75.75 0 00-1.5 0v5.5a.75.75 0 001.5 0V13.5zm4.997-2.25a2.25 2.25 0 014.5 0v2.5a2.25 2.25 0 01-4.5 0v-2.5zm7.5-.5v4.5a.75.75 0 001.5 0v-4.5a.25.25 0 01.25-.25h.5a.75.75 0 000-1.5h-3a.75.75 0 000 1.5h.5a.25.25 0 01.25.25z"
      fill="currentColor"
    />
  </Icon>
)

Hot.displayName = 'HotIcon'

export default Hot
