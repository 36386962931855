import { Icon } from '@chakra-ui/icon'

const StatsGraphBackground = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 21h21a1.5 1.5 0 001.5-1.5v-15A1.5 1.5 0 0022.5 3h-21A1.5 1.5 0 000 4.5v15A1.5 1.5 0 001.5 21zM18 12a1 1 0 112 0v4.5a1 1 0 11-2 0V12zm-4-5.5a1 1 0 00-1 1v9a1 1 0 102 0v-9a1 1 0 00-1-1zm-5.5 7a1 1 0 112 0v3a1 1 0 11-2 0v-3zm-3.5-3a1 1 0 00-1 1v5a1 1 0 102 0v-5a1 1 0 00-1-1z"
      fill="currentColor"
    />
  </Icon>
)

StatsGraphBackground.displayName = 'StatsGraphBackgroundIcon'

export default StatsGraphBackground
