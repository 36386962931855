import { Icon } from '@chakra-ui/icon'

const FlagTwo = props => (
  <Icon {...props}>
    <path
      d="M16.55 7.2a.249.249 0 010-.39l5.988-4.789c.521-.418.485-.8.417-1-.068-.2-.281-.521-.949-.521H5.993a.5.5 0 00-.5.5v12a.5.5 0 00.5.5h16.013c.668 0 .882-.325.949-.519.067-.194.1-.58-.417-1L16.55 7.2zM2.506 0a1.5 1.5 0 00-1.5 1.5v21a1.5 1.5 0 003 0v-21a1.5 1.5 0 00-1.5-1.5z"
      fill="currentColor"
    />
  </Icon>
)

FlagTwo.displayName = 'FlagTwoIcon'

export default FlagTwo
