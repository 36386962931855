import { Icon } from '@chakra-ui/icon'

const GameHorseshoe = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 3a2 2 0 01-2 2 .5.5 0 00-.5.5v.066a5.5 5.5 0 00.889 2.991 10 10 0 11-16.778 0A5.49 5.49 0 004.5 5.566V5.5A.5.5 0 004 5a2 2 0 01-2-2V2a2 2 0 012-2h3a2.5 2.5 0 012.5 2.5v3.066c0 2.12-.093 4.014-1.2 5.714A4.988 4.988 0 007.5 14a4 4 0 004 4h1a4 4 0 004-4 4.982 4.982 0 00-.8-2.719c-1.11-1.704-1.2-3.595-1.2-5.715V2.5A2.5 2.5 0 0117 0h3a2 2 0 012 2v1zM4 16a1 1 0 102 0 1 1 0 00-2 0zm2.5-6.5a1 1 0 110-2 1 1 0 010 2zM11 21a1 1 0 102 0 1 1 0 00-2 0zm5.5-12.5a1 1 0 112 0 1 1 0 01-2 0zM19 17a1 1 0 100-2 1 1 0 000 2z"
      fill="currentColor"
    />
  </Icon>
)

GameHorseshoe.displayName = 'GameHorseshoeIcon'

export default GameHorseshoe
