import { Icon } from '@chakra-ui/icon'

const Checkbox = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.545 0h20.91A1.547 1.547 0 0124 1.545v20.909c0 .853-.692 1.545-1.545 1.546H1.545A1.547 1.547 0 010 22.454V1.545A1.547 1.547 0 011.545 0zm10.26 17.344l7-9.5.005-.001a1 1 0 10-1.61-1.187l-6.388 8.664-4.187-3.35a1 1 0 10-1.25 1.561l5 4a1.013 1.013 0 001.43-.187z"
      fill="currentColor"
    />
  </Icon>
)

Checkbox.displayName = 'CheckboxIcon'

export default Checkbox
