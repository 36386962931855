import { Icon } from '@chakra-ui/icon'

const ChessPawn = props => (
  <Icon {...props}>
    <path
      d="M12 7.5A3.75 3.75 0 1012 0a3.75 3.75 0 000 7.5zM8.252 19h7.5a.5.5 0 00.459-.7c-1.155-2.788-1.894-5.291-1.148-8.043A1 1 0 0014.093 9H9.907a1 1 0 00-.966 1.262c.746 2.752.008 5.255-1.147 8.043a.5.5 0 00.458.695zM16.5 20.5h-9a3.5 3.5 0 00-3.448 2.9.51.51 0 00.5.6h14.901a.509.509 0 00.5-.6 3.5 3.5 0 00-3.453-2.9z"
      fill="currentColor"
    />
  </Icon>
)

ChessPawn.displayName = 'ChessPawnIcon'

export default ChessPawn
