import { Icon } from '@chakra-ui/icon'

const MessageBubble = props => (
  <Icon {...props}>
    <path
      d="M12 1.337C5.659 1.337.5 5.587.5 10.811a8.6 8.6 0 003.175 6.54l-2.3 4.587a.5.5 0 00.659.677l6.149-2.862c1.24.356 2.526.536 3.817.533 6.341 0 11.5-4.25 11.5-9.475S18.341 1.337 12 1.337z"
      fill="currentColor"
    />
  </Icon>
)

MessageBubble.displayName = 'MessageBubbleIcon'

export default MessageBubble
