import { Icon } from '@chakra-ui/icon'

const MilitaryRankTwo = props => (
  <Icon {...props}>
    <path
      d="M20.322 19.337l-7.638-4.492a1.431 1.431 0 00-1.369 0l-7.637 4.492A1.457 1.457 0 003 20.521v2a.757.757 0 001.185.679l7.637-4.494a.467.467 0 01.356 0l7.638 4.494A.756.756 0 0021 22.521v-2a1.48 1.48 0 00-.678-1.184z"
      fill="currentColor"
    />
    <path
      d="M20.322 12.337l-7.638-4.492a1.431 1.431 0 00-1.369 0l-7.637 4.492A1.457 1.457 0 003 13.521v2a.757.757 0 001.185.679l7.637-4.494a.467.467 0 01.356 0l7.638 4.494A.756.756 0 0021 15.521v-2a1.48 1.48 0 00-.678-1.184z"
      fill="currentColor"
    />
    <path
      d="M20.322 5.337L12.684.845a1.431 1.431 0 00-1.369 0L3.678 5.337A1.457 1.457 0 003 6.521v2a.757.757 0 001.185.679l7.637-4.494a.467.467 0 01.356 0L19.816 9.2A.756.756 0 0021 8.521v-2a1.48 1.48 0 00-.678-1.184z"
      fill="currentColor"
    />
  </Icon>
)

MilitaryRankTwo.displayName = 'MilitaryRankTwoIcon'

export default MilitaryRankTwo
