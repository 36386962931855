import { arcadeApiClient } from '../apiClients'
import useSWR from 'swr'
import { Team } from 'types'

const teamsFetcher = async (endpoint): Promise<{ teams: Team[] }> => {
  return arcadeApiClient.get(endpoint).then(res => res.data)
}

/* 
  This hook returns the teams that the current user manages. This should be used in places where a user's access 
  to a team allows them to perform some action relating to it. For example if we have a section where you can choose 
  to add a player to a team we should use this one 
*/
export const useManagedTeams = () => {
  const { data, isValidating, error } = useSWR(
    '/multi_platform/manage/teams',
    teamsFetcher,
  )

  return {
    teams: data?.teams as Team[],
    loading: isValidating,
    error,
  }
}
