import chakra from './chakra-theme'

export default {
  baseStyle: {
    ...chakra.baseStyle,
  },
  defaultProps: {
    ...chakra.defaultProps,
  },
}
