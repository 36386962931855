import { Icon } from '@chakra-ui/icon'

const RectangleStar = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 0a5.006 5.006 0 015 5v14a5.006 5.006 0 01-5 5H5a5.006 5.006 0 01-5-5V5a5.006 5.006 0 015-5h14zm-2.942 16.714a.422.422 0 00.104-.482l-1.425-3.278a.25.25 0 01.063-.286l2.447-2.18a.422.422 0 00-.281-.737h-2.907a.25.25 0 01-.23-.151l-1.442-3.345a.422.422 0 00-.775 0l-1.441 3.344a.25.25 0 01-.23.151H7.034a.422.422 0 00-.281.737L9.2 12.668c.08.072.106.187.063.286l-1.425 3.277a.423.423 0 00.594.537l3.445-1.941a.249.249 0 01.245 0l3.446 1.941a.422.422 0 00.49-.054z"
      fill="currentColor"
    />
  </Icon>
)

RectangleStar.displayName = 'RectangleStarIcon'

export default RectangleStar
