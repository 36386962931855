import { Icon } from '@chakra-ui/icon'

const UserFullGroup = props => (
  <Icon {...props}>
    <path
      d="M14 14v3.5c0 .174.044.548.049.61l.453 5.432A.5.5 0 0015 24h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 0120.19 18H22a.5.5 0 00.5-.5V14a5.491 5.491 0 00-9.431-3.834.248.248 0 00-.036.3c.633 1.07.967 2.29.967 3.534zM17 7a3.5 3.5 0 100-7 3.5 3.5 0 000 7zM1.5 17.5a.5.5 0 00.5.5h1.81c.13 0 .238.1.249.229l.441 5.313A.5.5 0 005 24h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 0110.19 18H12a.5.5 0 00.5-.5V14a5.5 5.5 0 10-11 0v3.5zM7 7a3.5 3.5 0 100-7 3.5 3.5 0 000 7z"
      fill="currentColor"
    />
    <path
      d="M14 14v3.5c0 .174.044.548.049.61l.453 5.432A.5.5 0 0015 24h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 0120.19 18H22a.5.5 0 00.5-.5V14a5.491 5.491 0 00-9.431-3.834.248.248 0 00-.036.3c.633 1.07.967 2.29.967 3.534zM17 7a3.5 3.5 0 100-7 3.5 3.5 0 000 7zM1.5 17.5a.5.5 0 00.5.5h1.81c.13 0 .238.1.249.229l.441 5.313A.5.5 0 005 24h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 0110.19 18H12a.5.5 0 00.5-.5V14a5.5 5.5 0 10-11 0v3.5zM7 7a3.5 3.5 0 100-7 3.5 3.5 0 000 7z"
      fill="currentColor"
    />
  </Icon>
)

UserFullGroup.displayName = 'UserFullGroupIcon'

export default UserFullGroup
