import { Icon } from '@chakra-ui/icon'

const EditAlternative = props => (
  <Icon {...props}>
    <path
      d="M20 11.491a1 1 0 00-1 1v8.5a1 1 0 01-1 1H3a1 1 0 01-1-1v-17a1 1 0 011-1h10a1 1 0 100-2H3a3 3 0 00-3 3v17a3 3 0 003 3h15a3 3 0 003-3v-8.5a1 1 0 00-1-1z"
      fill="currentColor"
    />
    <path
      d="M18.818 3.051a.516.516 0 00-.707 0L10.3 10.865a.5.5 0 00-.111.168l-1.416 3.535a.5.5 0 00.111.539c.144.137.353.18.539.11l3.535-1.417a.5.5 0 00.168-.111L20.94 5.88a.5.5 0 000-.707l-2.122-2.122zM23.415.577a2.047 2.047 0 00-2.828 0l-1.061 1.06a.5.5 0 000 .707l2.12 2.121a.5.5 0 00.707 0l1.061-1.06a2 2 0 000-2.828h.001z"
      fill="currentColor"
    />
  </Icon>
)

EditAlternative.displayName = 'EditAlternativeIcon'

export default EditAlternative
