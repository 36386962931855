import { Icon } from '@chakra-ui/icon'

const CursorOutline = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.753 15.637l4.447 4.447a1.5 1.5 0 002.117.005l1.768-1.768a1.5 1.5 0 000-2.121l-4.448-4.452 2.562-1.735.01-.006a1.5 1.5 0 00-.424-2.67l-12.7-3.811a1.25 1.25 0 00-1.557 1.56l3.81 12.7a1.5 1.5 0 002.676.414l1.739-2.563zm.554-2.271a1 1 0 00-1.535.15l-1.525 2.253A.25.25 0 018.8 15.7L5.977 6.287a.252.252 0 01.063-.249.247.247 0 01.248-.062L15.7 8.8a.25.25 0 01.067.445l-2.253 1.525a1 1 0 00-.146 1.536l4.773 4.773a.25.25 0 010 .353l-.708.707a.25.25 0 01-.353 0l-4.773-4.773z"
      fill="currentColor"
    />
  </Icon>
)

CursorOutline.displayName = 'CursorOutlineIcon'

export default CursorOutline
