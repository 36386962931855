import { Icon } from '@chakra-ui/icon'

const TrophyThree = props => (
  <Icon {...props}>
    <path
      d="M7 20a2 2 0 00-2 2v1a1 1 0 001 1h12a1 1 0 001-1v-1a2 2 0 00-2-2H7zM9 15.5a2.015 2.015 0 00-2 2v.5a.5.5 0 00.5.5h9a.5.5 0 00.5-.5v-.5a2.016 2.016 0 00-2-2H9z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.139 13.843a.5.5 0 01-.365.157h-3.547a.5.5 0 01-.5-.53 7.091 7.091 0 00-4.159-6.99A6.005 6.005 0 012 1a1 1 0 011-1h18a1 1 0 011 1 6.005 6.005 0 01-3.567 5.48 7.091 7.091 0 00-4.159 6.99.5.5 0 01-.135.373zM5.93 1.918a.748.748 0 00-.569.098.749.749 0 00-.238 1.033c.376.6.86 1.126 1.426 1.551a.75.75 0 10.9-1.2A4.236 4.236 0 016.4 2.253a.748.748 0 00-.47-.335z"
      fill="currentColor"
    />
  </Icon>
)

TrophyThree.displayName = 'TrophyThreeIcon'

export default TrophyThree
