import { Icon } from '@chakra-ui/icon'

const Molecule = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.217 3.742l3.023 1.714c.47.266.76.765.76 1.305v2.918a1.5 1.5 0 01-.762 1.306 71.223 71.223 0 00-8.039 5.453.5.5 0 00-.2.4v3.44a1.5 1.5 0 01-.77 1.31l-4 2.223a1.5 1.5 0 01-1.458 0l-4-2.223A1.5 1.5 0 012 20.277v-3.912a1.5 1.5 0 01.771-1.307l4-2.222a1.493 1.493 0 011.458 0l3.42 1.9a.5.5 0 00.541-.036l3.207-2.379a.251.251 0 00-.024-.419l-1.617-.923a1.5 1.5 0 01-.756-1.3V7.486a.5.5 0 00-.314-.465L9.234 5.64a.505.505 0 00-.415.02l-1.248.64a1.25 1.25 0 01-1.142 0l-1.75-.9A1.244 1.244 0 014 4.291V2.124a1.244 1.244 0 01.691-1.118l1.75-.875a1.256 1.256 0 011.118 0l1.75.875c.424.21.692.644.691 1.118v1.33a.5.5 0 00.314.464l3.378 1.349a.5.5 0 00.435-.031l2.6-1.494a1.5 1.5 0 011.49 0zm-11.445.487a.5.5 0 00.456 0l.5-.254A.5.5 0 008 3.53V2.9a.5.5 0 00-.276-.447l-.5-.25a.5.5 0 00-.448 0l-.5.25A.5.5 0 006 2.9v.627a.5.5 0 00.272.445l.5.257zM11 19.689a.5.5 0 01-.257.437l-3 1.666a.5.5 0 01-.486 0l-3-1.666A.5.5 0 014 19.689v-2.735a.5.5 0 01.257-.438l3-1.666a.5.5 0 01.486 0l3 1.666a.5.5 0 01.257.438v2.735zm8.748-10.155A.5.5 0 0020 9.1V7.343a.5.5 0 00-.249-.433L17.73 5.764a.5.5 0 00-.5 0l-1.98 1.14a.5.5 0 00-.25.433V9.1a.5.5 0 00.248.434l2 1.143a.5.5 0 00.5 0l2-1.143z"
      fill="currentColor"
    />
  </Icon>
)

Molecule.displayName = 'MoleculeIcon'

export default Molecule
