import { Icon } from '@chakra-ui/icon'

const BadgeStar = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.728 9.394l1.813 1.92a1 1 0 01.001 1.371l-1.813 1.92.609 2.57a1 1 0 01-.687 1.19l-2.531.754-.754 2.531a1 1 0 01-1.189.687l-2.569-.611-1.92 1.814a1 1 0 01-1.374 0l-1.92-1.813-2.57.609a1 1 0 01-1.189-.686l-.754-2.531-2.53-.754a1 1 0 01-.688-1.189l.611-2.57-1.813-1.92a1 1 0 010-1.373l1.812-1.92-.61-2.57a1 1 0 01.687-1.19l2.531-.754.754-2.53a1 1 0 011.189-.688l2.569.611L11.314.459a1 1 0 011.374 0l1.919 1.813 2.569-.609a1 1 0 011.19.687l.754 2.532 2.53.754a1 1 0 01.688 1.189l-.61 2.569zm-5.67 7.502a.422.422 0 00.104-.482l-1.352-3.108a.5.5 0 01.126-.573l2.311-2.06a.422.422 0 00-.281-.737h-2.742a.5.5 0 01-.459-.3l-1.377-3.193a.422.422 0 00-.775 0l-1.377 3.193a.5.5 0 01-.459.3H7.034a.422.422 0 00-.28.737l2.311 2.06a.5.5 0 01.126.573l-.114.263-1.239 2.848a.423.423 0 00.595.537l3.322-1.876a.5.5 0 01.49 0l3.323 1.872a.422.422 0 00.49-.054z"
      fill="currentColor"
    />
  </Icon>
)

BadgeStar.displayName = 'BadgeStarIcon'

export default BadgeStar
