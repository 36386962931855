import { Icon } from '@chakra-ui/icon'

const Menu = props => (
  <Icon {...props}>
    <path
      d="M3 4.5A1.5 1.5 0 014.5 3h15A1.5 1.5 0 0121 4.5v1A1.5 1.5 0 0119.5 7h-15A1.5 1.5 0 013 5.5v-1zM3 11.5A1.5 1.5 0 014.5 10h15a1.5 1.5 0 011.5 1.5v1a1.5 1.5 0 01-1.5 1.5h-15A1.5 1.5 0 013 12.5v-1zM3 18.5A1.5 1.5 0 014.5 17h15a1.5 1.5 0 011.5 1.5v1a1.5 1.5 0 01-1.5 1.5h-15A1.5 1.5 0 013 19.5v-1z"
      fill="currentColor"
    />
  </Icon>
)

Menu.displayName = 'MenuIcon'

export default Menu
