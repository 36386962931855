import { Icon } from '@chakra-ui/icon'

const Wrench = props => (
  <Icon {...props}>
    <svg
      width="24"
      height="24"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.64 3.36L13.5 5.5L11.5 3.5L13.64 1.36C13.139 1.133 12.586 1 12 1C9.791 1 8 2.791 8 5C8 5.552 8.112 6.078 8.314 6.557L1 12L5 16L10.443 8.686C10.922 8.888 11.448 9 12 9C14.209 9 16 7.209 16 5C16 4.414 15.867 3.861 15.64 3.36Z"
        stroke="#ffffff"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </Icon>
)

Wrench.displayName = 'WrenchIcon'

export default Wrench
