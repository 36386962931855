import { Icon } from '@chakra-ui/icon'

const Bookmark = props => (
  <Icon {...props}>
    <path
      d="M1.455 17.488h4.32a.49.49 0 01.48.5v4.5a1.417 1.417 0 00.792 1.421c.146.06.301.092.458.091.407-.013.792-.2 1.063-.516L22.48 8.992a2.06 2.06 0 000-2.825L17.12.585a1.865 1.865 0 00-2.711 0L.495 15.076a1.446 1.446 0 00-.405 1.587c.238.552.788.883 1.365.825z"
      fill="currentColor"
    />
  </Icon>
)

Bookmark.displayName = 'BookmarkIcon'

export default Bookmark
