import { Icon } from '@chakra-ui/icon'

const AlarmBellOff = props => (
  <Icon {...props}>
    <path
      d="M21 19.5a1 1 0 100-2 1.5 1.5 0 01-1.5-1.5v-4.863c0-.888-.134-1.77-.4-2.617L8.121 19.5H21zM14.236 21H9.764a.251.251 0 00-.249.222 2.5 2.5 0 104.969 0 .25.25 0 00-.248-.222zM1 23.993a1 1 0 00.707-.286l22-22a1.014 1.014 0 000-1.414 1 1 0 00-1.414 0l-5.041 5.041A7.285 7.285 0 0013 3.072V1a1 1 0 10-2 0v2.072a7.957 7.957 0 00-6.5 8.065V16A1.5 1.5 0 013 17.5a1 1 0 100 2h.086L.293 22.293a1.015 1.015 0 000 1.414 1 1 0 00.707.286z"
      fill="currentColor"
    />
  </Icon>
)

AlarmBellOff.displayName = 'AlarmBellOffIcon'

export default AlarmBellOff
