import { Icon } from '@chakra-ui/icon'

const Trash = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.5 7.5h-15A.5.5 0 004 8v14a2 2 0 002 2h12a2 2 0 002-2V8a.5.5 0 00-.5-.5zm-9.25 13a.75.75 0 01-1.5 0v-9a.75.75 0 011.5 0v9zm4.25.75a.75.75 0 00.75-.75v-9a.75.75 0 00-1.5 0v9c0 .414.336.75.75.75zM17.25 4H22a1 1 0 110 2H2a1 1 0 010-2h4.75A.25.25 0 007 3.75V2.5A2.5 2.5 0 019.5 0h5A2.5 2.5 0 0117 2.5v1.25c0 .138.112.25.25.25zM9 2.5v1.25c0 .138.112.25.25.25h5.5a.25.25 0 00.25-.25V2.5a.5.5 0 00-.5-.5h-5a.5.5 0 00-.5.5z"
      fill="currentColor"
    />
  </Icon>
)

Trash.displayName = 'TrashIcon'

export default Trash
