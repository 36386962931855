import { Icon } from '@chakra-ui/icon'

const GiftThree = props => (
  <Icon {...props}>
    <svg
      width="24"
      height="24"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.66663 2.33334C2.68177 1.87668 2.8775 1.44468 3.21085 1.1322C3.54421 0.819722 3.98794 0.652292 4.44463 0.666673C7.07396 0.666673 7.99996 4.00001 7.99996 4.00001H4.44463C3.98794 4.01439 3.54421 3.84696 3.21085 3.53448C2.8775 3.22199 2.68177 2.79 2.66663 2.33334V2.33334Z"
        stroke="#ffffff"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M11.5553 4.00001H8C8 4.00001 8.926 0.666673 11.5553 0.666673C12.012 0.652292 12.4558 0.819722 12.7891 1.1322C13.1225 1.44468 13.3182 1.87668 13.3333 2.33334C13.3182 2.79 13.1225 3.22199 12.7891 3.53448C12.4558 3.84696 12.012 4.01439 11.5553 4.00001Z"
        stroke="#ffffff"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M14 8.66667V14C14 14.3536 13.8595 14.6928 13.6095 14.9428C13.3594 15.1929 13.0203 15.3333 12.6667 15.3333H3.33333C2.97971 15.3333 2.64057 15.1929 2.39052 14.9428C2.14048 14.6928 2 14.3536 2 14V8.66667"
        stroke="#ffffff"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M15.3333 4H0.666626V6.66667H15.3333V4Z"
        stroke="#ffffff"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M8 4V15.3333"
        stroke="#ffffff"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  </Icon>
)

GiftThree.displayName = 'GiftThreeIcon'

export default GiftThree
