import { arcadeApiClient } from '../apiClients'
import { AuthContext } from '../providers'
import { useContext } from 'react'
import useSWR from 'swr'
import { NotificationSettings } from 'containers/GameWizard'

export interface StrategicPartner {
  id: string
  name: string
  credits: {
    amount: number
    unit: string
  }
  status: 'active' | 'inactive'
}

export interface Entity {
  campaignNotificationSettings: NotificationSettings
  chestBalance: number
  companyFundsInEscrow: number
  creditCardSurchargeExempt: boolean
  credits: number
  entityBalance: number
  id: number
  sendDailyEmailSummary: boolean
  sendWeeklyEmailSummary: boolean
  strategicPartners: StrategicPartner[]
  teamManagerCanAdd: 'all_users' | 'team_members'
  timeZone: string
  totalUserCount: number
  userFundsInEscrow: number
  userVisibilityMode: 'all_users' | 'team_users'
}

const fetcher = async (entityId: string): Promise<{ entity: Entity }> =>
  await arcadeApiClient
    .get(`multi_platform/manage/entities/${entityId}`)
    .then(res => res.data)

export const useEntity = (entityId?: number) => {
  const { auth } = useContext(AuthContext)
  const id = (entityId || auth?.entityId || '').toString()
  const { data, mutate } = useSWR(id, fetcher, { isPaused: () => !id })

  return { entity: data?.entity, refetch: mutate }
}
