import { Icon } from '@chakra-ui/icon'

const CheckCircleOutline = props => (
  <Icon {...props}>
    <path
      d="M18.48 6.449a1.25 1.25 0 00-1.747.265l-5.924 8.04-3.767-3.014a1.251 1.251 0 00-1.563 1.953l4.783 3.826a1.263 1.263 0 001.787-.235l6.7-9.087a1.25 1.25 0 00-.269-1.748z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 12C0 5.373 5.373 0 12 0c6.624.007 11.993 5.376 12 12 0 6.627-5.373 12-12 12S0 18.627 0 12zm2 0c0 5.523 4.477 10 10 10 5.52-.006 9.994-4.48 10-10 0-5.523-4.477-10-10-10S2 6.477 2 12z"
      fill="currentColor"
    />
  </Icon>
)

CheckCircleOutline.displayName = 'CheckCircleOutlineIcon'

export default CheckCircleOutline
