import { Dispatch, SetStateAction } from 'react'
import { ICON_BY_NAME } from 'ui/components/Icons'
import styles from './AnalyzeTypeHeader.module.css'

export enum AnalyzeType {
  Game = 'game',
  Metric = 'metric',
}

export interface AnalyzeTypeHeaderProps {
  analyzeType: AnalyzeType
  setAnalyzeType: Dispatch<SetStateAction<AnalyzeType>>
}

export const AnalyzeTypeHeader = ({
  analyzeType,
  setAnalyzeType,
}: AnalyzeTypeHeaderProps) => {
  return (
    <>
      <h1 className={styles.analyzeHeader}>What would you like to Analyze?</h1>
      <div className={styles.optionContainer}>
        <div
          className={`${styles.analyzeOption} ${
            analyzeType == AnalyzeType.Metric
              ? styles.analyzeOptionSelected
              : ''
          }`}
          onClick={() => setAnalyzeType(AnalyzeType.Metric)}
        >
          <ICON_BY_NAME.statsgraphalternate />
          <span>Metric</span>
        </div>
        <div className={styles.analyzeOptionSpacer} />
        <div
          className={`${styles.analyzeOption} ${
            analyzeType == AnalyzeType.Game ? styles.analyzeOptionSelected : ''
          }`}
          onClick={() => setAnalyzeType(AnalyzeType.Game)}
        >
          <ICON_BY_NAME.speedometeroutline />
          <span>Game</span>
        </div>
      </div>
    </>
  )
}
