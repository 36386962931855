import { Icon } from '@chakra-ui/icon'

const Bolt = props => (
  <Icon {...props}>
    <path
      d="M20.444 9.86A.503.503 0 0020 9.6h-7.376l1.864-8.058a.475.475 0 00-.284-.544.513.513 0 00-.611.16l-10 13.44a.464.464 0 00-.04.501.503.503 0 00.447.261h7.376l-1.864 8.054a.475.475 0 00.284.544.512.512 0 00.611-.16l10-13.44a.464.464 0 00.037-.499z"
      fill="currentColor"
    />
  </Icon>
)

Bolt.displayName = 'BoltIcon'

export default Bolt
