import { Icon } from '@chakra-ui/icon'

const TagsStack = props => (
  <Icon viewBox="0 0 13 13" {...props}>
    <g fill="none">
      <path
        d="M11.5225 6.89609L5.74221 1.11584H1.39746V0.365845H5.89746C5.99691 0.365866 6.09228 0.405389 6.16259 0.47572L12.0527 6.36584L11.5225 6.89609Z"
        fill="currentColor"
      />
      <path
        d="M10.8178 7.38097L5.41259 1.97572C5.34228 1.90539 5.24691 1.86587 5.14746 1.86584H1.02246C0.923005 1.86584 0.827622 1.90535 0.757296 1.97568C0.68697 2.04601 0.647461 2.14139 0.647461 2.24084V6.36584C0.647482 6.46529 0.687005 6.56066 0.757336 6.63097L6.16259 12.0362C6.26705 12.1407 6.39108 12.2236 6.52759 12.2801C6.66409 12.3367 6.8104 12.3658 6.95815 12.3658C7.1059 12.3658 7.25221 12.3367 7.38871 12.2801C7.52521 12.2236 7.64924 12.1407 7.75371 12.0362L10.8178 8.97209C10.9223 8.86763 11.0052 8.7436 11.0618 8.60709C11.1183 8.47059 11.1474 8.32429 11.1474 8.17653C11.1474 8.02878 11.1183 7.88247 11.0618 7.74597C11.0052 7.60947 10.9223 7.48544 10.8178 7.38097ZM3.64746 5.99084C3.42496 5.99084 3.20745 5.92486 3.02244 5.80125C2.83744 5.67763 2.69324 5.50193 2.6081 5.29636C2.52295 5.0908 2.50067 4.8646 2.54408 4.64637C2.58749 4.42814 2.69463 4.22768 2.85197 4.07035C3.0093 3.91302 3.20976 3.80587 3.42798 3.76246C3.64621 3.71905 3.87241 3.74133 4.07798 3.82648C4.28355 3.91163 4.45925 4.05582 4.58286 4.24083C4.70648 4.42583 4.77246 4.64334 4.77246 4.86584C4.77246 5.16421 4.65393 5.45036 4.44296 5.66134C4.23198 5.87232 3.94583 5.99084 3.64746 5.99084Z"
        fill="currentColor"
      />
    </g>
  </Icon>
)

TagsStack.displayName = 'TagsStackIcon'

export default TagsStack
