import { Icon } from '@chakra-ui/icon'

const Unlock = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.75 9.5h.75a2 2 0 012 2V22a2 2 0 01-2 2h-15a2 2 0 01-2-2V11.5a2 2 0 012-2h11.25a.5.5 0 00.5-.5V6.75a4.251 4.251 0 00-8.322-1.222 1.25 1.25 0 01-2.395-.717A6.751 6.751 0 0118.75 6.75V9.5zm-7.103 4.022A2 2 0 0011 17.223V19.5a1 1 0 102 0v-2.277a2 2 0 00-1.353-3.7z"
      fill="currentColor"
    />
  </Icon>
)

Unlock.displayName = 'UnlockIcon'

export default Unlock
