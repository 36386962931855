import { AUTH_STORAGE_KEY } from "ui/types"

export function getIdentifiers():
  | Record<string, never>
  | {
      entityId: string
      server: 'Development' | 'Staging' | 'RTPOS' | 'Production'
    } {
  const { endpoint, entityId } = JSON.parse(
    window.localStorage.getItem(AUTH_STORAGE_KEY) || '{}',
  )

  if (!endpoint || !entityId) return {}

  return { entityId, server: getServerName(endpoint) }
}

export function getServerName(
  endpoint: string,
): 'Development' | 'Staging' | 'RTPOS' | 'Production' {
  if (/localhost/.test(endpoint)) return 'Development'
  if (/staging/.test(endpoint)) return 'Staging'
  if (/rtpos/.test(endpoint)) return 'RTPOS'

  return 'Production'
}
