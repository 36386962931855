import { Icon } from '@chakra-ui/icon'

const Measure = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.52 7.922c0 .189.11.36.282.438a7.68 7.68 0 11-6.324 0 .48.48 0 00.282-.438V.96c0-.53.43-.96.96-.96h3.84c.53 0 .96.43.96.96v6.962zm2.376 8.398a.48.48 0 00.48-.436 5.738 5.738 0 00-3.576-5.866 1.912 1.912 0 01-1.2-1.78V2.4a.48.48 0 00-.48-.48h-.96a.48.48 0 00-.48.48v5.839a1.913 1.913 0 01-1.2 1.78 5.736 5.736 0 00-3.6 5.341c0 .177.008.351.022.524a.48.48 0 00.48.436h10.514z"
      fill="currentColor"
    />
    <path
      d="M22.08 1.92h-3.84c-1.06 0-1.92.86-1.92 1.92v6.72a.96.96 0 101.92 0V4.32a.48.48 0 01.48-.48h2.88a.48.48 0 01.48.48V7.2a.48.48 0 01-.48.48h-.48a.96.96 0 100 1.92h.48a.48.48 0 01.48.48v.96a.48.48 0 01-.48.48h-.48a.96.96 0 100 1.92h.48a.48.48 0 01.48.48v.96a.48.48 0 01-.48.48h-.48a.96.96 0 100 1.92h.48a.48.48 0 01.48.48v1.331a1.996 1.996 0 01-1.595 2.002 1.92 1.92 0 01-2.133-1.248.977.977 0 00-.917-.645.952.952 0 00-.908 1.248 3.848 3.848 0 004.017 2.574A3.957 3.957 0 0024 19.029V3.84c0-1.06-.86-1.92-1.92-1.92z"
      fill="currentColor"
    />
  </Icon>
)

Measure.displayName = 'MeasureIcon'

export default Measure
