import { Icon } from '@chakra-ui/icon'

const Login = props => (
  <Icon {...props}>
    <path
      d="M22 .5H11.5a2 2 0 00-2 2v3a1 1 0 002 0V3a.5.5 0 01.5-.5h9.5a.5.5 0 01.5.5v18a.5.5 0 01-.5.5H12a.5.5 0 01-.5-.5v-2.5a1 1 0 10-2 0v3a2 2 0 002 2H22a2 2 0 002-2v-19a2 2 0 00-2-2z"
      fill="currentColor"
    />
    <path
      d="M17 14a1.5 1.5 0 000-3H8V8.5a1 1 0 00-1.555-.832l-6 4a1 1 0 000 1.664l6 4A1 1 0 008 16.5V14h9z"
      fill="currentColor"
    />
  </Icon>
)

Login.displayName = 'LoginIcon'

export default Login
