import { Icon } from '@chakra-ui/icon'

const GuageOutline = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 12C0 5.373 5.373 0 12 0c6.624.007 11.993 5.376 12 12 0 6.627-5.373 12-12 12S0 18.627 0 12zm4.672 6.8a15.838 15.838 0 0114.655 0 10 10 0 10-14.655 0z"
      fill="currentColor"
    />
    <path
      d="M5.5 11.25H4a.75.75 0 000 1.5h1.5a.75.75 0 000-1.5zM6.97 5.91a.75.75 0 10-1.06 1.06l1.06 1.06a.75.75 0 101.06-1.06L6.97 5.91zM12 6.25a.75.75 0 00.75-.75V4a.75.75 0 00-1.5 0v1.5c0 .414.336.75.75.75zM20 11.25h-1.5a.75.75 0 000 1.5H20a.75.75 0 000-1.5zM18.354 6.646a.5.5 0 00-.611-.075c-.689.414-6.753 4.066-7.511 4.824-.93.932-.93 2.44 0 3.373.94.911 2.433.911 3.373 0 .758-.758 4.41-6.822 4.824-7.51a.5.5 0 00-.075-.612z"
      fill="currentColor"
    />
  </Icon>
)

GuageOutline.displayName = 'GuageOutlineIcon'

export default GuageOutline
