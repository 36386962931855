import { Icon } from '@chakra-ui/icon'

const DotsVertical = props => (
  <Icon {...props}>
    <path
      d="M12 6.5A3.25 3.25 0 1012 0a3.25 3.25 0 000 6.5zM12 15.25a3.25 3.25 0 100-6.5 3.25 3.25 0 000 6.5zM12 24a3.25 3.25 0 100-6.5 3.25 3.25 0 000 6.5z"
      fill="currentColor"
    />
  </Icon>
)

DotsVertical.displayName = 'DotsVerticalIcon'

export default DotsVertical
