import { Icon } from '@chakra-ui/icon'

const GameWand = props => (
  <Icon {...props}>
    <path
      d="M11.616 10.616L.366 21.866a1.25 1.25 0 101.768 1.768l11.25-11.25a1.25 1.25 0 00-1.768-1.768zM23 7h-3a1 1 0 100 2h3a1 1 0 100-2zM19.707 5.707l2-2a1 1 0 10-1.414-1.414l-2 2a1 1 0 001.414 1.414zM16 0a1 1 0 00-1 1v3a1 1 0 102 0V1a1 1 0 00-1-1zM11.707 2.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414-1.414l-2-2zM9 9h3a1 1 0 100-2H9a1 1 0 000 2zM16 11a1 1 0 00-1 1v3a1 1 0 102 0v-3a1 1 0 00-1-1zM19.707 10.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414-1.414l-2-2z"
      fill="currentColor"
    />
  </Icon>
)

GameWand.displayName = 'GameWandIcon'

export default GameWand
