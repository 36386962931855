export default {
  baseStyle: {
    popper: {
      zIndex: 10,
    },
  },

  sizes: {
    sm: {
      popper: {
        width: 'fit-content',
        maxWidth: 'fit-content',
        zIndex: -1,
      },
    },
  },
}
