import { Icon } from '@chakra-ui/icon'

const Archive = props => (
  <Icon {...props}>
    <path
      d="M21.5 2.5h-19a2 2 0 00-2 2v3a1 1 0 001 1h21a1 1 0 001-1v-3a2 2 0 00-2-2z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.5 10h19a1 1 0 011 1v8.5a2 2 0 01-2 2h-17a2 2 0 01-2-2V11a1 1 0 011-1zM14 14.75a1.252 1.252 0 001.25-1.25A1.252 1.252 0 0014 12.25h-4a1.25 1.25 0 100 2.5h4z"
      fill="currentColor"
    />
  </Icon>
)

Archive.displayName = 'ArchiveIcon'

export default Archive
