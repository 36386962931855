import { Icon } from '@chakra-ui/icon'

const StatsFive = props => (
  <Icon {...props}>
    <path
      d="M.5 15.5A.5.5 0 011 15h2a.5.5 0 01.5.5v5a.5.5 0 01-.5.5H1a.5.5 0 01-.5-.5v-5zM5.5 12.5A.5.5 0 016 12h2a.5.5 0 01.5.5v8a.5.5 0 01-.5.5H6a.5.5 0 01-.5-.5v-8zM10.5 9.5A.5.5 0 0111 9h2a.5.5 0 01.5.5v11a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-11zM15.5 6.5A.5.5 0 0116 6h2a.5.5 0 01.5.5v14a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-14zM20.5 3.5A.5.5 0 0121 3h2a.5.5 0 01.5.5v17a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-17z"
      fill="currentColor"
    />
  </Icon>
)

StatsFive.displayName = 'StatsFiveIcon'

export default StatsFive
