import { Icon } from '@chakra-ui/icon'

const Edit = props => (
  <Icon {...props}>
    <path
      d="M19.2 11.488c-.53 0-.96.448-.96 1v8.5c0 .552-.43 1-.96 1H2.88c-.53 0-.96-.448-.96-1v-17c0-.552.43-1 .96-1h9.6c.53 0 .96-.448.96-1s-.43-1-.96-1h-9.6C1.29.988 0 2.331 0 3.988v17c0 1.657 1.29 3 2.88 3h14.4c1.59 0 2.88-1.343 2.88-3v-8.5c0-.552-.43-1-.96-1zM22.478.578a1.91 1.91 0 00-2.715 0l-1.018 1.061a.515.515 0 000 .707l2.036 2.121a.467.467 0 00.679 0l1.018-1.061a2.06 2.06 0 000-2.828z"
      fill="currentColor"
    />
    <path
      d="M10.905 10.514l2.036 2.121a.467.467 0 00.678 0l6.11-6.364a.511.511 0 000-.708l-2.037-2.122a.481.481 0 00-.679 0l-6.108 6.366a.515.515 0 000 .707zM9.935 11.235a.491.491 0 00-.35.3L8.423 14.57a.515.515 0 00.107.539.493.493 0 00.517.111l2.909-1.212a.495.495 0 00.292-.364.514.514 0 00-.13-.454l-1.749-1.818a.468.468 0 00-.433-.137z"
      fill="currentColor"
    />
  </Icon>
)

Edit.displayName = 'EditIcon'

export default Edit
