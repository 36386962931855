import { Icon } from '@chakra-ui/icon'

const CircleWithHole = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 12C0 5.373 5.373 0 12 0c6.624.007 11.993 5.376 12 12 0 6.627-5.373 12-12 12S0 18.627 0 12zm6.5 0a5.5 5.5 0 1011 0 5.5 5.5 0 00-11 0z"
      fill="currentColor"
    />
  </Icon>
)

CircleWithHole.displayName = 'CircleWithHoleIcon'

export default CircleWithHole
