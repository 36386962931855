import { v4 as randomUUID } from 'uuid'

const STORAGE_KEY = 'slackOAuthState'

export function useStatefulSlackURL(url: string) {
  const state = (() => {
    const savedState = localStorage.getItem(STORAGE_KEY)
    if (savedState) return savedState

    const newKey = window.btoa(randomUUID())
    localStorage.setItem(STORAGE_KEY, newKey)
    return newKey
  })()

  const urlWithState = new URL(url as string)
  urlWithState.searchParams.set('state', state)

  const statefulURL = urlWithState.toString()

  const validateOAuthResponse = (response: string): Promise<void> =>
    response === localStorage.getItem(STORAGE_KEY)
      ? Promise.resolve(localStorage.removeItem(STORAGE_KEY))
      : Promise.reject(
          'The returned state parameter does not match with expected value',
        )

  return { statefulURL, validateOAuthResponse }
}
