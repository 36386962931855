import { Icon } from '@chakra-ui/icon'

const TorchOne = props => (
  <Icon {...props}>
    <path
      d="M18.608 11.04H5.468a.503.503 0 00-.44.253.464.464 0 00.023.491l1.4 2.027a2.522 2.522 0 002.087 1.069h.207l1.586 6.848c.177.767.886 1.313 1.705 1.313.818 0 1.527-.546 1.704-1.313l1.585-6.848h.213a2.511 2.511 0 002.075-1.067l1.411-2.027a.464.464 0 00.024-.493.503.503 0 00-.44-.253zM18.123 3.714a.516.516 0 00-.516-.091.483.483 0 00-.317.401 1.29 1.29 0 01-.506.875 1.396 1.396 0 01-1.002.272c-1.207-1.167-1.543-2.927-.846-4.429a.464.464 0 00.02-.49.503.503 0 00-.438-.252c-3.375 0-5.792 1.788-6.41 4.696-.636-.073-1.148-.535-1.264-1.14a.484.484 0 00-.315-.385.516.516 0 00-.504.081c-1.858 1.48-2.45 3.973-1.445 6.08a.502.502 0 00.448.268h4.39a.496.496 0 00.488-.373 5.728 5.728 0 011.613-2.88.257.257 0 01.235-.057.25.25 0 01.175.162C12.622 8.64 14.272 9.6 14.754 9.6h4.414a.5.5 0 00.469-.315 4.3 4.3 0 00.281-1.547 5.645 5.645 0 00-1.795-4.024z"
      fill="currentColor"
    />
  </Icon>
)

TorchOne.displayName = 'TorchOneIcon'

export default TorchOne
