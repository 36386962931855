import { arcadeApiClient } from '../apiClients'
import useSWR from 'swr'
import { FundingSource } from 'containers/GameWizard'

const fundingSourcesFetcher = async (url: string): Promise<{ fundingSources: FundingSource[] }> => 
  await arcadeApiClient.get(url).then(res => res.data)

export const useFundingSources = () => {
  const { data, isValidating, error, mutate } = useSWR('/multi_platform/funding_sources', fundingSourcesFetcher)

  return {
    fundingSources: data?.fundingSources as FundingSource[],
    loading: isValidating,
    refetch: mutate,
    error,
  }
}
