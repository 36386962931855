import useSWRImmutable from 'swr/immutable'
import { arcadeApiClient } from '../apiClients'

const fetcher = ([_generalKey, userId]) =>
  arcadeApiClient.get(`/multi_platform/users/${userId}`).then(res => {
    // This route returns the logged in user if the id is not found; ignore
    if (res.data.user.id != userId) {
      throw new Error('User not found')
    }
    return res.data.user.fullName
  })

export const useUserInfo = (userId: number | string | null | undefined) => {
  const { data: fullName, error } = useSWRImmutable(
    userId ? ['basic-user-info', userId] : null,
    fetcher,
  )

  return { error, fullName, loading: !error && !fullName }
}
