import { Icon } from '@chakra-ui/icon'

const FileFolder = props => (
  <Icon {...props}>
    <path
      d="M22.5 5H11.236a.5.5 0 01-.447-.276l-.947-1.9A1.494 1.494 0 008.5 2h-7A1.5 1.5 0 000 3.5v17A1.5 1.5 0 001.5 22h21a1.5 1.5 0 001.5-1.5v-14A1.5 1.5 0 0022.5 5z"
      fill="currentColor"
    />
  </Icon>
)

FileFolder.displayName = 'FileFolderIcon'

export default FileFolder
