import { useMemo } from 'react'
import useSWR, { Key, KeyedMutator, SWRConfiguration } from 'swr'
import { AxiosError, AxiosResponse } from 'axios'

import { useStateMachine } from '../useStateMachine'
import { ApiRequestState, networkRequestDigraph } from './stateMachine'
import {
  DataNormalizer,
  FetchMethods,
  makeFetcher,
  QueryParams,
} from './request'

export interface UseApiOptions<InputData, OutputData> {
  config?: SWRConfiguration<OutputData, Error>
  normalizer?: DataNormalizer<InputData, OutputData>
  onError?: (error: AxiosError<InputData>) => void
  method?: FetchMethods
  params?: QueryParams
}
interface UseApiOutput<Data> {
  data?: Data
  error?: Error
  mutate: KeyedMutator<Data>
  isValidating: boolean
  isLoading: boolean
  cancel: () => void
  resend: () => void
  requestState: ApiRequestState
}

export const useApi = <InputData, OutputData>(
  key: Key,
  {
    method = 'GET',
    params,
    normalizer,
    onError,
    config = {},
  }: UseApiOptions<InputData, OutputData> = {},
): UseApiOutput<OutputData> => {
  const [state, setState] = useStateMachine(
    ApiRequestState.INITIAL,
    networkRequestDigraph,
  )

  const onLoad = (): void => setState(ApiRequestState.LOADING)
  const onSucceed = (response: AxiosResponse<InputData>): void => {
    setState(ApiRequestState.SUCCESS)
    normalizer?.(response)
  }
  const onFail = (error: AxiosError<InputData>): void => {
    setState(ApiRequestState.ERROR)
    onError?.(error)
  }
  const cancel = (): void => setState(ApiRequestState.CANCELLED)

  const fetcher = useMemo(
    () =>
      makeFetcher<InputData, OutputData>({
        onLoad,
        onSucceed,
        onFail,
        method,
        params,
      }),
    [normalizer, method, params],
  )
  const swrResponse = useSWR<OutputData, Error>(key, fetcher, config)
  const isLoading = state === ApiRequestState.LOADING

  return {
    data: swrResponse.data,
    error: swrResponse.error,
    mutate: swrResponse.mutate,
    isValidating: swrResponse.isValidating,
    resend: swrResponse.mutate,
    cancel,
    isLoading,
    requestState: state,
  }
}
