import chakra from './chakra-theme'
import arc from './arc-theme'

export default {
  parts: [...chakra.parts, ...arc.parts],
  baseStyle: {
    ...chakra.baseStyle,
  },
  sizes: {
    ...chakra.sizes,
  },
  variants: {
    ...chakra.variants,
    ...arc.variants,
  },
  defaultProps: {
    ...chakra.defaultProps,
  },
}
