import { Icon } from '@chakra-ui/icon'

const BoltRadiate = props => (
  <Icon {...props}>
    <path
      d="M21.18 4.082c-5.193-4.763-13.166-4.763-18.359 0a1 1 0 101.357 1.466 11.573 11.573 0 0115.643 0 1 1 0 101.359-1.466z"
      fill="currentColor"
    />
    <path
      d="M18.189 6.955a9 9 0 00-12.378 0 1 1 0 001.375 1.451 7 7 0 019.628 0 1 1 0 001.375-1.453v.002z"
      fill="currentColor"
    />
    <path
      d="M15.1 14.49h-1.871a.25.25 0 01-.25-.25V8.99a.5.5 0 00-.891-.313l-5.9 7.371a.875.875 0 00.667 1.442h1.874a.25.25 0 01.25.25v5.25a.5.5 0 00.891.312l5.9-7.371a.874.874 0 00-.67-1.441z"
      fill="currentColor"
    />
  </Icon>
)

BoltRadiate.displayName = 'BoltRadiateIcon'

export default BoltRadiate
