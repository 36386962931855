import { useContext } from 'react'
import useSWR from 'swr'
import { arcadeApiClient } from '../apiClients'
import { SpectatorViewContext } from '../providers'
import { Team } from '../types'
import { useCurrentUser } from './'

const teamsFetcher = ([_key, id]): Promise<{ teams: Team[] }> =>
  arcadeApiClient
    .get(`/multi_platform/users/${id}/newsfeed_teams`)
    .then(res => res.data)

/*
      This hook returns the teams that are used to populate the team feeds selector.
      There are three possible collections depending on the role of the user:
        * Players will see teams that they are a member of
        * Team managers will see managed teams + teams they are a member of
        * Company managers and up will see all teams
*/
export const useNewsfeedTeams = () => {
  const { assumedUser } = useContext(SpectatorViewContext)
  const user = useCurrentUser()

  const id = assumedUser?.id || user?.id

  const { data, isValidating, error } = useSWR(
    id ? ['newsfeed-teams', id] : null,
    teamsFetcher,
  )

  return {
    teams: data?.teams as Team[],
    loading: isValidating,
    error,
  }
}
