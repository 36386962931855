import { Icon } from '@chakra-ui/icon'

const RibbonFirst = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.51 1.042c3.988.704 6.882 4.054 6.875 7.958-.004 2.833-1.55 5.457-4.073 6.914v6.24c0 .373-.233.71-.591.854a.985.985 0 01-1.045-.201l-2.506-2.415a.243.243 0 00-.34 0l-2.506 2.415a.985.985 0 01-1.045.2.924.924 0 01-.591-.853v-6.24C4.21 13.906 2.723 9.79 4.154 6.137c1.43-3.654 5.37-5.798 9.357-5.095zM5.053 9c0 3.696 3.11 6.692 6.948 6.692 3.835-.004 6.944-2.998 6.948-6.692 0-3.696-3.11-6.692-6.948-6.692-3.837 0-6.948 2.996-6.948 6.692z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 3.462c3.176 0 5.75 2.48 5.75 5.538-.003 3.058-2.576 5.535-5.75 5.539-3.176 0-5.75-2.48-5.75-5.539 0-3.059 2.574-5.538 5.75-5.538zm1.673 7.615a.47.47 0 00-.479-.462h-.48V6.923a.92.92 0 00-.529-.826.989.989 0 00-1.003.088l-1.15.83a.454.454 0 00.099.794.494.494 0 00.476-.055l1.15-.83v3.691h-.48a.47.47 0 00-.479.462.47.47 0 00.48.462h1.916a.47.47 0 00.48-.462z"
      fill="currentColor"
    />
  </Icon>
)

RibbonFirst.displayName = 'RibbonFirstIcon'

export default RibbonFirst
