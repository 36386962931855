import { Icon } from '@chakra-ui/icon'

const Link = props => (
  <Icon {...props}>
    <path
      d="M12.406 14.905a1 1 0 00-.543 1.307 1 1 0 01-.217 1.09l-2.828 2.829a2 2 0 01-2.828 0L3.868 18.01a2 2 0 010-2.829L6.7 12.353a1.013 1.013 0 011.091-.217 1 1 0 10.763-1.849 3.034 3.034 0 00-3.268.652l-2.832 2.828a4.006 4.006 0 000 5.657l2.122 2.121a4 4 0 005.656 0l2.829-2.828a3.008 3.008 0 00.651-3.27 1 1 0 00-1.306-.542z"
      fill="currentColor"
    />
    <path
      d="M7.757 16.241a1.011 1.011 0 001.414 0l7.779-7.778a1 1 0 00-1.414-1.414l-7.779 7.778a1 1 0 000 1.414z"
      fill="currentColor"
    />
    <path
      d="M21.546 4.574l-2.121-2.121a4.006 4.006 0 00-5.657 0l-2.829 2.828a3.006 3.006 0 00-.651 3.269 1 1 0 001.849-.764 1 1 0 01.217-1.086l2.828-2.828a2 2 0 012.829 0l2.121 2.121a2 2 0 010 2.829L17.3 11.645a1.015 1.015 0 01-1.091.217 1 1 0 00-.765 1.849 3.026 3.026 0 003.27-.651l2.828-2.828a4.007 4.007 0 00.004-5.658z"
      fill="currentColor"
    />
  </Icon>
)

Link.displayName = 'LinkIcon'

export default Link
