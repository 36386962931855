export const userRoleIsAtLeast = ({
  requiredRole,
  userRole,
}: {
  requiredRole: string
  userRole: string
}): boolean => {
  const VALID_ROLES = ['player', 'team_manager', 'company_manager', 'champion', 'csm', 'admin']

  /* This is to protect against cases where an undefined value gets passed in for role. The hunch is that this happens when we attempt to perform a role check before roles have loaded */
  if(!VALID_ROLES.includes(userRole)) {
    return false
  }

  switch (requiredRole) {
    case 'player':
      return VALID_ROLES.includes(
        userRole,
      )
    case 'team_manager':
      return userRole !== 'player'
    case 'company_manager':
      return userRole !== 'player' && userRole !== 'team_manager'
    case 'champion':
      return ['champion', 'csm', 'admin'].includes(userRole)
    case 'csm':
      return ['csm', 'admin'].includes(userRole)
    case 'admin':
      return userRole === 'admin'
    default:
      return false
  }
}
