import { Icon } from '@chakra-ui/icon'

const GameSpaceInvader = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23 9.25h-1.5a.5.5 0 01-.5-.5v-3.5a1 1 0 00-1-1h-3.5a.5.5 0 01-.5-.5v-1.5a1 1 0 00-1-1H9a1 1 0 00-1 1v1.5a.5.5 0 01-.5.5H4a1 1 0 00-1 1v3.5a.5.5 0 01-.5.5H1a1 1 0 00-1 1v6a1 1 0 001 1h4a1 1 0 001-1v-1.5a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v1.5a1 1 0 001 1h4a1 1 0 001-1v-1.5a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v1.5a1 1 0 001 1h4a1 1 0 001-1v-6a1 1 0 00-1-1zm-12.5 1a.5.5 0 01-.5.5H7a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5h3a.5.5 0 01.5.5v2zm6.5.5a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5h-3a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h3z"
      fill="currentColor"
    />
    <path
      d="M10.5 20.25a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v2a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-2z"
      fill="currentColor"
    />
  </Icon>
)

GameSpaceInvader.displayName = 'GameSpaceInvaderIcon'

export default GameSpaceInvader
