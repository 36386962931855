import { Icon } from '@chakra-ui/icon'

const AttachmentAlternate = props => (
  <Icon {...props}>
    <path
      d="M12 0C8.157.004 5.043 2.993 5.038 6.683v11.433c-.026 1.775.946 3.426 2.543 4.32a5.313 5.313 0 005.17 0c1.596-.894 2.568-2.545 2.542-4.32V9.324c0-1.745-1.474-3.16-3.293-3.16-1.819 0-3.293 1.415-3.293 3.16V16.8c0 .53.448.96 1 .96s1-.43 1-.96V9.324c0-.685.579-1.24 1.293-1.24s1.293.555 1.293 1.24v8.792c.02 1.086-.571 2.097-1.547 2.645a3.24 3.24 0 01-3.16 0c-.977-.548-1.568-1.56-1.548-2.645V6.683c0-2.631 2.222-4.764 4.962-4.764 2.74 0 4.962 2.133 4.962 4.764v7.237c0 .53.448.96 1 .96s1-.43 1-.96V6.683C18.957 2.993 15.842.004 12 0z"
      fill="currentColor"
    />
  </Icon>
)

AttachmentAlternate.displayName = 'AttachmentAlternateIcon'

export default AttachmentAlternate
