export enum SSKeyManageGames {
  GameColumns = 'manageGames.columns',
  GameStatusFilter = 'manageGames.statusFilter',
  GameTypeFilter = 'manageGames.typeFilter',
  GameMetricFilter = 'manageGames.metricFilter',
}

export enum SSKeyManageMetrics {
  ShowEnabled = 'manageMetrics.showEnabled',
  ShowDisabled = 'manageMetrics.showDisabled',
}

export const SS_KEY_TABLE_CONFIGS = 'sessionTableConfigs'

type SSKey = SSKeyManageGames | SSKeyManageMetrics | typeof SS_KEY_TABLE_CONFIGS

export const useSessionStorage = (storageKey: SSKey, defaultValue?: any) => {
  const getBoolean = () => {
    const savedValue = sessionStorage.getItem(storageKey)
    // If saved value, compare to string
    if (savedValue) return savedValue === 'true'
    // If a boolean was passed as defaultValue, return that
    if (typeof defaultValue === 'boolean') return defaultValue
    // Otherwise only return true if the string 'true' was passed as defaultValue
    return defaultValue === 'true'
  }

  const setBoolean = (val: boolean) =>
    sessionStorage.setItem(storageKey, val.toString())

  const setNumber = (val: number) =>
    sessionStorage.setItem(storageKey, val.toString())

  const getNumber = () => {
    const savedValue = sessionStorage.getItem(storageKey)

    if (savedValue !== null) {
      const asNumber = Number(savedValue)
      if (Number.isFinite(asNumber)) return asNumber
    }

    if (defaultValue) {
      // Shouldn't need to convert this, but TS was confused if not
      const asNumber = Number(defaultValue)
      if (Number.isFinite(asNumber)) return asNumber
    }

    return null
  }

  const getJSON = () => {
    const savedValue = sessionStorage.getItem(storageKey)

    if (savedValue !== null) return JSON.parse(savedValue)
    if (defaultValue !== undefined) return defaultValue
    return null
  }

  const setJSON = (val: any) =>
    sessionStorage.setItem(storageKey, JSON.stringify(val))

  const getString = (): string => {
    const savedValue = sessionStorage.getItem(storageKey)
    if (savedValue !== null) return savedValue

    if (defaultValue) return String(defaultValue)
    return ''
  }
  const setString = (val: string) => sessionStorage.setItem(storageKey, val)

  const removeItem = () => sessionStorage.removeItem(storageKey)

  return {
    getBoolean,
    getNumber,
    getJSON,
    getString,
    removeItem,
    setBoolean,
    setNumber,
    setJSON,
    setString,
  }
}
