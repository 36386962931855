import { Icon } from '@chakra-ui/icon'

const Balloon = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 0c4.379 0 7.929 3.408 7.929 7.612 0 3.554-2.468 7.726-5.842 9.003a.244.244 0 00-.147.152.232.232 0 00.031.205l.36.517c.234.338.255.772.056 1.13-.2.357-.588.58-1.01.581h-.127a.245.245 0 00-.25.24v1.2c0 .431.051.48.5.48.552 0 1 .43 1 .96s-.448.96-1 .96a2.376 2.376 0 01-1.828-.645A2.177 2.177 0 0111 20.64v-1.2a.245.245 0 00-.25-.24h-.127c-.422 0-.81-.224-1.01-.581-.2-.358-.178-.792.056-1.13l.36-.517a.232.232 0 00.031-.205.244.244 0 00-.147-.152c-3.374-1.277-5.842-5.451-5.842-9.003C4.071 3.408 7.621 0 12 0zM8.044 5.904C8.81 4.486 10.336 3.597 12 3.6c.414 0 .75-.322.75-.72 0-.398-.336-.72-.75-.72-2.223-.004-4.263 1.184-5.286 3.079a.695.695 0 00.023.727.76.76 0 00.673.337.752.752 0 00.634-.399z"
      fill="currentColor"
    />
  </Icon>
)

Balloon.displayName = 'BalloonIcon'

export default Balloon
