import { Icon } from '@chakra-ui/icon'

const Party = props => (
  <Icon {...props}>
    <svg
      width="24"
      height="24"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M1.33325 14.6667L4.66659 4.66666L11.3333 11.3333L1.33325 14.6667Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M6.6665 3.33333L7.33317 1.33333"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M12.6665 9.33333L14.6665 8.66666"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M11.3333 3.33333C11.0696 3.33333 10.8118 3.25513 10.5926 3.10862C10.3733 2.96212 10.2024 2.75388 10.1015 2.51024C10.0006 2.26661 9.97417 1.99852 10.0256 1.73988C10.0771 1.48124 10.2041 1.24366 10.3905 1.05719C10.577 0.870719 10.8146 0.743731 11.0732 0.692284C11.3319 0.640837 11.5999 0.667242 11.8436 0.768159C12.0872 0.869076 12.2955 1.03997 12.442 1.25924C12.5885 1.4785 12.6667 1.73629 12.6667 2C12.6667 2.35362 12.5262 2.69276 12.2761 2.94281C12.0261 3.19286 11.687 3.33333 11.3333 3.33333Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M15.3333 2C15.3333 3.06087 14.9118 4.07828 14.1617 4.82843C13.4115 5.57857 12.3941 6 11.3333 6"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M3.17456 9.14267L4.64789 13.562"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M5.19067 5.19066L7.64734 12.562"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </Icon>
)

Party.displayName = 'PartyIcon'

export default Party
