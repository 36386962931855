import { useContext, useEffect, useState } from 'react'
import { FeaturesContext } from './../providers'
import arcadeApiClient from '../apiClients/arcadeApiClient'
import { Feature, GlobalFeature } from './../types'

/*
This hook is used to drive out frontend feature flagging machinery. It accepts a string value that matches a valid feature key
and will return a boolean value indicating whether or not the feature is enabled.

A recommended pattern is to define relevant feature flags in a types object. For example: packages/mp/src/types/Feature.ts
*/

export const useFeatures = (...features: Feature[]): boolean => {
  const { enabledFeatures } = useContext(FeaturesContext)

  return features.every(feature => enabledFeatures?.includes(feature))
}

export const useGlobalFeatures = (
  ...features: GlobalFeature[]
): boolean | undefined => {
  const [enabledFeatures, setEnabledFeatures] = useState<GlobalFeature[]>()
  useEffect(() => {
    arcadeApiClient
      .get('/multi_platform/features/global')
      .then(res => setEnabledFeatures(res.data.features))
  }, [setEnabledFeatures])

  if (!enabledFeatures) return undefined

  return features?.every(feature => enabledFeatures?.includes(feature))
}
