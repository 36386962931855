import { Icon } from '@chakra-ui/icon'

const Heart = props => (
  <Icon viewBox="0 0 28 26" {...props}>
    <path
      d="M13.8299 23.655L3.40529 13.1823C2.07065 11.8415 1.33641 10.0618 1.33641 8.1642C1.33641 6.2664 2.06968 4.48683 3.40514 3.14623C4.741 1.80522 6.51152 1.06761 8.39792 1.06761C10.2843 1.06761 12.0547 1.80516 13.3906 3.14609C13.4097 3.16527 13.4217 3.17744 13.4341 3.1906L13.8299 3.61157L14.2256 3.1906C14.2379 3.1775 14.251 3.16432 14.2702 3.14499C15.605 1.8052 17.3754 1.06761 19.2618 1.06761C21.1481 1.06761 22.9186 1.80516 24.2544 3.14608C25.589 4.48684 26.3233 6.26762 26.3233 8.1642C26.3233 10.0618 25.5891 11.8415 24.2544 13.1823L13.8299 23.655Z"
      fill="currentColor"
    />
  </Icon>
)

Heart.displayName = 'HeartIcon'

export default Heart
