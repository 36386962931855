import { Icon } from '@chakra-ui/icon'

const TargetAlternate = props => (
  <Icon {...props}>
    <path
      d="M22.985 3.087a.456.456 0 00-.37-.191H21a.235.235 0 01-.23-.24V.98a.485.485 0 00-.189-.385.448.448 0 00-.41-.072c-.585.198-1.156.437-1.71.715v2.938a.245.245 0 01-.067.17l-.461.48a.225.225 0 01-.253.052.24.24 0 01-.142-.223V1.746a5.096 5.096 0 00-2.492 2.827c-.18.846.019 1.73.541 2.406a.249.249 0 01-.025.309l-.821.853a.222.222 0 01-.308.016c-3.287-2.755-8.042-2.508-11.048.574-3.005 3.082-3.302 8.015-.69 11.462a.246.246 0 010 .296l-1.111 1.459a.983.983 0 00.151 1.341c.401.32.974.25 1.293-.157l.97-1.275a.225.225 0 01.318-.044 8.03 8.03 0 0010.022-.146.225.225 0 01.314.028l1.27 1.48a.913.913 0 001.303.078.984.984 0 00.076-1.352l-1.384-1.614a.249.249 0 01-.014-.303c2.013-2.811 2.178-6.611.414-9.598a.23.23 0 00-.37-.041l-1.178 1.231a.239.239 0 00-.038.276c1.488 2.913.632 6.522-1.988 8.385-2.62 1.863-6.17 1.387-8.249-1.106-2.078-2.493-2.023-6.208.128-8.634 2.152-2.425 5.714-2.787 8.278-.841a.248.248 0 01.092.175.246.246 0 01-.066.192l-.671.697a.226.226 0 01-.295.027 4.622 4.622 0 00-3.832-.766c-2.26.538-3.836 2.662-3.762 5.067.074 2.406 1.778 4.42 4.066 4.808 2.288.387 4.52-.961 5.326-3.218a5.062 5.062 0 00-.011-3.527.235.235 0 00-.17-.144.227.227 0 00-.21.066l-1.12 1.162a.24.24 0 00-.062.205 2.951 2.951 0 01-.177 1.58c-.403 1.061-1.32 1.819-2.408 1.987-1.59.201-3.052-.929-3.321-2.568-.27-1.639.748-3.208 2.312-3.566a2.833 2.833 0 011.933.239.244.244 0 01.062.385l-.812.844a1.533 1.533 0 00-.543-.103c-.892 0-1.616.75-1.616 1.677 0 .926.724 1.677 1.616 1.677.892 0 1.615-.75 1.615-1.677a1.718 1.718 0 00-.099-.558l5.792-6.012a.224.224 0 01.296-.027c.494.396 1.096.62 1.72.639.202 0 .404-.026.6-.077 2.474-.634 3.844-5.131 3.902-5.322a.494.494 0 00-.072-.43z"
      fill="currentColor"
    />
  </Icon>
)

TargetAlternate.displayName = 'TargetAlternateIcon'

export default TargetAlternate
