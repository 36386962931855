import { Icon } from '@chakra-ui/icon'

const ThumbsUp = props => (
  <Icon {...props}>
    <path
      d="M5.85 20.0001C5.43333 20.0001 5.07917 19.8543 4.7875 19.5626C4.49583 19.271 4.35 18.9168 4.35 18.5001V7.80012C4.35 7.61679 4.3875 7.43345 4.4625 7.25012C4.5375 7.06679 4.64167 6.90845 4.775 6.77512L9.95 1.32512C10.1833 1.09179 10.4625 0.95012 10.7875 0.90012C11.1125 0.85012 11.4167 0.908454 11.7 1.07512C11.9833 1.24179 12.1958 1.47512 12.3375 1.77512C12.4792 2.07512 12.5167 2.38345 12.45 2.70012L11.5 7.20012H19.5C19.8833 7.20012 20.2292 7.35429 20.5375 7.66262C20.8458 7.97095 21 8.31679 21 8.70012V10.7501C21 10.9335 20.9792 11.146 20.9375 11.3876C20.8958 11.6293 20.8333 11.8418 20.75 12.0251L17.85 18.7251C17.7 19.0751 17.4542 19.3751 17.1125 19.6251C16.7708 19.8751 16.4083 20.0001 16.025 20.0001H5.85ZM1.425 20.0001C1.04167 20.0001 0.708333 19.8585 0.425 19.5751C0.141667 19.2918 0 18.9585 0 18.5751V8.62512C0 8.24179 0.141667 7.90845 0.425 7.62512C0.708333 7.34179 1.04167 7.20012 1.425 7.20012C1.80833 7.20012 2.14167 7.34179 2.425 7.62512C2.70833 7.90845 2.85 8.24179 2.85 8.62512V18.5751C2.85 18.9585 2.70833 19.2918 2.425 19.5751C2.14167 19.8585 1.80833 20.0001 1.425 20.0001Z"
      fill="#71717A"
    />
  </Icon>
)

ThumbsUp.displayName = 'ThumbsUpIcon'

export default ThumbsUp
