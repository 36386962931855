import { Icon } from '@chakra-ui/icon'

const SizeExpand = props => (
  <Icon {...props}>
    <path
      d="M0 23a1 1 0 001 1h5a1 1 0 00.707-1.707l-1.439-1.44a.249.249 0 010-.353l3.616-3.616a1.25 1.25 0 10-1.768-1.768L3.5 18.731a.25.25 0 01-.354 0l-1.439-1.439A1 1 0 000 18v5zM24 1a1 1 0 00-1-1h-5a1 1 0 00-.707 1.707l1.439 1.439a.25.25 0 010 .354l-3.616 3.615a1.25 1.25 0 001.768 1.768L20.5 5.267a.25.25 0 01.354 0l1.439 1.439A1 1 0 0024 6V1zM0 6a1 1 0 001.707.707L3.323 5.09l3.793 3.793a1.25 1.25 0 101.768-1.768L5.268 3.5a.25.25 0 010-.354l1.439-1.44A1 1 0 006 0H1a1 1 0 00-1 1v5zM24 18a1 1 0 00-1.707-.707l-1.439 1.439a.25.25 0 01-.354 0l-3.616-3.616a1.25 1.25 0 00-1.768 1.768l3.616 3.616a.249.249 0 010 .353l-1.439 1.44A1 1 0 0018 24h5a1 1 0 001-1v-5z"
      fill="currentColor"
    />
  </Icon>
)

SizeExpand.displayName = 'SizeExpandIcon'

export default SizeExpand
