import { Box, Flex } from '@chakra-ui/react'
import Select, { StylesConfig } from 'react-select'
import { DateRangePicker } from 'containers/ROIContainer/ROIEngagementContainer/FilterBar/DateRangePicker/DateRangePicker'
import { SelectionOption } from 'providers/dashboards/AnalyzeDashboardProvider'

export enum PeriodType {
  Custom = 'custom',
  Like = 'like',
  Sequential = 'sequential',
  Weekdays = 'weekdays',
}

interface DateRangesObject {
  [key: number]: Date[]
}

export interface AnalyzePeriodHeaderProps {
  dateRanges: DateRangesObject
  handleOptionsChange: any // SetStateAction<Dispatch<FilterOptions>>
  periodCount: SelectionOption | null
  periodType: SelectionOption | null
}

const selectStyles: StylesConfig = {
  // @ts-ignore
  control: baseStyles => ({
    ...baseStyles,
    borderRadius: '99px',
    paddingLeft: 'var(--chakra-sizes-6)',
    minWidth: '250px',
  }),
  // @ts-ignore
  option: baseStyles => ({
    ...baseStyles,
    zIndex: 9999,
  }),
  // @ts-ignore
  menu: baseStyles => ({
    ...baseStyles,
    zIndex: 9999,
  }),
  // @ts-ignore
  placeholder: baseStyles => ({
    ...baseStyles,
    color: 'var(--chakra-colors-text-primary)',
    fontWeight: '600',
    opacity: '0.5',
  }),
}

const numberOfPeriodOptions = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
]

const periodTypeOptions = [
  { label: 'Sequential', value: PeriodType.Sequential },
  { label: 'Like', value: PeriodType.Like },
  { label: 'Same Weekdays', value: PeriodType.Weekdays },
  { label: 'Custom', value: PeriodType.Custom },
]

export const AnalyzePeriodHeader = ({
  dateRanges,
  handleOptionsChange,
  periodCount,
  periodType,
}: AnalyzePeriodHeaderProps) => {
  const isCustomPeriods =
    periodType?.value == PeriodType.Custom && !!periodCount?.value

  const handleDateRangeChange = (index, newRange) => {
    handleOptionsChange(currentOptions => ({
      ...currentOptions,
      customComparisonPeriods: {
        ...currentOptions.customComparisonPeriods,
        [index]: newRange,
      },
    }))
  }

  const handlePeriodCountChange = periodCount => {
    handleOptionsChange(currentOptions => ({
      ...currentOptions,
      periodCount,
    }))
  }

  const handlePeriodTypeChange = periodType => {
    handleOptionsChange(currentOptions => ({
      ...currentOptions,
      periodType,
    }))
  }

  const renderCustomPeriods = periodCount =>
    Array.from({ length: periodCount }, (_value, index) => {
      return (
        <Box marginTop={4} width={'400px'}>
          <p>Comparison Period {index + 1}.</p>
          <DateRangePicker
            setValue={newRange => handleDateRangeChange(index, newRange)}
            value={dateRanges[index] || [null, null]}
          />
        </Box>
      )
    })

  return (
    <Box marginBottom={4} marginTop={7}>
      <Flex flexDirection="row">
        <Box marginRight={8}>
          <h1>Number of Comparison Periods</h1>
          <Select
            options={numberOfPeriodOptions}
            onChange={handlePeriodCountChange}
            value={periodCount}
            styles={selectStyles}
            menuPortalTarget={document.body}
          />
        </Box>
        <Box>
          <h1>Comparison Period Types</h1>
          <Select
            options={periodTypeOptions}
            onChange={handlePeriodTypeChange}
            value={periodType}
            styles={selectStyles}
            menuPortalTarget={document.body}
          />
        </Box>
      </Flex>
      {isCustomPeriods && renderCustomPeriods(periodCount?.value)}
    </Box>
  )
}
