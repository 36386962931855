import { Icon } from '@chakra-ui/icon'

const LeftPagination = props => (
  <Icon viewBox="0 0 6 10" {...props}>
    <path d="M6 0L6 10L0 5L6 0Z" fill="currentColor" />
  </Icon>
)

LeftPagination.displayName = 'LeftPaginationIcon'

export default LeftPagination
