import { Icon } from '@chakra-ui/icon'

const Cursor = props => (
  <Icon {...props}>
    <path
      d="M19.644 16.813l-4.668-4.667a.249.249 0 01.034-.382l3-2.08a1 1 0 00-.283-1.78L5.029 4.093a.751.751 0 00-.934.934l3.811 12.7a1 1 0 001.78.282l2.079-3a.25.25 0 01.382-.035l4.668 4.668a1 1 0 001.414 0l1.415-1.415a1 1 0 000-1.414z"
      fill="currentColor"
    />
  </Icon>
)

Cursor.displayName = 'CursorIcon'

export default Cursor
