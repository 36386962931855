import { GameCard, Reward } from 'ui/types/Games'
import { Assignment } from 'ui/types/GameTypes'

export interface Ranking {
  isSelf: boolean
  rank: number
  score: number
}

export interface Rules {
  goal: number
  metric: string
  unit: string
}

export interface Winner {
  id: number
  imageUrl: string
  name: string
}

export interface Player {
  awardCount?: number
  earnedReward?: Reward
  ranking: number
  score: number
}

export enum StatusType {
  Active = 'active',
  Completed = 'completed',
  NeedsVerification = 'needs_verification',
  Upcoming = 'prelaunch',
}

export enum SortDirection {
  Asc = 'asc',
  Desc = 'desc',
  None = 'none',
}

export interface Status {
  statusType: StatusType.Active | StatusType.Upcoming | StatusType.Completed
}

export interface GameResponse {
  assign: Assignment
  countActivitiesAfterGuide?: boolean
  coverImageUrl?: string
  currentUserScore: number
  expiresAt: Date
  id: number
  isRewardImmediatelyDistributed?: boolean
  isSingleGoalReward?: boolean
  limitWinners?: number
  name: string
  numberOfWinners?: number
  playerCount?: number
  playerInformation: Player
  rankings: Ranking[]
  rewards: Array<{
    type: Reward['type']
    value: any // returns string amount if reward type is tokens OR object containing rarity of chest as property if reward type is chest
  }>
  rewardsAwardedCount?: number
  rules: Rules
  startsAt: Date
  status: Status['statusType']
  totalScore: number
  type: GameCard['gameType']
  winner?: Winner
}

export enum GameFilters {
  Active = 'active',
  Upcoming = 'upcoming',
  Completed = 'completed',
}

export interface Game {
  assign: Assignment
  awardCount?: number
  awardedAmount?: number
  countActivitiesAfterGuide?: boolean
  coverImageUrl?: string
  earnedReward?: Reward
  endDateTime?: Date
  gameType: GameCard['gameType']
  goal: number
  id: string
  isCompleted?: boolean
  isCurrentUser?: boolean
  isRewardImmediatelyDistributed?: boolean
  isSingleGoalReward?: boolean
  isUpcoming?: boolean
  limitWinners?: number
  metric: string
  name: string
  numberOfWinners?: number
  place?: number
  prizeAmount: number
  prizeType: Reward['type']
  rewards: Reward[]
  startDateTime?: Date
  status?: Status['statusType']
  totalScore: number
  unit: string
  userId?: number
  userImageUrl?: string
  userName?: string
}

export interface GameCreationResponse {
  data: {
    message: 'Campaign has been created'
    gameId: number
  }
}

export const gamesFilterOptions = [...Object.keys(GameFilters)]

export const gamesFilterOptionValues: GameFilters[] = gamesFilterOptions.map(
  value => GameFilters[value],
)
export interface Rewards {
  chances?: [{ thing: string; count: string }]
  claimedAt?: string
  claimedBy?: number
  jackpot?: number
  receivingRank?: number
  receiving_rank: number
  type: string
  value?: string
}
