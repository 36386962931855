import { Icon } from '@chakra-ui/icon'

const MessageBubbles = props => (
  <Icon {...props}>
    <path
      d="M16.3 8.512c.459 0 .917.036 1.37.105a.25.25 0 00.285-.218c.028-.215.043-.431.045-.648C18 3.754 14.075.5 9.25.5S.5 3.754.5 7.751A6.556 6.556 0 002.746 12.6l-1.7 2.978a.5.5 0 00.62.713L6.1 14.516c.465.148.94.261 1.422.34a.25.25 0 00.284-.187C8.655 11.2 12.2 8.512 16.3 8.512z"
      fill="currentColor"
    />
    <path
      d="M16.3 10.012c-3.9 0-7.2 2.857-7.2 6.239a6.24 6.24 0 002.958 5.181 7.157 7.157 0 006.642.618l3.585 1.417a.557.557 0 00.676-.743l-.834-2.951a5.175 5.175 0 001.373-3.522c0-3.382-3.3-6.239-7.2-6.239z"
      fill="currentColor"
    />
  </Icon>
)

MessageBubbles.displayName = 'MessageBubblesIcon'

export default MessageBubbles
