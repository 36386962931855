import { Icon } from '@chakra-ui/icon'

const HomeOutline = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.293.293a1 1 0 011.414 0l11 11A1 1 0 0123 13h-1.25a.25.25 0 00-.25.25V23a1 1 0 01-1 1H15a1 1 0 01-1-1v-5a2 2 0 10-4 0v5a1 1 0 01-1 1H3.5a1 1 0 01-1-1v-9.75a.25.25 0 00-.25-.25H1a1 1 0 01-.707-1.707l11-11zm9.02 10.597a.25.25 0 00-.068-.231l-8.068-8.068a.25.25 0 00-.354 0l-8.068 8.068a.25.25 0 00.092.412A.97.97 0 014.5 12v9.75c0 .138.112.25.25.25h3a.25.25 0 00.25-.25V18a4 4 0 018 0v3.75c0 .138.112.25.25.25h3a.25.25 0 00.25-.25V12a.972.972 0 01.653-.929.25.25 0 00.16-.18z"
      fill="currentColor"
    />
  </Icon>
)

HomeOutline.displayName = 'HomeOutlineIcon'

export default HomeOutline
