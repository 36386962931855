import { Icon } from '@chakra-ui/icon'

const CheckAll = props => (
  <Icon viewBox="0 0 24 24" {...props}>
    <g fill="none">
      <path
        d="M2 14L6 18L18 3"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M23 8H20"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M23 13H16"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M23 18H12"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </g>
  </Icon>
)

CheckAll.displayName = 'CheckAllIcon'

export default CheckAll
