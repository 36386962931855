import { Icon } from '@chakra-ui/icon'

const RoundedRectangle = props => (
  <Icon {...props}>
    <path
      d="M0 5.5A5.5 5.5 0 015.5 0h13A5.5 5.5 0 0124 5.5v13a5.5 5.5 0 01-5.5 5.5h-13A5.5 5.5 0 010 18.5v-13z"
      fill="currentColor"
    />
  </Icon>
)

RoundedRectangle.displayName = 'RoundedRectangleIcon'

export default RoundedRectangle
