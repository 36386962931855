import { Icon } from '@chakra-ui/icon'

const GameWandStar = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.037 8.522l2.564 2.563a1.163 1.163 0 01-.984 2.048l-3.953-.438a.37.37 0 00-.096.06l-2.275 4.103a1.185 1.185 0 01-2.269-.321l-.627-2.718a.24.24 0 00-.403-.116L2.05 23.649A1.2 1.2 0 01.35 21.95l9.947-9.945a.24.24 0 00-.116-.403l-2.717-.626a1.185 1.185 0 01-.32-2.269l4.12-2.29a.407.407 0 00.042-.105l-.439-3.93a1.163 1.163 0 012.047-.984l2.574 2.574a.396.396 0 00.181.036l4.003-1.336a1.261 1.261 0 011.653 1.654l-1.338 4.017a.374.374 0 00.05.178zm-4.134 3.286a2.072 2.072 0 011.972-1.021l1.247.14a.241.241 0 00.192-.408l-.647-.646a2.19 2.19 0 01-.504-2.133l.8-2.4a.24.24 0 00-.303-.303l-2.4.799a2.184 2.184 0 01-2.134-.502l-.645-.646a.24.24 0 00-.409.196l.139 1.243A2.08 2.08 0 0112.192 8.1l-1.998 1.11a.241.241 0 00.063.444l2.06.474c.76.199 1.354.793 1.553 1.553l.48 2.061a.24.24 0 00.444.063l1.11-1.997z"
      fill="currentColor"
    />
  </Icon>
)

GameWandStar.displayName = 'GameWandStarIcon'

export default GameWandStar
