import { Icon } from '@chakra-ui/icon'

const HeartOutlineShadow = props => (
  <Icon viewBox="0 0 28 26" fill="none" {...props}>
    <g filter="url(#filter0_di)">
      <path
        d="M24.6394 2.62681C23.2021 1.18398 21.2933 0.388351 19.2617 0.388351C17.2302 0.388351 15.3214 1.18398 13.8852 2.62572C13.8667 2.64427 13.8483 2.66282 13.8298 2.68247C13.8113 2.66282 13.7939 2.64536 13.7755 2.62681C12.3382 1.18398 10.4294 0.388351 8.39786 0.388351C6.36632 0.388351 4.45754 1.18398 3.02025 2.62681C1.58296 4.06964 0.793152 5.98722 0.793152 8.02814C0.793152 10.069 1.58404 11.9866 3.02025 13.4295L13.8298 24.2889L24.6394 13.4295C26.0756 11.9866 26.8664 10.069 26.8664 8.02814C26.8664 5.98831 26.0756 4.06964 24.6394 2.62681Z"
        fill="#252529"
        fillOpacity="0.05"
      />
      <path
        d="M13.8298 23.5189L3.40522 13.0463C2.07059 11.7055 1.33635 9.92576 1.33635 8.02814C1.33635 6.13033 2.06962 4.35077 3.40508 3.01017C4.74094 1.66915 6.51145 0.931545 8.39786 0.931545C10.2842 0.931545 12.0547 1.6691 13.3905 3.01002C13.4096 3.02921 13.4217 3.04138 13.434 3.05453L13.8298 3.4755L14.2256 3.05453C14.2379 3.04143 14.2509 3.02826 14.2702 3.00893C15.6049 1.66914 17.3753 0.931545 19.2617 0.931545C21.1481 0.931545 22.9185 1.6691 24.2544 3.01002C25.589 4.35078 26.3233 6.13156 26.3233 8.02814C26.3233 9.92576 25.589 11.7055 24.2544 13.0463L13.8298 23.5189Z"
        stroke="currentColor"
        strokeWidth="1.3px"
      />
    </g>
    <path
      d="M13.8298 23.5189L3.40522 13.0463C2.07059 11.7055 1.33635 9.92576 1.33635 8.02814C1.33635 6.13033 2.06962 4.35077 3.40508 3.01017C4.74094 1.66915 6.51145 0.931545 8.39786 0.931545C10.2842 0.931545 12.0547 1.6691 13.3905 3.01002C13.4096 3.02921 13.4217 3.04138 13.434 3.05453L13.8298 3.4755L14.2256 3.05453C14.2379 3.04143 14.2509 3.02826 14.2702 3.00893C15.6049 1.66914 17.3753 0.931545 19.2617 0.931545C21.1481 0.931545 22.9185 1.6691 24.2544 3.01002C25.589 4.35078 26.3233 6.13156 26.3233 8.02814C26.3233 9.92576 25.589 11.7055 24.2544 13.0463L13.8298 23.5189Z"
      stroke="currentColor"
      strokeWidth="1.3px"
    />
    <defs>
      <filter
        id="filter0_di"
        x="0.793152"
        y="0.388351"
        width="28.2461"
        height="26.0733"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1.08639" dy="1.08639" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.31 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2.17277" dy="2.17277" />
        <feGaussianBlur stdDeviation="2.17277" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend mode="normal" in2="shape" result="effect2_innerShadow" />
      </filter>
    </defs>
  </Icon>
)

HeartOutlineShadow.displayName = 'HeartOutlineShadowIcon'

export default HeartOutlineShadow
