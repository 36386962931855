const baseStyle = {
  fontWeight: 'bold',
  color: 'text.secondary',
  marginTop: '2rem',
  marginBottom: '.3rem',
  fontFamily: 'codenext',
}

const defaultProps = {
  size: 'md',
  variant: 'base',
}

export default {
  baseStyle,
  defaultProps,
}
