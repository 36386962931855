import { Icon } from '@chakra-ui/icon'

const Notes = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 0h20a2 2 0 012 2v14a1 1 0 01-.293.707l-7 7A1 1 0 0116 24H2a2 2 0 01-2-2V2a2 2 0 012-2zm.5 2a.5.5 0 00-.5.5v19a.5.5 0 00.5.5H15a.5.5 0 00.5-.5v-4a2 2 0 012-2h4a.5.5 0 00.5-.5V2.5a.5.5 0 00-.5-.5h-19z"
      fill="currentColor"
    />
  </Icon>
)

Notes.displayName = 'NotesIcon'

export default Notes
