import { FC, useEffect, useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  Text,
} from '@chakra-ui/react'
import { useRollbar } from '@rollbar/react'
import { CbButton } from 'ui'

type FallbackUIProps = {
  error: Error
  resetError: () => void
}

export const CbFallbackUI: FC<FallbackUIProps> = ({ error }) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(true)
  const rollbar = useRollbar()

  useEffect(() => {
    rollbar.info('Error Boundary Visible', {
      message: error.message,
      name: error.name,
    })
  }, [])

  const closeDialog = () => setDialogOpen(false)

  const handleClose = () => closeDialog()

  const handleReload = () => {
    if (window) {
      setTimeout(() => {
        // wait for analytics event to submit before reloading
        window.location.reload()
      }, 400)
    }
  }
  return (
    <Modal
      isOpen={dialogOpen}
      onClose={handleClose}
      aria-labelledby="error-dialog-title"
      aria-describedby="error-dialog-description"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader id="error-dialog-title">
          <Text>{'Application Error'}</Text>
          <ModalCloseButton />
        </ModalHeader>
        <Text paddingLeft={'2.5rem'} paddingRight={'2.5rem'}>
          Arcade has detected an error, and needs to reload the page to recover.
          Our team has been notified and are working hard to prevent this from
          happening again.
        </Text>

        <ModalFooter>
          <CbButton onClick={handleReload}>{'Reload Page'}</CbButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
