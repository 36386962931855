import { useState } from 'react'

import type { ParamHookOutput } from './paramHookTypes'

export interface Pagination {
  page: number
  size: number
}
interface PaginationOutput extends ParamHookOutput<Pagination> {
  setPage: (page: number) => void
  setSize: (size: number) => void
  resetPage: () => void
  resetSize: () => void
}

export const usePagination = (
  initialPagination?: Pagination,
): PaginationOutput => {
  const defaultPage = initialPagination?.page || 0
  const defaultSize = initialPagination?.size || 25

  const [page, setPage] = useState(defaultPage)
  const [size, setSize] = useState(defaultSize)
  const values: Pagination = { page, size }

  const set = ({ page, size }: Pagination): void => {
    setPage(page)
    setSize(size)
  }

  const reset = (): void => {
    setPage(defaultPage)
    setSize(defaultSize)
  }
  const resetPage = (): void => setPage(defaultPage)
  const resetSize = (): void => setSize(defaultSize)

  const asParamString = `page=${page}&rows_per_page=${size}`

  return {
    values,
    set,
    setPage,
    setSize,
    reset,
    resetPage,
    resetSize,
    asParamString,
  }
}
