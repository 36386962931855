const baseStyle = {
  baseStyle: {
    label: {
      fontFamily: 'nunito',
      fontWeight: 'normal',
      margin: 0,
    },
  },
}

const defaultProps = {
  size: 'md',
  variant: 'base',
}

export default {
  parts: ['label'],
  baseStyle,
  defaultProps,
}
