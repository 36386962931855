import { Icon } from '@chakra-ui/icon'

const Comment = props => (
  <Icon {...props}>
    <svg
      width="24"
      height="24"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.6 11.2C14.6666 10.1333 15.3333 8.80001 15.3333 7.33334C15.3333 4.00001 12.0666 1.33334 7.99996 1.33334C3.93329 1.33334 0.666626 4.00001 0.666626 7.33334C0.666626 10.6667 3.93329 13.3333 7.99996 13.3333C8.73329 13.3333 9.39996 13.2667 10.0666 13.0667L14 14.6667L13.6 11.2Z"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M5.33325 6H10.6666"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M5.33325 8.66669H8.66659"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  </Icon>
)

Comment.displayName = 'CommentIcon'

export default Comment
