import { Icon } from '@chakra-ui/icon'

const FileOutline = props => (
  <Icon {...props}>
    <path
      d="M3 4a1 1 0 01-1-1V2a2 2 0 012-2h1.5a1 1 0 010 2H4v1a1 1 0 01-1 1zM5.5 22a1 1 0 110 2H4a2 2 0 01-2-2v-1a1 1 0 112 0v1h1.5zM20 24h-1.5a1 1 0 110-2H20v-1a1 1 0 112 0v1a2 2 0 01-2 2zM17 3a1 1 0 01-.707-.293L15.586 2H14.5a1 1 0 110-2h1.086c.53.002 1.038.212 1.414.586l.707.707A1 1 0 0117 3zM3 9a1 1 0 01-1-1V6.5a1 1 0 012 0V8a1 1 0 01-1 1zM10.75 2h-1.5a1 1 0 010-2h1.5a1 1 0 110 2zM10.5 24H9a1 1 0 110-2h1.5a1 1 0 110 2zM15 24h-1.5a1 1 0 110-2H15a1 1 0 110 2zM3 13.5a1 1 0 01-1-1V11a1 1 0 112 0v1.5a1 1 0 01-1 1zM3 18.5a1 1 0 01-1-1V16a1 1 0 112 0v1.5a1 1 0 01-1 1zM21 9a1 1 0 01-1-1V6.914l-.707-.707a1 1 0 011.414-1.414l.707.707c.376.374.587.883.586 1.414V8a1 1 0 01-1 1zM21 13.5a1 1 0 01-1-1V11a1 1 0 112 0v1.5a1 1 0 01-1 1zM21 18.5a1 1 0 01-1-1V16a1 1 0 112 0v1.5a1 1 0 01-1 1z"
      fill="currentColor"
    />
  </Icon>
)

FileOutline.displayName = 'FileOutlineIcon'

export default FileOutline
