import { Icon } from '@chakra-ui/icon'

const GameJoystick = props => (
  <Icon {...props}>
    <path
      d="M14 13.5v1a.5.5 0 00.5.5h5a.5.5 0 00.5-.5v-1a1.505 1.505 0 00-1.5-1.5h-3a1.506 1.506 0 00-1.5 1.5z"
      fill="currentColor"
    />
    <path
      d="M21.5 16h-10a.5.5 0 01-.5-.5v-5.376a.5.5 0 01.318-.466 5 5 0 10-3.636 0 .5.5 0 01.318.466V15.5a.5.5 0 01-.5.5h-5a1 1 0 00-1 1v2a.5.5 0 00.5.5h20a.5.5 0 00.5-.5v-2a1 1 0 00-1-1zM20.91 21H3.09a.5.5 0 00-.493.582l.321 1.925c.055.28.297.484.582.493h17a.612.612 0 00.582-.493l.321-1.925A.5.5 0 0020.91 21z"
      fill="currentColor"
    />
  </Icon>
)

GameJoystick.displayName = 'GameJoystickIcon'

export default GameJoystick
