import { Icon } from '@chakra-ui/icon'

const ChevronUp = props => (
  <Icon {...props}>
    <path
      d="M22.801 16.793L12.256 4.322a1.004 1.004 0 00-.738-.328c-.28 0-.546.118-.74.328L.24 16.793a1.013 1.013 0 00.103 1.414l1.776 1.557a.96.96 0 001.38-.111l7.839-9.453a.236.236 0 01.182-.087c.07 0 .136.032.181.087l7.842 9.45a.967.967 0 001.383.111l1.777-1.558a1.016 1.016 0 00.103-1.414l-.004.004z"
      fill="currentColor"
    />
  </Icon>
)

ChevronUp.displayName = 'ChevronUpIcon'

export default ChevronUp
