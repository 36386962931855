import { Icon } from '@chakra-ui/icon'

const StarHalfOutline = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.913 1.242c.217-.593.78-.99 1.411-.993a1 1 0 01.999 1v16.832a1 1 0 01-.408.807l-6.233 4.57a1.5 1.5 0 01-2.309-1.685l2.285-6.859L6.211 10.4a1.5 1.5 0 01.963-2.651h6.439l2.3-6.507zm.311 16.407a.252.252 0 00.1-.2l.005-11.362-1.057 2.994a1 1 0 01-.943.668h-5.07a.25.25 0 00-.159.443l4.362 3.622a1 1 0 01.31 1.086l-1.755 5.268a.25.25 0 00.385.281l3.822-2.8z"
      fill="currentColor"
    />
  </Icon>
)

StarHalfOutline.displayName = 'StarHalfOutlineIcon'

export default StarHalfOutline
