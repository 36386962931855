import { Icon } from '@chakra-ui/icon'

const Search = props => (
  <Icon {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.9782 16.2682L23.4374 20.7274C24.187 21.4771 24.187 22.6925 23.4374 23.4422C22.6824 24.1788 21.4776 24.1788 20.7226 23.4422L16.2634 18.984C12.0535 21.7985 6.39518 20.9606 3.18173 17.0467C-0.0317143 13.1328 0.24783 7.41964 3.82802 3.83818C7.40822 0.256717 13.1213 -0.0248476 17.0363 3.18722C20.9513 6.39928 21.7913 12.0573 18.9782 16.2682ZM17.76 10.8048C17.76 6.9609 14.6439 3.8448 10.8 3.8448C6.95786 3.84903 3.84424 6.96265 3.84 10.8048C3.84 14.6487 6.9561 17.7648 10.8 17.7648C14.6439 17.7648 17.76 14.6487 17.76 10.8048Z"
    />
  </Icon>
)

Search.displayName = 'SearchIcon'

export default Search
