const fontFamilyFallback = `-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`
const fontFamilyCodeNext = `"Code Next", ${fontFamilyFallback}`
const fontFamilyNunitoSans = `"Nunito Sans", ${fontFamilyFallback}`

export const fonts = {
  body: fontFamilyNunitoSans,
  heading: fontFamilyCodeNext,
  codenext: fontFamilyCodeNext,
  nunitosans: fontFamilyNunitoSans,
}

export const fontWeights = {
  normal: 400,
  semi: 600,
  semibold: 600,
  bold: 700,
}
