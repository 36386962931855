import { Icon } from '@chakra-ui/icon'

const Plus = props => (
  <Icon {...props} position="relative" top="0.5px">
    <path
      d="M0 12a1.5 1.5 0 001.5 1.5h8.75a.25.25 0 01.25.25v8.75a1.5 1.5 0 003 0v-8.75a.25.25 0 01.25-.25h8.75a1.5 1.5 0 000-3h-8.75a.25.25 0 01-.25-.25V1.5a1.5 1.5 0 00-3 0v8.75a.25.25 0 01-.25.25H1.5A1.5 1.5 0 000 12z"
      fill="currentColor"
    />
  </Icon>
)

Plus.displayName = 'PlusIcon'

export default Plus
