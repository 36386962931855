import { Icon } from '@chakra-ui/icon'

const TXT = props => (
  <Icon {...props}>
    <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
      <path d="m966.9 482.6h-70.9v-146.6c0-44-25.5-105.5-56.6-136.6l-142.8-142.8c-31.1-31.1-92.6-56.6-136.6-56.6h-480c-44 0-80 36-80 80v864c0 44 36 80 80 80h736c44 0 80-36 80-80v-80.5h70.9c31.4 0 57.1-25.7 57.1-57.1v-266.7c0-31.4-25.7-57.1-57.1-57.1zm-326.8-390c4.4 3.1 8.2 6.2 11.3 9.3l142.8 142.8c3.1 3.1 6.2 6.9 9.3 11.3h-163.4zm191.9 851.4c0 8.7-7.3 16-16 16h-736c-8.7 0-16-7.3-16-16v-864c0-8.7 7.3-16 16-16h480c4.8 0 10.2.6 16 1.7v254.3h254.3c1.1 5.8 1.7 11.2 1.7 16v146.6h-601.8c-31.4 0-57.1 25.7-57.1 57.1v266.8c0 31.4 25.7 57.1 57.1 57.1h601.8zm-331.6-381h45.1l41.5 69.3 40.7-69.3h44l-60.5 95.6 65.1 97.2h-46.7l-44.8-72.9-44.8 72.9h-43.8l63.9-99.4zm-180.7 34v-34h145.5v34h-52.3v158.7h-40.9v-158.7zm533.3 0h-52.3v158.7h-40.9v-158.7h-52.3v-34h145.5z" />
    </svg>
  </Icon>
)

TXT.displayName = 'TXTIcon'

export default TXT
