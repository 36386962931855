import { FC, ReactNode } from 'react'
import {
  Popover,
  PopoverTrigger,
  PopoverBody,
  PopoverContent,
  Tag,
  PopoverProps,
} from '@chakra-ui/react'

export interface CbTooltipProps extends PopoverProps {
  content: ReactNode
  width?: string
  backgroundColor?: string
  color?: string
  children: ReactNode
}

export const CbTooltip: FC<CbTooltipProps> = ({
  backgroundColor,
  children,
  color,
  content,
  width,
  ...props
}) => (
  <Popover trigger="hover" {...props}>
    <PopoverTrigger>
      <Tag backgroundColor="transparent" padding={0}>
        {children}
      </Tag>
    </PopoverTrigger>
    <PopoverContent
      backgroundColor={backgroundColor || 'cloud.800'}
      borderColor={backgroundColor || 'inherit'}
      maxWidth={width || '300px'}
      width="auto"
      color={color || 'cloud.100'}
    >
      <PopoverBody>{content}</PopoverBody>
    </PopoverContent>
  </Popover>
)
