import { Icon } from '@chakra-ui/icon'

const LoginCircle = props => (
  <Icon {...props}>
    <path
      d="M15.087 12.96a.49.49 0 00-.5.48v3.456c0 .369-.22.704-.567.864a1.064 1.064 0 01-1.058-.116l-7-5.376a.946.946 0 01-.375-.75c0-.292.138-.568.375-.75l7-5.376c.3-.23.712-.275 1.058-.116.347.16.567.497.567.866V9.6c0 .265.224.48.5.48h8.32a.51.51 0 00.386-.167.467.467 0 00.114-.39C22.885 3.995 17.869-.024 12.022 0 5.634.09.417 4.925.1 11.05c-.262 6.062 4.418 11.28 10.7 11.931 6.284.652 12.017-3.486 13.109-9.461a.472.472 0 00-.116-.39.515.515 0 00-.384-.17h-8.322z"
      fill="currentColor"
    />
  </Icon>
)

LoginCircle.displayName = 'LoginCircleIcon'

export default LoginCircle
