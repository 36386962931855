import { Icon } from '@chakra-ui/icon'

const Redo = props => (
  <Icon {...props}>
    <path
      d="M22.608.161a.5.5 0 00-.545.108L19.472 2.86a.25.25 0 01-.292.045 12.537 12.537 0 00-12.966.865A12.259 12.259 0 006.1 23.632a1.25 1.25 0 101.476-2.018 9.759 9.759 0 01.091-15.809 10 10 0 019.466-1.1.25.25 0 01.084.409l-1.85 1.85a.5.5 0 00.354.853h6.7a.5.5 0 00.5-.5V.623a.5.5 0 00-.313-.462z"
      fill="currentColor"
    />
  </Icon>
)

Redo.displayName = 'RedoIcon'

export default Redo
