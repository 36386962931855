import { Icon } from '@chakra-ui/icon'

const FlagGo = props => (
  <Icon {...props}>
    <path
      d="M17.52 5.069c-.663 0-1.2.516-1.2 1.152v3.686c0 .412.229.792.6.998.371.206.829.206 1.2 0s.6-.586.6-.998V6.221a1.13 1.13 0 00-.351-.815 1.226 1.226 0 00-.85-.337z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 1.382c-.53 0-.96.413-.96.922v11.98c0 .51.43.922.96.922h16.32c.53 0 .96-.412.96-.921V2.304c0-.509-.43-.922-.96-.922H6zm4.8 9.909c.663 0 1.2-.516 1.2-1.152a.226.226 0 00-.07-.164.245.245 0 00-.17-.068h-.48c-.398 0-.72-.31-.72-.691 0-.382.322-.691.72-.691h1.44c.398 0 .72.31.72.692v.922c0 1.4-1.182 2.534-2.64 2.534-1.458 0-2.64-1.135-2.64-2.534V6.452c0-1.4 1.182-2.534 2.64-2.534 1.458 0 2.64 1.134 2.64 2.534 0 .382-.322.691-.72.691-.398 0-.72-.31-.72-.69 0-.637-.537-1.153-1.2-1.153-.663 0-1.2.516-1.2 1.152v3.687c0 .636.537 1.152 1.2 1.152zm6.72 1.15c1.458 0 2.64-1.134 2.64-2.534V6.221c0-1.4-1.182-2.535-2.64-2.535-1.458 0-2.64 1.135-2.64 2.535v3.686c0 1.4 1.182 2.535 2.64 2.535z"
      fill="currentColor"
    />
    <path
      d="M2.16 0C1.365 0 .72.62.72 1.382V21.66c0 .763.645 1.382 1.44 1.382.795 0 1.44-.619 1.44-1.382V1.382C3.6.62 2.955 0 2.16 0z"
      fill="currentColor"
    />
  </Icon>
)

FlagGo.displayName = 'FlagGoIcon'

export default FlagGo
