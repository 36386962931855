import { createIcon } from '@chakra-ui/icon'

const VideoGameMushroom = createIcon({
  displayName: 'VideoGameMushroom',
  path: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 .5C4.36.5.923 7.709.923 12.828c-.153 2.715 1.318 5.249 3.702 6.377a.95.95 0 01.547.686c.432 2.103 2.22 3.607 4.291 3.609h5.077c2.071-.003 3.859-1.506 4.29-3.61a.95.95 0 01.548-.685c2.383-1.129 3.852-3.662 3.699-6.377C23.077 7.708 19.64.5 12 .5zm2.539 2.156c1.146 0 2.076.966 2.076 2.156 0 1.191-.93 2.157-2.076 2.157-1.148 0-2.078-.966-2.078-2.157 0-1.19.93-2.156 2.078-2.156zm-6.693 4.3c-1.784 0-3.23 1.501-3.23 3.353 0 .89.34 1.743.946 2.372a3.172 3.172 0 002.284.983c1.784 0 3.23-1.502 3.23-3.354 0-1.853-1.446-3.355-3.23-3.355zm9.23 11.992c-.001 1.455-1.138 2.634-2.54 2.635H9.46c-1.4-.003-2.535-1.181-2.537-2.635V16.77c0-.18.098-.346.254-.428a11.792 11.792 0 014.823-.75c1.64-.089 3.28.166 4.823.75a.481.481 0 01.254.428v2.177zm-.922-7.201c0 .794.62 1.438 1.384 1.438.765 0 1.385-.644 1.385-1.438 0-.794-.62-1.438-1.384-1.438-.765 0-1.385.644-1.385 1.438z"
        fill="currentColor"
      />
      <path
        d="M9.692 16.778a.706.706 0 00-.692.719v1.917c0 .397.31.718.692.718a.706.706 0 00.693-.718v-1.917a.706.706 0 00-.693-.719zM14.308 16.778a.706.706 0 00-.693.719v1.917c0 .397.31.718.693.718a.706.706 0 00.692-.718v-1.917a.706.706 0 00-.692-.719z"
        fill="currentColor"
      />
    </>
  ),
})

export default VideoGameMushroom
