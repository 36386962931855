import { Icon } from '@chakra-ui/icon'

const CheckeredFlag = props => (
  <Icon viewBox="0 0 24 24" {...props}>
    <g fill="none">
      <path
        d="M23 3C23 2.73478 22.8946 2.48043 22.7071 2.29289C22.5196 2.10536 22.2652 2 22 2H6V1C6 0.734784 5.89464 0.48043 5.70711 0.292893C5.51957 0.105357 5.26522 0 5 0C4.73478 0 4.48043 0.105357 4.29289 0.292893C4.10536 0.48043 4 0.734784 4 1V23C4 23.2652 4.10536 23.5196 4.29289 23.7071C4.48043 23.8946 4.73478 24 5 24C5.26522 24 5.51957 23.8946 5.70711 23.7071C5.89464 23.5196 6 23.2652 6 23V16H22C22.2652 16 22.5196 15.8946 22.7071 15.7071C22.8946 15.5196 23 15.2652 23 15V3ZM21 9H16V4H21V9ZM16 9V14H11V9H16ZM11 4V9H6V4H11Z"
        fill="currentColor"
      />
    </g>
  </Icon>
)

CheckeredFlag.displayName = 'CheckeredFlagIcon'

export default CheckeredFlag
