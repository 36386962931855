import { Icon } from '@chakra-ui/icon'

const AtSign = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.262 12C.336 5.586 5.586.442 12 .5 18.414.442 23.664 5.586 23.738 12v.891a4.438 4.438 0 01-7.877 2.736A5.376 5.376 0 0112 17.261 5.355 5.355 0 1117.354 12v.891a1.943 1.943 0 003.884 0V12A9.132 9.132 0 0012 3a9.132 9.132 0 00-9.238 9A9.132 9.132 0 0012 21a9.34 9.34 0 005.231-1.576 1.25 1.25 0 011.393 2.076 11.836 11.836 0 01-6.624 2C5.586 23.558.336 18.414.262 12zm9.067-.061A2.763 2.763 0 0012 14.76 2.812 2.812 0 0014.854 12a2.763 2.763 0 00-5.525-.061z"
      fill="currentColor"
    />
  </Icon>
)

AtSign.displayName = 'AtSignIcon'

export default AtSign
