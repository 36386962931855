import { Icon } from '@chakra-ui/icon'

const Target = props => (
  <Icon {...props}>
    <path
      d="M2.803 19.727a.24.24 0 010 .297l-1.156 1.46a.96.96 0 101.506 1.191l1.01-1.276a.24.24 0 01.33-.044 8.606 8.606 0 0010.422-.147.239.239 0 01.327.03l1.32 1.48a.972.972 0 001.356.078.96.96 0 00.08-1.354l-1.44-1.618a.24.24 0 01-.014-.301 8.598 8.598 0 00.393-9.681.246.246 0 00-.384-.04l-1.577 1.583a.232.232 0 00-.04.273 6.027 6.027 0 11-1.863-2.142.24.24 0 01.031.364l-.695.695a.24.24 0 01-.302.03 4.503 4.503 0 00-3.51-.654 4.561 4.561 0 105.432 5.542c.21-.848.17-1.738-.115-2.564a.242.242 0 00-.398-.083l-2.033 2.034a.226.226 0 00-.059.096 1.92 1.92 0 11-1.485-2.466.24.24 0 01.128.406l-.851.85a.719.719 0 000 1.018.733.733 0 001.019 0l7.032-7.034a.241.241 0 01.17-.07h3.203a.48.48 0 00.325-.127l2.88-2.646a.48.48 0 00-.172-.809l-2.713-.904a.243.243 0 01-.152-.152L19.903.328a.48.48 0 00-.81-.172l-2.647 2.88a.48.48 0 00-.126.324v3.203a.245.245 0 01-.07.17l-.923.922a.24.24 0 01-.32.016A8.632 8.632 0 002.804 19.727z"
      fill="currentColor"
    />
  </Icon>
)

Target.displayName = 'TargetIcon'

export default Target
