import { Icon } from '@chakra-ui/icon'

const Undo = props => (
  <Icon {...props}>
    <path
      d="M17.786 3.77a12.542 12.542 0 00-12.965-.865.249.249 0 01-.292-.045L1.937.269a.5.5 0 00-.853.353v6.7a.5.5 0 00.5.5h6.7a.5.5 0 00.354-.854L6.783 5.115a.249.249 0 01.084-.409 10 10 0 019.466 1.1 9.76 9.76 0 01.094 15.809 1.25 1.25 0 001.473 2.016 12.122 12.122 0 005.013-9.961c0-3.937-1.912-7.628-5.127-9.9z"
      fill="currentColor"
    />
  </Icon>
)

Undo.displayName = 'UndoIcon'

export default Undo
