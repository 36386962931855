import { Icon } from '@chakra-ui/icon'

const DirectionPad = props => (
  <Icon {...props}>
    <path
      d="M9.5 12c.001-.53-.21-1.04-.586-1.414l-1.5-1.5A2.015 2.015 0 006 8.5H1.5A1.5 1.5 0 000 10v4a1.5 1.5 0 001.5 1.5H6c.53.001 1.04-.21 1.414-.586l1.5-1.5c.376-.374.587-.883.586-1.414zM22.5 8.5H18c-.53-.001-1.04.21-1.414.586l-1.5 1.5a2 2 0 000 2.828l1.5 1.5c.374.376.883.587 1.414.586h4.5A1.5 1.5 0 0024 14v-4a1.5 1.5 0 00-1.5-1.5zM13.414 15.084a2 2 0 00-2.828 0l-1.5 1.5A1.992 1.992 0 008.5 18v4.5A1.5 1.5 0 0010 24h4a1.5 1.5 0 001.5-1.5V18c0-.53-.21-1.04-.585-1.414l-1.501-1.502zM10.586 8.912a2 2 0 002.828 0l1.5-1.5c.375-.374.586-.882.586-1.412V1.5A1.5 1.5 0 0014 0h-4a1.5 1.5 0 00-1.5 1.5V6c-.001.531.21 1.04.586 1.415l1.5 1.497z"
      fill="currentColor"
    />
  </Icon>
)

DirectionPad.displayName = 'DirectionPadIcon'

export default DirectionPad
