import { Icon } from '@chakra-ui/icon'

const ShieldAlternateOutline = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.559 1.75a2.117 2.117 0 011.029 1.816v7.963C22.588 18.36 13.591 24 12 24c-1.591 0-10.588-5.64-10.588-12.471V3.566A2.117 2.117 0 014.527 1.7l1.599.854a.5.5 0 00.467 0L11.019.241a2.119 2.119 0 011.962 0l4.425 2.313a.5.5 0 00.467 0l1.6-.854a2.117 2.117 0 012.086.05zm-9.336 19.646c1.773-.883 7.921-5.099 7.921-9.862V4.883a.5.5 0 00-.735-.441l-.844.45c-.61.327-1.344.33-1.957.008l-4.376-2.288a.5.5 0 00-.464 0L7.39 4.9a2.087 2.087 0 01-1.956-.01l-.843-.45a.5.5 0 00-.735.441v6.652c0 4.763 6.149 8.98 7.921 9.863a.5.5 0 00.446 0z"
      fill="currentColor"
    />
  </Icon>
)

ShieldAlternateOutline.displayName = 'ShieldAlternateOutlineIcon'

export default ShieldAlternateOutline
