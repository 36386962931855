import { Icon } from '@chakra-ui/icon'

const FilterOutline = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.017 1.183a2.09 2.09 0 01-.191 2.254l-7.166 9.75v6.252a2.142 2.142 0 01-.9 1.728l-.002.002c.003 0 .007.001-2.469 1.48a2.994 2.994 0 01-1.573.391 2.318 2.318 0 01-1.72-.563 2.13 2.13 0 01-.732-1.598v-7.694l-7.1-9.765a2.088 2.088 0 01-.175-2.246C1.375.453 2.148 0 2.992 0H21.01c.848 0 1.623.458 2.007 1.183zM13.16 12.807c0-.248.08-.49.229-.692l6.583-8.958a.464.464 0 00.035-.497.503.503 0 00-.444-.259H4.428c-.186 0-.357.1-.443.258a.464.464 0 00.033.497l6.517 8.965c.147.201.225.44.225.686v7.014c0 .172.096.33.25.416a.518.518 0 00.501-.001c.416-.232.916-.516 1.256-.71l.149-.084a.476.476 0 00.244-.413v-6.222z"
      fill="currentColor"
    />
  </Icon>
)

FilterOutline.displayName = 'FilterOutlineIcon'

export default FilterOutline
