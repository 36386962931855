import { Icon } from '@chakra-ui/icon'

const StatsAscend = props => (
  <Icon {...props}>
    <path
      d="M1.634 18.75c.368 0 .721-.14.989-.391L8.976 12.4a.48.48 0 01.67.01l2.137 2.138a3.456 3.456 0 004.773 0l3.577-3.577a.24.24 0 01.34 0l1.694 1.688a.965.965 0 001.646-.683V6.214a.965.965 0 00-.965-.964h-5.785a.964.964 0 00-.681 1.65l1.7 1.7c.045.045.07.107.071.171a.237.237 0 01-.071.17l-3.572 3.573a.485.485 0 01-.682 0l-2.138-2.138A3.359 3.359 0 007 10.294L.645 16.248a1.447 1.447 0 00.989 2.5v.002z"
      fill="currentColor"
    />
  </Icon>
)

StatsAscend.displayName = 'StatsAscendIcon'

export default StatsAscend
