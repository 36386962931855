export default {
  '.react-datepicker': {
    borderRadius: '24px',
    fontFamily: 'Code Next',
    borderWidth: '0px',
    boxShadow: '0px 20px 50px rgba(0, 0, 0, 0.2)',
  },
  '.react-datepicker__header': {
    borderTopRadius: '24px',
    backgroundColor: '#F8F8FB',
  },
  '.react-datepicker__day': {
    color: '#9193AB',
    borderRadius: '100%',
    '&:hover': {
      borderRadius: '100%',
      transition: 'border-color ease-out 150ms',
    },
  },
  '.react-datepicker__day--keyboard-selected': {
    backgroundColor: 'white',
    borderRadius: '100%',
    '&:hover': {
      backgroundColor: '#f0f0f0',
    },
  },
  '.react-datepicker__day--keyboard-selected.focus-visible': {
    backgroundColor: '#f0f0f0',
    borderRadius: '100%',
    outline: '#8959C3',
    '&:hover': {
      backgroundColor: '#f0f0f0',
    },
  },
  '.react-datepicker__day--today': {
    backgroundColor: '#f0f0f0',
    borderRadius: '100%',
    '&:hover': {
      backgroundColor: '#f0f0f0',
    },
  },
  '.react-datepicker__day--in-selecting-range': {
    opacity: '0.5',
    backgroundColor: '#8959C3',
    color: 'white',
    borderRadius: '100%',
    '&:hover': {
      backgroundColor: '#8959C3',
    },
  },
  '.react-datepicker__day--in-range': {
    opacity: '1',
    backgroundColor: '#8959C3',
    color: 'white',
    borderRadius: '100%',
    '&:hover': {
      backgroundColor: '#8959C3',
    },
  },
  '.react-datepicker__day--selected': {
    opacity: '1',
    backgroundColor: '#8959C3',
    color: 'white',
    borderRadius: '100%',
    '&:hover': {
      backgroundColor: '#8959C3',
    },
  },
  '.react-datepicker__day--selecting-range-start': {
    opacity: 1,
    backgroundColor: '#8959C3',
    color: 'white',
    borderRadius: '100%',
    '&:hover': {
      backgroundColor: '#8959C3',
    },
  },
  '.react-datepicker__day--outside-month': {
    color: '#bfc0c7',
  },
  '.react-datepicker-popper[data-placement^="bottom"]': {
    marginTop: '-2px',
    borderColor: '#ffffff',
  },
  '.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle':
    {
      borderBottomColor: '#F8F8FB',
    },
  '.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before':
    {
      borderBottomColor: 'transparent',
    },
  '.react-datepicker-popper[data-placement^="top"]': {
    marginTop: '-6px',
    borderColor: '#ffffff',
  },
  '.react-datepicker__month': {
    fontFamily: 'Nunito Sans',
    fontWeight: 'bold',
  },
  '.react-datepicker__day-name': {
    color: '#5F627D',
  },
  '.react-datepicker__year-dropdown': {
    backgroundColor: '#ffffff',
    boxShadow: '0px 20px 50px rgba(0, 0, 0, 0.2)',
    borderWidth: '0px',
    borderRadius: '8px',
  },
  '.react-datepicker__year-option': {
    color: '#05255B',
    '&:hover': {
      backgroundColor: '#dedfe3',
    },
  },
  '.react-datepicker-wrapper': {
    width: '-webkit-fill-available',
  },

  '.cb-datetime-picker .react-datepicker': {
    borderRadius: '14px',
    boxShadow: 'none',
    borderWidth: '1px',
    borderColor: 'cloud.300',
    padding: '8px',
    width: '100%',
  },
  '.cb-datetime-picker .react-datepicker__header': {
    backgroundColor: 'transparent',
    border: 'none',
    width: '100%',
  },
  '.cb-datetime-picker .react-datepicker__month-container': {
    float: 'none',
  },

  '.cb-datetime-picker .react-datepicker__day--disabled': {
    color: 'var(--chakra-colors-cloud-300)',
    cursor: 'default',
  },
  '.cb-datetime-picker .react-datepicker__navigation': {
    top: '1rem',
  },
}
