import { Icon } from '@chakra-ui/icon'

const RibbonStarOne = props => (
  <Icon {...props}>
    <path
      d="M3.456 15.115A.25.25 0 003.24 15a.254.254 0 00-.209.124l-2.4 4.14a1 1 0 001.032 1.487l2.391-.405.833 2.288a1 1 0 001.8.168l1.974-3.34a.25.25 0 00-.131-.363 10.283 10.283 0 01-5.074-3.984zM23.366 19.266L20.954 15.1a.25.25 0 00-.425-.012 10.3 10.3 0 01-5.069 4 .248.248 0 00-.13.362l1.981 3.353a1 1 0 001.8-.171l.832-2.287 2.392.405a1 1 0 001.031-1.488v.004z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.984.705a8.75 8.75 0 018.75 8.75 8.761 8.761 0 01-8.75 8.75 8.75 8.75 0 110-17.5zm3.33 12.845a.6.6 0 00.162-.671l-1.03-2.359a.25.25 0 01.054-.278l1.856-1.827a.558.558 0 00-.393-.974h-2.118a.25.25 0 01-.225-.141l-1.106-2.267a.6.6 0 00-1.071 0l-1.1 2.268a.25.25 0 01-.225.141H8a.558.558 0 00-.388.974l1.852 1.826a.252.252 0 01.054.278l-1.026 2.359a.6.6 0 00.849.745l2.52-1.42a.251.251 0 01.246 0l2.52 1.42a.6.6 0 00.687-.074z"
      fill="currentColor"
    />
  </Icon>
)

RibbonStarOne.displayName = 'RibbonStarOneIcon'

export default RibbonStarOne
