/*
  This is a hook courtesy of @danieljauch-arcade that provides the behaviour of a state machine.

  To use it you need to:
  * Define the possible states
    ```js
      enum State {
        INITIAL,
        CANCELLED,
        LOADING,
        ERROR,
        SUCCESS
      }
    ```

  * Define the possible transitions
    ```js
      const graph: Array<Transition<State>> = [
        [State.INITIAL, State.LOADING],
        [State.LOADING, State.CANCELLED],
        [State.LOADING, State.ERROR],
        [State.LOADING, State.SUCCESS],
      ]
    ```

  * Then instantiate the hook: `const [state, setState] = useStateMachine(State.INITIAL, graph)`

  
  Some important things to know:
  * If you attempt a transition that doesn't exist, nothing will happen. This is in one of two conditions: you're already in that state, or the transition hasn't been defined. This means you can't cancel a request that's already errored, or go back to initial, or fire the request again when it's already mid-flight.
  * You can treat this just like useState for purposes of passing props. This is just a value, and it could change, which will render again.
  * When you're in a "final state" (e.g. a state that has no transitions, in our example, this includes: cancelled, error, and success), nothing will happen when you call setState. This is on purpose! It makes sure that the only way you can start from the top is with a new component instance. If you need this to be able to loop, just make those transitions, and no final states will exist.
*/

import { Dispatch, useState } from 'react'

export type Transition<State> = [from: State, to: State]

export const useStateMachine = <State>(
  initialState: State,
  allowedTransitions: Array<Transition<State>>,
): [State, Dispatch<State>] => {
  const [currentState, setState] = useState(initialState)

  const availableTransitions = allowedTransitions.filter(
    ([fromState]) => fromState === currentState,
  )
  const isFinalNode = !availableTransitions.length

  const transitionState = (nextState: State): State => {
    if (isFinalNode) return currentState
    const isAllowed = !!availableTransitions.find(
      ([, toState]) => toState === nextState,
    )
    if (isAllowed) setState(nextState)
    return isAllowed ? nextState : currentState
  }

  return [currentState, transitionState]
}
