import { Icon } from '@chakra-ui/icon'

const StarSmall = props => (
  <Icon {...props}>
    <path
      d="M19.805 10.12a1.507 1.507 0 00-1.213-1.027l-3.625-.523-1.626-3.27a1.5 1.5 0 00-2.685 0L9.035 8.569l-3.624.524a1.51 1.51 0 00-1.211 1.02 1.492 1.492 0 00.382 1.542L7.2 14.191l-.619 3.585a1.5 1.5 0 002.174 1.582L12 17.662l3.248 1.7a1.5 1.5 0 002.174-1.584l-.622-3.586 2.618-2.537a1.5 1.5 0 00.387-1.535z"
      fill="currentColor"
    />
  </Icon>
)

StarSmall.displayName = 'StarSmallIcon'

export default StarSmall
