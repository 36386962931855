import { Icon } from '@chakra-ui/icon'

const Presentation = props => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g clip-path="url(#a)" filter="url(#b)">
      <path
        fill="currentColor"
        d="M21.667 28.333v5H30v3.334H10v-3.334h8.333v-5H6.667A1.667 1.667 0 0 1 5 26.667v-20H3.333V3.333h33.334v3.334H35v20a1.667 1.667 0 0 1-1.667 1.666zM8.333 25h23.334V6.667H8.333zm8.334-15L25 15.833l-8.333 5.834z"
      />
    </g>
    <defs>
      <linearGradient
        id="c"
        x1="20"
        x2="20"
        y1="3.333"
        y2="36.667"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FEE481" />
        <stop offset="1" stop-color="#D7AE88" />
      </linearGradient>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h40v40H0z" />
      </clipPath>
      <filter
        id="b"
        width="39.333"
        height="39.333"
        x=".333"
        y="2.333"
        color-interpolation-filters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="1.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_3076_8292"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_3076_8292"
          result="shape"
        />
      </filter>
    </defs>
  </Icon>
)

Presentation.displayName = 'PresentationIcon'

export default Presentation
