import { Icon } from '@chakra-ui/icon'

const UserFull = props => (
  <Icon {...props}>
    <path
      d="M12 8.5A5.506 5.506 0 006.5 14v3.5a.5.5 0 00.5.5h1.81c.13 0 .238.1.249.229l.441 5.313A.5.5 0 0010 24h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 0115.19 18H17a.5.5 0 00.5-.5V14A5.506 5.506 0 0012 8.5zM12 7a3.5 3.5 0 100-7 3.5 3.5 0 000 7z"
      fill="currentColor"
    />
    <path
      d="M12 8.5A5.506 5.506 0 006.5 14v3.5a.5.5 0 00.5.5h1.81c.13 0 .238.1.249.229l.441 5.313A.5.5 0 0010 24h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 0115.19 18H17a.5.5 0 00.5-.5V14A5.506 5.506 0 0012 8.5zM12 7a3.5 3.5 0 100-7 3.5 3.5 0 000 7z"
      fill="currentColor"
    />
  </Icon>
)

UserFull.displayName = 'UserFullIcon'

export default UserFull
