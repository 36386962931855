import { Icon } from '@chakra-ui/icon'

const StarBadge = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.793 7.943A4.8 4.8 0 0124 12a4.8 4.8 0 01-2.208 4.057 4.83 4.83 0 01-5.736 5.736 4.83 4.83 0 01-8.112 0 4.83 4.83 0 01-5.736-5.736 4.832 4.832 0 010-8.114 4.831 4.831 0 015.737-5.736 4.831 4.831 0 018.112 0 4.831 4.831 0 015.736 5.736zm-5.734 8.77a.422.422 0 00.104-.481l-1.354-3.112a.5.5 0 01.126-.573l2.312-2.06a.422.422 0 00-.281-.737h-2.743a.5.5 0 01-.459-.3l-1.377-3.193a.422.422 0 00-.775 0L10.236 9.45a.5.5 0 01-.459.3H7.034a.422.422 0 00-.281.737l2.312 2.06a.5.5 0 01.126.573l-1.353 3.111a.423.423 0 00.594.537l3.322-1.868a.5.5 0 01.491 0l3.324 1.868a.422.422 0 00.49-.054z"
      fill="currentColor"
    />
  </Icon>
)

StarBadge.displayName = 'StarBadgeIcon'

export default StarBadge
