import { Icon } from '@chakra-ui/icon'

const BoltAlternate = props => (
  <Icon {...props}>
    <path
      d="M17.5 8h-4.1L17.924.763A.5.5 0 0017.5 0h-8a.5.5 0 00-.485.379l-3 12A.5.5 0 006.5 13h3.345L7.018 23.367a.5.5 0 00.275.587.509.509 0 00.623-.178l10-15A.5.5 0 0017.5 8z"
      fill="currentColor"
    />
  </Icon>
)

BoltAlternate.displayName = 'BoltAlternateIcon'

export default BoltAlternate
