import { Icon } from '@chakra-ui/icon'

const Tag = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.894 1l12.582 12.588h-.004a1.54 1.54 0 01-.666 2.659l-4.774 1.457a.5.5 0 00-.332.332l-1.454 4.776a1.6 1.6 0 01-1.127 1.144 1.48 1.48 0 01-.355.044c-.447-.01-.871-.2-1.178-.524L1.007 10.897a3.739 3.739 0 01-1-2.411L0 2a2 2 0 012-2h6.483a3.931 3.931 0 012.411 1zM4.493 5.992a1.5 1.5 0 103 0 1.5 1.5 0 00-3 0z"
      fill="currentColor"
    />
  </Icon>
)

Tag.displayName = 'TagIcon'

export default Tag
