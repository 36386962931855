import { Icon } from '@chakra-ui/icon'

const TokenHistory = props => (
  <Icon viewBox="0 0 17 18" {...props}>
    <g fill="none">
      <path
        d="M7.66663 9.05797V11.7478C7.66663 12.8621 9.45729 13.7652 11.6666 13.7652C13.876 13.7652 15.6666 12.8621 15.6666 11.7478V9.05797"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M7.66663 11.7478V14.4377C7.66663 15.552 9.45729 16.4551 11.6666 16.4551C13.876 16.4551 15.6666 15.552 15.6666 14.4377V11.7478"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M11.6666 11.0754C13.8758 11.0754 15.6666 10.1721 15.6666 9.05797C15.6666 7.9438 13.8758 7.04058 11.6666 7.04058C9.45749 7.04058 7.66663 7.9438 7.66663 9.05797C7.66663 10.1721 9.45749 11.0754 11.6666 11.0754Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M5.66663 4.35072V6.36811H3.66663"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M5.66667 11.0754C3.08933 11.0754 1 8.96786 1 6.36811C1 3.76837 3.08933 1.66087 5.66667 1.66087C7.78067 1.66087 9.56667 3.07909 10.14 5.02386"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </g>
  </Icon>
)

TokenHistory.displayName = 'TokenHistoryIcon'

export default TokenHistory
