const baseStyle = {
  control: {
    borderWidth: '1.5px',
    borderColor: 'cloud.400',
    width: '18px',
    minHeight: '18px',
    _checked: {
      backgroundColor: 'chonky.500',
      borderColor: 'chonky.600',
    },
  },
}

const defaultProps = {
  size: 'md',
  variant: 'base',
}

export default {
  baseStyle,
  defaultProps,
  parts: ['control'],
}
