import { Icon } from '@chakra-ui/icon'

const Clipboard = props => (
  <Icon {...props}>
    <path
      d="M13.4 5.498c-.01.121.076.23.2.254.504.121.848.566.824 1.064V8.16c0 .66.557 1.194 1.244 1.194.33 0 .647-.125.88-.35a1.17 1.17 0 00.365-.844V4.776c0-.381-.158-.747-.44-1.016a1.527 1.527 0 00-1.06-.418h-1.724a.251.251 0 00-.177.07.235.235 0 00-.073.17c.002.455.001 1.433-.039 1.916zM8.954 21.372c0-.66-.557-1.195-1.244-1.195H3.484c-.59-.037-1.036-.525-1-1.09V6.816c-.027-.506.328-.957.842-1.07a.242.242 0 00.2-.248 48.412 48.412 0 01-.055-1.92.245.245 0 00-.25-.237H1.5a1.527 1.527 0 00-1.06.418C.157 4.03 0 4.394 0 4.776v16.355c0 .38.157.745.438 1.014.28.27.66.42 1.056.42H7.71c.687 0 1.243-.534 1.244-1.194zM11.937 5.253V3.345a.49.49 0 00-.5-.48h-.758a.248.248 0 01-.249-.213C10.317 1.686 9.466.956 8.454.956c-1.013 0-1.864.73-1.977 1.696a.246.246 0 01-.249.213h-.755a.49.49 0 00-.5.48v1.908c0 .265.224.48.5.48h5.967a.49.49 0 00.497-.48zM19.02 17.538h-3.979c-.414 0-.75.323-.75.72 0 .398.336.72.75.72h3.979c.414 0 .75-.322.75-.72 0-.397-.336-.72-.75-.72zM14.291 14.918c0 .398.336.72.75.72h2.486c.414 0 .75-.323.75-.72 0-.398-.336-.72-.75-.72h-2.486c-.2 0-.39.075-.53.21a.706.706 0 00-.22.51z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.916 12.529c.374.359.584.845.584 1.353v8.198c0 1.06-.895 1.92-2 1.92h-9c-1.105 0-2-.86-2-1.92v-9.547c.003-1.055.895-1.91 1.994-1.909H20.1c.529 0 1.036.202 1.41.561l1.406 1.344zm-9.416.009c-.552 0-1 .43-1 .96l.005 7.622c0 .53.448.96 1 .96h7c.552 0 1-.43 1-.96v-6.839a.94.94 0 00-.293-.68l-.818-.783a1.022 1.022 0 00-.707-.28H13.5z"
      fill="currentColor"
    />
  </Icon>
)

Clipboard.displayName = 'ClipboardIcon'

export default Clipboard
