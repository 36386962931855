import { Icon } from '@chakra-ui/icon'

const InfoCircle = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12C23.993 5.376 18.624.007 12 0zm.25 5a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM10.5 18.5h4a1 1 0 100-2h-.75a.25.25 0 01-.25-.25V11.5a2 2 0 00-2-2h-1a1 1 0 100 2h.75a.25.25 0 01.25.25v4.5a.25.25 0 01-.25.25h-.75a1 1 0 100 2z"
      fill="currentColor"
    />
  </Icon>
)

InfoCircle.displayName = 'InfoCircleIcon'

export default InfoCircle
