import { Icon } from '@chakra-ui/icon'

const EmailOutline = props => (
  <Icon
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.4093 1.37207L8.3333 7.66674L15.2566 1.37274"
      stroke="#44D0EF"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M14.3333 1H2.33333C1.59695 1 1 1.59695 1 2.33333V11.6667C1 12.403 1.59695 13 2.33333 13H14.3333C15.0697 13 15.6667 12.403 15.6667 11.6667V2.33333C15.6667 1.59695 15.0697 1 14.3333 1Z"
      stroke="#44D0EF"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </Icon>
)

EmailOutline.displayName = 'EmailOutlineIcon'

export default EmailOutline
