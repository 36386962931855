import { Icon } from '@chakra-ui/icon'

const GameCards = props => (
  <Icon {...props}>
    <path
      d="M15.175 11.4L12.8 8.233a1.03 1.03 0 00-1.6 0L8.825 11.4a1 1 0 000 1.2l2.375 3.167a1 1 0 001.6 0l2.375-3.167a1 1 0 000-1.2z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.5 24h13a3 3 0 003-3V3a3 3 0 00-3-3h-13a3 3 0 00-3 3v18a3 3 0 003 3zm-1-21a1 1 0 011-1h13a1 1 0 011 1v18a1 1 0 01-1 1h-13a1 1 0 01-1-1V3z"
      fill="currentColor"
    />
    <path
      d="M7.25 6a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5zM16.75 20.5a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5z"
      fill="currentColor"
    />
  </Icon>
)

GameCards.displayName = 'GameCardsIcon'

export default GameCards
