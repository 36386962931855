import { Icon } from '@chakra-ui/icon'

const CautionDiamond = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23 10.586L13.414 1a2 2 0 00-2.828 0L1 10.587a2 2 0 000 2.828L10.586 23a2 2 0 002.828 0L23 13.415a2 2 0 000-2.829zM11.068 6.417a1 1 0 112 0v6a1 1 0 11-2 0v-6zm1.023 11.51h.028a1.475 1.475 0 001.448-1.53 1.527 1.527 0 00-1.522-1.47h-.028a1.475 1.475 0 00-1.448 1.53 1.529 1.529 0 001.522 1.47z"
      fill="currentColor"
    />
  </Icon>
)

CautionDiamond.displayName = 'CautionDiamondIcon'

export default CautionDiamond
