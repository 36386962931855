import { Icon } from '@chakra-ui/icon'

const MoreCircle = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 12C0 5.373 5.373 0 12 0c6.624.007 11.993 5.376 12 12 0 6.627-5.373 12-12 12S0 18.627 0 12zm6.5 2a2 2 0 100-4 2 2 0 000 4zm5.5-4a2 2 0 110 4 2 2 0 010-4zm3.5 2a2 2 0 104 0 2 2 0 00-4 0z"
      fill="currentColor"
    />
  </Icon>
)

MoreCircle.displayName = 'MoreCircleIcon'

export default MoreCircle
