import { Icon } from '@chakra-ui/icon'

const MessageBubbleCenterOutline = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.5 0a3.254 3.254 0 013.25 3.25v12.5A3.254 3.254 0 0120.5 19h-2.948l-3.888 4.263a2.251 2.251 0 01-3.329 0L6.448 19H3.5a3.254 3.254 0 01-3.25-3.25V3.25A3.254 3.254 0 013.5 0h17zm0 16.5c.414 0 .75-.336.75-.75V3.25a.751.751 0 00-.75-.75h-17a.751.751 0 00-.75.75v12.5c0 .414.336.75.75.75H7c.352 0 .687.149.924.409l3.891 4.267a.25.25 0 00.37 0l3.891-4.268A1.25 1.25 0 0117 16.5h3.5z"
      fill="currentColor"
    />
  </Icon>
)

MessageBubbleCenterOutline.displayName = 'MessageBubbleCenterOutlineIcon'

export default MessageBubbleCenterOutline
