import { Icon } from '@chakra-ui/icon'

const FlagWind = props => (
  <Icon {...props}>
    <path
      d="M23.563 15.609L16.794 10.2a.251.251 0 010-.39l5.987-4.789c.522-.418.486-.8.418-1-.068-.2-.281-.521-.949-.521h-9.5V2a1.5 1.5 0 00-1.5-1.5h-6a.5.5 0 00-.5.5v12a.5.5 0 00.5.5h4.23a3.555 3.555 0 00-1.56 1.625 1 1 0 00.928 1.375h14.4a.5.5 0 00.313-.891h.002zM1.75 0a1.5 1.5 0 00-1.5 1.5v21a1.5 1.5 0 003 0v-21A1.5 1.5 0 001.75 0z"
      fill="currentColor"
    />
  </Icon>
)

FlagWind.displayName = 'FlagWindIcon'

export default FlagWind
