import { Icon } from '@chakra-ui/icon'

const ChatIcon = props => {
  return (
    <Icon viewBox="0 -6 40 40" {...props}>
      <svg width="32" height="32" viewBox="0 0 18 18" fill="currentColor">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.03125 7.76043C1.03125 4.02569 4.71936 0.986816 9.25184 0.986816C13.7843 0.986816 17.4724 4.0264 17.4724 7.76043C17.4724 11.4944 13.7843 14.5333 9.25184 14.5333C8.32976 14.5349 7.41206 14.4067 6.52575 14.1524L2.13101 16.1977C2.08354 16.2196 2.03193 16.2311 1.97964 16.2314C1.85506 16.2315 1.73935 16.167 1.67397 16.0609C1.60859 15.9549 1.60291 15.8225 1.65897 15.7113L3.29749 12.4349C1.8883 11.2883 1.05863 9.57697 1.03125 7.76043ZM4.55936 7.80203C4.55936 8.39634 5.04114 8.87812 5.63545 8.87812C6.22975 8.87812 6.71153 8.39634 6.71153 7.80203C6.71153 7.20773 6.22975 6.72595 5.63545 6.72595C5.04114 6.72595 4.55936 7.20773 4.55936 7.80203ZM9.25183 6.72595C9.84613 6.72595 10.3279 7.20773 10.3279 7.80203C10.3279 8.39634 9.84613 8.87812 9.25183 8.87812C8.65752 8.87812 8.17574 8.39634 8.17574 7.80203C8.17574 7.20773 8.65752 6.72595 9.25183 6.72595ZM11.7627 7.80203C11.7627 8.39634 12.2445 8.87812 12.8388 8.87812C13.4331 8.87812 13.9149 8.39634 13.9149 7.80203C13.9149 7.20773 13.4331 6.72595 12.8388 6.72595C12.2445 6.72595 11.7627 7.20773 11.7627 7.80203Z"
          fill="white"
        />
      </svg>
    </Icon>
  )
}

export default ChatIcon
