import { Icon } from '@chakra-ui/icon'

const BatteryFull = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 8.5a2 2 0 012 2v3a2 2 0 01-2 2h-.5v1a2 2 0 01-2 2H2a2 2 0 01-2-2v-9a2 2 0 012-2h17.5a2 2 0 012 2v1h.5zm-.25 5a.25.25 0 00.25-.25v-2.5a.25.25 0 00-.25-.25H20.5a1 1 0 01-1-1V8a.5.5 0 00-.5-.5H2.5A.5.5 0 002 8v8a.5.5 0 00.5.5H19a.5.5 0 00.5-.5v-1.5a1 1 0 011-1h1.25z"
      fill="currentColor"
    />
    <path
      d="M6 9.5a.5.5 0 00-.5-.5H3.875A.868.868 0 003 9.857v4.286c.006.478.397.86.875.857H5.5a.5.5 0 00.5-.5v-5zM7 9.5a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v5a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-5zM11 9.5a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v5a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-5zM15 14.5a.5.5 0 00.5.5h1.687a.837.837 0 00.813-.857V9.857A.837.837 0 0017.187 9H15.5a.5.5 0 00-.5.5v5z"
      fill="currentColor"
    />
  </Icon>
)

BatteryFull.displayName = 'BatteryFullIcon'

export default BatteryFull
