import { Icon } from '@chakra-ui/icon'

const StarOutline = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.442 7.976a1.469 1.469 0 011.374.959 1.47 1.47 0 01-.423 1.622l-5.042 4.179 2.112 6.338a1.465 1.465 0 01-2.256 1.644L12 18.9l-5.208 3.816a1.465 1.465 0 01-2.255-1.648l2.111-6.334-5.031-4.169a1.466 1.466 0 01.941-2.589H8.5l2.121-6.008a1.465 1.465 0 012.751-.018L15.5 7.976h5.942zm-6.204 6.745a1 1 0 01.311-1.086l3.875-3.216a.25.25 0 00-.159-.443H14.79a1 1 0 01-.943-.668L12.236 4.74a.25.25 0 00-.472 0l-1.612 4.568a1 1 0 01-.943.668H4.734a.25.25 0 00-.159.443l3.878 3.212a1 1 0 01.31 1.086l-1.548 4.647a.25.25 0 00.385.28l3.807-2.793a1 1 0 011.183 0l3.812 2.798a.25.25 0 00.385-.281l-1.549-4.647z"
      fill="currentColor"
    />
  </Icon>
)

StarOutline.displayName = 'StarOutlineIcon'

export default StarOutline
