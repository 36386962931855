import { Icon } from '@chakra-ui/icon'

const PhoneTwo = props => (
  <Icon
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.87225 9.12366L8.44492 10.5317C7.70059 10.0829 7.00913 9.55175 6.38359 8.94832C5.78109 8.32174 5.25005 7.63016 4.80025 6.88632L6.20692 5.45899C6.29212 5.37248 6.34965 5.26257 6.37221 5.14327C6.39476 5.02396 6.38132 4.90064 6.33359 4.78899L4.86692 1.37032C4.80898 1.23552 4.70454 1.12603 4.57262 1.06178C4.44071 0.997539 4.29011 0.982826 4.14825 1.02032L1.45892 1.73166C1.32462 1.76647 1.20611 1.84584 1.12279 1.95678C1.03947 2.06771 0.996266 2.20364 1.00025 2.34232C1.17137 5.47017 2.47189 8.42962 4.66025 10.671C6.90204 12.86 9.86228 14.1608 12.9909 14.3317C13.1297 14.3363 13.266 14.2935 13.3772 14.2102C13.4883 14.1269 13.5677 14.0082 13.6023 13.8737L14.3129 11.183C14.3506 11.0412 14.3361 10.8906 14.2719 10.7587C14.2078 10.6267 14.0984 10.5223 13.9636 10.4643L10.5443 8.99832C10.4325 8.9499 10.3088 8.93596 10.1891 8.95829C10.0694 8.98062 9.95904 9.0382 9.87225 9.12366V9.12366Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </Icon>
)

PhoneTwo.displayName = 'PhoneTwoIcon'

export default PhoneTwo
