import { Icon } from '@chakra-ui/icon'

const GameSword = props => (
  <Icon {...props}>
    <path
      d="M6.964 7.982a.72.72 0 011.018-1.018l7.995 7.996a.48.48 0 00.679 0l2.267-2.265a.48.48 0 01.545-.096.96.96 0 001.091-1.546 1.92 1.92 0 00-2.715 0l-.576.576a.48.48 0 01-.697-.019L7.13 1.056a1.44 1.44 0 00-.918-.48L1.066.006A.96.96 0 00.01 1.066l.566 5.147c.04.354.208.68.473.917l10.56 9.443a.48.48 0 01.02.697l-.576.576a1.92 1.92 0 000 2.715.96.96 0 001.545-1.092.48.48 0 01.096-.546l2.265-2.264a.48.48 0 000-.679L6.964 7.982zM22.549 20.172l-4.196-4.194a.48.48 0 00-.678 0l-1.697 1.697a.48.48 0 000 .678l4.194 4.195a1.68 1.68 0 102.377-2.375z"
      fill="currentColor"
    />
  </Icon>
)

GameSword.displayName = 'GameSwordIcon'

export default GameSword
