import { Icon } from '@chakra-ui/icon'

const StarExperience = props => (
  <Icon {...props}>
    <path
      d="M15.41 10.876a.24.24 0 00-.323.225v1.275a.24.24 0 00.323.225.89.89 0 000-1.728v.003z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.53 9.844c.454-.43.595-1.094.355-1.67a1.567 1.567 0 00-1.462-.974h-4.686a.96.96 0 01-.854-.521L13.885.849a1.587 1.587 0 00-2.81 0L8.077 6.68a.96.96 0 01-.854.521H2.537c-.64-.005-1.219.38-1.462.973-.24.576-.1 1.241.354 1.67l4.043 3.95a.96.96 0 01.237.998L3.588 21c-.21.592-.03 1.253.452 1.656a1.588 1.588 0 001.772.207l6.228-3.212a.954.954 0 01.88 0l6.228 3.212c.578.301 1.28.22 1.772-.207.481-.403.661-1.062.452-1.653l-2.124-6.212a.96.96 0 01.238-.999l4.045-3.948zM12 16.032H9.12a.72.72 0 010-1.44h.24a.48.48 0 00.48-.48v-2.64a.241.241 0 00-.384-.192l-.384.288a.72.72 0 01-.864-1.152l1.152-.864a1.2 1.2 0 011.92.96v3.6c0 .265.215.48.48.48H12a.72.72 0 010 1.44zm3.087-1.5a.48.48 0 01.392-.47 2.421 2.421 0 002.041-2.323 2.468 2.468 0 00-2.544-2.379h-.609a.72.72 0 00-.72.72v5.232a.72.72 0 101.44 0v-.78z"
      fill="currentColor"
    />
  </Icon>
)

StarExperience.displayName = 'StarExperienceIcon'

export default StarExperience
