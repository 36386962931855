import { Icon } from '@chakra-ui/icon'

const Layers = props => (
  <Icon {...props}>
    <path
      d="M1.155 7.4l9.649 4.2c.763.33 1.629.33 2.392 0l9.649-4.2a1.5 1.5 0 000-2.751L13.2.452a3.009 3.009 0 00-2.392 0L1.157 4.646a1.5 1.5 0 000 2.752l-.002.002z"
      fill="currentColor"
    />
    <path
      d="M22.844 10.648l-1.244-.542a1.006 1.006 0 00-.8 0L12 13.933l-8.8-3.827a1.006 1.006 0 00-.8 0l-1.244.541a1.5 1.5 0 000 2.752l9.644 4.194c.763.33 1.629.33 2.392 0l9.649-4.193a1.5 1.5 0 000-2.752h.003z"
      fill="currentColor"
    />
    <path
      d="M22.844 16.6l-1.244-.539a1 1 0 00-.8 0L12 19.888l-8.8-3.827a1 1 0 00-.8 0l-1.244.54a1.5 1.5 0 000 2.752l9.644 4.195c.763.33 1.629.33 2.392 0l9.645-4.193a1.5 1.5 0 000-2.753l.007-.002z"
      fill="currentColor"
    />
  </Icon>
)

Layers.displayName = 'LayersIcon'

export default Layers
