import { useState, useContext } from 'react'
import { AppContext } from '../providers'
import { getDirectUploadURL } from '../utils/arcadeXMLHttpRequest'

interface DirectUploadResponse {
  url: string
  signedBlobId: string
}

interface DirectUploadHook {
  progress: number
  isUploading: boolean
  uploadFiles: (files: FileList | File[]) => Promise<DirectUploadResponse[]>
}

export const useDirectUpload = (): DirectUploadHook => {
  const { setToast } = useContext(AppContext)
  const [progress, setProgress] = useState(0)
  const [isUploading, setIsUploading] = useState(false)

  const uploadFiles = async (files: FileList | File[]) => {
    setIsUploading(true)

    const { DirectUpload } = await import('@rails/activestorage')

    const responses: any[] = []
    const maxFileSizeMB = 100
    const maxFileSize = maxFileSizeMB * 1024 * 1024 // 100 MB in bytes

    for (let i = 0; i < files.length; i++) {
      const file = files[i]

      if (file.size > maxFileSize) {
        console.error(
          `File ${file.name} is too big. Max file size is ${maxFileSizeMB} MB.`,
        )
        setToast({
          title: 'Upload Error',
          description: `File ${file.name} is too big. Max file size is ${maxFileSizeMB} MB.`,
          status: 'error',
        })
        continue
      }

      const upload = new DirectUpload(file, getDirectUploadURL())
      try {
        const response = await new Promise((resolve, reject) => {
          upload.create((error, blob) => {
            if (error) {
              reject(error)
            } else {
              resolve({ file, ...blob })
            }
          })
        })

        responses.push(response)

        const currentProgress = ((i + 1) / files.length) * 100
        setProgress(currentProgress)
      } catch (error) {
        console.error(error)
      }
    }

    setIsUploading(false)

    return responses
  }

  return { progress, isUploading, uploadFiles }
}
