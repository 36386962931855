export enum QueryParamDrawer {
  AwardTokens = 'award-tokens',
  AwardChest = 'award-chest',
  BadgeDrawer = 'view-badges',
  CreateMetric = 'create-metric',
  CreateRecharge = 'create-recharge',
  EditMetric = 'edit-metric',
  EditRecharge = 'edit-recharge',
  FilterTeams = 'filter-teams',
  GiveRecognitionStar = 'give-recognition-star',
  ManageActivitiesCreate = 'manage-activities-create',
  ManageActivitiesEdit = 'manage-activities-edit',
  ManageActivitiesFilters = 'manage-activities-filters',
  ManageGamesEdit = 'manage-games-edit',
  ManageGamesClone = 'manage-games-clone',
  ManageGamesCancelGame = 'manage-games-cancel-game',
  ManageMetricsFilter = 'manage-metrics-filter',
  ManagePlayerClawback = 'manage-player-clawback',
  ManagePlayerColumns = 'manage-player-columns',
  ManagePlayerFilters = 'manage-player-filters',
  ManagePlayerInvite = 'manage-player-invite',
  ManageRewardsFilter = 'manage-rewards-filter',
  ManageSettings = 'manage-settings',
  ManageTransactionsFilter = 'manage-transactions-filter',
  BuyTokens = 'buy-tokens',
  ManageTransactionsTransferTokens = 'transfer-tokens',
  ManageTransactionsCSV = 'export-csv',
  UpdatePassword = 'update-password',
  UpdateProfile = 'update-profile',
  UpsertTeam = 'upsert-team',
  ViewRecognitionStars = 'view-recognition-stars',
  NotificationSettings = 'notification-settings',
}

export enum QueryParamModal {
  RewardDetail = 'reward-detail',
}
