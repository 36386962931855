import { Icon } from '@chakra-ui/icon'

const SendAlternate = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.333.108a1.049 1.049 0 011.123.136c.267.252.385.624.312.984l-5.094 21.25a1.983 1.983 0 01-2.983 1.215l-3.882-2.442-2.193 2.194a1.49 1.49 0 01-2.545-1.054V18.27l-5.939-3.736a1.981 1.981 0 01.139-3.434L22.333.108zM17.608 5.06a.25.25 0 00-.308-.071L2.608 12.652a.25.25 0 00-.018.433L7.7 16.3a.251.251 0 00.323-.045L17.6 5.376a.25.25 0 00.008-.316zM9.07 21.16v-1.634l1 .631-1 1.003zm7.617.673a.25.25 0 00.149-.173l3.821-16.01a.25.25 0 00-.431-.224L9.791 17.276a.25.25 0 00.054.376l6.614 4.16a.25.25 0 00.228.02z"
      fill="currentColor"
    />
  </Icon>
)

SendAlternate.displayName = 'SendAlternateIcon'

export default SendAlternate
