import { Icon } from '@chakra-ui/icon'

const SyncThree = props => (
  <Icon {...props}>
    <path
      d="M12.985 20.57a1.25 1.25 0 10.613 2.424 10.482 10.482 0 007.657-8.218.25.25 0 01.223-.2l1.691-.152a.5.5 0 00.326-.833l-3.2-3.55a.527.527 0 00-.417-.163.5.5 0 00-.38.235l-2.512 4.065a.5.5 0 00.425.762h.045l.838-.075a.249.249 0 01.261.323 7.92 7.92 0 01-5.57 5.382zM8.23 2.754a.25.25 0 01-.28-.111L7.079 1.2a.5.5 0 00-.891.072L4.4 5.707a.5.5 0 00.414.684l4.753.473a.417.417 0 00.05 0 .5.5 0 00.428-.759l-.458-.756a.25.25 0 01.175-.376 7.906 7.906 0 017.309 2.677 1.25 1.25 0 101.908-1.615A10.427 10.427 0 008.23 2.754zM2.558 8.746a1.245 1.245 0 00-1.595.762 10.493 10.493 0 002.582 10.948c.08.078.1.201.045.3l-.8 1.47a.5.5 0 00.512.734l4.727-.692a.5.5 0 00.382-.7l-1.99-4.349a.5.5 0 00-.894-.031l-.424.781a.25.25 0 01-.414.039 7.966 7.966 0 01-1.369-7.666 1.25 1.25 0 00-.762-1.596z"
      fill="currentColor"
    />
  </Icon>
)

SyncThree.displayName = 'SyncThreeIcon'

export default SyncThree
