import { Icon } from '@chakra-ui/icon'

const Close = props => (
  <Icon {...props}>
    <path
      d="M.421 20.582A1.44 1.44 0 102.46 22.62l8.891-8.891a.24.24 0 01.34 0l8.891 8.893a1.44 1.44 0 002.038-2.037l-8.891-8.894a.24.24 0 010-.34l8.893-8.891A1.44 1.44 0 0020.58.422l-8.891 8.89a.24.24 0 01-.34 0L2.46.422A1.44 1.44 0 00.42 2.46l8.891 8.891a.24.24 0 010 .34l-8.89 8.892z"
      fill="currentColor"
    />
  </Icon>
)

Close.displayName = 'CloseIcon'

export default Close
