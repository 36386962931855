import { Icon } from '@chakra-ui/icon'

const Shield = props => (
  <Icon {...props}>
    <path
      d="M19.5 7.462c0-.906.877-2.128 2.347-3.269a1 1 0 00.094-1.5L19.623.378a1 1 0 00-1.2-.161L15.5 1.886l-3-1.717a1 1 0 00-.991 0l-3 1.717L5.58.217a1 1 0 00-1.2.161L2.059 2.7a1 1 0 00.094 1.5C3.623 5.334 4.5 6.556 4.5 7.462a4.331 4.331 0 01-1.21 2.573 6.848 6.848 0 00-1.79 4.427c0 6.812 9.955 9.379 10.379 9.485a.5.5 0 00.242 0c.424-.106 10.379-2.673 10.379-9.485a6.848 6.848 0 00-1.79-4.427 4.331 4.331 0 01-1.21-2.573z"
      fill="currentColor"
    />
  </Icon>
)

Shield.displayName = 'ShieldIcon'

export default Shield
