import { Icon } from '@chakra-ui/icon'

const Home = props => (
  <Icon {...props}>
    <path
      d="M23.707 11.293l-11-11a1 1 0 00-1.414 0l-11 11A1 1 0 001 13h1.25a.25.25 0 01.25.25V23a1 1 0 001 1h5.75a.25.25 0 00.25-.25V19a2.5 2.5 0 015 0v4.75c0 .138.112.25.25.25h5.75a1 1 0 001-1v-9.75a.25.25 0 01.25-.25H23a1 1 0 00.707-1.707z"
      fill="currentColor"
    />
  </Icon>
)

Home.displayName = 'HomeIcon'

export default Home
