import { Icon } from '@chakra-ui/icon'

const CircleOutline = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 12C0 5.373 5.373 0 12 0c6.624.007 11.993 5.376 12 12 0 6.627-5.373 12-12 12S0 18.627 0 12zm2.5 0a9.5 9.5 0 009.5 9.5 9.511 9.511 0 009.5-9.5 9.5 9.5 0 00-19 0z"
      fill="currentColor"
    />
  </Icon>
)

CircleOutline.displayName = 'CircleOutlineIcon'

export default CircleOutline
