import { useEffect, useState } from 'react'
import useSWR from 'swr'
import { get } from 'lodash'
import { arcadeApiClient } from '../apiClients'
import { snakeCaseKeys } from '../utils/string'

const PAYMENT_SUCCESS_CHECK_TIMEOUT = 30_000
export const ACH_SUBMITTED_SUCCESSFULLY = 'ACH_SUBMITTED_SUCCESSFULLY'

export const PAYMENT_STATUS_FAILED = 'Failed'
const PAYMENT_STATUS_SUCCESSFUL = 'Successful'
export const PAYMENT_STATUS_TIMEOUT = 'Timeout'
export const MICRODEPOSIT_VERIFICATION_REQUIRED =
  'The user submitted bank account information that must be verified via microdeposit information'

export type PaymentType = {
  id: string
  paymentMethodType: string
  accountType: string
  name: string
  last4: string
  scope: string
}

export type PaymentMethodCardDetails = {
  brand: string
  expMonth: number | string
  expYear: number | string
  last4: string
  postalCode: string
  cardType: PaymentMethodScope
  scope: PaymentMethodScope
}

export type PaymentMethodBankAccountDetails = {
  accountHolderName: string
  bankName: string
  last4: string
  scope: PaymentMethodScope
}

export type PaymentMethod = {
  id: string
  card: PaymentMethodCardDetails | null
  usBankAccount: PaymentMethodBankAccountDetails | null
}

export enum PaymentMethodType {
  BankAccount = 'account',
  Card = 'card',
}

type UpdateCardParams = {
  expMonth: number | string
  expYear: number | string
  paymentMethodId: string
  paymentMethodType: PaymentMethodType
  postalCode: string
}

type UpdateBankAccountParams = {
  accountHolderName: string
  paymentMethodId: string
  paymentMethodType: PaymentMethodType
}

export type UpdatePaymentMethodParams =
  | UpdateCardParams
  | UpdateBankAccountParams

enum PurchaseStatus {
  Failed = 'failed',
  Pending = 'pending',
  Processed = 'processed',
}

export enum PaymentMethodScope {
  Company = 'company',
  Personal = 'personal',
}

type PurchaseCompleteExecutors = {
  resolve: (value: any) => void
  reject: (value: any) => void
}

type PaymentMethodMetadata = {
  scope: PaymentMethodScope
}

const fetcher = async (url: string): Promise<PaymentMethod[]> =>
  arcadeApiClient.get(url).then(res => res.data.paymentMethods)

const purchaseStatusFetcher = async (url: string): Promise<PurchaseStatus> =>
  arcadeApiClient.get(url).then(res => res.data.status)

export const usePaymentMethods = () => {
  const [loading, setLoading] = useState(false)
  const [notConfigured, setNotConfigured] = useState<boolean>(false)
  const [invoiceId, setInvoiceId] = useState<string | null>(null)

  /* FNs to ultimately resolve or reject the promise associated with waiting for a purchase to 
     complete (when webhook changes status in our API) */
  const [purchaseCompleteExecutors, setPurchaseCompleteExecutors] =
    useState<PurchaseCompleteExecutors | null>(null)

  useEffect(() => {
    if (!purchaseCompleteExecutors) return

    const { reject } = purchaseCompleteExecutors
    setTimeout(() => {
      reject(PAYMENT_STATUS_TIMEOUT)
      setInvoiceId(null)
      setPurchaseCompleteExecutors(null)
    }, PAYMENT_SUCCESS_CHECK_TIMEOUT)
  }, [purchaseCompleteExecutors])

  const { data: purchaseStatus } = useSWR(
    `/multi_platform/manage/payments/invoice/${invoiceId}/status`,
    purchaseStatusFetcher,
    {
      isPaused: () => !invoiceId,
      refreshInterval: 1000,
    },
  )

  useEffect(() => {
    if (!invoiceId || !purchaseCompleteExecutors || !purchaseStatus) return

    const { reject, resolve } = purchaseCompleteExecutors
    if (purchaseStatus === PurchaseStatus.Pending) return

    if (purchaseStatus === PurchaseStatus.Processed) {
      resolve(PAYMENT_STATUS_SUCCESSFUL)
    }

    if (purchaseStatus === PurchaseStatus.Failed) {
      reject(PAYMENT_STATUS_FAILED)
    }

    setInvoiceId(null)
    setPurchaseCompleteExecutors(null)
  }, [invoiceId, purchaseCompleteExecutors, purchaseStatus])

  const paymentMethodUrl = '/multi_platform/manage/payments/saved_methods'

  const {
    data: paymentMethods,
    error,
    isValidating,
    mutate: refetchMethods,
  } = useSWR(paymentMethodUrl, fetcher, {
    isPaused: () => notConfigured,
  })
  const isInitialLoading = isValidating && !paymentMethods && !error

  useEffect(() => {
    const missingStripeCustomerId =
      get(error, 'response.data.message') === 'company_not_registered'

    setNotConfigured(missingStripeCustomerId)
  }, [error])

  const removeMethod = (paymentMethodId: string) => {
    setLoading(true)
    return arcadeApiClient
      .delete(`/multi_platform/manage/payments/method/${paymentMethodId}`)
      .then(() => refetchMethods())
      .finally(() => setLoading(false))
  }

  const updateMethod = (params: UpdatePaymentMethodParams) => {
    const { paymentMethodId, ...otherParams } = params
    setLoading(true)

    return arcadeApiClient
      .patch(
        `/multi_platform/manage/payments/method/${paymentMethodId}`,
        snakeCaseKeys(otherParams),
      )
      .then(() => refetchMethods())
      .finally(() => setLoading(false))
  }

  const attachMethod = (paymentMethodId: string) => {
    setLoading(true)

    return arcadeApiClient
      .put(`/multi_platform/manage/payments/method/${paymentMethodId}`)
      .then(() => refetchMethods())
      .finally(() => setLoading(false))
  }

  const submitPurchase: (
    paymentMethodId: string,
    amount: number,
    paymentMethodType: PaymentMethodType,
    skipSuccessCheck: boolean,
    accountType: PaymentMethodScope,
  ) => Promise<any> = (
    paymentMethodId,
    amount,
    paymentMethodType,
    skipSuccessCheck,
    accountType,
  ) => {
    setLoading(true)

    return arcadeApiClient
      .post('multi_platform/manage/payments/purchase_tokens', {
        amount,
        payment_method_id: paymentMethodId,
        payment_method_type: paymentMethodType,
        account_type: accountType,
      })
      .then(({ data: { invoiceId } }) => {
        return new Promise((resolve, reject) => {
          if (skipSuccessCheck) return reject(ACH_SUBMITTED_SUCCESSFULLY)

          setInvoiceId(invoiceId)
          setPurchaseCompleteExecutors({ resolve, reject })
        })
      })
      .finally(() => setLoading(false))
  }

  const createSetupIntent = (scope: PaymentMethodScope) => {
    setLoading(true)

    return arcadeApiClient
      .post('/multi_platform/manage/payments/setup_intent', {
        scope,
      })
      .finally(() => setLoading(false))
  }

  const setMethodMetadata = (
    paymentMethodId: string,
    metadata: PaymentMethodMetadata,
  ) => {
    setLoading(true)

    return arcadeApiClient
      .patch(
        `/multi_platform/manage/payments/method/${paymentMethodId}/metadata`,
        { metadata },
      )
      .then(() => refetchMethods())
      .finally(() => setLoading(false))
  }

  const detailsByPaymentMethodId = (
    paymentMethodId: string,
  ): PaymentMethodCardDetails | PaymentMethodBankAccountDetails | null => {
    const method = paymentMethods?.find(({ id }) => id === paymentMethodId)
    if (!method) return null

    if (method.card) return method.card as PaymentMethodCardDetails
    return method.usBankAccount as PaymentMethodBankAccountDetails
  }

  return {
    attachMethod,
    createSetupIntent,
    detailsByPaymentMethodId,
    isInitialLoading,
    loading: loading || isValidating,
    notConfigured,
    paymentMethods: paymentMethods || [],
    removeMethod,
    setMethodMetadata,
    submitPurchase,
    updateMethod,
  }
}
