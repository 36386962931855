import { Icon } from '@chakra-ui/icon'

const Send = props => (
  <Icon {...props}>
    <path
      d="M22.985.638a.5.5 0 00-.719-.559L1.783 10.818a1.462 1.462 0 00-.1 2.528l4.56 2.882c.094.06.217.05.3-.025L18.7 5.335a.25.25 0 01.362.343L9.346 17.864a.252.252 0 00.062.37L15.84 22.3a1.453 1.453 0 002.19-.895L22.985.638zM7.885 19.182a.25.25 0 00-.384.211v3.584a1 1 0 001.707.708l2.017-2.017a.251.251 0 00-.043-.388l-3.297-2.098z"
      fill="currentColor"
    />
  </Icon>
)

Send.displayName = 'SendIcon'

export default Send
