import { forwardRef, Input, InputProps } from '@chakra-ui/react'
import styles from './CbInput.module.css'
export interface CbInputProps extends InputProps {
  readOnly?: boolean
  onEnterSubmit?: () => void
}
export const CbInput = forwardRef(
  ({ onEnterSubmit, variant, ...props }, ref) => {
    return (
      <Input
        className={styles.input}
        borderWidth={'1px'}
        borderColor="cloud.300"
        aria-label="input"
        fontFamily="body"
        onKeyDown={e => {
          if (e.key === 'Enter') {
            e.preventDefault()
            e.stopPropagation()
            onEnterSubmit?.()
          }
        }}
        ref={ref}
        {...props}
        variant={variant || 'cb-base'}
        _disabled={{
          backgroundColor: 'cloud.300',
          cursor: 'not-allowed',
          color: 'cloud.700',
        }}
      />
    )
  },
)
