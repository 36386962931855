import { Icon } from '@chakra-ui/icon'

const CSV = props => (
  <Icon {...props}>
    <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
      <path d="m966.9 482.6h-70.9v-146.6c0-44-25.5-105.5-56.6-136.6l-142.8-142.8c-31.1-31.1-92.6-56.6-136.6-56.6h-480c-44 0-80 36-80 80v864c0 44 36 80 80 80h736c44 0 80-36 80-80v-80.5h70.9c31.4 0 57.1-25.7 57.1-57.1v-266.7c0-31.4-25.7-57.1-57.1-57.1zm-326.8-390c4.4 3.1 8.2 6.2 11.3 9.3l142.8 142.8c3.1 3.1 6.2 6.9 9.3 11.3h-163.4zm191.9 851.4c0 8.7-7.3 16-16 16h-736c-8.7 0-16-7.3-16-16v-864c0-8.7 7.3-16 16-16h480c4.8 0 10.2.6 16 1.7v254.3h254.3c1.1 5.8 1.7 11.2 1.7 16v146.6h-601.8c-31.4 0-57.1 25.7-57.1 57.1v266.8c0 31.4 25.7 57.1 57.1 57.1h601.8zm-225.2-249.9c-2.1-2.9-5.2-5.8-9.3-8.5s-12.4-7.1-25-13.1c-11.8-5.5-20.6-10.9-26.5-16s-10.6-11-14.1-17.8-5.3-14.7-5.3-23.7c0-17.1 5.8-30.5 17.3-40.2 11.6-9.8 27.5-14.6 47.9-14.6 10 0 19.6 1.2 28.7 3.6s18.6 5.7 28.5 10l-13.2 31.8c-10.3-4.2-18.8-7.2-25.5-8.8-6.7-1.7-13.3-2.5-19.8-2.5-7.7 0-13.7 1.8-17.8 5.4s-6.2 8.3-6.2 14.1c0 3.6.8 6.7 2.5 9.4s4.3 5.3 8 7.8c3.6 2.5 12.3 7 25.9 13.5 18 8.6 30.4 17.2 37 25.9 6.7 8.7 10 19.3 10 31.8 0 17.4-6.3 31.1-18.8 41.1s-30 15-52.3 15c-20.6 0-38.8-3.9-54.6-11.6v-38c13 5.8 24 9.9 33 12.3s17.2 3.6 24.7 3.6c9 0 15.8-1.7 20.6-5.1s7.2-8.5 7.2-15.3c.3-3.8-.8-7.2-2.9-10.1zm-186.4 30.2c13.5 0 29.9-3.4 49.2-10.2v34.3c-15.8 6.6-33.5 9.9-53 9.9-28 0-49.5-8.5-64.3-25.5-14.9-17-22.3-41.4-22.3-73.2 0-20 3.6-37.6 10.9-52.7s17.8-26.6 31.4-34.7c13.7-8 29.7-12.1 48.1-12.1 18.7 0 37.5 4.5 56.4 13.6l-13.1 33.3c-7.2-3.4-14.5-6.4-21.8-9-7.3-2.5-14.5-3.8-21.5-3.8-15.4 0-27.3 5.8-35.7 17.3-8.4 11.6-12.7 27.7-12.7 48.3 0 43 16.2 64.5 48.4 64.5zm375.9 31.4h-44.6l-65.3-192.7h41.3l36.3 114.7c2 6.8 4.1 14.7 6.3 23.7s3.5 15.3 4 18.8c1-8.1 4.3-22.2 9.9-42.5l36.4-114.7h41.3z" />
    </svg>
  </Icon>
)

CSV.displayName = 'CSVIcon'

export default CSV
