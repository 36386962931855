import { Icon } from '@chakra-ui/icon'

const ArrowUp = props => (
  <Icon {...props}>
    <path
      d="M23.421 11.765L13.768.8A2.64 2.64 0 0012 0a2.645 2.645 0 00-1.768.8L.579 11.765A1.413 1.413 0 102.7 13.632l7.45-8.466a.25.25 0 01.437.166v17.255a1.413 1.413 0 102.826 0V5.332a.25.25 0 01.438-.165l7.449 8.465a1.413 1.413 0 102.121-1.867z"
      fill="currentColor"
    />
  </Icon>
)

ArrowUp.displayName = 'ArrowUpIcon'

export default ArrowUp
