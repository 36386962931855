import { Icon } from '@chakra-ui/icon'

const ArrowDown = props => (
  <Icon {...props}>
    <path
      d="M23.292 10.24a1.413 1.413 0 00-1.994.127l-7.448 8.464a.251.251 0 01-.438-.166V1.413a1.412 1.412 0 00-2.824 0v17.252a.251.251 0 01-.438.166L2.7 10.367a1.413 1.413 0 10-2.119 1.867L10.233 23.2a2.352 2.352 0 003.534 0l9.652-10.967a1.412 1.412 0 00-.127-1.993z"
      fill="currentColor"
    />
  </Icon>
)

ArrowDown.displayName = 'ArrowDownIcon'

export default ArrowDown
