import { Icon } from '@chakra-ui/icon'

const ChatBubbleCautionOutline = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 10.811C0 5.311 5.383.836 12 .836S24 5.31 24 10.81s-5.383 9.974-12 9.974a14.273 14.273 0 01-3.772-.5l-5.981 2.784a1 1 0 01-1.316-1.355l2.126-4.244A9.009 9.009 0 010 10.811zm8.437 7.451c1.155.35 2.356.526 3.563.523 5.514 0 10-3.577 10-7.974s-4.486-7.975-10-7.975S2 6.411 2 10.811a7.207 7.207 0 002.925 5.627 1 1 0 01.269 1.229l-.869 1.736a.25.25 0 00.329.338l3.07-1.429a.99.99 0 01.713-.05z"
      fill="currentColor"
    />
    <path
      d="M12 12.374c.69 0 1.25-.56 1.25-1.25v-4.75a1.25 1.25 0 10-2.5 0v4.75a1.25 1.25 0 001.25 1.25zM12 16.374a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
      fill="currentColor"
    />
  </Icon>
)

ChatBubbleCautionOutline.displayName = 'ChatBubbleCautionOutlineIcon'

export default ChatBubbleCautionOutline
