import { Icon } from '@chakra-ui/icon'

const ChevronDownWide = props => (
  <Icon viewBox="0 0 10 7" {...props}>
    <path d="M1 1L5 5L9 1" stroke="currentcolor" strokeWidth="2" fill="none" />
  </Icon>
)

ChevronDownWide.displayName = 'ChevronDownWideIcon'

export default ChevronDownWide
