import { Icon } from '@chakra-ui/icon'

const MoreCircleOutline = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 12C0 5.373 5.373 0 12 0c6.624.007 11.993 5.376 12 12 0 6.627-5.373 12-12 12S0 18.627 0 12zm2 0c0 5.523 4.477 10 10 10 5.52-.006 9.994-4.48 10-10 0-5.523-4.477-10-10-10S2 6.477 2 12z"
      fill="currentColor"
    />
    <path
      d="M12 14a2 2 0 100-4 2 2 0 000 4zM17.5 14a2 2 0 100-4 2 2 0 000 4zM6.5 14a2 2 0 100-4 2 2 0 000 4z"
      fill="currentColor"
    />
  </Icon>
)

MoreCircleOutline.displayName = 'MoreCircleOutlineIcon'

export default MoreCircleOutline
