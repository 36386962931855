import { Icon } from '@chakra-ui/icon'

const GamePickaxe = props => (
  <Icon {...props}>
    <path
      d="M20.048 5.719a1 1 0 000-1.415l-.348-.353a1 1 0 00-1.414 0l-.122.122a.5.5 0 01-.671.032A17.4 17.4 0 007.013.126 1.009 1.009 0 006 .888a.989.989 0 00.54 1.136 32.527 32.527 0 016.582 4.186.5.5 0 01.037.741l-.183.183a1 1 0 000 1.413l2.474 2.475a1 1 0 001.414 0l.183-.182a.5.5 0 01.741.036 32.547 32.547 0 014.186 6.583.99.99 0 001.138.541 1 1 0 00.762-1.016 17.413 17.413 0 00-3.98-10.475.5.5 0 01.032-.671l.122-.119zM12.8 10.137a.75.75 0 00-1.06 0L.491 21.387a1.252 1.252 0 000 1.768l.353.354a1.25 1.25 0 001.768 0l11.249-11.25a.752.752 0 000-1.061L12.8 10.137z"
      fill="currentColor"
    />
  </Icon>
)

GamePickaxe.displayName = 'GamePickaxeIcon'

export default GamePickaxe
