import { Icon } from '@chakra-ui/icon'

const GamePacman = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6 12.873l7.825 5.285a.955.955 0 01.413.644.932.932 0 01-.2.731c-2.265 2.826-5.773 4.478-9.487 4.467-5.62 0-10.488-3.744-11.712-9.01C.214 9.724 2.96 4.348 8.047 2.053 13.134-.24 19.198 1.161 22.64 5.428c.168.207.24.471.2.73a.955.955 0 01-.414.644L14.6 12.087a.474.474 0 00-.212.393c0 .156.079.303.212.393zM8.651 7.2c0 1.06.895 1.92 2 1.92.53 0 1.04-.202 1.414-.562.375-.36.586-.849.586-1.358 0-1.06-.895-1.92-2-1.92s-2 .86-2 1.92z"
      fill="currentColor"
    />
  </Icon>
)

GamePacman.displayName = 'GamePacmanIcon'

export default GamePacman
