import { Icon } from '@chakra-ui/icon'

const TokensLarge = props => (
  <Icon viewBox="0 0 256 256" {...props}>
    <rect
      x="103.525"
      y="136.428"
      width="51.62"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="103.525"
      y="210.506"
      width="51.62"
      height="10.6459"
      fill="#E89600"
    />
    <rect
      x="103.525"
      y="200.304"
      width="51.62"
      height="10.6459"
      fill="#F5AD00"
    />
    <rect
      x="103.525"
      y="147.074"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="92.845"
      y="147.074"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="82.1648"
      y="157.72"
      width="10.68"
      height="42.5837"
      fill="#FCCC00"
    />
    <rect x="92.845" y="157.72" width="10.68" height="10.6459" fill="#FFF267" />
    <rect
      x="92.845"
      y="168.366"
      width="10.68"
      height="31.9377"
      fill="#F5AD00"
    />
    <rect
      x="92.845"
      y="200.304"
      width="10.68"
      height="10.6459"
      fill="#F5AD00"
    />
    <rect
      x="103.525"
      y="157.72"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="103.525"
      y="168.366"
      width="10.68"
      height="21.2918"
      fill="#E89600"
    />
    <rect
      x="144.465"
      y="168.366"
      width="10.68"
      height="21.2918"
      fill="#E89600"
    />
    <rect
      x="103.525"
      y="189.658"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="144.465"
      y="189.658"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="114.205"
      y="157.72"
      width="30.26"
      height="10.6459"
      fill="#E89600"
    />
    <rect
      x="114.205"
      y="189.658"
      width="30.26"
      height="10.6459"
      fill="#E89600"
    />
    <rect
      x="114.205"
      y="168.366"
      width="30.26"
      height="21.2918"
      fill="#FCD303"
    />
    <rect
      x="114.205"
      y="147.074"
      width="40.94"
      height="10.6459"
      fill="#F5AD00"
    />
    <rect
      x="144.465"
      y="157.72"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="155.145"
      y="147.074"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="155.145"
      y="157.72"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="155.145"
      y="168.366"
      width="10.68"
      height="31.9377"
      fill="#F5AD00"
    />
    <rect
      x="155.145"
      y="200.304"
      width="10.68"
      height="10.6459"
      fill="#E89600"
    />
    <rect
      x="165.825"
      y="157.72"
      width="10.68"
      height="42.5837"
      fill="#FCCC00"
    />
    <rect
      x="103.525"
      y="126.226"
      width="51.62"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="103.525"
      y="200.303"
      width="51.62"
      height="10.6459"
      fill="#E89600"
    />
    <rect
      x="103.525"
      y="190.101"
      width="51.62"
      height="10.6459"
      fill="#F5AD00"
    />
    <rect
      x="103.525"
      y="136.871"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="92.845"
      y="136.871"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="82.1648"
      y="147.517"
      width="10.68"
      height="42.5837"
      fill="#FCCC00"
    />
    <rect
      x="92.845"
      y="147.517"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="92.845"
      y="158.163"
      width="10.68"
      height="31.9377"
      fill="#F5AD00"
    />
    <rect
      x="92.845"
      y="190.101"
      width="10.68"
      height="10.6459"
      fill="#F5AD00"
    />
    <rect
      x="103.525"
      y="147.517"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="103.525"
      y="158.163"
      width="10.68"
      height="21.2918"
      fill="#E89600"
    />
    <rect
      x="144.465"
      y="158.163"
      width="10.68"
      height="21.2918"
      fill="#E89600"
    />
    <rect
      x="103.525"
      y="179.455"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="144.465"
      y="179.455"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="114.205"
      y="147.517"
      width="30.26"
      height="10.6459"
      fill="#E89600"
    />
    <rect
      x="114.205"
      y="179.455"
      width="30.26"
      height="10.6459"
      fill="#E89600"
    />
    <rect
      x="114.205"
      y="158.163"
      width="30.26"
      height="21.2918"
      fill="#FCD303"
    />
    <rect
      x="114.205"
      y="136.871"
      width="40.94"
      height="10.6459"
      fill="#F5AD00"
    />
    <rect
      x="144.465"
      y="147.517"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="155.145"
      y="136.871"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="155.145"
      y="147.517"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="155.145"
      y="158.163"
      width="10.68"
      height="31.9377"
      fill="#F5AD00"
    />
    <rect
      x="155.145"
      y="190.101"
      width="10.68"
      height="10.6459"
      fill="#E89600"
    />
    <rect
      x="165.825"
      y="147.517"
      width="10.68"
      height="42.5837"
      fill="#FCCC00"
    />
    <rect
      x="103.525"
      y="116.023"
      width="51.62"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="103.525"
      y="190.101"
      width="51.62"
      height="10.6459"
      fill="#E89600"
    />
    <rect
      x="103.525"
      y="179.899"
      width="51.62"
      height="10.6459"
      fill="#F5AD00"
    />
    <rect
      x="103.525"
      y="126.669"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="92.845"
      y="126.669"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="82.165"
      y="137.315"
      width="10.68"
      height="42.5837"
      fill="#FCCC00"
    />
    <rect
      x="92.845"
      y="137.315"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="92.845"
      y="147.961"
      width="10.68"
      height="31.9377"
      fill="#F5AD00"
    />
    <rect
      x="92.845"
      y="179.899"
      width="10.68"
      height="10.6459"
      fill="#F5AD00"
    />
    <rect
      x="103.525"
      y="137.315"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="103.525"
      y="147.961"
      width="10.68"
      height="21.2918"
      fill="#E89600"
    />
    <rect
      x="144.465"
      y="147.961"
      width="10.68"
      height="21.2918"
      fill="#E89600"
    />
    <rect
      x="103.525"
      y="169.253"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="144.465"
      y="169.253"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="114.205"
      y="137.315"
      width="30.26"
      height="10.6459"
      fill="#E89600"
    />
    <rect
      x="114.205"
      y="169.253"
      width="30.26"
      height="10.6459"
      fill="#E89600"
    />
    <rect
      x="114.205"
      y="147.961"
      width="30.26"
      height="21.2918"
      fill="#FCD303"
    />
    <rect
      x="114.205"
      y="126.669"
      width="40.94"
      height="10.6459"
      fill="#F5AD00"
    />
    <rect
      x="144.465"
      y="137.315"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="155.145"
      y="126.669"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="155.145"
      y="137.315"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="155.145"
      y="147.961"
      width="10.68"
      height="31.9377"
      fill="#F5AD00"
    />
    <rect
      x="155.145"
      y="179.899"
      width="10.68"
      height="10.6459"
      fill="#E89600"
    />
    <rect
      x="165.825"
      y="137.315"
      width="10.68"
      height="42.5837"
      fill="#FCCC00"
    />
    <rect
      x="103.525"
      y="105.821"
      width="51.62"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="103.525"
      y="179.899"
      width="51.62"
      height="10.6459"
      fill="#E89600"
    />
    <rect
      x="103.525"
      y="169.696"
      width="51.62"
      height="10.6459"
      fill="#F5AD00"
    />
    <rect
      x="103.525"
      y="116.467"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="92.845"
      y="116.467"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="82.1648"
      y="127.113"
      width="10.68"
      height="42.5837"
      fill="#FCCC00"
    />
    <rect
      x="92.845"
      y="127.113"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="92.845"
      y="137.759"
      width="10.68"
      height="31.9377"
      fill="#F5AD00"
    />
    <rect
      x="92.845"
      y="169.696"
      width="10.68"
      height="10.6459"
      fill="#F5AD00"
    />
    <rect
      x="103.525"
      y="127.113"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="103.525"
      y="137.759"
      width="10.68"
      height="21.2918"
      fill="#E89600"
    />
    <rect
      x="144.465"
      y="137.759"
      width="10.68"
      height="21.2918"
      fill="#E89600"
    />
    <rect
      x="103.525"
      y="159.051"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="144.465"
      y="159.051"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="114.205"
      y="127.113"
      width="30.26"
      height="10.6459"
      fill="#E89600"
    />
    <rect
      x="114.205"
      y="159.051"
      width="30.26"
      height="10.6459"
      fill="#E89600"
    />
    <rect
      x="114.205"
      y="137.759"
      width="30.26"
      height="21.2918"
      fill="#FCD303"
    />
    <rect
      x="114.205"
      y="116.467"
      width="40.94"
      height="10.6459"
      fill="#F5AD00"
    />
    <rect
      x="144.465"
      y="127.113"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="155.145"
      y="116.467"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="155.145"
      y="127.113"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="155.145"
      y="137.759"
      width="10.68"
      height="31.9377"
      fill="#F5AD00"
    />
    <rect
      x="155.145"
      y="169.696"
      width="10.68"
      height="10.6459"
      fill="#E89600"
    />
    <rect
      x="165.825"
      y="127.113"
      width="10.68"
      height="42.5837"
      fill="#FCCC00"
    />
    <rect
      x="103.525"
      y="95.6187"
      width="51.62"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="103.525"
      y="169.697"
      width="51.62"
      height="10.6459"
      fill="#E89600"
    />
    <rect
      x="103.525"
      y="159.494"
      width="51.62"
      height="10.6459"
      fill="#F5AD00"
    />
    <rect
      x="103.525"
      y="106.264"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="92.845"
      y="106.264"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="82.1648"
      y="116.911"
      width="10.68"
      height="42.5837"
      fill="#FCCC00"
    />
    <rect
      x="92.845"
      y="116.911"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="92.845"
      y="127.556"
      width="10.68"
      height="31.9377"
      fill="#F5AD00"
    />
    <rect
      x="92.845"
      y="159.494"
      width="10.68"
      height="10.6459"
      fill="#F5AD00"
    />
    <rect
      x="103.525"
      y="116.911"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="103.525"
      y="127.556"
      width="10.68"
      height="21.2918"
      fill="#E89600"
    />
    <rect
      x="144.465"
      y="127.556"
      width="10.68"
      height="21.2918"
      fill="#E89600"
    />
    <rect
      x="103.525"
      y="148.848"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="144.465"
      y="148.848"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="114.205"
      y="116.911"
      width="30.26"
      height="10.6459"
      fill="#E89600"
    />
    <rect
      x="114.205"
      y="148.848"
      width="30.26"
      height="10.6459"
      fill="#E89600"
    />
    <rect
      x="114.205"
      y="127.556"
      width="30.26"
      height="21.2918"
      fill="#FCD303"
    />
    <rect
      x="114.205"
      y="106.264"
      width="40.94"
      height="10.6459"
      fill="#F5AD00"
    />
    <rect
      x="144.465"
      y="116.911"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="155.145"
      y="106.264"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="155.145"
      y="116.911"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="155.145"
      y="127.556"
      width="10.68"
      height="31.9377"
      fill="#F5AD00"
    />
    <rect
      x="155.145"
      y="159.494"
      width="10.68"
      height="10.6459"
      fill="#E89600"
    />
    <rect
      x="165.825"
      y="116.911"
      width="10.68"
      height="42.5837"
      fill="#FCCC00"
    />
    <rect
      x="103.525"
      y="85.4164"
      width="51.62"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="103.525"
      y="159.494"
      width="51.62"
      height="10.6459"
      fill="#E89600"
    />
    <rect
      x="103.525"
      y="149.292"
      width="51.62"
      height="10.6459"
      fill="#F5AD00"
    />
    <rect
      x="103.525"
      y="96.0624"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="92.845"
      y="96.0624"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="82.1648"
      y="106.708"
      width="10.68"
      height="42.5837"
      fill="#FCCC00"
    />
    <rect
      x="92.845"
      y="106.708"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="92.845"
      y="117.354"
      width="10.68"
      height="31.9377"
      fill="#F5AD00"
    />
    <rect
      x="92.845"
      y="149.292"
      width="10.68"
      height="10.6459"
      fill="#F5AD00"
    />
    <rect
      x="103.525"
      y="106.708"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="103.525"
      y="117.354"
      width="10.68"
      height="21.2918"
      fill="#E89600"
    />
    <rect
      x="144.465"
      y="117.354"
      width="10.68"
      height="21.2918"
      fill="#E89600"
    />
    <rect
      x="103.525"
      y="138.646"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="144.465"
      y="138.646"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="114.205"
      y="106.708"
      width="30.26"
      height="10.6459"
      fill="#E89600"
    />
    <rect
      x="114.205"
      y="138.646"
      width="30.26"
      height="10.6459"
      fill="#E89600"
    />
    <rect
      x="114.205"
      y="117.354"
      width="30.26"
      height="21.2918"
      fill="#FCD303"
    />
    <rect
      x="114.205"
      y="96.0624"
      width="40.94"
      height="10.6459"
      fill="#F5AD00"
    />
    <rect
      x="144.465"
      y="106.708"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="155.145"
      y="96.0624"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="155.145"
      y="106.708"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="155.145"
      y="117.354"
      width="10.68"
      height="31.9377"
      fill="#F5AD00"
    />
    <rect
      x="155.145"
      y="149.292"
      width="10.68"
      height="10.6459"
      fill="#E89600"
    />
    <rect
      x="165.825"
      y="106.708"
      width="10.68"
      height="42.5837"
      fill="#FCCC00"
    />
    <rect
      x="103.525"
      y="75.214"
      width="51.62"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="103.525"
      y="149.292"
      width="51.62"
      height="10.6459"
      fill="#E89600"
    />
    <rect
      x="103.525"
      y="139.089"
      width="51.62"
      height="10.6459"
      fill="#F5AD00"
    />
    <rect
      x="103.525"
      y="85.8597"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="92.845"
      y="85.8597"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="82.1648"
      y="96.5056"
      width="10.68"
      height="42.5837"
      fill="#FCCC00"
    />
    <rect
      x="92.845"
      y="96.5057"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="92.845"
      y="107.152"
      width="10.68"
      height="31.9377"
      fill="#F5AD00"
    />
    <rect
      x="92.845"
      y="139.089"
      width="10.68"
      height="10.6459"
      fill="#F5AD00"
    />
    <rect
      x="103.525"
      y="96.5057"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="103.525"
      y="107.152"
      width="10.68"
      height="21.2918"
      fill="#E89600"
    />
    <rect
      x="144.465"
      y="107.152"
      width="10.68"
      height="21.2918"
      fill="#E89600"
    />
    <rect
      x="103.525"
      y="128.443"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="144.465"
      y="128.443"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="114.205"
      y="96.5057"
      width="30.26"
      height="10.6459"
      fill="#E89600"
    />
    <rect
      x="114.205"
      y="128.443"
      width="30.26"
      height="10.6459"
      fill="#E89600"
    />
    <rect
      x="114.205"
      y="107.152"
      width="30.26"
      height="21.2918"
      fill="#FCD303"
    />
    <rect
      x="114.205"
      y="85.8597"
      width="40.94"
      height="10.6459"
      fill="#F5AD00"
    />
    <rect
      x="144.465"
      y="96.5057"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="155.145"
      y="85.8597"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="155.145"
      y="96.5057"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="155.145"
      y="107.152"
      width="10.68"
      height="31.9377"
      fill="#F5AD00"
    />
    <rect
      x="155.145"
      y="139.089"
      width="10.68"
      height="10.6459"
      fill="#E89600"
    />
    <rect
      x="165.825"
      y="96.5057"
      width="10.68"
      height="42.5837"
      fill="#FCCC00"
    />
    <rect
      x="103.525"
      y="65.0117"
      width="51.62"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="103.525"
      y="139.089"
      width="51.62"
      height="10.6459"
      fill="#E89600"
    />
    <rect
      x="103.525"
      y="128.887"
      width="51.62"
      height="10.6459"
      fill="#F5AD00"
    />
    <rect
      x="103.525"
      y="75.6575"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="92.845"
      y="75.6575"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="82.1648"
      y="86.3035"
      width="10.68"
      height="42.5837"
      fill="#FCCC00"
    />
    <rect
      x="92.845"
      y="86.3035"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="92.845"
      y="96.9492"
      width="10.68"
      height="31.9377"
      fill="#F5AD00"
    />
    <rect
      x="92.845"
      y="128.887"
      width="10.68"
      height="10.6459"
      fill="#F5AD00"
    />
    <rect
      x="103.525"
      y="86.3035"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="103.525"
      y="96.9492"
      width="10.68"
      height="21.2918"
      fill="#E89600"
    />
    <rect
      x="144.465"
      y="96.9492"
      width="10.68"
      height="21.2918"
      fill="#E89600"
    />
    <rect
      x="103.525"
      y="118.241"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="144.465"
      y="118.241"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="114.205"
      y="86.3035"
      width="30.26"
      height="10.6459"
      fill="#E89600"
    />
    <rect
      x="114.205"
      y="118.241"
      width="30.26"
      height="10.6459"
      fill="#E89600"
    />
    <rect
      x="114.205"
      y="96.9492"
      width="30.26"
      height="21.2918"
      fill="#FCD303"
    />
    <rect
      x="114.205"
      y="75.6575"
      width="40.94"
      height="10.6459"
      fill="#F5AD00"
    />
    <rect
      x="144.465"
      y="86.3035"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="155.145"
      y="75.6575"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="155.145"
      y="86.3035"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="155.145"
      y="96.9492"
      width="10.68"
      height="31.9377"
      fill="#F5AD00"
    />
    <rect
      x="155.145"
      y="128.887"
      width="10.68"
      height="10.6459"
      fill="#E89600"
    />
    <rect
      x="165.825"
      y="86.3035"
      width="10.68"
      height="42.5837"
      fill="#FCCC00"
    />
    <rect
      x="103.525"
      y="54.8096"
      width="51.62"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="103.525"
      y="128.887"
      width="51.62"
      height="10.6459"
      fill="#E89600"
    />
    <rect
      x="103.525"
      y="118.685"
      width="51.62"
      height="10.6459"
      fill="#F5AD00"
    />
    <rect
      x="103.525"
      y="65.4553"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="92.8447"
      y="65.4553"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="82.1648"
      y="76.1013"
      width="10.68"
      height="42.5837"
      fill="#FCCC00"
    />
    <rect
      x="92.845"
      y="76.1013"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="92.845"
      y="86.7471"
      width="10.68"
      height="31.9377"
      fill="#F5AD00"
    />
    <rect
      x="92.845"
      y="118.685"
      width="10.68"
      height="10.6459"
      fill="#F5AD00"
    />
    <rect
      x="103.525"
      y="76.1013"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="103.525"
      y="86.7471"
      width="10.68"
      height="21.2918"
      fill="#E89600"
    />
    <rect
      x="144.465"
      y="86.7471"
      width="10.68"
      height="21.2918"
      fill="#E89600"
    />
    <rect
      x="103.525"
      y="108.039"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="144.465"
      y="108.039"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="114.205"
      y="76.1013"
      width="30.26"
      height="10.6459"
      fill="#E89600"
    />
    <rect
      x="114.205"
      y="108.039"
      width="30.26"
      height="10.6459"
      fill="#E89600"
    />
    <rect
      x="114.205"
      y="86.7471"
      width="30.26"
      height="21.2918"
      fill="#FCD303"
    />
    <rect
      x="114.205"
      y="65.4553"
      width="40.94"
      height="10.6459"
      fill="#F5AD00"
    />
    <rect
      x="144.465"
      y="76.1013"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="155.145"
      y="65.4553"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="155.145"
      y="76.1013"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="155.145"
      y="86.7471"
      width="10.68"
      height="31.9377"
      fill="#F5AD00"
    />
    <rect
      x="155.145"
      y="118.685"
      width="10.68"
      height="10.6459"
      fill="#E89600"
    />
    <rect
      x="165.825"
      y="76.1013"
      width="10.68"
      height="42.5837"
      fill="#FCCC00"
    />
    <rect
      x="103.525"
      y="44.6071"
      width="51.62"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="103.525"
      y="118.685"
      width="51.62"
      height="10.6459"
      fill="#E89600"
    />
    <rect
      x="103.525"
      y="108.483"
      width="51.62"
      height="10.6459"
      fill="#F5AD00"
    />
    <rect
      x="103.525"
      y="55.2528"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="92.845"
      y="55.2528"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="82.165"
      y="65.8988"
      width="10.68"
      height="42.5837"
      fill="#FCCC00"
    />
    <rect
      x="92.845"
      y="65.8988"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="92.845"
      y="76.5448"
      width="10.68"
      height="31.9377"
      fill="#F5AD00"
    />
    <rect
      x="92.845"
      y="108.483"
      width="10.68"
      height="10.6459"
      fill="#F5AD00"
    />
    <rect
      x="103.525"
      y="65.8988"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="103.525"
      y="76.5448"
      width="10.68"
      height="21.2918"
      fill="#E89600"
    />
    <rect
      x="144.465"
      y="76.5448"
      width="10.68"
      height="21.2918"
      fill="#E89600"
    />
    <rect
      x="103.525"
      y="97.8365"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="144.465"
      y="97.8365"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="114.205"
      y="65.8988"
      width="30.26"
      height="10.6459"
      fill="#E89600"
    />
    <rect
      x="114.205"
      y="97.8365"
      width="30.26"
      height="10.6459"
      fill="#E89600"
    />
    <rect
      x="114.205"
      y="76.5448"
      width="30.26"
      height="21.2918"
      fill="#FCD303"
    />
    <rect
      x="114.205"
      y="55.2528"
      width="40.94"
      height="10.6459"
      fill="#F5AD00"
    />
    <rect
      x="144.465"
      y="65.8988"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="155.145"
      y="55.2528"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="155.145"
      y="65.8988"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="155.145"
      y="76.5448"
      width="10.68"
      height="31.9377"
      fill="#F5AD00"
    />
    <rect
      x="155.145"
      y="108.483"
      width="10.68"
      height="10.6459"
      fill="#E89600"
    />
    <rect
      x="165.825"
      y="65.8988"
      width="10.68"
      height="42.5837"
      fill="#FCCC00"
    />
    <rect
      x="103.525"
      y="34.4047"
      width="51.62"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="103.525"
      y="108.483"
      width="51.62"
      height="10.6459"
      fill="#E89600"
    />
    <rect
      x="103.525"
      y="98.2804"
      width="51.62"
      height="10.6459"
      fill="#F5AD00"
    />
    <rect
      x="103.525"
      y="45.0509"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="92.845"
      y="45.0509"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="82.1648"
      y="55.6967"
      width="10.68"
      height="42.5837"
      fill="#FCCC00"
    />
    <rect
      x="92.845"
      y="55.6967"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="92.845"
      y="66.3427"
      width="10.68"
      height="31.9377"
      fill="#F5AD00"
    />
    <rect
      x="92.845"
      y="98.2804"
      width="10.68"
      height="10.6459"
      fill="#F5AD00"
    />
    <rect
      x="103.525"
      y="55.6967"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="103.525"
      y="66.3427"
      width="10.68"
      height="21.2918"
      fill="#E89600"
    />
    <rect
      x="144.465"
      y="66.3427"
      width="10.68"
      height="21.2918"
      fill="#E89600"
    />
    <rect
      x="103.525"
      y="87.6344"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="144.465"
      y="87.6344"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="114.205"
      y="55.6967"
      width="30.26"
      height="10.6459"
      fill="#E89600"
    />
    <rect
      x="114.205"
      y="87.6344"
      width="30.26"
      height="10.6459"
      fill="#E89600"
    />
    <rect
      x="114.205"
      y="66.3427"
      width="30.26"
      height="21.2918"
      fill="#FCD303"
    />
    <rect
      x="114.205"
      y="45.0509"
      width="40.94"
      height="10.6459"
      fill="#F5AD00"
    />
    <rect
      x="144.465"
      y="55.6967"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="155.145"
      y="45.0509"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="155.145"
      y="55.6967"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="155.145"
      y="66.3427"
      width="10.68"
      height="31.9377"
      fill="#F5AD00"
    />
    <rect
      x="155.145"
      y="98.2804"
      width="10.68"
      height="10.6459"
      fill="#E89600"
    />
    <rect
      x="165.825"
      y="55.6967"
      width="10.68"
      height="42.5837"
      fill="#FCCC00"
    />
    <rect
      x="103.525"
      y="24.2023"
      width="51.62"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="103.525"
      y="98.2802"
      width="51.62"
      height="10.6459"
      fill="#E89600"
    />
    <rect
      x="103.525"
      y="88.0778"
      width="51.62"
      height="10.6459"
      fill="#F5AD00"
    />
    <rect
      x="103.525"
      y="34.848"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect x="92.845" y="34.848" width="10.68" height="10.6459" fill="#FCCC00" />
    <rect
      x="82.1648"
      y="45.4941"
      width="10.68"
      height="42.5837"
      fill="#FCCC00"
    />
    <rect x="92.845" y="45.494" width="10.68" height="10.6459" fill="#FFF267" />
    <rect x="92.845" y="56.14" width="10.68" height="31.9377" fill="#F5AD00" />
    <rect
      x="92.845"
      y="88.0778"
      width="10.68"
      height="10.6459"
      fill="#F5AD00"
    />
    <rect
      x="103.525"
      y="45.494"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect x="103.525" y="56.14" width="10.68" height="21.2918" fill="#E89600" />
    <rect x="144.465" y="56.14" width="10.68" height="21.2918" fill="#E89600" />
    <rect
      x="103.525"
      y="77.4318"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="144.465"
      y="77.4318"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="114.205"
      y="45.494"
      width="30.26"
      height="10.6459"
      fill="#E89600"
    />
    <rect
      x="114.205"
      y="77.4318"
      width="30.26"
      height="10.6459"
      fill="#E89600"
    />
    <rect x="114.205" y="56.14" width="30.26" height="21.2918" fill="#FCD303" />
    <rect
      x="114.205"
      y="34.848"
      width="40.94"
      height="10.6459"
      fill="#F5AD00"
    />
    <rect
      x="144.465"
      y="45.494"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="155.145"
      y="34.848"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="155.145"
      y="45.494"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect x="155.145" y="56.14" width="10.68" height="31.9377" fill="#F5AD00" />
    <rect
      x="155.145"
      y="88.0778"
      width="10.68"
      height="10.6459"
      fill="#E89600"
    />
    <rect
      x="165.825"
      y="45.494"
      width="10.68"
      height="42.5837"
      fill="#FCCC00"
    />
    <rect x="103.525" y="14" width="51.62" height="10.6459" fill="#FCCC00" />
    <rect
      x="103.525"
      y="88.0779"
      width="51.62"
      height="10.6459"
      fill="#E89600"
    />
    <rect
      x="103.525"
      y="77.8755"
      width="51.62"
      height="10.6459"
      fill="#F5AD00"
    />
    <rect
      x="103.525"
      y="24.6458"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="92.8452"
      y="24.6458"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="82.165"
      y="35.2917"
      width="10.68"
      height="42.5837"
      fill="#FCCC00"
    />
    <rect
      x="92.8452"
      y="35.2917"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="92.8452"
      y="45.9377"
      width="10.68"
      height="31.9377"
      fill="#F5AD00"
    />
    <rect
      x="92.8452"
      y="77.8755"
      width="10.68"
      height="10.6459"
      fill="#F5AD00"
    />
    <rect
      x="103.525"
      y="35.2917"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="103.525"
      y="45.9377"
      width="10.68"
      height="21.2918"
      fill="#E89600"
    />
    <rect
      x="144.465"
      y="45.9377"
      width="10.68"
      height="21.2918"
      fill="#E89600"
    />
    <rect
      x="103.525"
      y="67.2295"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="144.465"
      y="67.2295"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="114.205"
      y="35.2917"
      width="30.26"
      height="10.6459"
      fill="#E89600"
    />
    <rect
      x="114.205"
      y="67.2295"
      width="30.26"
      height="10.6459"
      fill="#E89600"
    />
    <rect
      x="114.205"
      y="45.9377"
      width="30.26"
      height="21.2918"
      fill="#FCD303"
    />
    <rect
      x="114.205"
      y="24.6458"
      width="40.94"
      height="10.6459"
      fill="#F5AD00"
    />
    <rect
      x="144.465"
      y="35.2917"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="155.145"
      y="24.6458"
      width="10.68"
      height="10.6459"
      fill="#FCCC00"
    />
    <rect
      x="155.145"
      y="35.2917"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="155.145"
      y="45.9377"
      width="10.68"
      height="31.9377"
      fill="#F5AD00"
    />
    <rect
      x="155.145"
      y="77.8755"
      width="10.68"
      height="10.6459"
      fill="#E89600"
    />
    <rect
      x="165.825"
      y="35.2917"
      width="10.68"
      height="42.5837"
      fill="#FCCC00"
    />
    <rect
      x="60.3601"
      y="146.63"
      width="51.62"
      height="10.6459"
      fill="#FDD200"
    />
    <rect
      x="60.3601"
      y="220.708"
      width="51.62"
      height="10.6459"
      fill="#F5A004"
    />
    <rect
      x="60.3601"
      y="210.506"
      width="51.62"
      height="10.6459"
      fill="#F5AD00"
    />
    <rect
      x="60.3601"
      y="157.276"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="49.6802"
      y="157.276"
      width="10.68"
      height="10.6459"
      fill="#FDD200"
    />
    <rect x="39" y="167.922" width="10.68" height="42.5837" fill="#FDD200" />
    <rect
      x="49.6802"
      y="167.922"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="49.6802"
      y="178.568"
      width="10.68"
      height="31.9377"
      fill="#F5AD00"
    />
    <rect
      x="49.6802"
      y="210.506"
      width="10.68"
      height="10.6459"
      fill="#F5AD00"
    />
    <rect
      x="60.3601"
      y="167.922"
      width="10.68"
      height="10.6459"
      fill="#FDD200"
    />
    <rect
      x="60.3601"
      y="178.568"
      width="10.68"
      height="21.2918"
      fill="#F5A004"
    />
    <rect x="101.3" y="178.568" width="10.68" height="21.2918" fill="#F5A004" />
    <rect
      x="60.3601"
      y="199.86"
      width="10.68"
      height="10.6459"
      fill="#FDD200"
    />
    <rect x="101.3" y="199.86" width="10.68" height="10.6459" fill="#FDD200" />
    <rect x="71.04" y="167.922" width="30.26" height="10.6459" fill="#F5A004" />
    <rect x="71.04" y="199.86" width="30.26" height="10.6459" fill="#F5A004" />
    <rect x="71.04" y="178.568" width="30.26" height="21.2918" fill="#FCD303" />
    <rect x="71.04" y="157.276" width="40.94" height="10.6459" fill="#F5AD00" />
    <rect x="101.3" y="167.922" width="10.68" height="10.6459" fill="#FDD200" />
    <rect
      x="111.98"
      y="157.276"
      width="10.68"
      height="10.6459"
      fill="#FDD200"
    />
    <rect
      x="111.98"
      y="167.922"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="111.98"
      y="178.568"
      width="10.68"
      height="31.9377"
      fill="#F5AD00"
    />
    <rect
      x="111.98"
      y="210.506"
      width="10.68"
      height="10.6459"
      fill="#F5A004"
    />
    <rect
      x="122.66"
      y="167.922"
      width="10.68"
      height="42.5837"
      fill="#FDD200"
    />
    <rect
      x="60.3601"
      y="136.428"
      width="51.62"
      height="10.6459"
      fill="#FDD200"
    />
    <rect
      x="60.3601"
      y="210.506"
      width="51.62"
      height="10.6459"
      fill="#F5A004"
    />
    <rect
      x="60.3601"
      y="200.303"
      width="51.62"
      height="10.6459"
      fill="#F5AD00"
    />
    <rect
      x="60.3601"
      y="147.074"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="49.6802"
      y="147.074"
      width="10.68"
      height="10.6459"
      fill="#FDD200"
    />
    <rect x="39" y="157.72" width="10.68" height="42.5837" fill="#FDD200" />
    <rect
      x="49.6802"
      y="157.72"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="49.6802"
      y="168.366"
      width="10.68"
      height="31.9377"
      fill="#F5AD00"
    />
    <rect
      x="49.6802"
      y="200.303"
      width="10.68"
      height="10.6459"
      fill="#F5AD00"
    />
    <rect
      x="60.3601"
      y="157.72"
      width="10.68"
      height="10.6459"
      fill="#FDD200"
    />
    <rect
      x="60.3601"
      y="168.366"
      width="10.68"
      height="21.2918"
      fill="#F5A004"
    />
    <rect x="101.3" y="168.366" width="10.68" height="21.2918" fill="#F5A004" />
    <rect
      x="60.3601"
      y="189.658"
      width="10.68"
      height="10.6459"
      fill="#FDD200"
    />
    <rect x="101.3" y="189.658" width="10.68" height="10.6459" fill="#FDD200" />
    <rect x="71.04" y="157.72" width="30.26" height="10.6459" fill="#F5A004" />
    <rect x="71.04" y="189.658" width="30.26" height="10.6459" fill="#F5A004" />
    <rect x="71.04" y="168.366" width="30.26" height="21.2918" fill="#FCD303" />
    <rect x="71.04" y="147.074" width="40.94" height="10.6459" fill="#F5AD00" />
    <rect x="101.3" y="157.72" width="10.68" height="10.6459" fill="#FDD200" />
    <rect
      x="111.98"
      y="147.074"
      width="10.68"
      height="10.6459"
      fill="#FDD200"
    />
    <rect x="111.98" y="157.72" width="10.68" height="10.6459" fill="#FFF267" />
    <rect
      x="111.98"
      y="168.366"
      width="10.68"
      height="31.9377"
      fill="#F5AD00"
    />
    <rect
      x="111.98"
      y="200.303"
      width="10.68"
      height="10.6459"
      fill="#F5A004"
    />
    <rect x="122.66" y="157.72" width="10.68" height="42.5837" fill="#FDD200" />
    <rect
      x="60.3601"
      y="126.226"
      width="51.62"
      height="10.6459"
      fill="#FDD200"
    />
    <rect
      x="60.3601"
      y="200.303"
      width="51.62"
      height="10.6459"
      fill="#F5A004"
    />
    <rect
      x="60.3601"
      y="190.101"
      width="51.62"
      height="10.6459"
      fill="#F5AD00"
    />
    <rect
      x="60.3601"
      y="136.871"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="49.6802"
      y="136.871"
      width="10.68"
      height="10.6459"
      fill="#FDD200"
    />
    <rect x="39" y="147.517" width="10.68" height="42.5837" fill="#FDD200" />
    <rect
      x="49.6802"
      y="147.517"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="49.6802"
      y="158.163"
      width="10.68"
      height="31.9377"
      fill="#F5AD00"
    />
    <rect
      x="49.6802"
      y="190.101"
      width="10.68"
      height="10.6459"
      fill="#F5AD00"
    />
    <rect
      x="60.3601"
      y="147.517"
      width="10.68"
      height="10.6459"
      fill="#FDD200"
    />
    <rect
      x="60.3601"
      y="158.163"
      width="10.68"
      height="21.2918"
      fill="#F5A004"
    />
    <rect x="101.3" y="158.163" width="10.68" height="21.2918" fill="#F5A004" />
    <rect
      x="60.3601"
      y="179.455"
      width="10.68"
      height="10.6459"
      fill="#FDD200"
    />
    <rect x="101.3" y="179.455" width="10.68" height="10.6459" fill="#FDD200" />
    <rect x="71.04" y="147.517" width="30.26" height="10.6459" fill="#F5A004" />
    <rect x="71.04" y="179.455" width="30.26" height="10.6459" fill="#F5A004" />
    <rect x="71.04" y="158.163" width="30.26" height="21.2918" fill="#FCD303" />
    <rect x="71.04" y="136.871" width="40.94" height="10.6459" fill="#F5AD00" />
    <rect x="101.3" y="147.517" width="10.68" height="10.6459" fill="#FDD200" />
    <rect
      x="111.98"
      y="136.871"
      width="10.68"
      height="10.6459"
      fill="#FDD200"
    />
    <rect
      x="111.98"
      y="147.517"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="111.98"
      y="158.163"
      width="10.68"
      height="31.9377"
      fill="#F5AD00"
    />
    <rect
      x="111.98"
      y="190.101"
      width="10.68"
      height="10.6459"
      fill="#F5A004"
    />
    <rect
      x="122.66"
      y="147.517"
      width="10.68"
      height="42.5837"
      fill="#FDD200"
    />
    <rect
      x="60.3601"
      y="116.023"
      width="51.62"
      height="10.6459"
      fill="#FDD200"
    />
    <rect
      x="60.3601"
      y="190.101"
      width="51.62"
      height="10.6459"
      fill="#F5A004"
    />
    <rect
      x="60.3601"
      y="179.899"
      width="51.62"
      height="10.6459"
      fill="#F5AD00"
    />
    <rect
      x="60.3601"
      y="126.669"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="49.6802"
      y="126.669"
      width="10.68"
      height="10.6459"
      fill="#FDD200"
    />
    <rect
      x="39.0002"
      y="137.315"
      width="10.68"
      height="42.5837"
      fill="#FDD200"
    />
    <rect
      x="49.6802"
      y="137.315"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="49.6802"
      y="147.961"
      width="10.68"
      height="31.9377"
      fill="#F5AD00"
    />
    <rect
      x="49.6802"
      y="179.899"
      width="10.68"
      height="10.6459"
      fill="#F5AD00"
    />
    <rect
      x="60.3601"
      y="137.315"
      width="10.68"
      height="10.6459"
      fill="#FDD200"
    />
    <rect
      x="60.3601"
      y="147.961"
      width="10.68"
      height="21.2918"
      fill="#F5A004"
    />
    <rect x="101.3" y="147.961" width="10.68" height="21.2918" fill="#F5A004" />
    <rect
      x="60.3601"
      y="169.253"
      width="10.68"
      height="10.6459"
      fill="#FDD200"
    />
    <rect x="101.3" y="169.253" width="10.68" height="10.6459" fill="#FDD200" />
    <rect
      x="71.0403"
      y="137.315"
      width="30.26"
      height="10.6459"
      fill="#F5A004"
    />
    <rect
      x="71.0403"
      y="169.253"
      width="30.26"
      height="10.6459"
      fill="#F5A004"
    />
    <rect
      x="71.0403"
      y="147.961"
      width="30.26"
      height="21.2918"
      fill="#FCD303"
    />
    <rect
      x="71.0403"
      y="126.669"
      width="40.94"
      height="10.6459"
      fill="#F5AD00"
    />
    <rect x="101.3" y="137.315" width="10.68" height="10.6459" fill="#FDD200" />
    <rect
      x="111.98"
      y="126.669"
      width="10.68"
      height="10.6459"
      fill="#FDD200"
    />
    <rect
      x="111.98"
      y="137.315"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="111.98"
      y="147.961"
      width="10.68"
      height="31.9377"
      fill="#F5AD00"
    />
    <rect
      x="111.98"
      y="179.899"
      width="10.68"
      height="10.6459"
      fill="#F5A004"
    />
    <rect
      x="122.66"
      y="137.315"
      width="10.68"
      height="42.5837"
      fill="#FDD200"
    />
    <rect
      x="60.3601"
      y="105.821"
      width="51.62"
      height="10.6459"
      fill="#FDD200"
    />
    <rect
      x="60.3601"
      y="179.899"
      width="51.62"
      height="10.6459"
      fill="#F5A004"
    />
    <rect
      x="60.3601"
      y="169.696"
      width="51.62"
      height="10.6459"
      fill="#F5AD00"
    />
    <rect
      x="60.3601"
      y="116.467"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="49.6802"
      y="116.467"
      width="10.68"
      height="10.6459"
      fill="#FDD200"
    />
    <rect x="39" y="127.113" width="10.68" height="42.5837" fill="#FDD200" />
    <rect
      x="49.6802"
      y="127.113"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="49.6802"
      y="137.758"
      width="10.68"
      height="31.9377"
      fill="#F5AD00"
    />
    <rect
      x="49.6802"
      y="169.696"
      width="10.68"
      height="10.6459"
      fill="#F5AD00"
    />
    <rect
      x="60.3601"
      y="127.113"
      width="10.68"
      height="10.6459"
      fill="#FDD200"
    />
    <rect
      x="60.3601"
      y="137.758"
      width="10.68"
      height="21.2918"
      fill="#F5A004"
    />
    <rect x="101.3" y="137.758" width="10.68" height="21.2918" fill="#F5A004" />
    <rect
      x="60.3601"
      y="159.05"
      width="10.68"
      height="10.6459"
      fill="#FDD200"
    />
    <rect x="101.3" y="159.05" width="10.68" height="10.6459" fill="#FDD200" />
    <rect x="71.04" y="127.113" width="30.26" height="10.6459" fill="#F5A004" />
    <rect x="71.04" y="159.05" width="30.26" height="10.6459" fill="#F5A004" />
    <rect x="71.04" y="137.758" width="30.26" height="21.2918" fill="#FCD303" />
    <rect x="71.04" y="116.467" width="40.94" height="10.6459" fill="#F5AD00" />
    <rect x="101.3" y="127.113" width="10.68" height="10.6459" fill="#FDD200" />
    <rect
      x="111.98"
      y="116.467"
      width="10.68"
      height="10.6459"
      fill="#FDD200"
    />
    <rect
      x="111.98"
      y="127.113"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="111.98"
      y="137.758"
      width="10.68"
      height="31.9377"
      fill="#F5AD00"
    />
    <rect
      x="111.98"
      y="169.696"
      width="10.68"
      height="10.6459"
      fill="#F5A004"
    />
    <rect
      x="122.66"
      y="127.113"
      width="10.68"
      height="42.5837"
      fill="#FDD200"
    />
    <rect
      x="60.3601"
      y="95.6185"
      width="51.62"
      height="10.6459"
      fill="#FDD200"
    />
    <rect
      x="60.3601"
      y="169.696"
      width="51.62"
      height="10.6459"
      fill="#F5A004"
    />
    <rect
      x="60.3601"
      y="159.494"
      width="51.62"
      height="10.6459"
      fill="#F5AD00"
    />
    <rect
      x="60.3601"
      y="106.264"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="49.6802"
      y="106.264"
      width="10.68"
      height="10.6459"
      fill="#FDD200"
    />
    <rect x="39" y="116.91" width="10.68" height="42.5837" fill="#FDD200" />
    <rect
      x="49.6802"
      y="116.911"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="49.6802"
      y="127.556"
      width="10.68"
      height="31.9377"
      fill="#F5AD00"
    />
    <rect
      x="49.6802"
      y="159.494"
      width="10.68"
      height="10.6459"
      fill="#F5AD00"
    />
    <rect
      x="60.3601"
      y="116.911"
      width="10.68"
      height="10.6459"
      fill="#FDD200"
    />
    <rect
      x="60.3601"
      y="127.556"
      width="10.68"
      height="21.2918"
      fill="#F5A004"
    />
    <rect x="101.3" y="127.556" width="10.68" height="21.2918" fill="#F5A004" />
    <rect
      x="60.3601"
      y="148.848"
      width="10.68"
      height="10.6459"
      fill="#FDD200"
    />
    <rect x="101.3" y="148.848" width="10.68" height="10.6459" fill="#FDD200" />
    <rect x="71.04" y="116.911" width="30.26" height="10.6459" fill="#F5A004" />
    <rect x="71.04" y="148.848" width="30.26" height="10.6459" fill="#F5A004" />
    <rect x="71.04" y="127.556" width="30.26" height="21.2918" fill="#FCD303" />
    <rect x="71.04" y="106.264" width="40.94" height="10.6459" fill="#F5AD00" />
    <rect x="101.3" y="116.911" width="10.68" height="10.6459" fill="#FDD200" />
    <rect
      x="111.98"
      y="106.264"
      width="10.68"
      height="10.6459"
      fill="#FDD200"
    />
    <rect
      x="111.98"
      y="116.911"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="111.98"
      y="127.556"
      width="10.68"
      height="31.9377"
      fill="#F5AD00"
    />
    <rect
      x="111.98"
      y="159.494"
      width="10.68"
      height="10.6459"
      fill="#F5A004"
    />
    <rect
      x="122.66"
      y="116.911"
      width="10.68"
      height="42.5837"
      fill="#FDD200"
    />
    <rect
      x="60.3601"
      y="85.4163"
      width="51.62"
      height="10.6459"
      fill="#FDD200"
    />
    <rect
      x="60.3601"
      y="159.494"
      width="51.62"
      height="10.6459"
      fill="#F5A004"
    />
    <rect
      x="60.3601"
      y="149.292"
      width="51.62"
      height="10.6459"
      fill="#F5AD00"
    />
    <rect
      x="60.3601"
      y="96.0623"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="49.6802"
      y="96.0623"
      width="10.68"
      height="10.6459"
      fill="#FDD200"
    />
    <rect x="39" y="106.708" width="10.68" height="42.5837" fill="#FDD200" />
    <rect
      x="49.6802"
      y="106.708"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="49.6802"
      y="117.354"
      width="10.68"
      height="31.9377"
      fill="#F5AD00"
    />
    <rect
      x="49.6802"
      y="149.292"
      width="10.68"
      height="10.6459"
      fill="#F5AD00"
    />
    <rect
      x="60.3601"
      y="106.708"
      width="10.68"
      height="10.6459"
      fill="#FDD200"
    />
    <rect
      x="60.3601"
      y="117.354"
      width="10.68"
      height="21.2918"
      fill="#F5A004"
    />
    <rect x="101.3" y="117.354" width="10.68" height="21.2918" fill="#F5A004" />
    <rect
      x="60.3601"
      y="138.646"
      width="10.68"
      height="10.6459"
      fill="#FDD200"
    />
    <rect x="101.3" y="138.646" width="10.68" height="10.6459" fill="#FDD200" />
    <rect x="71.04" y="106.708" width="30.26" height="10.6459" fill="#F5A004" />
    <rect x="71.04" y="138.646" width="30.26" height="10.6459" fill="#F5A004" />
    <rect x="71.04" y="117.354" width="30.26" height="21.2918" fill="#FCD303" />
    <rect x="71.04" y="96.0623" width="40.94" height="10.6459" fill="#F5AD00" />
    <rect x="101.3" y="106.708" width="10.68" height="10.6459" fill="#FDD200" />
    <rect
      x="111.98"
      y="96.0623"
      width="10.68"
      height="10.6459"
      fill="#FDD200"
    />
    <rect
      x="111.98"
      y="106.708"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="111.98"
      y="117.354"
      width="10.68"
      height="31.9377"
      fill="#F5AD00"
    />
    <rect
      x="111.98"
      y="149.292"
      width="10.68"
      height="10.6459"
      fill="#F5A004"
    />
    <rect
      x="122.66"
      y="106.708"
      width="10.68"
      height="42.5837"
      fill="#FDD200"
    />
    <rect
      x="60.3601"
      y="75.214"
      width="51.62"
      height="10.6459"
      fill="#FDD200"
    />
    <rect
      x="60.3601"
      y="149.292"
      width="51.62"
      height="10.6459"
      fill="#F5A004"
    />
    <rect
      x="60.3601"
      y="139.089"
      width="51.62"
      height="10.6459"
      fill="#F5AD00"
    />
    <rect
      x="60.3601"
      y="85.8597"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="49.6802"
      y="85.8597"
      width="10.68"
      height="10.6459"
      fill="#FDD200"
    />
    <rect x="39" y="96.5056" width="10.68" height="42.5837" fill="#FDD200" />
    <rect
      x="49.6802"
      y="96.5057"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="49.6802"
      y="107.152"
      width="10.68"
      height="31.9377"
      fill="#F5AD00"
    />
    <rect
      x="49.6802"
      y="139.089"
      width="10.68"
      height="10.6459"
      fill="#F5AD00"
    />
    <rect
      x="60.3601"
      y="96.5057"
      width="10.68"
      height="10.6459"
      fill="#FDD200"
    />
    <rect
      x="60.3601"
      y="107.152"
      width="10.68"
      height="21.2918"
      fill="#F5A004"
    />
    <rect x="101.3" y="107.152" width="10.68" height="21.2918" fill="#F5A004" />
    <rect
      x="60.3601"
      y="128.443"
      width="10.68"
      height="10.6459"
      fill="#FDD200"
    />
    <rect x="101.3" y="128.443" width="10.68" height="10.6459" fill="#FDD200" />
    <rect x="71.04" y="96.5057" width="30.26" height="10.6459" fill="#F5A004" />
    <rect x="71.04" y="128.443" width="30.26" height="10.6459" fill="#F5A004" />
    <rect x="71.04" y="107.152" width="30.26" height="21.2918" fill="#FCD303" />
    <rect x="71.04" y="85.8597" width="40.94" height="10.6459" fill="#F5AD00" />
    <rect x="101.3" y="96.5057" width="10.68" height="10.6459" fill="#FDD200" />
    <rect
      x="111.98"
      y="85.8597"
      width="10.68"
      height="10.6459"
      fill="#FDD200"
    />
    <rect
      x="111.98"
      y="96.5057"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="111.98"
      y="107.152"
      width="10.68"
      height="31.9377"
      fill="#F5AD00"
    />
    <rect
      x="111.98"
      y="139.089"
      width="10.68"
      height="10.6459"
      fill="#F5A004"
    />
    <rect
      x="122.66"
      y="96.5057"
      width="10.68"
      height="42.5837"
      fill="#FDD200"
    />
    <rect
      x="144.02"
      y="157.276"
      width="51.62"
      height="10.6459"
      fill="#FBE700"
    />
    <rect
      x="144.02"
      y="231.354"
      width="51.62"
      height="10.6459"
      fill="#F5CA05"
    />
    <rect
      x="144.02"
      y="221.152"
      width="51.62"
      height="10.6459"
      fill="#FAD703"
    />
    <rect
      x="144.02"
      y="167.922"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="133.34"
      y="167.922"
      width="10.68"
      height="10.6459"
      fill="#FBE700"
    />
    <rect
      x="122.66"
      y="178.568"
      width="10.68"
      height="42.5837"
      fill="#FBE700"
    />
    <rect
      x="133.34"
      y="178.568"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="133.34"
      y="189.214"
      width="10.68"
      height="31.9377"
      fill="#FAD703"
    />
    <rect
      x="133.34"
      y="221.152"
      width="10.68"
      height="10.6459"
      fill="#FAD703"
    />
    <rect
      x="144.02"
      y="178.568"
      width="10.68"
      height="10.6459"
      fill="#FBE700"
    />
    <rect
      x="144.02"
      y="189.214"
      width="10.68"
      height="21.2918"
      fill="#F5CA05"
    />
    <rect
      x="184.96"
      y="189.214"
      width="10.68"
      height="21.2918"
      fill="#F5CA05"
    />
    <rect
      x="144.02"
      y="210.506"
      width="10.68"
      height="10.6459"
      fill="#FBE700"
    />
    <rect
      x="184.96"
      y="210.506"
      width="10.68"
      height="10.6459"
      fill="#FBE700"
    />
    <rect x="154.7" y="178.568" width="30.26" height="10.6459" fill="#F5CA05" />
    <rect x="154.7" y="210.506" width="30.26" height="10.6459" fill="#F5CA05" />
    <rect x="154.7" y="189.214" width="30.26" height="21.2918" fill="#FCD303" />
    <rect x="154.7" y="167.922" width="40.94" height="10.6459" fill="#FAD703" />
    <rect
      x="184.96"
      y="178.568"
      width="10.68"
      height="10.6459"
      fill="#FBE700"
    />
    <rect
      x="195.64"
      y="167.922"
      width="10.68"
      height="10.6459"
      fill="#FBE700"
    />
    <rect
      x="195.64"
      y="178.568"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="195.64"
      y="189.214"
      width="10.68"
      height="31.9377"
      fill="#FAD703"
    />
    <rect
      x="195.64"
      y="221.152"
      width="10.68"
      height="10.6459"
      fill="#F5CA05"
    />
    <rect
      x="206.32"
      y="178.568"
      width="10.68"
      height="42.5837"
      fill="#FBE700"
    />
    <rect
      x="144.02"
      y="147.074"
      width="51.62"
      height="10.6459"
      fill="#FBE700"
    />
    <rect
      x="144.02"
      y="221.152"
      width="51.62"
      height="10.6459"
      fill="#F5CA05"
    />
    <rect
      x="144.02"
      y="210.949"
      width="51.62"
      height="10.6459"
      fill="#FAD703"
    />
    <rect x="144.02" y="157.72" width="10.68" height="10.6459" fill="#FFF267" />
    <rect x="133.34" y="157.72" width="10.68" height="10.6459" fill="#FBE700" />
    <rect
      x="122.66"
      y="168.366"
      width="10.68"
      height="42.5837"
      fill="#FBE700"
    />
    <rect
      x="133.34"
      y="168.366"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="133.34"
      y="179.012"
      width="10.68"
      height="31.9377"
      fill="#FAD703"
    />
    <rect
      x="133.34"
      y="210.949"
      width="10.68"
      height="10.6459"
      fill="#FAD703"
    />
    <rect
      x="144.02"
      y="168.366"
      width="10.68"
      height="10.6459"
      fill="#FBE700"
    />
    <rect
      x="144.02"
      y="179.012"
      width="10.68"
      height="21.2918"
      fill="#F5CA05"
    />
    <rect
      x="184.96"
      y="179.012"
      width="10.68"
      height="21.2918"
      fill="#F5CA05"
    />
    <rect
      x="144.02"
      y="200.303"
      width="10.68"
      height="10.6459"
      fill="#FBE700"
    />
    <rect
      x="184.96"
      y="200.303"
      width="10.68"
      height="10.6459"
      fill="#FBE700"
    />
    <rect x="154.7" y="168.366" width="30.26" height="10.6459" fill="#F5CA05" />
    <rect x="154.7" y="200.303" width="30.26" height="10.6459" fill="#F5CA05" />
    <rect x="154.7" y="179.012" width="30.26" height="21.2918" fill="#FCD303" />
    <rect x="154.7" y="157.72" width="40.94" height="10.6459" fill="#FAD703" />
    <rect
      x="184.96"
      y="168.366"
      width="10.68"
      height="10.6459"
      fill="#FBE700"
    />
    <rect x="195.64" y="157.72" width="10.68" height="10.6459" fill="#FBE700" />
    <rect
      x="195.64"
      y="168.366"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="195.64"
      y="179.012"
      width="10.68"
      height="31.9377"
      fill="#FAD703"
    />
    <rect
      x="195.64"
      y="210.949"
      width="10.68"
      height="10.6459"
      fill="#F5CA05"
    />
    <rect
      x="206.32"
      y="168.366"
      width="10.68"
      height="42.5837"
      fill="#FBE700"
    />
    <rect
      x="144.02"
      y="137.315"
      width="51.62"
      height="10.6459"
      fill="#FBE700"
    />
    <rect
      x="144.02"
      y="211.393"
      width="51.62"
      height="10.6459"
      fill="#F5CA05"
    />
    <rect
      x="144.02"
      y="201.191"
      width="51.62"
      height="10.6459"
      fill="#FAD703"
    />
    <rect
      x="144.02"
      y="147.961"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="133.34"
      y="147.961"
      width="10.68"
      height="10.6459"
      fill="#FBE700"
    />
    <rect
      x="122.66"
      y="158.607"
      width="10.68"
      height="42.5837"
      fill="#FBE700"
    />
    <rect
      x="133.34"
      y="158.607"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="133.34"
      y="169.253"
      width="10.68"
      height="31.9377"
      fill="#FAD703"
    />
    <rect
      x="133.34"
      y="201.191"
      width="10.68"
      height="10.6459"
      fill="#FAD703"
    />
    <rect
      x="144.02"
      y="158.607"
      width="10.68"
      height="10.6459"
      fill="#FBE700"
    />
    <rect
      x="144.02"
      y="169.253"
      width="10.68"
      height="21.2918"
      fill="#F5CA05"
    />
    <rect
      x="184.96"
      y="169.253"
      width="10.68"
      height="21.2918"
      fill="#F5CA05"
    />
    <rect
      x="144.02"
      y="190.545"
      width="10.68"
      height="10.6459"
      fill="#FBE700"
    />
    <rect
      x="184.96"
      y="190.545"
      width="10.68"
      height="10.6459"
      fill="#FBE700"
    />
    <rect x="154.7" y="158.607" width="30.26" height="10.6459" fill="#F5CA05" />
    <rect x="154.7" y="190.545" width="30.26" height="10.6459" fill="#F5CA05" />
    <rect x="154.7" y="169.253" width="30.26" height="21.2918" fill="#FCD303" />
    <rect x="154.7" y="147.961" width="40.94" height="10.6459" fill="#FAD703" />
    <rect
      x="184.96"
      y="158.607"
      width="10.68"
      height="10.6459"
      fill="#FBE700"
    />
    <rect
      x="195.64"
      y="147.961"
      width="10.68"
      height="10.6459"
      fill="#FBE700"
    />
    <rect
      x="195.64"
      y="158.607"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="195.64"
      y="169.253"
      width="10.68"
      height="31.9377"
      fill="#FAD703"
    />
    <rect
      x="195.64"
      y="201.191"
      width="10.68"
      height="10.6459"
      fill="#F5CA05"
    />
    <rect
      x="206.32"
      y="158.607"
      width="10.68"
      height="42.5837"
      fill="#FBE700"
    />
    <rect
      x="144.02"
      y="127.556"
      width="51.62"
      height="10.6459"
      fill="#FBE700"
    />
    <rect
      x="144.02"
      y="201.634"
      width="51.62"
      height="10.6459"
      fill="#F5CA05"
    />
    <rect
      x="144.02"
      y="191.432"
      width="51.62"
      height="10.6459"
      fill="#FAD703"
    />
    <rect
      x="144.02"
      y="138.202"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="133.34"
      y="138.202"
      width="10.68"
      height="10.6459"
      fill="#FBE700"
    />
    <rect
      x="122.66"
      y="148.848"
      width="10.68"
      height="42.5837"
      fill="#FBE700"
    />
    <rect
      x="133.34"
      y="148.848"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="133.34"
      y="159.494"
      width="10.68"
      height="31.9377"
      fill="#FAD703"
    />
    <rect
      x="133.34"
      y="191.432"
      width="10.68"
      height="10.6459"
      fill="#FAD703"
    />
    <rect
      x="144.02"
      y="148.848"
      width="10.68"
      height="10.6459"
      fill="#FBE700"
    />
    <rect
      x="144.02"
      y="159.494"
      width="10.68"
      height="21.2918"
      fill="#F5CA05"
    />
    <rect
      x="184.96"
      y="159.494"
      width="10.68"
      height="21.2918"
      fill="#F5CA05"
    />
    <rect
      x="144.02"
      y="180.786"
      width="10.68"
      height="10.6459"
      fill="#FBE700"
    />
    <rect
      x="184.96"
      y="180.786"
      width="10.68"
      height="10.6459"
      fill="#FBE700"
    />
    <rect x="154.7" y="148.848" width="30.26" height="10.6459" fill="#F5CA05" />
    <rect x="154.7" y="180.786" width="30.26" height="10.6459" fill="#F5CA05" />
    <rect x="154.7" y="159.494" width="30.26" height="21.2918" fill="#FCD303" />
    <rect x="154.7" y="138.202" width="40.94" height="10.6459" fill="#FAD703" />
    <rect
      x="184.96"
      y="148.848"
      width="10.68"
      height="10.6459"
      fill="#FBE700"
    />
    <rect
      x="195.64"
      y="138.202"
      width="10.68"
      height="10.6459"
      fill="#FBE700"
    />
    <rect
      x="195.64"
      y="148.848"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="195.64"
      y="159.494"
      width="10.68"
      height="31.9377"
      fill="#FAD703"
    />
    <rect
      x="195.64"
      y="191.432"
      width="10.68"
      height="10.6459"
      fill="#F5CA05"
    />
    <rect
      x="206.32"
      y="148.848"
      width="10.68"
      height="42.5837"
      fill="#FBE700"
    />
    <rect
      x="144.02"
      y="117.798"
      width="51.62"
      height="10.6459"
      fill="#FBE700"
    />
    <rect
      x="144.02"
      y="191.875"
      width="51.62"
      height="10.6459"
      fill="#F5CA05"
    />
    <rect
      x="144.02"
      y="181.673"
      width="51.62"
      height="10.6459"
      fill="#FAD703"
    />
    <rect
      x="144.02"
      y="128.444"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="133.34"
      y="128.444"
      width="10.68"
      height="10.6459"
      fill="#FBE700"
    />
    <rect
      x="122.66"
      y="139.089"
      width="10.68"
      height="42.5837"
      fill="#FBE700"
    />
    <rect
      x="133.34"
      y="139.089"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="133.34"
      y="149.736"
      width="10.68"
      height="31.9377"
      fill="#FAD703"
    />
    <rect
      x="133.34"
      y="181.673"
      width="10.68"
      height="10.6459"
      fill="#FAD703"
    />
    <rect
      x="144.02"
      y="139.089"
      width="10.68"
      height="10.6459"
      fill="#FBE700"
    />
    <rect
      x="144.02"
      y="149.736"
      width="10.68"
      height="21.2918"
      fill="#F5CA05"
    />
    <rect
      x="184.96"
      y="149.736"
      width="10.68"
      height="21.2918"
      fill="#F5CA05"
    />
    <rect
      x="144.02"
      y="171.027"
      width="10.68"
      height="10.6459"
      fill="#FBE700"
    />
    <rect
      x="184.96"
      y="171.027"
      width="10.68"
      height="10.6459"
      fill="#FBE700"
    />
    <rect x="154.7" y="139.089" width="30.26" height="10.6459" fill="#F5CA05" />
    <rect x="154.7" y="171.027" width="30.26" height="10.6459" fill="#F5CA05" />
    <rect x="154.7" y="149.736" width="30.26" height="21.2918" fill="#FCD303" />
    <rect x="154.7" y="128.444" width="40.94" height="10.6459" fill="#FAD703" />
    <rect
      x="184.96"
      y="139.089"
      width="10.68"
      height="10.6459"
      fill="#FBE700"
    />
    <rect
      x="195.64"
      y="128.444"
      width="10.68"
      height="10.6459"
      fill="#FBE700"
    />
    <rect
      x="195.64"
      y="139.089"
      width="10.68"
      height="10.6459"
      fill="#FFF267"
    />
    <rect
      x="195.64"
      y="149.736"
      width="10.68"
      height="31.9377"
      fill="#FAD703"
    />
    <rect
      x="195.64"
      y="181.673"
      width="10.68"
      height="10.6459"
      fill="#F5CA05"
    />
    <rect
      x="206.32"
      y="139.089"
      width="10.68"
      height="42.5837"
      fill="#FBE700"
    />
  </Icon>
)

TokensLarge.displayName = 'TokensLargeIcon'

export default TokensLarge
