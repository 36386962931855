import { Icon } from '@chakra-ui/icon'

const RibbonFirstTwo = props => (
  <Icon {...props}>
    <path
      d="M23.366 19.266l-2.4-4.138a.25.25 0 00-.424-.013c-1.22 1.838-3 3.236-5.075 3.985a.25.25 0 00-.131.363l1.975 3.343a1 1 0 001.8-.172l.832-2.287 2.392.405a1 1 0 001.031-1.488v.002zM3.471 15.09a.25.25 0 00-.425.012L.634 19.267a1 1 0 001.032 1.487l2.391-.405.833 2.288a1 1 0 001.8.168l1.98-3.351a.251.251 0 00-.13-.362 10.286 10.286 0 01-5.069-4.002z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.016.706a8.75 8.75 0 018.75 8.75 8.76 8.76 0 01-8.75 8.75 8.75 8.75 0 110-17.5zM10.002 7.96a.75.75 0 00.741.14l1.007-.344v4.949a.75.75 0 001.5 0v-6a.75.75 0 00-.993-.71l-2 .686a.75.75 0 00-.255 1.279z"
      fill="currentColor"
    />
  </Icon>
)

RibbonFirstTwo.displayName = 'RibbonFirstTwoIcon'

export default RibbonFirstTwo
