import { Icon } from '@chakra-ui/icon'

const TokensMedium = props => (
  <Icon viewBox="0 0 256 256" {...props}>
    <rect
      x="77.9705"
      y="109.07"
      width="55.5116"
      height="11.4887"
      fill="#FCCC00"
    />
    <rect
      x="77.9705"
      y="189.013"
      width="55.5116"
      height="11.4887"
      fill="#E89600"
    />
    <rect
      x="77.9705"
      y="178.003"
      width="55.5116"
      height="11.4887"
      fill="#F5AD00"
    />
    <rect
      x="77.9705"
      y="120.559"
      width="11.4851"
      height="11.4887"
      fill="#FFF267"
    />
    <rect
      x="66.4851"
      y="120.559"
      width="11.4851"
      height="11.4887"
      fill="#FCCC00"
    />
    <rect x="55" y="132.048" width="11.4851" height="45.9549" fill="#FCCC00" />
    <rect
      x="66.4851"
      y="132.048"
      width="11.4851"
      height="11.4887"
      fill="#FFF267"
    />
    <rect
      x="66.4851"
      y="143.536"
      width="11.4851"
      height="34.4662"
      fill="#F5AD00"
    />
    <rect
      x="66.4851"
      y="178.003"
      width="11.4851"
      height="11.4887"
      fill="#F5AD00"
    />
    <rect
      x="77.9705"
      y="132.048"
      width="11.4851"
      height="11.4887"
      fill="#FCCC00"
    />
    <rect
      x="77.9705"
      y="143.536"
      width="11.4851"
      height="22.9774"
      fill="#E89600"
    />
    <rect
      x="121.997"
      y="143.536"
      width="11.4851"
      height="22.9774"
      fill="#E89600"
    />
    <rect
      x="77.9705"
      y="166.514"
      width="11.4851"
      height="11.4887"
      fill="#FCCC00"
    />
    <rect
      x="121.997"
      y="166.514"
      width="11.4851"
      height="11.4887"
      fill="#FCCC00"
    />
    <rect
      x="89.4553"
      y="132.048"
      width="32.5413"
      height="11.4887"
      fill="#E89600"
    />
    <rect
      x="89.4553"
      y="166.514"
      width="32.5413"
      height="11.4887"
      fill="#E89600"
    />
    <rect
      x="89.4553"
      y="143.536"
      width="32.5413"
      height="22.9774"
      fill="#FCD303"
    />
    <rect
      x="89.4553"
      y="120.559"
      width="44.0264"
      height="11.4887"
      fill="#F5AD00"
    />
    <rect
      x="121.997"
      y="132.048"
      width="11.4851"
      height="11.4887"
      fill="#FCCC00"
    />
    <rect
      x="133.482"
      y="120.559"
      width="11.4851"
      height="11.4887"
      fill="#FCCC00"
    />
    <rect
      x="133.482"
      y="132.048"
      width="11.4851"
      height="11.4887"
      fill="#FFF267"
    />
    <rect
      x="133.482"
      y="143.536"
      width="11.4851"
      height="34.4662"
      fill="#F5AD00"
    />
    <rect
      x="133.482"
      y="178.003"
      width="11.4851"
      height="11.4887"
      fill="#E89600"
    />
    <rect
      x="144.967"
      y="132.048"
      width="11.4851"
      height="45.9549"
      fill="#FCCC00"
    />
    <rect
      x="77.9702"
      y="98.0602"
      width="55.5116"
      height="11.4887"
      fill="#FCCC00"
    />
    <rect
      x="77.9702"
      y="178.003"
      width="55.5116"
      height="11.4887"
      fill="#E89600"
    />
    <rect
      x="77.9702"
      y="166.993"
      width="55.5116"
      height="11.4887"
      fill="#F5AD00"
    />
    <rect
      x="77.9702"
      y="109.549"
      width="11.4851"
      height="11.4887"
      fill="#FFF267"
    />
    <rect
      x="66.4849"
      y="109.549"
      width="11.4851"
      height="11.4887"
      fill="#FCCC00"
    />
    <rect x="55" y="121.038" width="11.4851" height="45.9549" fill="#FCCC00" />
    <rect
      x="66.4849"
      y="121.038"
      width="11.4851"
      height="11.4887"
      fill="#FFF267"
    />
    <rect
      x="66.4849"
      y="132.526"
      width="11.4851"
      height="34.4662"
      fill="#F5AD00"
    />
    <rect
      x="66.4849"
      y="166.993"
      width="11.4851"
      height="11.4887"
      fill="#F5AD00"
    />
    <rect
      x="77.9702"
      y="121.038"
      width="11.4851"
      height="11.4887"
      fill="#FCCC00"
    />
    <rect
      x="77.9702"
      y="132.526"
      width="11.4851"
      height="22.9774"
      fill="#E89600"
    />
    <rect
      x="121.997"
      y="132.526"
      width="11.4851"
      height="22.9774"
      fill="#E89600"
    />
    <rect
      x="77.9702"
      y="155.504"
      width="11.4851"
      height="11.4887"
      fill="#FCCC00"
    />
    <rect
      x="121.997"
      y="155.504"
      width="11.4851"
      height="11.4887"
      fill="#FCCC00"
    />
    <rect
      x="89.4553"
      y="121.038"
      width="32.5413"
      height="11.4887"
      fill="#E89600"
    />
    <rect
      x="89.4553"
      y="155.504"
      width="32.5413"
      height="11.4887"
      fill="#E89600"
    />
    <rect
      x="89.4553"
      y="132.526"
      width="32.5413"
      height="22.9774"
      fill="#FCD303"
    />
    <rect
      x="89.4553"
      y="109.549"
      width="44.0264"
      height="11.4887"
      fill="#F5AD00"
    />
    <rect
      x="121.997"
      y="121.038"
      width="11.4851"
      height="11.4887"
      fill="#FCCC00"
    />
    <rect
      x="133.482"
      y="109.549"
      width="11.4851"
      height="11.4887"
      fill="#FCCC00"
    />
    <rect
      x="133.482"
      y="121.038"
      width="11.4851"
      height="11.4887"
      fill="#FFF267"
    />
    <rect
      x="133.482"
      y="132.526"
      width="11.4851"
      height="34.4662"
      fill="#F5AD00"
    />
    <rect
      x="133.482"
      y="166.993"
      width="11.4851"
      height="11.4887"
      fill="#E89600"
    />
    <rect
      x="144.967"
      y="121.038"
      width="11.4851"
      height="45.9549"
      fill="#FCCC00"
    />
    <rect
      x="77.9702"
      y="87.0502"
      width="55.5116"
      height="11.4887"
      fill="#FCCC00"
    />
    <rect
      x="77.9702"
      y="166.993"
      width="55.5116"
      height="11.4887"
      fill="#E89600"
    />
    <rect
      x="77.9702"
      y="155.983"
      width="55.5116"
      height="11.4887"
      fill="#F5AD00"
    />
    <rect
      x="77.9702"
      y="98.5389"
      width="11.4851"
      height="11.4887"
      fill="#FFF267"
    />
    <rect
      x="66.4849"
      y="98.5389"
      width="11.4851"
      height="11.4887"
      fill="#FCCC00"
    />
    <rect x="55" y="110.028" width="11.4851" height="45.9549" fill="#FCCC00" />
    <rect
      x="66.4849"
      y="110.028"
      width="11.4851"
      height="11.4887"
      fill="#FFF267"
    />
    <rect
      x="66.4849"
      y="121.516"
      width="11.4851"
      height="34.4662"
      fill="#F5AD00"
    />
    <rect
      x="66.4849"
      y="155.983"
      width="11.4851"
      height="11.4887"
      fill="#F5AD00"
    />
    <rect
      x="77.9702"
      y="110.028"
      width="11.4851"
      height="11.4887"
      fill="#FCCC00"
    />
    <rect
      x="77.9702"
      y="121.516"
      width="11.4851"
      height="22.9774"
      fill="#E89600"
    />
    <rect
      x="121.997"
      y="121.516"
      width="11.4851"
      height="22.9774"
      fill="#E89600"
    />
    <rect
      x="77.9702"
      y="144.494"
      width="11.4851"
      height="11.4887"
      fill="#FCCC00"
    />
    <rect
      x="121.997"
      y="144.494"
      width="11.4851"
      height="11.4887"
      fill="#FCCC00"
    />
    <rect
      x="89.4553"
      y="110.028"
      width="32.5413"
      height="11.4887"
      fill="#E89600"
    />
    <rect
      x="89.4553"
      y="144.494"
      width="32.5413"
      height="11.4887"
      fill="#E89600"
    />
    <rect
      x="89.4553"
      y="121.516"
      width="32.5413"
      height="22.9774"
      fill="#FCD303"
    />
    <rect
      x="89.4553"
      y="98.5389"
      width="44.0264"
      height="11.4887"
      fill="#F5AD00"
    />
    <rect
      x="121.997"
      y="110.028"
      width="11.4851"
      height="11.4887"
      fill="#FCCC00"
    />
    <rect
      x="133.482"
      y="98.5389"
      width="11.4851"
      height="11.4887"
      fill="#FCCC00"
    />
    <rect
      x="133.482"
      y="110.028"
      width="11.4851"
      height="11.4887"
      fill="#FFF267"
    />
    <rect
      x="133.482"
      y="121.516"
      width="11.4851"
      height="34.4662"
      fill="#F5AD00"
    />
    <rect
      x="133.482"
      y="155.983"
      width="11.4851"
      height="11.4887"
      fill="#E89600"
    />
    <rect
      x="144.967"
      y="110.028"
      width="11.4851"
      height="45.9549"
      fill="#FCCC00"
    />
    <rect
      x="77.9702"
      y="76.04"
      width="55.5116"
      height="11.4887"
      fill="#FCCC00"
    />
    <rect
      x="77.9702"
      y="155.982"
      width="55.5116"
      height="11.4887"
      fill="#E89600"
    />
    <rect
      x="77.9702"
      y="144.972"
      width="55.5116"
      height="11.4887"
      fill="#F5AD00"
    />
    <rect
      x="77.9702"
      y="87.5288"
      width="11.4851"
      height="11.4887"
      fill="#FFF267"
    />
    <rect
      x="66.4849"
      y="87.5288"
      width="11.4851"
      height="11.4887"
      fill="#FCCC00"
    />
    <rect x="55" y="99.0176" width="11.4851" height="45.9549" fill="#FCCC00" />
    <rect
      x="66.4849"
      y="99.0176"
      width="11.4851"
      height="11.4887"
      fill="#FFF267"
    />
    <rect
      x="66.4849"
      y="110.506"
      width="11.4851"
      height="34.4662"
      fill="#F5AD00"
    />
    <rect
      x="66.4849"
      y="144.972"
      width="11.4851"
      height="11.4887"
      fill="#F5AD00"
    />
    <rect
      x="77.9702"
      y="99.0176"
      width="11.4851"
      height="11.4887"
      fill="#FCCC00"
    />
    <rect
      x="77.9702"
      y="110.506"
      width="11.4851"
      height="22.9774"
      fill="#E89600"
    />
    <rect
      x="121.997"
      y="110.506"
      width="11.4851"
      height="22.9774"
      fill="#E89600"
    />
    <rect
      x="77.9702"
      y="133.484"
      width="11.4851"
      height="11.4887"
      fill="#FCCC00"
    />
    <rect
      x="121.997"
      y="133.484"
      width="11.4851"
      height="11.4887"
      fill="#FCCC00"
    />
    <rect
      x="89.4553"
      y="99.0176"
      width="32.5413"
      height="11.4887"
      fill="#E89600"
    />
    <rect
      x="89.4553"
      y="133.484"
      width="32.5413"
      height="11.4887"
      fill="#E89600"
    />
    <rect
      x="89.4553"
      y="110.506"
      width="32.5413"
      height="22.9774"
      fill="#FCD303"
    />
    <rect
      x="89.4553"
      y="87.5288"
      width="44.0264"
      height="11.4887"
      fill="#F5AD00"
    />
    <rect
      x="121.997"
      y="99.0176"
      width="11.4851"
      height="11.4887"
      fill="#FCCC00"
    />
    <rect
      x="133.482"
      y="87.5288"
      width="11.4851"
      height="11.4887"
      fill="#FCCC00"
    />
    <rect
      x="133.482"
      y="99.0176"
      width="11.4851"
      height="11.4887"
      fill="#FFF267"
    />
    <rect
      x="133.482"
      y="110.506"
      width="11.4851"
      height="34.4662"
      fill="#F5AD00"
    />
    <rect
      x="133.482"
      y="144.972"
      width="11.4851"
      height="11.4887"
      fill="#E89600"
    />
    <rect
      x="144.967"
      y="99.0176"
      width="11.4851"
      height="45.9549"
      fill="#FCCC00"
    />
    <rect
      x="77.9702"
      y="65.03"
      width="55.5116"
      height="11.4887"
      fill="#FCCC00"
    />
    <rect
      x="77.9702"
      y="144.972"
      width="55.5116"
      height="11.4887"
      fill="#E89600"
    />
    <rect
      x="77.9702"
      y="133.962"
      width="55.5116"
      height="11.4887"
      fill="#F5AD00"
    />
    <rect
      x="77.9702"
      y="76.5188"
      width="11.4851"
      height="11.4887"
      fill="#FFF267"
    />
    <rect
      x="66.4849"
      y="76.5188"
      width="11.4851"
      height="11.4887"
      fill="#FCCC00"
    />
    <rect x="55" y="88.0076" width="11.4851" height="45.9549" fill="#FCCC00" />
    <rect
      x="66.4849"
      y="88.0076"
      width="11.4851"
      height="11.4887"
      fill="#FFF267"
    />
    <rect
      x="66.4849"
      y="99.4963"
      width="11.4851"
      height="34.4662"
      fill="#F5AD00"
    />
    <rect
      x="66.4849"
      y="133.962"
      width="11.4851"
      height="11.4887"
      fill="#F5AD00"
    />
    <rect
      x="77.9702"
      y="88.0076"
      width="11.4851"
      height="11.4887"
      fill="#FCCC00"
    />
    <rect
      x="77.9702"
      y="99.4963"
      width="11.4851"
      height="22.9774"
      fill="#E89600"
    />
    <rect
      x="121.997"
      y="99.4963"
      width="11.4851"
      height="22.9774"
      fill="#E89600"
    />
    <rect
      x="77.9702"
      y="122.474"
      width="11.4851"
      height="11.4887"
      fill="#FCCC00"
    />
    <rect
      x="121.997"
      y="122.474"
      width="11.4851"
      height="11.4887"
      fill="#FCCC00"
    />
    <rect
      x="89.4553"
      y="88.0076"
      width="32.5413"
      height="11.4887"
      fill="#E89600"
    />
    <rect
      x="89.4553"
      y="122.474"
      width="32.5413"
      height="11.4887"
      fill="#E89600"
    />
    <rect
      x="89.4553"
      y="99.4963"
      width="32.5413"
      height="22.9774"
      fill="#FCD303"
    />
    <rect
      x="89.4553"
      y="76.5188"
      width="44.0264"
      height="11.4887"
      fill="#F5AD00"
    />
    <rect
      x="121.997"
      y="88.0076"
      width="11.4851"
      height="11.4887"
      fill="#FCCC00"
    />
    <rect
      x="133.482"
      y="76.5188"
      width="11.4851"
      height="11.4887"
      fill="#FCCC00"
    />
    <rect
      x="133.482"
      y="88.0076"
      width="11.4851"
      height="11.4887"
      fill="#FFF267"
    />
    <rect
      x="133.482"
      y="99.4963"
      width="11.4851"
      height="34.4662"
      fill="#F5AD00"
    />
    <rect
      x="133.482"
      y="133.962"
      width="11.4851"
      height="11.4887"
      fill="#E89600"
    />
    <rect
      x="144.967"
      y="88.0076"
      width="11.4851"
      height="45.9549"
      fill="#FCCC00"
    />
    <rect
      x="77.9702"
      y="54.02"
      width="55.5116"
      height="11.4887"
      fill="#FCCC00"
    />
    <rect
      x="77.9702"
      y="133.962"
      width="55.5116"
      height="11.4887"
      fill="#E89600"
    />
    <rect
      x="77.9702"
      y="122.952"
      width="55.5116"
      height="11.4887"
      fill="#F5AD00"
    />
    <rect
      x="77.9702"
      y="65.5088"
      width="11.4851"
      height="11.4887"
      fill="#FFF267"
    />
    <rect
      x="66.4849"
      y="65.5088"
      width="11.4851"
      height="11.4887"
      fill="#FCCC00"
    />
    <rect x="55" y="76.9974" width="11.4851" height="45.9549" fill="#FCCC00" />
    <rect
      x="66.4849"
      y="76.9976"
      width="11.4851"
      height="11.4887"
      fill="#FFF267"
    />
    <rect
      x="66.4849"
      y="88.4863"
      width="11.4851"
      height="34.4662"
      fill="#F5AD00"
    />
    <rect
      x="66.4849"
      y="122.952"
      width="11.4851"
      height="11.4887"
      fill="#F5AD00"
    />
    <rect
      x="77.9702"
      y="76.9976"
      width="11.4851"
      height="11.4887"
      fill="#FCCC00"
    />
    <rect
      x="77.9702"
      y="88.4863"
      width="11.4851"
      height="22.9774"
      fill="#E89600"
    />
    <rect
      x="121.997"
      y="88.4863"
      width="11.4851"
      height="22.9774"
      fill="#E89600"
    />
    <rect
      x="77.9702"
      y="111.464"
      width="11.4851"
      height="11.4887"
      fill="#FCCC00"
    />
    <rect
      x="121.997"
      y="111.464"
      width="11.4851"
      height="11.4887"
      fill="#FCCC00"
    />
    <rect
      x="89.4553"
      y="76.9976"
      width="32.5413"
      height="11.4887"
      fill="#E89600"
    />
    <rect
      x="89.4553"
      y="111.464"
      width="32.5413"
      height="11.4887"
      fill="#E89600"
    />
    <rect
      x="89.4553"
      y="88.4863"
      width="32.5413"
      height="22.9774"
      fill="#FCD303"
    />
    <rect
      x="89.4553"
      y="65.5088"
      width="44.0264"
      height="11.4887"
      fill="#F5AD00"
    />
    <rect
      x="121.997"
      y="76.9976"
      width="11.4851"
      height="11.4887"
      fill="#FCCC00"
    />
    <rect
      x="133.482"
      y="65.5088"
      width="11.4851"
      height="11.4887"
      fill="#FCCC00"
    />
    <rect
      x="133.482"
      y="76.9976"
      width="11.4851"
      height="11.4887"
      fill="#FFF267"
    />
    <rect
      x="133.482"
      y="88.4863"
      width="11.4851"
      height="34.4662"
      fill="#F5AD00"
    />
    <rect
      x="133.482"
      y="122.952"
      width="11.4851"
      height="11.4887"
      fill="#E89600"
    />
    <rect
      x="144.967"
      y="76.9976"
      width="11.4851"
      height="45.9549"
      fill="#FCCC00"
    />
    <rect
      x="77.9702"
      y="43.01"
      width="55.5116"
      height="11.4887"
      fill="#FCCC00"
    />
    <rect
      x="77.9702"
      y="122.952"
      width="55.5116"
      height="11.4887"
      fill="#E89600"
    />
    <rect
      x="77.9702"
      y="111.942"
      width="55.5116"
      height="11.4887"
      fill="#F5AD00"
    />
    <rect
      x="77.9702"
      y="54.4988"
      width="11.4851"
      height="11.4887"
      fill="#FFF267"
    />
    <rect
      x="66.4849"
      y="54.4988"
      width="11.4851"
      height="11.4887"
      fill="#FCCC00"
    />
    <rect x="55" y="65.9874" width="11.4851" height="45.9549" fill="#FCCC00" />
    <rect
      x="66.4849"
      y="65.9875"
      width="11.4851"
      height="11.4887"
      fill="#FFF267"
    />
    <rect
      x="66.4849"
      y="77.4761"
      width="11.4851"
      height="34.4662"
      fill="#F5AD00"
    />
    <rect
      x="66.4849"
      y="111.942"
      width="11.4851"
      height="11.4887"
      fill="#F5AD00"
    />
    <rect
      x="77.9702"
      y="65.9875"
      width="11.4851"
      height="11.4887"
      fill="#FCCC00"
    />
    <rect
      x="77.9702"
      y="77.4761"
      width="11.4851"
      height="22.9774"
      fill="#E89600"
    />
    <rect
      x="121.997"
      y="77.4761"
      width="11.4851"
      height="22.9774"
      fill="#E89600"
    />
    <rect
      x="77.9702"
      y="100.454"
      width="11.4851"
      height="11.4887"
      fill="#FCCC00"
    />
    <rect
      x="121.997"
      y="100.454"
      width="11.4851"
      height="11.4887"
      fill="#FCCC00"
    />
    <rect
      x="89.4553"
      y="65.9875"
      width="32.5413"
      height="11.4887"
      fill="#E89600"
    />
    <rect
      x="89.4553"
      y="100.454"
      width="32.5413"
      height="11.4887"
      fill="#E89600"
    />
    <rect
      x="89.4553"
      y="77.4761"
      width="32.5413"
      height="22.9774"
      fill="#FCD303"
    />
    <rect
      x="89.4553"
      y="54.4988"
      width="44.0264"
      height="11.4887"
      fill="#F5AD00"
    />
    <rect
      x="121.997"
      y="65.9875"
      width="11.4851"
      height="11.4887"
      fill="#FCCC00"
    />
    <rect
      x="133.482"
      y="54.4988"
      width="11.4851"
      height="11.4887"
      fill="#FCCC00"
    />
    <rect
      x="133.482"
      y="65.9875"
      width="11.4851"
      height="11.4887"
      fill="#FFF267"
    />
    <rect
      x="133.482"
      y="77.4761"
      width="11.4851"
      height="34.4662"
      fill="#F5AD00"
    />
    <rect
      x="133.482"
      y="111.942"
      width="11.4851"
      height="11.4887"
      fill="#E89600"
    />
    <rect
      x="144.967"
      y="65.9875"
      width="11.4851"
      height="45.9549"
      fill="#FCCC00"
    />
    <rect x="77.9707" y="32" width="55.5116" height="11.4887" fill="#FCCC00" />
    <rect
      x="77.9707"
      y="111.942"
      width="55.5116"
      height="11.4887"
      fill="#E89600"
    />
    <rect
      x="77.9707"
      y="100.932"
      width="55.5116"
      height="11.4887"
      fill="#F5AD00"
    />
    <rect
      x="77.9707"
      y="43.4888"
      width="11.4851"
      height="11.4887"
      fill="#FFF267"
    />
    <rect
      x="66.4854"
      y="43.4888"
      width="11.4851"
      height="11.4887"
      fill="#FCCC00"
    />
    <rect
      x="55.0002"
      y="54.9774"
      width="11.4851"
      height="45.9549"
      fill="#FCCC00"
    />
    <rect
      x="66.4854"
      y="54.9775"
      width="11.4851"
      height="11.4887"
      fill="#FFF267"
    />
    <rect
      x="66.4854"
      y="66.4661"
      width="11.4851"
      height="34.4662"
      fill="#F5AD00"
    />
    <rect
      x="66.4854"
      y="100.932"
      width="11.4851"
      height="11.4887"
      fill="#F5AD00"
    />
    <rect
      x="77.9707"
      y="54.9775"
      width="11.4851"
      height="11.4887"
      fill="#FCCC00"
    />
    <rect
      x="77.9707"
      y="66.4661"
      width="11.4851"
      height="22.9774"
      fill="#E89600"
    />
    <rect
      x="121.997"
      y="66.4661"
      width="11.4851"
      height="22.9774"
      fill="#E89600"
    />
    <rect
      x="77.9707"
      y="89.4436"
      width="11.4851"
      height="11.4887"
      fill="#FCCC00"
    />
    <rect
      x="121.997"
      y="89.4436"
      width="11.4851"
      height="11.4887"
      fill="#FCCC00"
    />
    <rect
      x="89.4556"
      y="54.9775"
      width="32.5413"
      height="11.4887"
      fill="#E89600"
    />
    <rect
      x="89.4556"
      y="89.4436"
      width="32.5413"
      height="11.4887"
      fill="#E89600"
    />
    <rect
      x="89.4556"
      y="66.4661"
      width="32.5413"
      height="22.9774"
      fill="#FCD303"
    />
    <rect
      x="89.4556"
      y="43.4888"
      width="44.0264"
      height="11.4887"
      fill="#F5AD00"
    />
    <rect
      x="121.997"
      y="54.9775"
      width="11.4851"
      height="11.4887"
      fill="#FCCC00"
    />
    <rect
      x="133.482"
      y="43.4888"
      width="11.4851"
      height="11.4887"
      fill="#FCCC00"
    />
    <rect
      x="133.482"
      y="54.9775"
      width="11.4851"
      height="11.4887"
      fill="#FFF267"
    />
    <rect
      x="133.482"
      y="66.4661"
      width="11.4851"
      height="34.4662"
      fill="#F5AD00"
    />
    <rect
      x="133.482"
      y="100.932"
      width="11.4851"
      height="11.4887"
      fill="#E89600"
    />
    <rect
      x="144.967"
      y="54.9775"
      width="11.4851"
      height="45.9549"
      fill="#FCCC00"
    />
    <rect
      x="121.518"
      y="131.569"
      width="55.5116"
      height="11.4887"
      fill="#FBE700"
    />
    <rect
      x="121.518"
      y="211.511"
      width="55.5116"
      height="11.4887"
      fill="#F5CA05"
    />
    <rect
      x="121.518"
      y="200.501"
      width="55.5116"
      height="11.4887"
      fill="#FAD703"
    />
    <rect
      x="121.518"
      y="143.058"
      width="11.4851"
      height="11.4887"
      fill="#FFF267"
    />
    <rect
      x="110.033"
      y="143.058"
      width="11.4851"
      height="11.4887"
      fill="#FBE700"
    />
    <rect
      x="98.5479"
      y="154.547"
      width="11.4851"
      height="45.9549"
      fill="#FBE700"
    />
    <rect
      x="110.033"
      y="154.547"
      width="11.4851"
      height="11.4887"
      fill="#FFF267"
    />
    <rect
      x="110.033"
      y="166.035"
      width="11.4851"
      height="34.4662"
      fill="#FAD703"
    />
    <rect
      x="110.033"
      y="200.501"
      width="11.4851"
      height="11.4887"
      fill="#FAD703"
    />
    <rect
      x="121.518"
      y="154.547"
      width="11.4851"
      height="11.4887"
      fill="#FBE700"
    />
    <rect
      x="121.518"
      y="166.035"
      width="11.4851"
      height="22.9774"
      fill="#F5CA05"
    />
    <rect
      x="165.545"
      y="166.035"
      width="11.4851"
      height="22.9774"
      fill="#F5CA05"
    />
    <rect
      x="121.518"
      y="189.013"
      width="11.4851"
      height="11.4887"
      fill="#FBE700"
    />
    <rect
      x="165.545"
      y="189.013"
      width="11.4851"
      height="11.4887"
      fill="#FBE700"
    />
    <rect
      x="133.004"
      y="154.547"
      width="32.5413"
      height="11.4887"
      fill="#F5CA05"
    />
    <rect
      x="133.004"
      y="189.013"
      width="32.5413"
      height="11.4887"
      fill="#F5CA05"
    />
    <rect
      x="133.004"
      y="166.035"
      width="32.5413"
      height="22.9774"
      fill="#FCD303"
    />
    <rect
      x="133.004"
      y="143.058"
      width="44.0264"
      height="11.4887"
      fill="#FAD703"
    />
    <rect
      x="165.545"
      y="154.547"
      width="11.4851"
      height="11.4887"
      fill="#FBE700"
    />
    <rect
      x="177.03"
      y="143.058"
      width="11.4851"
      height="11.4887"
      fill="#FBE700"
    />
    <rect
      x="177.03"
      y="154.547"
      width="11.4851"
      height="11.4887"
      fill="#FFF267"
    />
    <rect
      x="177.03"
      y="166.035"
      width="11.4851"
      height="34.4662"
      fill="#FAD703"
    />
    <rect
      x="177.03"
      y="200.501"
      width="11.4851"
      height="11.4887"
      fill="#F5CA05"
    />
    <rect
      x="188.515"
      y="154.547"
      width="11.4851"
      height="45.9549"
      fill="#FBE700"
    />
    <rect
      x="121.518"
      y="120.559"
      width="55.5116"
      height="11.4887"
      fill="#FBE700"
    />
    <rect
      x="121.518"
      y="200.501"
      width="55.5116"
      height="11.4887"
      fill="#F5CA05"
    />
    <rect
      x="121.518"
      y="189.491"
      width="55.5116"
      height="11.4887"
      fill="#FAD703"
    />
    <rect
      x="121.518"
      y="132.048"
      width="11.4851"
      height="11.4887"
      fill="#FFF267"
    />
    <rect
      x="110.033"
      y="132.048"
      width="11.4851"
      height="11.4887"
      fill="#FBE700"
    />
    <rect
      x="98.5479"
      y="143.536"
      width="11.4851"
      height="45.9549"
      fill="#FBE700"
    />
    <rect
      x="110.033"
      y="143.536"
      width="11.4851"
      height="11.4887"
      fill="#FFF267"
    />
    <rect
      x="110.033"
      y="155.025"
      width="11.4851"
      height="34.4662"
      fill="#FAD703"
    />
    <rect
      x="110.033"
      y="189.491"
      width="11.4851"
      height="11.4887"
      fill="#FAD703"
    />
    <rect
      x="121.518"
      y="143.536"
      width="11.4851"
      height="11.4887"
      fill="#FBE700"
    />
    <rect
      x="121.518"
      y="155.025"
      width="11.4851"
      height="22.9774"
      fill="#F5CA05"
    />
    <rect
      x="165.545"
      y="155.025"
      width="11.4851"
      height="22.9774"
      fill="#F5CA05"
    />
    <rect
      x="121.518"
      y="178.002"
      width="11.4851"
      height="11.4887"
      fill="#FBE700"
    />
    <rect
      x="165.545"
      y="178.002"
      width="11.4851"
      height="11.4887"
      fill="#FBE700"
    />
    <rect
      x="133.004"
      y="143.536"
      width="32.5413"
      height="11.4887"
      fill="#F5CA05"
    />
    <rect
      x="133.004"
      y="178.002"
      width="32.5413"
      height="11.4887"
      fill="#F5CA05"
    />
    <rect
      x="133.004"
      y="155.025"
      width="32.5413"
      height="22.9774"
      fill="#FCD303"
    />
    <rect
      x="133.004"
      y="132.048"
      width="44.0264"
      height="11.4887"
      fill="#FAD703"
    />
    <rect
      x="165.545"
      y="143.536"
      width="11.4851"
      height="11.4887"
      fill="#FBE700"
    />
    <rect
      x="177.03"
      y="132.048"
      width="11.4851"
      height="11.4887"
      fill="#FBE700"
    />
    <rect
      x="177.03"
      y="143.536"
      width="11.4851"
      height="11.4887"
      fill="#FFF267"
    />
    <rect
      x="177.03"
      y="155.025"
      width="11.4851"
      height="34.4662"
      fill="#FAD703"
    />
    <rect
      x="177.03"
      y="189.491"
      width="11.4851"
      height="11.4887"
      fill="#F5CA05"
    />
    <rect
      x="188.515"
      y="143.536"
      width="11.4851"
      height="45.9549"
      fill="#FBE700"
    />
    <rect
      x="121.518"
      y="110.028"
      width="55.5116"
      height="11.4887"
      fill="#FBE700"
    />
    <rect
      x="121.518"
      y="189.97"
      width="55.5116"
      height="11.4887"
      fill="#F5CA05"
    />
    <rect
      x="121.518"
      y="178.96"
      width="55.5116"
      height="11.4887"
      fill="#FAD703"
    />
    <rect
      x="121.518"
      y="121.516"
      width="11.4851"
      height="11.4887"
      fill="#FFF267"
    />
    <rect
      x="110.033"
      y="121.516"
      width="11.4851"
      height="11.4887"
      fill="#FBE700"
    />
    <rect
      x="98.5479"
      y="133.005"
      width="11.4851"
      height="45.9549"
      fill="#FBE700"
    />
    <rect
      x="110.033"
      y="133.005"
      width="11.4851"
      height="11.4887"
      fill="#FFF267"
    />
    <rect
      x="110.033"
      y="144.494"
      width="11.4851"
      height="34.4662"
      fill="#FAD703"
    />
    <rect
      x="110.033"
      y="178.96"
      width="11.4851"
      height="11.4887"
      fill="#FAD703"
    />
    <rect
      x="121.518"
      y="133.005"
      width="11.4851"
      height="11.4887"
      fill="#FBE700"
    />
    <rect
      x="121.518"
      y="144.494"
      width="11.4851"
      height="22.9774"
      fill="#F5CA05"
    />
    <rect
      x="165.545"
      y="144.494"
      width="11.4851"
      height="22.9774"
      fill="#F5CA05"
    />
    <rect
      x="121.518"
      y="167.471"
      width="11.4851"
      height="11.4887"
      fill="#FBE700"
    />
    <rect
      x="165.545"
      y="167.471"
      width="11.4851"
      height="11.4887"
      fill="#FBE700"
    />
    <rect
      x="133.004"
      y="133.005"
      width="32.5413"
      height="11.4887"
      fill="#F5CA05"
    />
    <rect
      x="133.004"
      y="167.471"
      width="32.5413"
      height="11.4887"
      fill="#F5CA05"
    />
    <rect
      x="133.004"
      y="144.494"
      width="32.5413"
      height="22.9774"
      fill="#FCD303"
    />
    <rect
      x="133.004"
      y="121.516"
      width="44.0264"
      height="11.4887"
      fill="#FAD703"
    />
    <rect
      x="165.545"
      y="133.005"
      width="11.4851"
      height="11.4887"
      fill="#FBE700"
    />
    <rect
      x="177.03"
      y="121.516"
      width="11.4851"
      height="11.4887"
      fill="#FBE700"
    />
    <rect
      x="177.03"
      y="133.005"
      width="11.4851"
      height="11.4887"
      fill="#FFF267"
    />
    <rect
      x="177.03"
      y="144.494"
      width="11.4851"
      height="34.4662"
      fill="#FAD703"
    />
    <rect
      x="177.03"
      y="178.96"
      width="11.4851"
      height="11.4887"
      fill="#F5CA05"
    />
    <rect
      x="188.515"
      y="133.005"
      width="11.4851"
      height="45.9549"
      fill="#FBE700"
    />
    <rect
      x="121.518"
      y="99.4962"
      width="55.5116"
      height="11.4887"
      fill="#FBE700"
    />
    <rect
      x="121.518"
      y="179.438"
      width="55.5116"
      height="11.4887"
      fill="#F5CA05"
    />
    <rect
      x="121.518"
      y="168.428"
      width="55.5116"
      height="11.4887"
      fill="#FAD703"
    />
    <rect
      x="121.518"
      y="110.985"
      width="11.4851"
      height="11.4887"
      fill="#FFF267"
    />
    <rect
      x="110.033"
      y="110.985"
      width="11.4851"
      height="11.4887"
      fill="#FBE700"
    />
    <rect
      x="98.5479"
      y="122.474"
      width="11.4851"
      height="45.9549"
      fill="#FBE700"
    />
    <rect
      x="110.033"
      y="122.474"
      width="11.4851"
      height="11.4887"
      fill="#FFF267"
    />
    <rect
      x="110.033"
      y="133.962"
      width="11.4851"
      height="34.4662"
      fill="#FAD703"
    />
    <rect
      x="110.033"
      y="168.428"
      width="11.4851"
      height="11.4887"
      fill="#FAD703"
    />
    <rect
      x="121.518"
      y="122.474"
      width="11.4851"
      height="11.4887"
      fill="#FBE700"
    />
    <rect
      x="121.518"
      y="133.962"
      width="11.4851"
      height="22.9774"
      fill="#F5CA05"
    />
    <rect
      x="165.545"
      y="133.962"
      width="11.4851"
      height="22.9774"
      fill="#F5CA05"
    />
    <rect
      x="121.518"
      y="156.94"
      width="11.4851"
      height="11.4887"
      fill="#FBE700"
    />
    <rect
      x="165.545"
      y="156.94"
      width="11.4851"
      height="11.4887"
      fill="#FBE700"
    />
    <rect
      x="133.004"
      y="122.474"
      width="32.5413"
      height="11.4887"
      fill="#F5CA05"
    />
    <rect
      x="133.004"
      y="156.94"
      width="32.5413"
      height="11.4887"
      fill="#F5CA05"
    />
    <rect
      x="133.004"
      y="133.962"
      width="32.5413"
      height="22.9774"
      fill="#FCD303"
    />
    <rect
      x="133.004"
      y="110.985"
      width="44.0264"
      height="11.4887"
      fill="#FAD703"
    />
    <rect
      x="165.545"
      y="122.474"
      width="11.4851"
      height="11.4887"
      fill="#FBE700"
    />
    <rect
      x="177.03"
      y="110.985"
      width="11.4851"
      height="11.4887"
      fill="#FBE700"
    />
    <rect
      x="177.03"
      y="122.474"
      width="11.4851"
      height="11.4887"
      fill="#FFF267"
    />
    <rect
      x="177.03"
      y="133.962"
      width="11.4851"
      height="34.4662"
      fill="#FAD703"
    />
    <rect
      x="177.03"
      y="168.428"
      width="11.4851"
      height="11.4887"
      fill="#F5CA05"
    />
    <rect
      x="188.515"
      y="122.474"
      width="11.4851"
      height="45.9549"
      fill="#FBE700"
    />
    <rect
      x="121.518"
      y="88.9648"
      width="55.5116"
      height="11.4887"
      fill="#FBE700"
    />
    <rect
      x="121.518"
      y="168.907"
      width="55.5116"
      height="11.4887"
      fill="#F5CA05"
    />
    <rect
      x="121.518"
      y="157.897"
      width="55.5116"
      height="11.4887"
      fill="#FAD703"
    />
    <rect
      x="121.518"
      y="100.454"
      width="11.4851"
      height="11.4887"
      fill="#FFF267"
    />
    <rect
      x="110.033"
      y="100.454"
      width="11.4851"
      height="11.4887"
      fill="#FBE700"
    />
    <rect
      x="98.5479"
      y="111.942"
      width="11.4851"
      height="45.9549"
      fill="#FBE700"
    />
    <rect
      x="110.033"
      y="111.942"
      width="11.4851"
      height="11.4887"
      fill="#FFF267"
    />
    <rect
      x="110.033"
      y="123.431"
      width="11.4851"
      height="34.4662"
      fill="#FAD703"
    />
    <rect
      x="110.033"
      y="157.897"
      width="11.4851"
      height="11.4887"
      fill="#FAD703"
    />
    <rect
      x="121.518"
      y="111.942"
      width="11.4851"
      height="11.4887"
      fill="#FBE700"
    />
    <rect
      x="121.518"
      y="123.431"
      width="11.4851"
      height="22.9774"
      fill="#F5CA05"
    />
    <rect
      x="165.545"
      y="123.431"
      width="11.4851"
      height="22.9774"
      fill="#F5CA05"
    />
    <rect
      x="121.518"
      y="146.408"
      width="11.4851"
      height="11.4887"
      fill="#FBE700"
    />
    <rect
      x="165.545"
      y="146.408"
      width="11.4851"
      height="11.4887"
      fill="#FBE700"
    />
    <rect
      x="133.004"
      y="111.942"
      width="32.5413"
      height="11.4887"
      fill="#F5CA05"
    />
    <rect
      x="133.004"
      y="146.408"
      width="32.5413"
      height="11.4887"
      fill="#F5CA05"
    />
    <rect
      x="133.004"
      y="123.431"
      width="32.5413"
      height="22.9774"
      fill="#FCD303"
    />
    <rect
      x="133.004"
      y="100.454"
      width="44.0264"
      height="11.4887"
      fill="#FAD703"
    />
    <rect
      x="165.545"
      y="111.942"
      width="11.4851"
      height="11.4887"
      fill="#FBE700"
    />
    <rect
      x="177.03"
      y="100.454"
      width="11.4851"
      height="11.4887"
      fill="#FBE700"
    />
    <rect
      x="177.03"
      y="111.942"
      width="11.4851"
      height="11.4887"
      fill="#FFF267"
    />
    <rect
      x="177.03"
      y="123.431"
      width="11.4851"
      height="34.4662"
      fill="#FAD703"
    />
    <rect
      x="177.03"
      y="157.897"
      width="11.4851"
      height="11.4887"
      fill="#F5CA05"
    />
    <rect
      x="188.515"
      y="111.942"
      width="11.4851"
      height="45.9549"
      fill="#FBE700"
    />
  </Icon>
)

TokensMedium.displayName = 'TokensMediumIcon'

export default TokensMedium
