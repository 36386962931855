import { Icon } from '@chakra-ui/icon'

const PodiumAlternate = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.67 10.229l-1.052-2.1a1.872 1.872 0 00-1.619-1H17.5v-.5a.722.722 0 00-.112-.371l-1.269-3.13a1.872 1.872 0 00-1.619-1h-5a1.89 1.89 0 00-1.647 1.059L6.614 6.255a.733.733 0 00-.114.383l.008.488H3a1.872 1.872 0 00-1.619 1L.33 10.229a3.485 3.485 0 00-.33 1.4v8.75a1.5 1.5 0 001.5 1.5h21a1.5 1.5 0 001.5-1.5v-8.75a3.485 3.485 0 00-.33-1.4zm-2.42 7.147a2 2 0 01-3.927.537.75.75 0 011.444-.4.5.5 0 10.483-.633.75.75 0 010-1.5.5.5 0 10-.483-.631.75.75 0 01-1.449-.387 2 2 0 113.492 1.768c.284.353.44.793.44 1.246zM6.5 18.626a.75.75 0 00-.75-.75h-.938l1.133-1.419a2.537 2.537 0 00.555-1.581 2 2 0 10-4 0 .75.75 0 001.5 0 .5.5 0 111 0c0 .234-.079.46-.224.644l-2.111 2.637a.75.75 0 00.585 1.219h2.5a.75.75 0 00.75-.75zm4.979-7.459a.75.75 0 11-.96-1.152l1.086-.907a1 1 0 011.641.768v3.715h.254a.75.75 0 010 1.5h-2.25a.75.75 0 010-1.5h.5v-2.647l-.271.223zm6.018-.791c0 .138.112.25.25.25h3.478a.25.25 0 00.224-.362l-.5-1a.249.249 0 00-.224-.138h-2.978a.25.25 0 00-.25.25v1zm-11.25-1.25a.249.249 0 01.25.25v1a.25.25 0 01-.25.25H2.77a.249.249 0 01-.223-.362l.5-1a.249.249 0 01.224-.138h2.976zm3.546-5a.251.251 0 00-.231.154l-.521 1.25a.249.249 0 00.23.346h5.456a.25.25 0 00.231-.346l-.522-1.25a.25.25 0 00-.231-.154H9.793z"
      fill="currentColor"
    />
  </Icon>
)

PodiumAlternate.displayName = 'PodiumAlternateIcon'

export default PodiumAlternate
