import { Icon } from '@chakra-ui/icon'

const Sync = props => (
  <Icon {...props}>
    <path
      d="M10.319 4.936c2.6-.618 5.33.248 7.1 2.252a1.25 1.25 0 001.872-1.657A9.737 9.737 0 009.743 2.5a10.269 10.269 0 00-7.365 7.11.249.249 0 01-.271.178l-1.033-.13a.5.5 0 00-.493.745l2.476 4.342a.5.5 0 00.373.248c.02.002.041.002.062 0a.5.5 0 00.359-.152l3.477-3.593a.5.5 0 00-.3-.844l-1.878-.232a.25.25 0 01-.2-.333 7.7 7.7 0 015.369-4.903zM23.406 14.1a.5.5 0 00.015-.526l-2.5-4.329A.5.5 0 0020.546 9a.489.489 0 00-.421.151l-3.456 3.614a.5.5 0 00.3.842l1.848.221a.25.25 0 01.183.117c.04.065.048.144.023.216a7.688 7.688 0 01-5.369 4.9 7.243 7.243 0 01-7.1-2.253 1.25 1.25 0 10-1.872 1.656 9.74 9.74 0 009.549 3.03 10.26 10.26 0 007.369-7.12.251.251 0 01.27-.179l1.058.127a.43.43 0 00.06 0 .5.5 0 00.418-.222z"
      fill="currentColor"
    />
  </Icon>
)

Sync.displayName = 'SyncIcon'

export default Sync
