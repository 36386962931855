import { Icon } from '@chakra-ui/icon'

const CloseCircleOutline = props => (
  <Icon {...props}>
    <path
      d="M17.666 6.333a1.249 1.249 0 00-1.768 0l-3.722 3.722a.25.25 0 01-.353 0L8.1 6.333A1.25 1.25 0 106.333 8.1l3.722 3.722a.25.25 0 010 .354L6.333 15.9a1.251 1.251 0 000 1.768 1.269 1.269 0 001.768 0l3.722-3.722a.249.249 0 01.353 0l3.724 3.72a1.272 1.272 0 001.768 0 1.25 1.25 0 000-1.768l-3.722-3.722a.25.25 0 010-.354l3.72-3.722a1.25 1.25 0 000-1.767z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 12C0 5.373 5.373 0 12 0c6.624.007 11.993 5.376 12 12 0 6.627-5.373 12-12 12S0 18.627 0 12zm2 0c0 5.523 4.477 10 10 10 5.52-.006 9.994-4.48 10-10 0-5.523-4.477-10-10-10S2 6.477 2 12z"
      fill="currentColor"
    />
  </Icon>
)

CloseCircleOutline.displayName = 'CloseCircleOutlineIcon'

export default CloseCircleOutline
