import { Icon } from '@chakra-ui/icon'

const Coins = props => (
  <Icon {...props}>
    <svg
      width="24"
      height="24"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M0.666748 2.66667V5.33334C0.666748 6.43801 2.45741 7.33334 4.66675 7.33334C6.87608 7.33334 8.66675 6.43801 8.66675 5.33334V2.66667"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M0.666748 5.33334V8.00001C0.666748 9.10468 2.45741 10 4.66675 10C5.69141 10 6.62541 9.80668 7.33341 9.49001"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M0.666748 8V10.6667C0.666748 11.7713 2.45741 12.6667 4.66675 12.6667C5.69141 12.6667 6.62608 12.474 7.33341 12.1573"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M4.66675 4.66667C6.87589 4.66667 8.66675 3.77124 8.66675 2.66667C8.66675 1.5621 6.87589 0.666672 4.66675 0.666672C2.45761 0.666672 0.666748 1.5621 0.666748 2.66667C0.666748 3.77124 2.45761 4.66667 4.66675 4.66667Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M7.3335 8V10.6667C7.3335 11.7713 9.12416 12.6667 11.3335 12.6667C13.5428 12.6667 15.3335 11.7713 15.3335 10.6667V8"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M7.3335 10.6667V13.3333C7.3335 14.438 9.12416 15.3333 11.3335 15.3333C13.5428 15.3333 15.3335 14.438 15.3335 13.3333V10.6667"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M11.3335 10C13.5426 10 15.3335 9.10457 15.3335 8C15.3335 6.89543 13.5426 6 11.3335 6C9.12436 6 7.3335 6.89543 7.3335 8C7.3335 9.10457 9.12436 10 11.3335 10Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </Icon>
)

Coins.displayName = 'CoinsIcon'

export default Coins
