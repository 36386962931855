import { Icon } from '@chakra-ui/icon'

const GameControllerOne = props => (
  <Icon {...props}>
    <path
      d="M5 2h4.5A1.5 1.5 0 0111 3.5V5a1 1 0 102 0V3.5A3.5 3.5 0 009.5 0H5a1 1 0 000 2z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.5 7.5h15A4.5 4.5 0 0124 12v9.88a2.1 2.1 0 01-.626 1.495 2.131 2.131 0 01-1.515.625c-1.938 0-2.265-.635-4.067-4.129l-.005-.01-.336-.65c-.16-.311-.332-.643-.517-.997A2.5 2.5 0 0014.789 17H9.21a2.519 2.519 0 00-2.166 1.245l-.723 1.394C4.377 23.4 4.078 23.974 2.129 24H2.1a2.1 2.1 0 01-1.485-.606A2.056 2.056 0 010 21.92V12a4.5 4.5 0 014.5-4.5zm2 6h.75a.75.75 0 000-1.5H6.5a.5.5 0 01-.5-.5v-.75a.75.75 0 00-1.5 0v.75a.5.5 0 01-.5.5h-.75a.75.75 0 000 1.5H4a.5.5 0 01.5.5v.75a.75.75 0 001.5 0V14a.5.5 0 01.5-.5zm9.5 0a1 1 0 110-2 1 1 0 010 2zm1.5 1a1 1 0 102 0 1 1 0 00-2 0zm1-3a1 1 0 110-2 1 1 0 010 2zm1.5 1a1 1 0 102 0 1 1 0 00-2 0z"
      fill="currentColor"
    />
  </Icon>
)

GameControllerOne.displayName = 'GameControllerOneIcon'

export default GameControllerOne
