import { Icon } from '@chakra-ui/icon'

const HomeOutlineTwo = props => (
  <Icon {...props}>
    <svg
      width="24"
      height="24"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.666626 7L7.99996 1L15.3333 7"
        stroke="#ffffff"
        strokeMiterlimit="10"
      />
      <path
        d="M6.66663 15V11H9.33329V15"
        stroke="#ffffff"
        strokeMiterlimit="10"
      />
      <path
        d="M2.66663 8.33334V15H13.3333V8.33334"
        stroke="#ffffff"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  </Icon>
)

HomeOutlineTwo.displayName = 'HomeOutlineTwoIcon'

export default HomeOutlineTwo
