import { Card, NewCard } from 'types'

enum UserStatus {
  Assigned = 'assigned',
  Completed = 'completed',
  InProgress = 'in_progress',
}

export enum GuideStatus {
  Draft = 'draft',
  Published = 'published',
  Archived = 'archived',
}

export interface BaseGuide {
  cards?: Card[] | NewCard[]
}

export interface NewGuide extends BaseGuide {
  id?: number
  status?: GuideStatus
  title?: string
  description?: string
}

export interface Guide extends NewGuide {
  id: number
  mostRecentScore: null | number
  status: GuideStatus
  title: string
  userStatus: UserStatus
}
