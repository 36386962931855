import { Icon } from '@chakra-ui/icon'

const SettingsCog = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.155 9.314l1.266.449a2.375 2.375 0 010 4.475l-1.266.451a1.374 1.374 0 00-.78 1.885l.576 1.213a2.376 2.376 0 01-3.165 3.165l-1.213-.576a1.374 1.374 0 00-1.885.78l-.45 1.266a2.376 2.376 0 01-4.476 0l-.45-1.265a1.373 1.373 0 00-1.885-.781l-1.213.576a2.376 2.376 0 01-3.165-3.165l.576-1.213a1.374 1.374 0 00-.78-1.885l-1.266-.45a2.375 2.375 0 010-4.475l1.266-.45a1.375 1.375 0 00.78-1.886l-.576-1.213A2.376 2.376 0 016.214 3.05l1.213.577a1.375 1.375 0 001.885-.781l.45-1.266a2.376 2.376 0 014.476 0l.45 1.265a1.375 1.375 0 001.885.782l1.213-.577a2.376 2.376 0 013.165 3.165l-.576 1.213a1.374 1.374 0 00.78 1.886zM7.559 13.841A4.93 4.93 0 0012 16.785a4.588 4.588 0 001.84-.344 4.813 4.813 0 002.6-6.281 4.868 4.868 0 00-6.281-2.6 4.813 4.813 0 00-2.6 6.281z"
      fill="currentColor"
    />
  </Icon>
)

SettingsCog.displayName = 'SettingsCogIcon'

export default SettingsCog
