import { Icon } from '@chakra-ui/icon'

const LayersTogether = props => (
  <Icon {...props}>
    <path
      d="M21.5 7H19.5C18.9477 7 18.5 7.44772 18.5 8C18.5 8.55228 18.9477 9 19.5 9H21.5C21.7761 9 22 9.22386 22 9.5V21.5C22 21.7761 21.7761 22 21.5 22H9.5C9.22386 22 9 21.7761 9 21.5V19.5C9 18.9477 8.55228 18.5 8 18.5C7.44772 18.5 7 18.9477 7 19.5V21.5C7 22.163 7.26339 22.7989 7.73223 23.2678C8.20107 23.7366 8.83696 24 9.5 24H21.5C22.163 24 22.7989 23.7366 23.2678 23.2678C23.7366 22.7989 24 22.163 24 21.5V9.5C24 8.83696 23.7366 8.20107 23.2678 7.73223C22.7989 7.26339 22.163 7 21.5 7Z"
      fill="currentcolor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 15C17 16.1046 16.1046 17 15 17H2C0.89543 17 0 16.1046 0 15V2C0 0.89543 0.89543 0 2 0H15C16.1046 0 17 0.89543 17 2V15ZM2.5 2C2.22386 2 2 2.22386 2 2.5V14.5C2 14.7761 2.22386 15 2.5 15H14.5C14.7761 15 15 14.7761 15 14.5V2.5C15 2.22386 14.7761 2 14.5 2H2.5Z"
      fill="currentcolor"
    />
  </Icon>
)

LayersTogether.displayName = 'LayersTogetherIcon'

export default LayersTogether
