import { Icon } from '@chakra-ui/icon'

const Podium = props => (
  <Icon {...props}>
    <path
      d="M5.5 9.5h-4A1.5 1.5 0 000 11v9a1.5 1.5 0 001.5 1.5h4A.5.5 0 006 21V10a.5.5 0 00-.5-.5zM22.5 13.5h-4a.5.5 0 00-.5.5v7a.5.5 0 00.5.5h4A1.5 1.5 0 0024 20v-5a1.5 1.5 0 00-1.5-1.5z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 2.5h6A1.5 1.5 0 0116.5 4v17a.5.5 0 01-.5.5H8a.5.5 0 01-.5-.5V4A1.5 1.5 0 019 2.5zm2 8.25h2a.75.75 0 000-1.5.25.25 0 01-.25-.25V6.5a1.252 1.252 0 00-1.25-1.25H11a.75.75 0 000 1.5.25.25 0 01.25.25v2a.25.25 0 01-.25.25.75.75 0 000 1.5z"
      fill="currentColor"
    />
  </Icon>
)

Podium.displayName = 'PodiumIcon'

export default Podium
