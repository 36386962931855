import { Icon } from '@chakra-ui/icon'

const ChevronDown = props => (
  <Icon {...props}>
    <path
      d="M11.52 19.5a2.17 2.17 0 01-1.66-.78L.442 7.568a1.901 1.901 0 01.187-2.574 1.723 1.723 0 012.475.135l8.235 9.752a.236.236 0 00.181.085c.07 0 .135-.03.18-.085l8.236-9.752a1.734 1.734 0 011.694-.627c.631.127 1.148.6 1.349 1.237a1.904 1.904 0 01-.38 1.829l-9.416 11.148a2.18 2.18 0 01-1.663.784z"
      fill="currentColor"
    />
  </Icon>
)

ChevronDown.displayName = 'ChevronDownIcon'

export default ChevronDown
