import { Icon } from '@chakra-ui/icon'

const Speedometer = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 14C.007 7.376 5.376 2.007 12 2c6.624.007 11.993 5.376 12 12v2.5a2.5 2.5 0 01-2.5 2.5h-19A2.5 2.5 0 010 16.5V14zm19.5 1.25h2a.75.75 0 000-1.5h-2a.75.75 0 000 1.5zm-.754-3.816a.751.751 0 01-.376-1.4l1.732-1a.75.75 0 01.75 1.3l-1.732 1a.739.739 0 01-.374.1zm-5.886 5.171A2.746 2.746 0 0014.75 14c0-.665-.242-1.308-.681-1.808l3.743-5.76a.75.75 0 10-1.258-.818l-3.741 5.758a2.746 2.746 0 10.047 5.233zM8.192 8.4a.757.757 0 01-1.025-.27l-1-1.732a.75.75 0 111.3-.75l1 1.732a.749.749 0 01-.275 1.02zm-5.044 1.932l1.732 1a.76.76 0 001.02-.274.749.749 0 00-.27-1.026l-1.732-1a.75.75 0 00-.75 1.3zM5.25 14.5a.75.75 0 01-.75.75h-2a.75.75 0 010-1.5h2a.75.75 0 01.75.75zm7.5-10a.75.75 0 00-1.5 0v2a.75.75 0 001.5 0v-2z"
      fill="currentColor"
    />
    <path
      d="M12 15.25a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5z"
      fill="currentColor"
    />
  </Icon>
)

Speedometer.displayName = 'SpeedometerIcon'

export default Speedometer
