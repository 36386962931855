import { Icon } from '@chakra-ui/icon'

const Eye = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 4.6c4.4-.062 8.787 3.014 11.432 5.924a2.21 2.21 0 01.003 2.947c-2.617 2.88-6.941 5.929-11.288 5.929h-.308c-4.332 0-8.657-3.049-11.271-5.928a2.211 2.211 0 010-2.948C3.213 7.615 7.6 4.537 12 4.6zm0 2.8a4.6 4.6 0 100 9.2 4.6 4.6 0 000-9.2z"
      fill="currentColor"
    />
    <path d="M12 14a2 2 0 100-4 2 2 0 000 4z" fill="currentColor" />
  </Icon>
)

Eye.displayName = 'EyeIcon'

export default Eye
