import { Icon } from '@chakra-ui/icon'

const TrophyStarAlternate = props => (
  <Icon {...props}>
    <path
      d="M15.6 18.5a.5.5 0 00.469-.676l-.143-.379A3.011 3.011 0 0013.5 15.53v-1.5a.5.5 0 00-.255-.436l-1-.563a.5.5 0 00-.49 0l-1 .564a.5.5 0 00-.254.435v1.5a3.013 3.013 0 00-2.423 1.915l-.142.379a.5.5 0 00.464.676h7.2zM15.09 12.334c.472.27 1.064.204 1.466-.161.401-.353.526-.927.309-1.415l-1.092-2.512a.5.5 0 01.126-.573l1.969-1.754c.362-.346.478-.877.293-1.343a1.2 1.2 0 00-1.14-.743h-2.212a.5.5 0 01-.459-.3L13.135.714a1.261 1.261 0 00-2.271-.001L9.648 3.531a.5.5 0 01-.459.3H6.977a1.2 1.2 0 00-1.138.741 1.244 1.244 0 00.312 1.364L8.1 7.674a.5.5 0 01.126.573l-1.091 2.511a1.226 1.226 0 00.31 1.416 1.25 1.25 0 001.466.16l2.844-1.6a.5.5 0 01.49 0l2.845 1.6zM7 20a2 2 0 100 4h10a2 2 0 100-4H7z"
      fill="currentColor"
    />
  </Icon>
)

TrophyStarAlternate.displayName = 'TrophyStarAlternateIcon'

export default TrophyStarAlternate
