import { Icon } from '@chakra-ui/icon'

const NoTokens = props => (
  <Icon viewBox="0 0 265 299" {...props}>
    <rect
      x="172.97"
      y="212.061"
      width="23.2727"
      height="23.2727"
      fill="#D8D8D8"
    />
    <rect x="76" y="22" width="116.364" height="23.2727" fill="#FBE700" />
    <rect
      x="52.7273"
      y="45.2728"
      width="23.2727"
      height="23.2727"
      fill="#FBE700"
    />
    <rect
      x="29.4546"
      y="68.5455"
      width="23.2727"
      height="162.909"
      fill="#FBE700"
    />
    <rect
      x="192.364"
      y="91.8182"
      width="23.2727"
      height="139.636"
      fill="#FAD703"
    />
    <rect
      x="52.7273"
      y="91.8182"
      width="23.2727"
      height="140.589"
      fill="#FAD703"
    />
    <rect
      x="99.2727"
      y="91.8182"
      width="69.8182"
      height="116.364"
      fill="#FBD801"
    />
    <rect
      x="192.364"
      y="45.2728"
      width="23.2727"
      height="23.2727"
      fill="#FBE700"
    />
    <rect
      x="169.091"
      y="68.5455"
      width="23.2727"
      height="23.2727"
      fill="#FCCC00"
    />
    <rect x="76" y="68.5455" width="23.2727" height="23.2727" fill="#FCCC00" />
    <rect
      x="169.091"
      y="208.182"
      width="23.2727"
      height="23.2727"
      fill="#FBE700"
    />
    <rect x="76" y="208.182" width="23.2727" height="23.2727" fill="#FBE700" />
    <rect
      x="99.2727"
      y="45.2728"
      width="93.0909"
      height="23.2727"
      fill="#FAD703"
    />
    <rect
      x="52.7273"
      y="231.455"
      width="139.636"
      height="23.2727"
      fill="#FAD703"
    />
    <rect
      x="99.2727"
      y="68.5455"
      width="69.8182"
      height="23.2727"
      fill="#F5CA05"
    />
    <rect x="76" y="91.8182" width="23.2727" height="116.364" fill="#F5CA05" />
    <rect
      x="99.2727"
      y="208.182"
      width="69.8182"
      height="23.2727"
      fill="#F5CA05"
    />
    <rect
      x="169.091"
      y="91.8182"
      width="23.2727"
      height="116.364"
      fill="#F5CA05"
    />
    <rect
      x="215.636"
      y="68.5455"
      width="23.2727"
      height="162.909"
      fill="#F5CA05"
    />
    <rect
      x="192.364"
      y="231.455"
      width="23.2727"
      height="23.2727"
      fill="#F5CA05"
    />
    <rect x="76" y="254.727" width="116.364" height="23.2727" fill="#F5CA05" />
    <rect x="76" y="45.2728" width="23.2727" height="23.2727" fill="#FFF267" />
    <rect
      x="52.7273"
      y="68.5455"
      width="23.2727"
      height="23.2727"
      fill="#FFF267"
    />
    <rect
      x="192.364"
      y="68.5455"
      width="23.2727"
      height="23.2727"
      fill="#FFF267"
    />
    <line
      x1="252.753"
      y1="11.2887"
      x2="11.2887"
      y2="287.247"
      stroke="#FF7060"
      strokeWidth="16"
      strokeLinecap="round"
    />
  </Icon>
)

NoTokens.displayName = 'NoTokensIcon'

export default NoTokens
