import { Icon } from '@chakra-ui/icon'

const Gauge = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.306 18.227A16.119 16.119 0 0011.52 15.6a16.12 16.12 0 00-8.786 2.627.48.48 0 01-.656-.115A11.514 11.514 0 0111.994.01 11.626 11.626 0 0123.04 11.402c.027 2.4-.7 4.746-2.078 6.71a.48.48 0 01-.656.116zM11.52 2.64a.72.72 0 00-.72.72V4.8a.72.72 0 001.44 0V3.36a.72.72 0 00-.72-.72zm-6.72 9.6H3.36a.72.72 0 010-1.44H4.8a.72.72 0 010 1.44zm1.968-4.752a.718.718 0 00.72-.72.72.72 0 00-.211-.51L6.259 5.242a.72.72 0 00-1.018 1.017l1.018 1.018a.718.718 0 00.509.211zm4.674 6.38a2.271 2.271 0 01-1.62-3.89c.727-.725 6.761-4.445 7.447-4.867a.48.48 0 01.66.66c-.422.686-4.142 6.72-4.868 7.445a2.281 2.281 0 01-1.619.652zm6.078-2.348c0 .398.322.72.72.72h1.44a.72.72 0 100-1.44h-1.44a.72.72 0 00-.72.72z"
      fill="currentColor"
    />
    <path
      d="M11.52 17.04a14.45 14.45 0 00-7.71 2.208.488.488 0 00-.073.762 11.496 11.496 0 0015.566 0 .487.487 0 00-.073-.761 14.444 14.444 0 00-7.71-2.209z"
      fill="currentColor"
    />
  </Icon>
)

Gauge.displayName = 'GaugeIcon'

export default Gauge
