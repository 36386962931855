import { createIcon } from '@chakra-ui/icon'

const Lemon = createIcon({
  displayName: 'Lemon',
  path: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.712 18.672C16.309 18.672 20.0989 16.1125 20.6255 12.8126C21.0644 12.7761 21.408 12.4477 21.408 12.048C21.408 11.6505 21.0684 11.3236 20.633 11.284C20.1361 7.96067 16.3316 5.37598 11.712 5.37598C7.06617 5.37598 3.24472 7.99009 2.78282 11.3407C2.47317 11.4574 2.25598 11.7302 2.25598 12.048C2.25598 12.3683 2.47657 12.6428 2.79007 12.758C3.28335 16.0842 7.08963 18.672 11.712 18.672Z"
        fill="#FFE215"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.712 18.6722C16.6693 18.6722 20.688 15.6958 20.688 12.0242C20.688 10.9848 20.366 10.0012 19.7918 9.125C19.393 12.2389 12.5505 18.4913 2.8858 13.2402C3.65718 16.3311 7.31545 18.6722 11.712 18.6722Z"
        fill="#FAD939"
        fillOpacity="0.48"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.712 18.6725C16.3076 18.6725 20.0966 16.1145 20.625 12.8161C20.6658 12.7268 21.4403 12.8287 21.3999 12.071C21.3595 11.3133 20.9798 11.4359 20.6263 11.2412C19.0196 17.8128 11.2902 18.6725 5.92773 17.1083C7.48992 18.0843 9.50821 18.6725 11.712 18.6725Z"
        fill="#FFCA13"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.856 14.9281C12.9164 14.9281 13.7683 13.6452 13.7683 13.115C11.808 12.6915 10.6231 12.9543 9.94464 13.115C9.94464 13.6452 10.7956 14.9281 11.856 14.9281Z"
        fill="#614137"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9054 14.464C12.6046 14.7081 12.2442 14.8801 11.856 14.8801C11.4833 14.8801 11.1364 14.7216 10.8433 14.493C10.9746 14.1342 11.389 13.8721 11.88 13.8721C12.3576 13.8721 12.7629 14.1201 12.9054 14.464Z"
        fill="#F7CBFC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.088 13.2957C8.68446 13.2957 9.168 12.8122 9.168 12.2157C9.168 11.6193 8.68446 11.1357 8.088 11.1357C7.49153 11.1357 7.008 11.6193 7.008 12.2157C7.008 12.8122 7.49153 13.2957 8.088 13.2957Z"
        fill="#614137"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.432 13.3436C16.0285 13.3436 16.512 12.8601 16.512 12.2636C16.512 11.6671 16.0285 11.1836 15.432 11.1836C14.8355 11.1836 14.352 11.6671 14.352 12.2636C14.352 12.8601 14.8355 13.3436 15.432 13.3436Z"
        fill="#614137"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.80801 12.336C8.9008 12.336 8.97601 12.2608 8.97601 12.168C8.97601 12.0752 8.9008 12 8.80801 12C8.71523 12 8.64001 12.0752 8.64001 12.168C8.64001 12.2608 8.71523 12.336 8.80801 12.336Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.70401 11.712C7.79679 11.712 7.87201 11.6368 7.87201 11.544C7.87201 11.4512 7.79679 11.376 7.70401 11.376C7.61123 11.376 7.53601 11.4512 7.53601 11.544C7.53601 11.6368 7.61123 11.712 7.70401 11.712Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.68798 12.5758C8.741 12.5758 8.78398 12.5328 8.78398 12.4798C8.78398 12.4268 8.741 12.3838 8.68798 12.3838C8.63496 12.3838 8.59198 12.4268 8.59198 12.4798C8.59198 12.5328 8.63496 12.5758 8.68798 12.5758Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.432 11.712C15.5248 11.712 15.6 11.6368 15.6 11.544C15.6 11.4512 15.5248 11.376 15.432 11.376C15.3392 11.376 15.264 11.4512 15.264 11.544C15.264 11.6368 15.3392 11.712 15.432 11.712Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.808 12.8165C14.9008 12.8165 14.976 12.7413 14.976 12.6485C14.976 12.5557 14.9008 12.4805 14.808 12.4805C14.7152 12.4805 14.64 12.5557 14.64 12.6485C14.64 12.7413 14.7152 12.8165 14.808 12.8165Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.696 11.7604C15.749 11.7604 15.792 11.7174 15.792 11.6644C15.792 11.6113 15.749 11.5684 15.696 11.5684C15.643 11.5684 15.6 11.6113 15.6 11.6644C15.6 11.7174 15.643 11.7604 15.696 11.7604Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.34403 13.8719C7.50309 13.8719 7.63203 13.743 7.63203 13.5839C7.63203 13.4248 7.50309 13.2959 7.34403 13.2959C7.18497 13.2959 7.05603 13.4248 7.05603 13.5839C7.05603 13.743 7.18497 13.8719 7.34403 13.8719Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.464 13.824C16.6231 13.824 16.752 13.6951 16.752 13.536C16.752 13.377 16.6231 13.248 16.464 13.248C16.305 13.248 16.176 13.377 16.176 13.536C16.176 13.6951 16.305 13.824 16.464 13.824Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.53696 10.1841C7.45317 10.1723 7.35509 10.1739 7.27366 10.2154C7.20024 10.2527 7.15251 10.3398 7.12375 10.4339C7.10998 10.479 7.10184 10.5215 7.09716 10.5528C7.09484 10.5684 7.0934 10.5811 7.09255 10.5897C7.09213 10.5939 7.09185 10.5972 7.09169 10.5993L7.09153 10.6015L7.0915 10.6018L7.0915 10.6019L7.08734 10.672L6.94696 10.6637L6.95112 10.5935L7.02131 10.5977C6.95112 10.5935 6.95112 10.5935 6.95112 10.5935L6.95114 10.5933L6.95116 10.593L6.95123 10.5919C6.95129 10.591 6.95137 10.5898 6.95149 10.5883C6.95172 10.5853 6.95208 10.5811 6.95261 10.5758C6.95365 10.5652 6.95537 10.5503 6.95808 10.5321C6.96347 10.4959 6.97293 10.4463 6.98926 10.3928C7.0207 10.2899 7.08319 10.1545 7.20987 10.09C7.32854 10.0296 7.46046 10.0313 7.55666 10.0449C7.60564 10.0518 7.64769 10.0622 7.67759 10.0708C7.69258 10.0752 7.70465 10.0791 7.71314 10.082C7.7174 10.0835 7.72076 10.0847 7.72317 10.0856C7.72437 10.0861 7.72533 10.0864 7.72605 10.0867L7.72693 10.087L7.72723 10.0871L7.72739 10.0872C7.72741 10.0872 7.72743 10.0872 7.70199 10.1521L7.72739 10.0872L7.79289 10.1129L7.74158 10.2438L7.67624 10.2182L7.67618 10.2182L7.676 10.2181L7.67444 10.2175C7.67294 10.217 7.67053 10.2161 7.66729 10.215C7.66079 10.2127 7.65099 10.2095 7.63849 10.2059C7.6134 10.1986 7.57796 10.1899 7.53696 10.1841Z"
        fill="#50313E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0048 10.1626C16.0886 10.1508 16.1867 10.1524 16.2681 10.1939C16.3415 10.2312 16.3892 10.3183 16.418 10.4124C16.4318 10.4575 16.4399 10.5 16.4446 10.5314C16.4469 10.547 16.4484 10.5596 16.4492 10.5682C16.4496 10.5724 16.4499 10.5757 16.4501 10.5778L16.4502 10.58L16.4502 10.5804L16.4502 10.5804L16.4544 10.6505L16.5948 10.6422L16.5906 10.572L16.5204 10.5762C16.5906 10.572 16.5906 10.5721 16.5906 10.572L16.5906 10.5718L16.5906 10.5715L16.5905 10.5704C16.5905 10.5695 16.5904 10.5683 16.5903 10.5668C16.59 10.5639 16.5897 10.5596 16.5891 10.5543C16.5881 10.5437 16.5864 10.5288 16.5837 10.5106C16.5783 10.4744 16.5688 10.4248 16.5525 10.3714C16.521 10.2685 16.4586 10.133 16.3319 10.0685C16.2132 10.0081 16.0813 10.0098 15.9851 10.0234C15.9361 10.0303 15.8941 10.0407 15.8642 10.0493C15.8492 10.0537 15.8371 10.0576 15.8286 10.0606C15.8244 10.062 15.821 10.0632 15.8186 10.0641C15.8174 10.0646 15.8164 10.0649 15.8157 10.0652L15.8148 10.0655L15.8145 10.0657L15.8144 10.0657C15.8143 10.0657 15.8143 10.0657 15.8398 10.1307L15.8144 10.0657L15.7489 10.0914L15.8002 10.2223L15.8655 10.1967L15.8656 10.1967L15.8658 10.1966L15.8673 10.196C15.8688 10.1955 15.8712 10.1946 15.8745 10.1935C15.881 10.1913 15.8908 10.188 15.9033 10.1844C15.9283 10.1772 15.9638 10.1684 16.0048 10.1626Z"
        fill="#50313E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.66401 12.239C2.73028 12.239 2.78401 12.1853 2.78401 12.119C2.78401 12.0527 2.73028 11.999 2.66401 11.999C2.59773 11.999 2.54401 12.0527 2.54401 12.119C2.54401 12.1853 2.59773 12.239 2.66401 12.239Z"
        fill="#FAD838"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.85602 11.8074C2.9223 11.8074 2.97602 11.7537 2.97602 11.6874C2.97602 11.6211 2.9223 11.5674 2.85602 11.5674C2.78975 11.5674 2.73602 11.6211 2.73602 11.6874C2.73602 11.7537 2.78975 11.8074 2.85602 11.8074Z"
        fill="#FAD838"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.09601 12.0955C3.16229 12.0955 3.21601 12.0417 3.21601 11.9755C3.21601 11.9092 3.16229 11.8555 3.09601 11.8555C3.02974 11.8555 2.97601 11.9092 2.97601 11.9755C2.97601 12.0417 3.02974 12.0955 3.09601 12.0955Z"
        fill="#FAD838"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.52802 12.1433C3.59429 12.1433 3.64802 12.0896 3.64802 12.0233C3.64802 11.957 3.59429 11.9033 3.52802 11.9033C3.46175 11.9033 3.40802 11.957 3.40802 12.0233C3.40802 12.0896 3.46175 12.1433 3.52802 12.1433Z"
        fill="#FAD838"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.76801 11.615C3.83428 11.615 3.88801 11.5613 3.88801 11.495C3.88801 11.4287 3.83428 11.375 3.76801 11.375C3.70174 11.375 3.64801 11.4287 3.64801 11.495C3.64801 11.5613 3.70174 11.615 3.76801 11.615Z"
        fill="#FAD838"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.76801 11.0398C3.83428 11.0398 3.88801 10.9861 3.88801 10.9198C3.88801 10.8535 3.83428 10.7998 3.76801 10.7998C3.70174 10.7998 3.64801 10.8535 3.64801 10.9198C3.64801 10.9861 3.70174 11.0398 3.76801 11.0398Z"
        fill="#FAD838"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.28803 11.0398C3.3543 11.0398 3.40803 10.9861 3.40803 10.9198C3.40803 10.8535 3.3543 10.7998 3.28803 10.7998C3.22176 10.7998 3.16803 10.8535 3.16803 10.9198C3.16803 10.9861 3.22176 11.0398 3.28803 11.0398Z"
        fill="#FAD838"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.47999 10.4636C3.54626 10.4636 3.59999 10.4099 3.59999 10.3436C3.59999 10.2774 3.54626 10.2236 3.47999 10.2236C3.41371 10.2236 3.35999 10.2774 3.35999 10.3436C3.35999 10.4099 3.41371 10.4636 3.47999 10.4636Z"
        fill="#FAD838"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.672 9.93531C3.73828 9.93531 3.792 9.88159 3.792 9.81531C3.792 9.74904 3.73828 9.69531 3.672 9.69531C3.60573 9.69531 3.552 9.74904 3.552 9.81531C3.552 9.88159 3.60573 9.93531 3.672 9.93531Z"
        fill="#FAD838"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.91199 10.2235C3.97827 10.2235 4.03199 10.1591 4.03199 10.0795C4.03199 10 3.97827 9.93555 3.91199 9.93555C3.84572 9.93555 3.79199 10 3.79199 10.0795C3.79199 10.1591 3.84572 10.2235 3.91199 10.2235Z"
        fill="#FAD838"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.344 10.2714C4.41027 10.2714 4.464 10.2069 4.464 10.1274C4.464 10.0479 4.41027 9.9834 4.344 9.9834C4.27772 9.9834 4.224 10.0479 4.224 10.1274C4.224 10.2069 4.27772 10.2714 4.344 10.2714Z"
        fill="#FAD838"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.53602 9.74391C4.60229 9.74391 4.65602 9.69018 4.65602 9.62391C4.65602 9.55763 4.60229 9.50391 4.53602 9.50391C4.46974 9.50391 4.41602 9.55763 4.41602 9.62391C4.41602 9.69018 4.46974 9.74391 4.53602 9.74391Z"
        fill="#FAD838"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.68 9.11988C4.74627 9.11988 4.8 9.06616 4.8 8.99988C4.8 8.93361 4.74627 8.87988 4.68 8.87988C4.61372 8.87988 4.56 8.93361 4.56 8.99988C4.56 9.06616 4.61372 9.11988 4.68 9.11988Z"
        fill="#FAD838"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.10401 9.11988C4.17028 9.11988 4.22401 9.06616 4.22401 8.99988C4.22401 8.93361 4.17028 8.87988 4.10401 8.87988C4.03773 8.87988 3.98401 8.93361 3.98401 8.99988C3.98401 9.06616 4.03773 9.11988 4.10401 9.11988Z"
        fill="#FAD838"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.10401 9.11988C4.17028 9.11988 4.22401 9.06616 4.22401 8.99988C4.22401 8.93361 4.17028 8.87988 4.10401 8.87988C4.03773 8.87988 3.98401 8.93361 3.98401 8.99988C3.98401 9.06616 4.03773 9.11988 4.10401 9.11988Z"
        fill="#FAD838"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.10401 9.11988C4.17028 9.11988 4.22401 9.06616 4.22401 8.99988C4.22401 8.93361 4.17028 8.87988 4.10401 8.87988C4.03773 8.87988 3.98401 8.93361 3.98401 8.99988C3.98401 9.06616 4.03773 9.11988 4.10401 9.11988Z"
        fill="#FAD838"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.72803 8.39918C4.79431 8.39918 4.84803 8.34545 4.84803 8.27918C4.84803 8.21291 4.79431 8.15918 4.72803 8.15918C4.66176 8.15918 4.60803 8.21291 4.60803 8.27918C4.60803 8.34545 4.66176 8.39918 4.72803 8.39918Z"
        fill="#FAD838"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.448 8.78297C5.51428 8.78297 5.568 8.72924 5.568 8.66297C5.568 8.59669 5.51428 8.54297 5.448 8.54297C5.38173 8.54297 5.328 8.59669 5.328 8.66297C5.328 8.72924 5.38173 8.78297 5.448 8.78297Z"
        fill="#FAD838"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.15998 8.11109C5.22625 8.11109 5.27998 8.05737 5.27998 7.99109C5.27998 7.92482 5.22625 7.87109 5.15998 7.87109C5.0937 7.87109 5.03998 7.92482 5.03998 7.99109C5.03998 8.05737 5.0937 8.11109 5.15998 8.11109Z"
        fill="#FAD838"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.59198 7.67945C5.65826 7.67945 5.71198 7.62573 5.71198 7.55945C5.71198 7.49318 5.65826 7.43945 5.59198 7.43945C5.52571 7.43945 5.47198 7.49318 5.47198 7.55945C5.47198 7.62573 5.52571 7.67945 5.59198 7.67945Z"
        fill="#FAD838"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.92798 7.77516C5.99426 7.77516 6.04798 7.72143 6.04798 7.65516C6.04798 7.58888 5.99426 7.53516 5.92798 7.53516C5.86171 7.53516 5.80798 7.58888 5.80798 7.65516C5.80798 7.72143 5.86171 7.77516 5.92798 7.77516Z"
        fill="#FAD838"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.07203 8.20792C6.1383 8.20792 6.19203 8.14345 6.19203 8.06392C6.19203 7.98439 6.1383 7.91992 6.07203 7.91992C6.00575 7.91992 5.95203 7.98439 5.95203 8.06392C5.95203 8.14345 6.00575 8.20792 6.07203 8.20792Z"
        fill="#FAD838"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.456 7.72745C6.52227 7.72745 6.576 7.66298 6.576 7.58345C6.576 7.50392 6.52227 7.43945 6.456 7.43945C6.38972 7.43945 6.336 7.50392 6.336 7.58345C6.336 7.66298 6.38972 7.72745 6.456 7.72745Z"
        fill="#FAD838"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.84003 7.87101C6.90631 7.87101 6.96003 7.80654 6.96003 7.72701C6.96003 7.64748 6.90631 7.58301 6.84003 7.58301C6.77376 7.58301 6.72003 7.64748 6.72003 7.72701C6.72003 7.80654 6.77376 7.87101 6.84003 7.87101Z"
        fill="#FAD838"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4 16.7048C17.4662 16.7048 17.52 16.6511 17.52 16.5848C17.52 16.5186 17.4662 16.4648 17.4 16.4648C17.3337 16.4648 17.28 16.5186 17.28 16.5848C17.28 16.6511 17.3337 16.7048 17.4 16.7048Z"
        fill="#FAD939"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.2159 16.5125C18.2822 16.5125 18.3359 16.4587 18.3359 16.3925C18.3359 16.3262 18.2822 16.2725 18.2159 16.2725C18.1497 16.2725 18.0959 16.3262 18.0959 16.3925C18.0959 16.4587 18.1497 16.5125 18.2159 16.5125Z"
        fill="#FAD939"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.408 16.1765C18.4742 16.1765 18.528 16.1228 18.528 16.0565C18.528 15.9902 18.4742 15.9365 18.408 15.9365C18.3417 15.9365 18.288 15.9902 18.288 16.0565C18.288 16.1228 18.3417 16.1765 18.408 16.1765Z"
        fill="#FAD939"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.832 16.1765C17.8982 16.1765 17.952 16.1228 17.952 16.0565C17.952 15.9902 17.8982 15.9365 17.832 15.9365C17.7657 15.9365 17.712 15.9902 17.712 16.0565C17.712 16.1228 17.7657 16.1765 17.832 16.1765Z"
        fill="#FAD939"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.736 15.7927C17.8022 15.7927 17.856 15.739 17.856 15.6727C17.856 15.6065 17.8022 15.5527 17.736 15.5527C17.6697 15.5527 17.616 15.6065 17.616 15.6727C17.616 15.739 17.6697 15.7927 17.736 15.7927Z"
        fill="#FAD939"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.6 15.073C18.6663 15.073 18.72 15.0193 18.72 14.953C18.72 14.8867 18.6663 14.833 18.6 14.833C18.5337 14.833 18.48 14.8867 18.48 14.953C18.48 15.0193 18.5337 15.073 18.6 15.073Z"
        fill="#FAD939"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.744 15.5046C18.8102 15.5046 18.864 15.4509 18.864 15.3846C18.864 15.3184 18.8102 15.2646 18.744 15.2646C18.6777 15.2646 18.624 15.3184 18.624 15.3846C18.624 15.4509 18.6777 15.5046 18.744 15.5046Z"
        fill="#FAD939"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.32 15.4568C19.3862 15.4568 19.44 15.4031 19.44 15.3368C19.44 15.2705 19.3862 15.2168 19.32 15.2168C19.2537 15.2168 19.2 15.2705 19.2 15.3368C19.2 15.4031 19.2537 15.4568 19.32 15.4568Z"
        fill="#FAD939"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.272 14.9285C19.3383 14.9285 19.392 14.8748 19.392 14.8085C19.392 14.7422 19.3383 14.6885 19.272 14.6885C19.2057 14.6885 19.152 14.7422 19.152 14.8085C19.152 14.8748 19.2057 14.9285 19.272 14.9285Z"
        fill="#FAD939"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.704 14.8328C19.7703 14.8328 19.824 14.779 19.824 14.7128C19.824 14.6465 19.7703 14.5928 19.704 14.5928C19.6377 14.5928 19.584 14.6465 19.584 14.7128C19.584 14.779 19.6377 14.8328 19.704 14.8328Z"
        fill="#FAD939"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.704 14.2087C19.7703 14.2087 19.824 14.155 19.824 14.0887C19.824 14.0225 19.7703 13.9688 19.704 13.9688C19.6377 13.9688 19.584 14.0225 19.584 14.0887C19.584 14.155 19.6377 14.2087 19.704 14.2087Z"
        fill="#FAD939"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9919 14.4011C20.0582 14.4011 20.1119 14.3474 20.1119 14.2811C20.1119 14.2149 20.0582 14.1611 19.9919 14.1611C19.9257 14.1611 19.8719 14.2149 19.8719 14.2811C19.8719 14.3474 19.9257 14.4011 19.9919 14.4011Z"
        fill="#FAD939"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.2319 13.2008C20.2982 13.2008 20.3519 13.1578 20.3519 13.1048C20.3519 13.0518 20.2982 13.0088 20.2319 13.0088C20.1657 13.0088 20.1119 13.0518 20.1119 13.1048C20.1119 13.1578 20.1657 13.2008 20.2319 13.2008Z"
        fill="#FAD939"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.568 12.7211C20.6343 12.7211 20.688 12.6889 20.688 12.6491C20.688 12.6094 20.6343 12.5771 20.568 12.5771C20.5017 12.5771 20.448 12.6094 20.448 12.6491C20.448 12.6889 20.5017 12.7211 20.568 12.7211Z"
        fill="#FAD939"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.952 12.2407C21.0182 12.2407 21.072 12.2084 21.072 12.1687C21.072 12.1289 21.0182 12.0967 20.952 12.0967C20.8857 12.0967 20.832 12.1289 20.832 12.1687C20.832 12.2084 20.8857 12.2407 20.952 12.2407Z"
        fill="#FAD939"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.9999 12.5289C21.0662 12.5289 21.1199 12.4859 21.1199 12.4329C21.1199 12.3799 21.0662 12.3369 20.9999 12.3369C20.9337 12.3369 20.8799 12.3799 20.8799 12.4329C20.8799 12.4859 20.9337 12.5289 20.9999 12.5289Z"
        fill="#FAD939"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.4696 6.44727L15.5145 6.46053L15.5013 6.50549C15.5145 6.46053 15.5145 6.46052 15.5145 6.46053L15.5148 6.4606L15.5155 6.4608L15.518 6.46155C15.5201 6.4622 15.5233 6.46318 15.5275 6.46447C15.5359 6.46707 15.5483 6.47095 15.5644 6.47615C15.5967 6.48656 15.6438 6.50227 15.7039 6.52357C15.824 6.56618 15.9958 6.63119 16.203 6.72097C16.6173 6.90049 17.1739 7.1793 17.7427 7.57654C18.3126 7.97456 18.7504 8.42875 19.0457 8.78323C19.1934 8.96054 19.3056 9.11308 19.381 9.22145C19.4186 9.27564 19.4471 9.31881 19.4663 9.34852C19.4758 9.36337 19.483 9.37487 19.4879 9.3827C19.4903 9.38661 19.4922 9.38961 19.4934 9.39166L19.4948 9.394L19.4952 9.39463L19.4953 9.39481L19.4954 9.39486C19.4954 9.39488 19.4954 9.39489 19.4552 9.419L19.4954 9.39486L19.5195 9.43509L19.4391 9.48331L19.415 9.44312L19.4149 9.443L19.4146 9.44252L19.4134 9.44048C19.4123 9.43864 19.4105 9.43585 19.4082 9.43214C19.4036 9.42473 19.3967 9.41367 19.3874 9.39925C19.3689 9.37042 19.341 9.32819 19.304 9.27497C19.23 9.16853 19.1194 9.01818 18.9737 8.84323C18.6821 8.49319 18.2503 8.04537 17.689 7.6534C17.1266 7.26065 16.5759 6.98472 16.1657 6.80699C15.9607 6.71815 15.7909 6.65391 15.6726 6.61193C15.6134 6.59094 15.5671 6.57551 15.5356 6.56536C15.5199 6.56029 15.5079 6.55653 15.4999 6.55406C15.4959 6.55282 15.4929 6.55191 15.4909 6.5513L15.4887 6.55064L15.4881 6.55048L15.4431 6.53719L15.4696 6.44727Z"
        fill="#FFF97A"
      />
    </>
  ),
})

export default Lemon
