import { Icon } from '@chakra-ui/icon'

const StatsPieChartTwo = props => (
  <Icon {...props}>
    <path
      d="M23 10.5a.5.5 0 00.5-.5c-.007-5.52-4.48-9.993-10-10a.5.5 0 00-.5.5v9.025c0 .538.437.975.975.976L23 10.5zM23.5 12H14a.677.677 0 00-.5 1.207l6.718 6.718a.5.5 0 00.707 0A10.433 10.433 0 0024 12.5a.5.5 0 00-.5-.5z"
      fill="currentColor"
    />
    <path
      d="M10.588 24a10.322 10.322 0 007.337-3.084.5.5 0 000-.707l-6.718-6.718a.91.91 0 01-.207-.5v-9.5a.5.5 0 00-.5-.5A10.505 10.505 0 1010.588 24z"
      fill="currentColor"
    />
  </Icon>
)

StatsPieChartTwo.displayName = 'StatsPieChartTwoIcon'

export default StatsPieChartTwo
