import { Icon } from '@chakra-ui/icon'

const SizeShrink = props => (
  <Icon {...props}>
    <path
      d="M23.384.615a1.251 1.251 0 00-1.768 0L18.2 4.027a.251.251 0 01-.354 0L16.525 2.7a1 1 0 00-1.707.707v4.774a1 1 0 001 1h4.773a1 1 0 00.709-1.707l-1.328-1.326a.25.25 0 010-.353l3.412-3.412a1.25 1.25 0 000-1.768zM.616 23.383a1.25 1.25 0 001.768 0L5.8 19.971a.251.251 0 01.354 0L7.475 21.3a1 1 0 001.707-.707v-4.776a1 1 0 00-1-1H3.409a1 1 0 00-.709 1.707l1.328 1.326a.25.25 0 010 .353L.616 21.615a1.251 1.251 0 000 1.768zM14.818 20.59a1 1 0 001.707.707l1.326-1.326a.251.251 0 01.354 0l3.411 3.412a1.25 1.25 0 001.768-1.768L19.972 18.2a.25.25 0 010-.353l1.328-1.323a1 1 0 00-.707-1.707h-4.775a1 1 0 00-1 1v4.773zM9.182 3.408A1 1 0 007.475 2.7L6.149 4.027a.251.251 0 01-.354 0L2.384.615A1.25 1.25 0 00.616 2.383L4.028 5.8a.25.25 0 010 .353L2.7 7.474a1 1 0 00.707 1.707h4.775a1 1 0 001-1V3.408z"
      fill="currentColor"
    />
  </Icon>
)

SizeShrink.displayName = 'SizeShrinkIcon'

export default SizeShrink
