import { Icon } from '@chakra-ui/icon'

const Lock = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.5 9.5h-.75V6.75a6.75 6.75 0 00-13.5 0V9.5H4.5a2 2 0 00-2 2V22a2 2 0 002 2h15a2 2 0 002-2V11.5a2 2 0 00-2-2zm-9.5 6a2 2 0 113 1.723V19.5a1 1 0 11-2 0v-2.277a1.994 1.994 0 01-1-1.723zm2-13a4.25 4.25 0 00-4.25 4.25V9a.5.5 0 00.5.5h7.5a.5.5 0 00.5-.5V6.75A4.25 4.25 0 0012 2.5z"
      fill="currentColor"
    />
  </Icon>
)

Lock.displayName = 'LockIcon'

export default Lock
