import { Icon } from '@chakra-ui/icon'

const Rectangle = props => (
  <Icon {...props}>
    <path
      d="M0 1.5A1.5 1.5 0 011.5 0h21A1.5 1.5 0 0124 1.5v21a1.5 1.5 0 01-1.5 1.5h-21A1.5 1.5 0 010 22.5v-21z"
      fill="currentColor"
    />
  </Icon>
)

Rectangle.displayName = 'RectangleIcon'

export default Rectangle
