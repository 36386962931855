import { Icon } from '@chakra-ui/icon'

const RibbonStarTwo = props => (
  <Icon {...props}>
    <path
      d="M23.366 19.766l-3-5.18a.25.25 0 00-.439.011 4.663 4.663 0 01-4.317 2.548.25.25 0 00-.191.078 4.505 4.505 0 01-.963.765.251.251 0 00-.086.341l2.942 4.978a1 1 0 001.8-.17l.832-2.288 2.392.405a1 1 0 001.031-1.488h-.001zM8.583 17.224a.25.25 0 00-.191-.078A4.666 4.666 0 014.074 14.6a.251.251 0 00-.439-.011l-3 5.181a1 1 0 001.032 1.487l2.391-.405.833 2.289a1 1 0 001.8.167l2.941-4.976a.251.251 0 00-.086-.341 4.552 4.552 0 01-.963-.767z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.01 14.72a3.17 3.17 0 01-3.197.78 3.169 3.169 0 01-5.626 0 3.17 3.17 0 01-3.979-3.981 3.169 3.169 0 010-5.625 3.169 3.169 0 013.978-3.975 3.17 3.17 0 015.627 0 3.17 3.17 0 013.978 3.978 3.169 3.169 0 010 5.625 3.17 3.17 0 01-.78 3.198zM10.08 9.545l-.774 1.775a.46.46 0 00.653.574l1.907-1.075a.249.249 0 01.245 0l1.912 1.075a.458.458 0 00.652-.574l-.773-1.775a.25.25 0 01.054-.278l1.4-1.374a.428.428 0 00-.3-.748h-1.591A.251.251 0 0113.237 7l-.833-1.793a.463.463 0 00-.824 0L10.747 7a.25.25 0 01-.231.145H8.925a.428.428 0 00-.3.748l1.4 1.374a.252.252 0 01.054.278z"
      fill="currentColor"
    />
  </Icon>
)

RibbonStarTwo.displayName = 'RibbonStarTwoIcon'

export default RibbonStarTwo
