import { Icon } from '@chakra-ui/icon'

const CursorAlternateOutline = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.382 2.648l8.272 20.028a1.736 1.736 0 003.274-.188l2.348-8.215 8.211-2.346a1.737 1.737 0 00.19-3.276L2.651.38A1.737 1.737 0 00.382 2.648zm3.296 1.027a.25.25 0 01.272-.054l15.085 6.227a.25.25 0 01-.026.472l-5.82 1.662a1.745 1.745 0 00-1.2 1.194L10.324 19a.25.25 0 01-.472.029L3.623 3.947a.25.25 0 01.055-.272z"
      fill="currentColor"
    />
  </Icon>
)

CursorAlternateOutline.displayName = 'CursorAlternateOutlineIcon'

export default CursorAlternateOutline
