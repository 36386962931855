import { Icon } from '@chakra-ui/icon'

const Users = props => (
  <Icon {...props}>
    <path
      d="M20.095 16.6l-.542-.2a.601.601 0 01-.131-.308 2.669 2.669 0 01.055-1.3 5.765 5.765 0 001.407-4.312 3.755 3.755 0 00-.945-2.833A3.446 3.446 0 0017.28 6.5a3.678 3.678 0 00-1.55.348.251.251 0 00-.143.221 8.94 8.94 0 01-1.622 5.349.26.26 0 00-.041.217c.064.252.147.498.248.737.025.062.075.11.136.134 2.337.9 5.376 2.392 5.376 6.994V22c0 .073-.005.146-.015.218a.258.258 0 00.059.197.237.237 0 00.18.085h3.612A.49.49 0 0024 22v-.853c0-3.101-2.075-3.871-3.905-4.547z"
      fill="currentColor"
    />
    <path
      d="M18.24 20.5c0-3.948-2.662-4.935-5.012-5.806l-.71-.266c-.064-.025-.159-.142-.232-.4a3.491 3.491 0 01.078-1.95c.91-1.045 1.8-2.333 1.8-5.536a4.76 4.76 0 00-1.196-3.587A4.369 4.369 0 009.6 1.5a4.369 4.369 0 00-3.369 1.456 4.76 4.76 0 00-1.196 3.589c0 3.2.89 4.491 1.796 5.529.215.63.243 1.311.081 1.957-.073.255-.168.372-.232.4-.242.093-.487.184-.732.274-2.338.862-4.992 1.84-4.992 5.8V22c0 .276.215.5.48.5h16.32a.47.47 0 00.342-.145.51.51 0 00.142-.355v-1.5z"
      fill="currentColor"
    />
  </Icon>
)

Users.displayName = 'UsersIcon'

export default Users
