import { Icon } from '@chakra-ui/icon'

const Crown = props => (
  <Icon {...props}>
    <path
      d="M3.45 17.25c-1.101.218-1.864 1.271-1.763 2.434.1 1.163 1.032 2.057 2.153 2.066H19.2a2.066 2.066 0 001.612-.723c.352-.424.543-.967.537-1.527.053-1.107-.704-2.077-1.755-2.25H3.45zM19.392 16.25a.485.485 0 00.471-.4l1.152-6.11c1.035-.063 1.882-.88 2.021-1.95.14-1.07-.468-2.092-1.449-2.44-.98-.348-2.06.077-2.577 1.013-.516.936-.323 2.122.46 2.829l-2.328 2.767a.472.472 0 01-.355.17.47.47 0 01-.36-.161l-3.896-4.429c1.163-.502 1.824-1.79 1.578-3.077s-1.33-2.213-2.589-2.213c-1.26 0-2.343.926-2.59 2.213-.245 1.286.416 2.575 1.58 3.077l-3.886 4.415a.471.471 0 01-.362.162.473.473 0 01-.356-.174L3.608 9.158a2.313 2.313 0 00.388-2.827c-.536-.917-1.621-1.31-2.589-.937C.44 5.767-.138 6.8.03 7.862c.166 1.06 1.03 1.849 2.062 1.881l1.105 6.1c.043.236.24.407.471.407h15.725z"
      fill="currentColor"
    />
  </Icon>
)

Crown.displayName = 'CrownIcon'

export default Crown
