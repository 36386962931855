import { AUTH_STORAGE_KEY } from 'ui/types'

const DIRECT_UPLOAD_PATHNAME = '/multi_platform/direct_uploads'

/* The Direct Upload machinery is provided by an NPM package out of our control.
   That package makes an API request that needs authentication, so we patch XMLHttpRequest
   to attach the auth token if that is the request being made.  However, we don't know the
   server location until a user has logged in, and it's preferential to make this patch at 
   the root of the app for visibility.

   * Expose a method for getting the Direct Upload URL based on local storage endpoint value
   * Compare pathname of XMLHttpRequest to DIRECT_UPLOAD_PATHNAME and add header if matches
*/

export const getDirectUploadURL = () => {
  const auth = JSON.parse(
    window?.localStorage.getItem(AUTH_STORAGE_KEY) || '{}',
  )
  const hostname = auth.endpoint || process.env.ARCADE_API_ENDPOINT

  return hostname + DIRECT_UPLOAD_PATHNAME
}

export const monkeyPatchXMLHttpRequest = () => {
  const nativeSend = XMLHttpRequest.prototype.send
  const nativeOpen = XMLHttpRequest.prototype.open

  XMLHttpRequest.prototype.open = function () {
    this.pathname = new URL(arguments[1]).pathname
    return nativeOpen.apply(this, arguments)
  }

  XMLHttpRequest.prototype.send = function () {
    if (this.pathname === DIRECT_UPLOAD_PATHNAME) {
      const auth = JSON.parse(localStorage.getItem(AUTH_STORAGE_KEY) || '{}')
      this.setRequestHeader('X-User-Token', auth.token)
    }

    return nativeSend.apply(this, arguments)
  }
}
