import { Icon } from '@chakra-ui/icon'

const Posts = props => (
  <Icon viewBox="0 0 18 18" {...props}>
    <path
      d="M13 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V5L13 0ZM16 16H2V2H12V6H16V16ZM4 14H14V12H4V14ZM9 4H4V6H9V4ZM4 10H14V8H4V10Z"
      fill="currentColor"
    />
  </Icon>
)

Posts.displayName = 'PostsIcon'

export default Posts
