import { Icon } from '@chakra-ui/icon'

const Logout = props => (
  <Icon {...props}>
    <path
      d="M13.5 17.5a1 1 0 00-1 1V21a.5.5 0 01-.5.5H2.5A.5.5 0 012 21V3a.5.5 0 01.5-.5H12a.5.5 0 01.5.5v2.5a1 1 0 102 0v-3a2 2 0 00-2-2H2a2 2 0 00-2 2v19a2 2 0 002 2h10.5a2 2 0 002-2v-3a1 1 0 00-1-1z"
      fill="currentColor"
    />
    <path
      d="M23.555 11.669l-6-4A1 1 0 0016 8.5V11H7a1.5 1.5 0 000 3h9v2.5c.008.55.45.992 1 1a1 1 0 00.555-.168l6-4a1 1 0 000-1.664v.001z"
      fill="currentColor"
    />
  </Icon>
)

Logout.displayName = 'LogoutIcon'

export default Logout
