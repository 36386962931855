import { Icon } from '@chakra-ui/icon'

const TokensSmall = props => (
  <Icon viewBox="0 0 256 256" {...props}>
    <rect x="122.136" y="59" width="56.6819" height="11.7053" fill="#FCCC00" />
    <rect
      x="122.136"
      y="140.449"
      width="56.6819"
      height="11.7053"
      fill="#E89600"
    />
    <rect
      x="122.136"
      y="129.231"
      width="56.6819"
      height="11.7053"
      fill="#F5AD00"
    />
    <rect
      x="122.136"
      y="70.7053"
      width="11.7273"
      height="11.7053"
      fill="#FFF267"
    />
    <rect
      x="110.409"
      y="70.7053"
      width="11.7273"
      height="11.7053"
      fill="#FCCC00"
    />
    <rect
      x="98.6819"
      y="82.4104"
      width="11.7273"
      height="46.8211"
      fill="#FCCC00"
    />
    <rect
      x="110.409"
      y="82.4104"
      width="11.7273"
      height="11.7053"
      fill="#FFF267"
    />
    <rect
      x="110.409"
      y="94.116"
      width="11.7273"
      height="35.1158"
      fill="#F5AD00"
    />
    <rect
      x="110.409"
      y="129.231"
      width="11.7273"
      height="11.7053"
      fill="#F5AD00"
    />
    <rect
      x="122.136"
      y="82.4104"
      width="11.7273"
      height="11.7053"
      fill="#FCCC00"
    />
    <rect
      x="122.136"
      y="94.116"
      width="11.7273"
      height="23.4105"
      fill="#E89600"
    />
    <rect
      x="167.091"
      y="94.116"
      width="11.7273"
      height="23.4105"
      fill="#E89600"
    />
    <rect
      x="122.136"
      y="117.526"
      width="11.7273"
      height="11.7053"
      fill="#FCCC00"
    />
    <rect
      x="167.091"
      y="117.526"
      width="11.7273"
      height="11.7053"
      fill="#FCCC00"
    />
    <rect
      x="133.864"
      y="82.4104"
      width="33.2273"
      height="11.7053"
      fill="#E89600"
    />
    <rect
      x="133.864"
      y="117.526"
      width="33.2273"
      height="11.7053"
      fill="#E89600"
    />
    <rect
      x="133.864"
      y="94.116"
      width="33.2273"
      height="23.4105"
      fill="#FCD303"
    />
    <rect
      x="133.864"
      y="70.7053"
      width="44.9546"
      height="11.7053"
      fill="#F5AD00"
    />
    <rect
      x="167.091"
      y="82.4104"
      width="11.7273"
      height="11.7053"
      fill="#FCCC00"
    />
    <rect
      x="178.818"
      y="70.7053"
      width="11.7273"
      height="11.7053"
      fill="#FCCC00"
    />
    <rect
      x="178.818"
      y="82.4104"
      width="11.7273"
      height="11.7053"
      fill="#FFF267"
    />
    <rect
      x="178.818"
      y="94.116"
      width="11.7273"
      height="35.1158"
      fill="#F5AD00"
    />
    <rect
      x="178.818"
      y="129.231"
      width="11.7273"
      height="11.7053"
      fill="#E89600"
    />
    <rect
      x="190.546"
      y="82.4104"
      width="11.7273"
      height="46.8211"
      fill="#FCCC00"
    />
    <rect
      x="65.4543"
      y="81.4353"
      width="56.6819"
      height="11.7053"
      fill="#FDD200"
    />
    <rect
      x="65.4543"
      y="162.884"
      width="56.6819"
      height="11.7053"
      fill="#F5A004"
    />
    <rect
      x="65.4543"
      y="151.667"
      width="56.6819"
      height="11.7053"
      fill="#F5AD00"
    />
    <rect
      x="65.4543"
      y="93.1404"
      width="11.7273"
      height="11.7053"
      fill="#FFF267"
    />
    <rect
      x="53.7273"
      y="93.1404"
      width="11.7273"
      height="11.7053"
      fill="#FDD200"
    />
    <rect x="42" y="104.846" width="11.7273" height="46.8211" fill="#FDD200" />
    <rect
      x="53.7273"
      y="104.846"
      width="11.7273"
      height="11.7053"
      fill="#FFF267"
    />
    <rect
      x="53.7273"
      y="116.551"
      width="11.7273"
      height="35.1158"
      fill="#F5AD00"
    />
    <rect
      x="53.7273"
      y="151.667"
      width="11.7273"
      height="11.7053"
      fill="#F5AD00"
    />
    <rect
      x="65.4543"
      y="104.846"
      width="11.7273"
      height="11.7053"
      fill="#FDD200"
    />
    <rect
      x="65.4543"
      y="116.551"
      width="11.7273"
      height="23.4105"
      fill="#F5A004"
    />
    <rect
      x="110.409"
      y="116.551"
      width="11.7273"
      height="23.4105"
      fill="#F5A004"
    />
    <rect
      x="65.4543"
      y="139.961"
      width="11.7273"
      height="11.7053"
      fill="#FDD200"
    />
    <rect
      x="110.409"
      y="139.961"
      width="11.7273"
      height="11.7053"
      fill="#FDD200"
    />
    <rect
      x="77.1821"
      y="104.846"
      width="33.2273"
      height="11.7053"
      fill="#F5A004"
    />
    <rect
      x="77.1821"
      y="139.961"
      width="33.2273"
      height="11.7053"
      fill="#F5A004"
    />
    <rect
      x="77.1821"
      y="116.551"
      width="33.2273"
      height="23.4105"
      fill="#FCD303"
    />
    <rect
      x="77.1821"
      y="93.1404"
      width="44.9546"
      height="11.7053"
      fill="#F5AD00"
    />
    <rect
      x="110.409"
      y="104.846"
      width="11.7273"
      height="11.7053"
      fill="#FDD200"
    />
    <rect
      x="122.136"
      y="93.1404"
      width="11.7273"
      height="11.7053"
      fill="#FDD200"
    />
    <rect
      x="122.136"
      y="104.846"
      width="11.7273"
      height="11.7053"
      fill="#FFF267"
    />
    <rect
      x="122.136"
      y="116.551"
      width="11.7273"
      height="35.1158"
      fill="#F5AD00"
    />
    <rect
      x="122.136"
      y="151.667"
      width="11.7273"
      height="11.7053"
      fill="#F5A004"
    />
    <rect
      x="133.864"
      y="104.846"
      width="11.7273"
      height="46.8211"
      fill="#FDD200"
    />
    <rect
      x="133.864"
      y="104.846"
      width="56.6819"
      height="11.7053"
      fill="#FBE700"
    />
    <rect
      x="133.864"
      y="186.295"
      width="56.6819"
      height="11.7053"
      fill="#F5CA05"
    />
    <rect
      x="133.864"
      y="175.077"
      width="56.6819"
      height="11.7053"
      fill="#FAD703"
    />
    <rect
      x="133.864"
      y="116.551"
      width="11.7273"
      height="11.7053"
      fill="#FFF267"
    />
    <rect
      x="122.136"
      y="116.551"
      width="11.7273"
      height="11.7053"
      fill="#FBE700"
    />
    <rect
      x="110.409"
      y="128.256"
      width="11.7273"
      height="46.8211"
      fill="#FBE700"
    />
    <rect
      x="122.136"
      y="128.256"
      width="11.7273"
      height="11.7053"
      fill="#FFF267"
    />
    <rect
      x="122.136"
      y="139.961"
      width="11.7273"
      height="35.1158"
      fill="#FAD703"
    />
    <rect
      x="122.136"
      y="175.077"
      width="11.7273"
      height="11.7053"
      fill="#FAD703"
    />
    <rect
      x="133.864"
      y="128.256"
      width="11.7273"
      height="11.7053"
      fill="#FBE700"
    />
    <rect
      x="133.864"
      y="139.961"
      width="11.7273"
      height="23.4105"
      fill="#F5CA05"
    />
    <rect
      x="178.818"
      y="139.961"
      width="11.7273"
      height="23.4105"
      fill="#F5CA05"
    />
    <rect
      x="133.864"
      y="163.372"
      width="11.7273"
      height="11.7053"
      fill="#FBE700"
    />
    <rect
      x="178.818"
      y="163.372"
      width="11.7273"
      height="11.7053"
      fill="#FBE700"
    />
    <rect
      x="145.591"
      y="128.256"
      width="33.2273"
      height="11.7053"
      fill="#F5CA05"
    />
    <rect
      x="145.591"
      y="163.372"
      width="33.2273"
      height="11.7053"
      fill="#F5CA05"
    />
    <rect
      x="145.591"
      y="139.961"
      width="33.2273"
      height="23.4105"
      fill="#FCD303"
    />
    <rect
      x="145.591"
      y="116.551"
      width="44.9546"
      height="11.7053"
      fill="#FAD703"
    />
    <rect
      x="178.818"
      y="128.256"
      width="11.7273"
      height="11.7053"
      fill="#FBE700"
    />
    <rect
      x="190.546"
      y="116.551"
      width="11.7273"
      height="11.7053"
      fill="#FBE700"
    />
    <rect
      x="190.546"
      y="128.256"
      width="11.7273"
      height="11.7053"
      fill="#FFF267"
    />
    <rect
      x="190.546"
      y="139.961"
      width="11.7273"
      height="35.1158"
      fill="#FAD703"
    />
    <rect
      x="190.546"
      y="175.077"
      width="11.7273"
      height="11.7053"
      fill="#F5CA05"
    />
    <rect
      x="202.273"
      y="128.256"
      width="11.7273"
      height="46.8211"
      fill="#FBE700"
    />
  </Icon>
)

TokensSmall.displayName = 'TokensSmallIcon'

export default TokensSmall
