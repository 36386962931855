import { Icon } from '@chakra-ui/icon'

const MessageBubblesOutline = props => (
  <Icon {...props}>
    <path
      d="M7.189 13.121a8.152 8.152 0 01-.638-.164 1 1 0 00-.662.028l-2.12.849.49-.859a1 1 0 00-.243-1.275A5.217 5.217 0 012 7.633C2 4.527 5.2 2 9.13 2c3.93 0 7.131 2.526 7.131 5.632a1 1 0 102 0C18.261 3.424 14.165 0 9.13 0S0 3.424 0 7.633a7.144 7.144 0 002.123 5.05L.61 15.331a1 1 0 001.24 1.424l4.466-1.787c.151.04.3.076.457.109a1 1 0 10.416-1.956z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 16.251a5.643 5.643 0 01-1.313 3.649l.721 2.599a.88.88 0 01-.311 1.259.961.961 0 01-1.019.163l-3.373-1.336a7.651 7.651 0 01-6.919-.734 6.738 6.738 0 01-3.186-5.6c0-3.653 3.524-6.74 7.7-6.74s7.7 3.087 7.7 6.74zm-3.387 3.648a1 1 0 01.257-.97v-.003a3.68 3.68 0 001.139-2.675c0-2.525-2.662-4.74-5.7-4.74-3.038 0-5.7 2.215-5.7 4.74a4.75 4.75 0 002.276 3.923 5.67 5.67 0 005.406.424.993.993 0 01.757-.023l1.952.732-.387-1.408z"
      fill="currentColor"
    />
  </Icon>
)

MessageBubblesOutline.displayName = 'MessageBubblesOutlineIcon'

export default MessageBubblesOutline
