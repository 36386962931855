import { Icon, IconProps } from '@chakra-ui/icon'
import { ComponentWithAs } from '@chakra-ui/react'

interface HeartPixelProps extends IconProps {
  backgroundColor?: string
  strokeColor?: string
}

const HeartPixel: ComponentWithAs<'svg', HeartPixelProps> = ({
  backgroundColor = '#EBEBEB',
  strokeColor = '#C4C4C4',
  width = 6,
  height = 6,
  ...props
}) => (
  <Icon {...props} width={width} height={height}>
    <path
      fill={strokeColor}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.286 0H3.429v3.429h6.857v3.428h3.428V3.43h6.857v10.285H24V3.43H20.57V0h-6.857v3.429h-3.428V0zM3.429 13.714h3.428v3.429H3.43v-3.429zm17.142 0h-3.428v3.429h3.428v-3.429zM6.857 17.143h3.429v3.428H6.857v-3.428zm10.286 0h-3.429v3.428h-3.428V24h3.428v-3.429h3.429v-3.428zM3.429 3.429H0v10.285h3.429V3.43z"
    />
    <path
      fill={backgroundColor}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.571 3.429h-6.857v3.428h-3.428V3.43H3.429v10.285h3.428v3.429h10.286v-3.429h-3.429 6.857V3.43zM10.286 17.143h3.428v3.428h-3.428v-3.428z"
    />
  </Icon>
)

export default HeartPixel
