import { Icon } from '@chakra-ui/icon'

const DotsHorizontal = props => (
  <Icon {...props}>
    <path
      d="M3.25 15.25a3.25 3.25 0 100-6.5 3.25 3.25 0 000 6.5zM12 15.25a3.25 3.25 0 100-6.5 3.25 3.25 0 000 6.5zM20.75 15.25a3.25 3.25 0 100-6.5 3.25 3.25 0 000 6.5z"
      fill="currentColor"
    />
  </Icon>
)

DotsHorizontal.displayName = 'DotsHorizontalIcon'

export default DotsHorizontal
