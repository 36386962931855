import { Icon } from '@chakra-ui/icon'

const ChevronLeft = props => (
  <Icon {...props}>
    <path
      d="M4.5 11.52c0-.635.283-1.24.78-1.66L16.432.442a1.901 1.901 0 012.574.187 1.723 1.723 0 01-.135 2.475l-9.752 8.235a.236.236 0 00-.085.181c0 .07.03.135.085.18l9.752 8.236c.513.414.753 1.062.627 1.694a1.799 1.799 0 01-1.237 1.349 1.904 1.904 0 01-1.829-.38L5.284 13.182A2.18 2.18 0 014.5 11.52z"
      fill="currentColor"
    />
  </Icon>
)

ChevronLeft.displayName = 'ChevronLeftIcon'

export default ChevronLeft
