import { AUTH_STORAGE_KEY } from 'ui/types'

export const mergeAuth = (args: Record<string, number | string>) => {
  const currentAuth = JSON.parse(
    localStorage.getItem(AUTH_STORAGE_KEY) as string,
  )

  const newAuth = { ...currentAuth, ...args }

  localStorage.setItem(AUTH_STORAGE_KEY, JSON.stringify(newAuth))

  return newAuth
}
