import { MouseEvent as ReactMouseEvent, ReactNode } from 'react'
import { Button, ButtonProps, forwardRef, Tooltip } from '@chakra-ui/react'
import styles from './CbButton.module.css'

export type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'outline'
  | 'ghost'
  | 'ghost-tame'
  | 'ghost-on-dark'
  | 'link'
  | 'link-on-dark'
  | 'danger'
  | 'unstyled'
  | 'secondary-action'
  | 'solid'

export interface CbButtonProps extends Omit<ButtonProps, 'variant'> {
  'aria-label'?: string
  children?: ReactNode
  onClick?: (event: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => void
  variant?: ButtonVariant
  round?: boolean
  href?: string
  tooltipContent?: string | null
}
export const CbButton = forwardRef(
  (
    {
      children,
      variant = 'primary',
      round = false,
      className = '',
      tooltipContent,
      ...props
    }: CbButtonProps,
    ref,
  ) => {
    const renderButton = () => (
      <Button
        as={props.href ? 'a' : 'button'}
        className={`${className} ${variant !== 'unstyled' ? styles.base : ''} ${
          round ? styles.round : ''
        } ${styles[variant]}`}
        minWidth={props.width || 'auto'}
        ref={ref}
        {...props}
      >
        {children}
      </Button>
    )

    if (tooltipContent) {
      return (
        /* shouldWrapChildren is required to ensure that it works correctly with a disabled button - see: https://v1.chakra-ui.com/docs/components/overlay/tooltip#tooltip-around-disabled-button */
        <Tooltip
          className={styles.tooltip}
          label={tooltipContent}
          shouldWrapChildren
        >
          {renderButton()}
        </Tooltip>
      )
    }

    return renderButton()
  },
)
