import { Icon } from '@chakra-ui/icon'

const People = props => (
  <Icon {...props}>
    <svg
      width="24"
      height="24"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6666 14H15.3333V11.118C15.3332 10.9848 15.2932 10.8547 15.2184 10.7444C15.1437 10.6341 15.0376 10.5488 14.9139 10.4993L12.4193 9.49934C12.2958 9.45 12.1898 9.36485 12.1151 9.25484C12.0404 9.14484 12.0003 9.01499 11.9999 8.882V8.29667C12.4043 8.06463 12.7404 7.73021 12.9744 7.32705C13.2085 6.92388 13.3322 6.46619 13.3333 6V4.66667C13.3334 4.19852 13.2102 3.7386 12.9762 3.33313C12.7422 2.92766 12.4056 2.59094 12.0002 2.35682C11.5948 2.1227 11.1349 1.99943 10.6667 1.9994C10.1986 1.99938 9.73868 2.1226 9.33325 2.35667"
        stroke="#ffffff"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M9.58063 11.166L7.08596 10.166C6.96246 10.1167 6.85654 10.0315 6.78182 9.92151C6.70709 9.8115 6.66698 9.68166 6.66663 9.54867V8.96334C7.07096 8.7313 7.40706 8.39688 7.64112 7.99371C7.87518 7.59055 7.99894 7.13285 7.99996 6.66667V5.33334C7.99996 4.62609 7.71901 3.94782 7.21891 3.44772C6.71881 2.94762 6.04054 2.66667 5.33329 2.66667C4.62605 2.66667 3.94777 2.94762 3.44767 3.44772C2.94758 3.94782 2.66663 4.62609 2.66663 5.33334V6.66667C2.66764 7.13285 2.79141 7.59055 3.02547 7.99371C3.25953 8.39688 3.59563 8.7313 3.99996 8.96334V9.54867C3.99987 9.68189 3.95988 9.81202 3.88514 9.92229C3.8104 10.0326 3.70433 10.1179 3.58063 10.1673L1.08596 11.1673C0.962462 11.2167 0.856543 11.3018 0.781818 11.4118C0.707092 11.5218 0.666976 11.6517 0.666626 11.7847V14H9.99996V11.7847C9.99987 11.6515 9.95988 11.5213 9.88514 11.411C9.8104 11.3008 9.70433 11.2154 9.58063 11.166Z"
        stroke="#ffffff"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  </Icon>
)

People.displayName = 'PeopleIcon'

export default People
