import { Icon } from '@chakra-ui/icon'

const QuillThree = props => (
  <Icon {...props}>
    <path
      d="M23.808 1.21a.72.72 0 00-.614-1.204 22.93 22.93 0 00-17.791 13.11l-2.53 5.593a.616.616 0 00-.043.126l-1.722 2.733a.96.96 0 101.624 1.024l4.337-6.92a.48.48 0 01.813.512l-1.143 1.813a.24.24 0 00.294.35l3.3-1.355a11.874 11.874 0 005.61-4.642.24.24 0 00-.02-.288l-2.185-2.546a.241.241 0 01.182-.396h3.36a.21.21 0 00.192-.13 10.391 10.391 0 011.967-3.067l4.369-4.713z"
      fill="currentColor"
    />
  </Icon>
)

QuillThree.displayName = 'QuillThreeIcon'

export default QuillThree
