import numbro from 'numbro'
import { isFinite } from 'lodash'

export const formatNumber = (number: number, withSign?: boolean): string => {
  let formattedNumber = Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(number)
  if (withSign) {
    return Math.sign(number) < 0 ? formattedNumber : `+${formattedNumber}`
  }
  return formattedNumber
}

export const roundAndSeperate = (number: number) =>
  numbro(number).format({ mantissa: 2, thousandSeparated: true })

export const toAverage = (values: number[]): number =>
  values.length > 0 ? values.reduce((a, b) => a + b, 0) / values.length : 0

export const pluralize = (
  count: number,
  singular: string,
  customPlural?: string,
): string => {
  if (count === 1) return singular
  if (customPlural) return customPlural
  return `${singular}s`
}

export const getOrdinal = (number: number): string => {
  const ordinal = ['th', 'st', 'nd', 'rd']
  const v = number % 100
  return number + (ordinal[(v - 20) % 10] || ordinal[v] || ordinal[0])
}

export const formatPercent = (value: number, options = {}): string => {
  if (!isFinite(value)) return `${value}`

  return numbro(value).format({ output: 'percent', mantissa: 2, ...options })
}
