import { Icon } from '@chakra-ui/icon'

const FileFolderOutline = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.429 4.921c.366.366.571.862.571 1.379v14.25a1.95 1.95 0 01-1.95 1.95H1.95A1.95 1.95 0 010 20.55V3.45A1.95 1.95 0 011.95 1.5h7.111c.74 0 1.415.418 1.745 1.079l.747 1.494A.5.5 0 0012 4.35h10.05a1.95 1.95 0 011.379.571zM21.548 20.5a.5.5 0 00.5-.5L22 6.8a.5.5 0 00-.5-.5l-9.84.05a1.941 1.941 0 01-1.742-1.075l-.761-1.523a.5.5 0 00-.449-.277L2.452 3.5a.5.5 0 00-.5.5L2 20.049a.5.5 0 00.5.5l19.048-.049z"
      fill="currentColor"
    />
  </Icon>
)

FileFolderOutline.displayName = 'FileFolderOutlineIcon'

export default FileFolderOutline
