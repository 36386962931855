import { Icon } from '@chakra-ui/icon'

const User = props => (
  <Icon {...props}>
    <path
      d="M12 11.75a5.5 5.5 0 100-11 5.5 5.5 0 000 11zM12 13.25a9.511 9.511 0 00-9.5 9.5.5.5 0 00.5.5h18a.5.5 0 00.5-.5 9.511 9.511 0 00-9.5-9.5z"
      fill="currentColor"
    />
  </Icon>
)

User.displayName = 'UserIcon'

export default User
