import { FC, ReactNode } from 'react'
import { render, RenderResult } from '@testing-library/react'
import { ChakraProvider } from '@chakra-ui/react'
import theme from '../styles/theme'
// import { MemoryRouterProvider } from 'next-router-mock/MemoryRouterProvider/next-13'
import { SWRConfig } from 'swr'

const Wrapper: FC = ({ children }: { children: ReactNode }) => (
  <SWRConfig
    value={{
      provider: () => new Map(),
      isOnline() {
        /* Customize the network state detector */
        return true
      },
      isVisible() {
        /* Customize the visibility state detector */
        return true
      },
      initFocus(callback) {},
      initReconnect(callback) {},
    }}
  >
    {/* <MemoryRouterProvider> */}
    <ChakraProvider theme={theme}>{children}</ChakraProvider>
    {/* </MemoryRouterProvider> */}
  </SWRConfig>
)
export const renderWithTheme = (ui): RenderResult => {
  return render(ui, { wrapper: Wrapper })
}
