import { Icon } from '@chakra-ui/icon'

const Transfer = props => (
  <Icon {...props}>
    <path
      d="M8.25 7.25v8.25s0 1-1.249 1c-1.25 0-1.25-1-1.25-1V7.25A.249.249 0 005.5 7h-1a.5.5 0 01-.385-.82l2.5-3a.5.5 0 01.769 0l2.5 3A.5.5 0 019.5 7h-1a.25.25 0 00-.25.25zM15.75 16.25V8s0-1 1.25-1 1.25 1 1.25 1v8.25a.249.249 0 00.25.25h1a.5.5 0 01.385.82l-2.5 3a.5.5 0 01-.769 0l-2.5-3a.5.5 0 01.384-.82h1a.25.25 0 00.25-.25z"
      fill="#252529"
    />
  </Icon>
)

Transfer.displayName = 'TransferIcon'

export default Transfer
