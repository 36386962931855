export const IMAGE_SIZE_MOBILE = '68px'
export const IMAGE_SIZE_DESKTOP = '88px'

export enum TransactionTypes {
  TokensReceived = 'tokens_received',
  TokensGiven = 'tokens_awarded',
  TokensRemoved = 'token_clawback',
  StorePurchase = 'reward_purchased',
  ChestReceived = 'chest_received',
  TokensPurchased = 'tokens_purchased',
}

export enum TransactionTitles {
  TokensReceived = 'Tokens Received',
  TokensGiven = 'Tokens Given',
  TokensRemoved = 'Tokens Removed',
  StorePurchase = 'Store Purchase',
  ChestReceived = 'Chest Received',
  TokensPurchased = 'Tokens Purchased',
}

export const transactionDisplay = {
  [TransactionTypes.TokensReceived]: {
    title: TransactionTitles.TokensReceived,
  },
  [TransactionTypes.TokensGiven]: {
    title: TransactionTitles.TokensGiven,
  },
  [TransactionTypes.TokensRemoved]: {
    title: TransactionTitles.TokensRemoved,
  },
  [TransactionTypes.StorePurchase]: {
    title: TransactionTitles.StorePurchase,
  },
  [TransactionTypes.ChestReceived]: {
    title: TransactionTitles.ChestReceived,
  },
  [TransactionTypes.TokensPurchased]: {
    title: TransactionTitles.TokensPurchased,
  },
}

export enum SourceType {
  User = 'User',
  Chest = 'Chest',
  LootBox = 'LootBox',
  Game = 'Campaign',
}

export interface Source {
  id: number
  name: any
  type: SourceType
}

export interface RewardsActivityCardType {
  id: number
  amount?: number
  createdAt: Date
  imageUrl?: string
  reason?: string
  source?: Source
  redemptionInformation?: {
    link: string
    secret_code?: string
  }
  transactionType:
    | TransactionTypes.TokensReceived
    | TransactionTypes.TokensGiven
    | TransactionTypes.TokensRemoved
    | TransactionTypes.StorePurchase
    | TransactionTypes.ChestReceived
    | TransactionTypes.TokensPurchased
}
