import { Icon } from '@chakra-ui/icon'

const BookmarkAlternate = props => (
  <Icon {...props}>
    <path
      d="M20 6.5a1 1 0 001-1V4a4 4 0 00-4-4H7a4 4 0 00-4 4v18.484a1.407 1.407 0 00.825 1.421 1.407 1.407 0 001.589-.421l3.232-3.231a.5.5 0 01.708 0l3.232 3.231c.283.316.683.503 1.107.516.165 0 .329-.032.482-.095.552-.247.884-.82.825-1.421V4.11a2.079 2.079 0 011.662-2.084A2 2 0 0119 4v1.5a1 1 0 001 1z"
      fill="currentColor"
    />
  </Icon>
)

BookmarkAlternate.displayName = 'BookmarkAlternateIcon'

export default BookmarkAlternate
