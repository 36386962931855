import { Icon } from '@chakra-ui/icon'

const ChessPieces = props => (
  <Icon {...props}>
    <path
      d="M5.193 9.9a.25.25 0 01.171.433 14.94 14.94 0 00-1.438 1.476c-.793 1.1-1.651 1.838-1.729 5.216a.5.5 0 00.5.512h7.214a.5.5 0 00.5-.5c.046-12.264 1.784-11.6-2.03-13.5A164.423 164.423 0 015.5 2.039a.637.637 0 00-.934.563V3.7a1 1 0 01-.368.775L.656 7.353c-.449.257-.7.758-.637 1.272C.285 9.439.437 9.9 1.292 9.9h3.901zM11.078 19.535c-.149-.409-.624-.5-1.1-.5H2.312c-.472 0-.947.091-1.1.5L.8 21.436a.468.468 0 00.114.415.569.569 0 00.424.184h9.621a.57.57 0 00.423-.184.465.465 0 00.114-.415l-.418-1.901zM23.989 21.436l-.416-1.9c-.15-.409-.625-.5-1.1-.5h-7.667c-.471 0-.946.091-1.095.5l-.417 1.9a.468.468 0 00.113.415c.11.118.263.185.424.184h9.621a.571.571 0 00.424-.184.465.465 0 00.113-.415zM15.2 17.535h6.65a.5.5 0 00.5-.52 15.413 15.413 0 00-1.845-6.58 1.1 1.1 0 00-.962-.571H17.5c-.4 0-.77.22-.962.571a15.428 15.428 0 00-1.845 6.58.5.5 0 00.5.52h.007zM18.497 8.768a2.906 2.906 0 100-5.812 2.906 2.906 0 000 5.812z"
      fill="currentColor"
    />
  </Icon>
)

ChessPieces.displayName = 'ChessPiecesIcon'

export default ChessPieces
