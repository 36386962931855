import { Icon } from '@chakra-ui/icon'

const ArrowLongLeft = props => (
  <Icon {...props}>
    <path
      d="M22.56 10.5H7.706a.245.245 0 01-.24-.25V7.222c0-.404-.234-.769-.593-.924a.932.932 0 00-1.046.217L1.24 11.293a1.03 1.03 0 000 1.414l4.587 4.778a.94.94 0 00.679.293c.53 0 .96-.448.96-1V13.75c0-.138.107-.25.24-.25H22.56c.795 0 1.44-.672 1.44-1.5s-.645-1.5-1.44-1.5z"
      fill="currentColor"
    />
  </Icon>
)

ArrowLongLeft.displayName = 'ArrowLongLeftIcon'

export default ArrowLongLeft
