import { Icon } from '@chakra-ui/icon'

const StatSearch = props => (
  <Icon {...props}>
    <path
      d="M18.25 22.56H.75c-.414 0-.75.322-.75.72 0 .398.336.72.75.72h17.5c.414 0 .75-.322.75-.72 0-.398-.336-.72-.75-.72zM1.5 10.08a.49.49 0 00-.5.48v10.8c0 .133.112.24.25.24h3.5c.138 0 .25-.108.25-.24v-10.8a.49.49 0 00-.5-.48h-3zM7 21.36c0 .133.112.24.25.24h3.5c.138 0 .25-.108.25-.24v-6.24c0-.265-.224-.24-.5-.24h-3a.49.49 0 00-.5.48v6zM16.75 21.6c.138 0 .25-.107.25-.24v-4.08c0-.265-.224-.24-.5-.24h-3a.49.49 0 00-.5.48v3.84c0 .133.112.24.25.24h3.5z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.156 11.54a.232.232 0 00.028.307l2.523 2.423a.933.933 0 01-.012 1.346 1.03 1.03 0 01-1.402.012l-2.523-2.423a.258.258 0 00-.321-.026c-2.972 2.003-7.046 1.492-9.382-1.177-2.335-2.67-2.157-6.61.41-9.075C13.045.462 17.15.291 19.93 2.534c2.78 2.242 3.312 6.153 1.226 9.006zM10.429 7.68c0 2.651 2.239 4.8 5 4.8 2.76-.003 4.997-2.15 5-4.8 0-2.651-2.239-4.8-5-4.8s-5 2.149-5 4.8z"
      fill="currentColor"
    />
  </Icon>
)

StatSearch.displayName = 'StatSearchIcon'

export default StatSearch
