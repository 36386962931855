import { Icon } from '@chakra-ui/icon'

const CrownDiamond = props => (
  <Icon {...props}>
    <path
      d="M7.223 16.056a.24.24 0 00-.239-.216H3.6a.96.96 0 100 1.92h3.896a.24.24 0 00.2-.372 2.96 2.96 0 01-.473-1.332zM12.806 9.12h5.556c.12 0 .222-.09.238-.208l.464-3.526a.242.242 0 01.154-.192A1.44 1.44 0 1017.8 4.95a.24.24 0 01.06.293A3.298 3.298 0 0114.88 7.2c-2.413 0-3.34-2.731-3.673-4.28a.239.239 0 01.108-.254 1.44 1.44 0 10-1.511 0c.086.053.13.155.108.254C9.581 4.47 8.653 7.2 6.24 7.2a3.28 3.28 0 01-2.986-1.955.242.242 0 01.06-.294 1.44 1.44 0 10-1.413.24.244.244 0 01.155.192l1.068 8.116a.48.48 0 00.48.418h4.154a.239.239 0 00.192-.096l2.52-3.509a2.91 2.91 0 012.335-1.192z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.724 14.946l-2.736-3.81a1.446 1.446 0 00-1.152-.576h-7.03c-.46.002-.892.222-1.164.592L8.928 14.93a1.452 1.452 0 00.158 1.908l6.232 5.799a1.44 1.44 0 001.994.008l6.262-5.829a1.44 1.44 0 00.15-1.869zm-3.397 2.27l-3.84 3.577a.24.24 0 01-.326 0l-3.845-3.577a.24.24 0 01.164-.416h7.68a.24.24 0 01.163.416h.004zm.517-2.336a.239.239 0 00.192-.384l-1.379-1.92a.242.242 0 00-.192-.096h-6.29a.24.24 0 00-.192.096l-1.38 1.92a.24.24 0 00.193.384h9.049z"
      fill="currentColor"
    />
  </Icon>
)

CrownDiamond.displayName = 'CrownDiamondIcon'

export default CrownDiamond
