import moment, { MomentInput } from 'moment'
import { DateTime } from 'luxon'
import { inflect } from 'inflection'

export const formatBirthday = (date: Date): string =>
  moment(date).format('MMMM Do')

export const formatAnniversary = (date: Date): string =>
  moment(date).format('MMM Do, YYYY')

export const formatRangeDate = (myDate: Date) =>
  moment(myDate).format('YYYY-MM-DD')

export const formatDateTime = (myDate: Date) =>
  moment(myDate).format('MMM D, h:mma')

export const getDateFromDateTime = (dateTime: Date) => {
  if (!dateTime) return null

  const year = dateTime.getFullYear()
  const month = dateTime.getMonth() + 1
  const day = dateTime.getDate()

  return `${year}-${month}-${day}`
}

export const getDaysFromToday = (futureDay: Date | string): number => {
  return Math.floor(
    (new Date(futureDay).getTime() - new Date().getTime()) / (1000 * 3600 * 24),
  )
}
export const formatUsDate = (date: Date): string => {
  if (!date) {
    return ''
  }
  return date.toLocaleDateString('en-US')
}

export const getNormalizedBirthday = (birthday: string): Date | null => {
  if (!birthday) {
    return null
  }
  const normalizedBirthday = new Date(birthday)
  normalizedBirthday.setFullYear(new Date().getFullYear())
  return normalizedBirthday
}

export const isAfter = (date1: MomentInput, date2: MomentInput): boolean =>
  moment(date1).isAfter(date2)

export const timeFromNow = (date: MomentInput): string =>
  moment(date).fromNow(true)

/* The method that Luxon uses under the hood to divine these values will not
   necessarily match up with a human's expectations, as seen below.  This function
   should only be used for rough estimates, and not for precise calculations.

   This test asserts the rough format of the output:
   * 2 words max
   * Proper pluralization
   * No 0 units
*/
export const durationSinceInWords = (value: string): string => {
  const date = DateTime.fromISO(value)

  /* Convert to a duration, and to effectively round down to the nearest day,
     shift to the smallest unit of hours, and then set the hours to 0  */
  const duration = DateTime.now()
    .diff(date)
    .shiftTo('years', 'months', 'days', 'hours')
    .set({ hours: 0 })

  /* Filter out any units that are 0, and then take the two largest units */
  return Object.entries(duration.toObject())
    .filter(([_unit, value]) => value !== 0)
    .slice(0, 2)
    .map(([unit, value]) => `${value} ${inflect(unit, value)}`)
    .join(', ')
}
