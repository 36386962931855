import { useState, useContext } from 'react'
import { arcadeApiClient } from '../apiClients'
import { AuthContext } from '../providers'
import { Reaction } from 'types/Reaction'

export const useReactions = (
  itemId: number,
  itemType: 'NewsfeedItem' | 'Comment',
  itemReactions: Reaction[],
) => {
  const { auth } = useContext(AuthContext)
  const [reactions, setReactions] = useState<Reaction[]>(itemReactions || [])

  const onReact = (chosenReaction: string) => {
    const reaction = reactions.find(
      reaction =>
        reaction.user.id === auth.id && reaction.reaction === chosenReaction,
    )

    if (reaction) {
      return deleteReaction(reaction)
    } else {
      return addReaction(chosenReaction)
    }
  }

  const deleteReaction = (reaction: Reaction) =>
    arcadeApiClient
      .delete(`/multi_platform/reactions/${reaction.id}`)
      .then(() => {
        setReactions(prevReactions =>
          prevReactions.filter(_reaction => _reaction.id !== reaction.id),
        )
      })

  const addReaction = (reaction: string) =>
    arcadeApiClient
      .post('/multi_platform/reactions', {
        reaction: {
          reaction: reaction,
          reactable_id: itemId,
          reactable_type: itemType,
        },
      })
      .then(response => {
        setReactions(prevReactions => [
          ...prevReactions,
          response.data.reaction,
        ])
      })

  return { reactions, onReact }
}
