import { Icon } from '@chakra-ui/icon'

const SyncFour = props => (
  <Icon {...props}>
    <path
      d="M12.567 1.258c-4.4.175-8.255 3-9.749 7.142a.25.25 0 01-.271.163L.858 8.309a.5.5 0 00-.506.743l2.679 4.7a.5.5 0 00.786.107l3.77-3.746a.5.5 0 00-.279-.85l-1.715-.256a.25.25 0 01-.192-.35 8.259 8.259 0 117.866 11.59 1.25 1.25 0 10.045 2.5h.047a10.751 10.751 0 10-.792-21.487v-.002z"
      fill="currentColor"
    />
  </Icon>
)

SyncFour.displayName = 'SyncFourIcon'

export default SyncFour
