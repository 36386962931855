export interface TimePeriod {
  name: string
  key: string
}

export const TimePeriodOptions = [
  { name: 'All time', key: 'all_dates' },
  { name: 'Today', key: 'today' },
  { name: 'This week', key: 'this_week' },
  { name: 'This month', key: 'this_month' },
  { name: 'This year', key: 'this_year' },
  { name: 'Last 7 days', key: 'last_7_days' },
  { name: 'Last 30 days', key: 'last_30_days' },
  { name: 'Last week', key: 'last_week' },
  { name: 'Last month', key: 'last_month' },
] as TimePeriod[]
