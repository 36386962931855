import { Icon } from '@chakra-ui/icon'

const CloseCircle = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 12C0 5.373 5.373 0 12 0c6.624.008 11.992 5.376 12 12 0 6.627-5.373 12-12 12S0 18.627 0 12zm17.763 5.049a1 1 0 00-.273-.973l-3.9-3.899a.249.249 0 010-.353l3.9-3.9a1 1 0 10-1.412-1.414l-3.9 3.9a.25.25 0 01-.354 0l-3.9-3.9a1 1 0 10-1.416 1.413l3.9 3.9a.249.249 0 010 .353l-3.9 3.9a1 1 0 000 1.414c.393.384 1.02.384 1.414 0l3.9-3.9a.252.252 0 01.354 0l3.9 3.9a1 1 0 001.687-.441z"
      fill="currentColor"
    />
  </Icon>
)

CloseCircle.displayName = 'CloseCircleIcon'

export default CloseCircle
