import { Icon } from '@chakra-ui/icon'

const TourchThree = props => (
  <Icon {...props}>
    <path
      d="M8.75 10.261h6.75a.5.5 0 00.421-.231c.429-.768.63-1.642.579-2.52a4.151 4.151 0 00-2.816-3.964.5.5 0 00-.684.408 1.3 1.3 0 01-.338.716A3.486 3.486 0 0113.8.915a.5.5 0 00-.408-.89A7.8 7.8 0 007.5 7.51c.036.903.324 1.777.831 2.525a.5.5 0 00.419.226zM19.962 12.07a.5.5 0 00-.462-.309h-15a.5.5 0 00-.354.854 12.086 12.086 0 004.81 2.674l1.828 7.723a1.247 1.247 0 002.434 0l1.828-7.723a12.085 12.085 0 004.808-2.674.5.5 0 00.108-.545z"
      fill="currentColor"
    />
  </Icon>
)

TourchThree.displayName = 'TourchThreeIcon'

export default TourchThree
