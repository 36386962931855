import { Icon } from '@chakra-ui/icon'

const ArrowDownRight = props => (
  <Icon {...props}>
    <path
      d="M21.5 4a1.5 1.5 0 00-3 0v11.76a.25.25 0 01-.427.176L5.06 2.937A1.5 1.5 0 002.94 5.06l13.029 13.015a.25.25 0 01-.177.427H4a1.5 1.5 0 000 3h15A2.5 2.5 0 0021.5 19V4z"
      fill="currentColor"
    />
  </Icon>
)

ArrowDownRight.displayName = 'ArrowDownRightIcon'

export default ArrowDownRight
