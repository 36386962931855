import { Icon } from '@chakra-ui/icon'

const Share = props => (
  <Icon {...props}>
    <path
      d="M20.5 9.6c0-1.06-.895-1.92-2-1.92h-3.25a.245.245 0 00-.25.24v1.44c0 .133.112.24.25.24H18c.276 0 .5.215.5.48V21.6a.49.49 0 01-.5.48H6a.49.49 0 01-.5-.48V10.08A.49.49 0 016 9.6h2.75c.066 0 .13-.025.177-.07A.235.235 0 009 9.358v-1.44a.235.235 0 00-.074-.17.256.256 0 00-.178-.069H5.5c-1.105 0-2 .86-2 1.92v12.48c0 1.06.895 1.92 2 1.92h13c1.105 0 2-.86 2-1.92V9.6z"
      fill="currentColor"
    />
    <path
      d="M10.5 11.52c0 .795.672 1.44 1.5 1.44s1.5-.645 1.5-1.44V6.479c0-.133.112-.24.25-.24h1.75a1 1 0 00.918-.596A.932.932 0 0016.2 4.6l-3.5-3.36a1.03 1.03 0 00-1.414 0l-3.5 3.36a.932.932 0 00-.216 1.05c.156.36.524.593.93.59h1.75c.138 0 .25.107.25.24v5.04z"
      fill="currentColor"
    />
  </Icon>
)

Share.displayName = 'ShareIcon'

export default Share
