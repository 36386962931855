import { Icon } from '@chakra-ui/icon'

const Pencil = props => (
  <Icon {...props}>
    <path
      d="M14.8712117,4.14911578 C14.8244888,4.10199477 14.7688295,4.06457603 14.7075907,4.03899044 C14.646367,4.01340485 14.5806676,4 14.5142877,4 C14.4479835,4 14.3823445,4.0131026 14.3211057,4.03852195 C14.2598669,4.06394131 14.204253,4.10117869 14.1574393,4.14810324 L3.14756286,15.1551057 C3.05307364,15.249514 3,15.3776686 3,15.5112638 C3,15.6448589 3.05307364,15.7728624 3.14756286,15.8674672 L8.13538091,20.8525013 C8.22990037,20.9469549 8.3580785,21 8.49171519,21 C8.62536701,21 8.75354514,20.9469549 8.8480646,20.8525013 L19.8528756,9.85361426 C19.9470776,9.75917578 20,9.63129317 20,9.49795491 C20,9.36463177 19.9470776,9.23673405 19.8528756,9.14231068 L14.8712117,4.14911578Z"
      fill="currentColor"
    ></path>
    <path
      d="M2.27132751,17.1403686 C2.21080209,17.0800393 2.1352897,17.0368852 2.05255815,17.0153803 C1.96984104,16.9940197 1.88287906,16.9948857 1.80063842,17.0181225 C1.71826782,17.041215 1.6435351,17.0858125 1.58415031,17.1474406 C1.52476552,17.2089245 1.48287996,17.2852741 1.46278183,17.3684072 L0.0131778217,23.4079674 C-0.00595265785,23.4880696 -0.00422886734,23.5716357 0.018184452,23.6508719 C0.0405979157,23.7299638 0.0829509822,23.8021279 0.141198035,23.8602922 C0.199819041,23.9177348 0.272016395,23.95959 0.351097937,23.9818166 C0.430180924,24.0040431 0.513591073,24.0059194 0.593598112,23.9873011 L6.63074591,22.5440185 C6.7139828,22.5241012 6.79041925,22.4823903 6.85214305,22.4230714 C6.91388129,22.3637525 6.95866894,22.2891348 6.98191463,22.2067234 C7.00516032,22.1244562 7.00601218,22.037282 6.98438358,21.9545819 C6.96275498,21.8717375 6.9194256,21.7962538 6.8588713,21.7357803 L2.27132751,17.1403686Z"
      fill="currentColor"
    ></path>
    <path
      d="M23.2658653,2.87248003 L21.1291303,0.735074997 C20.6575208,0.264364522 20.018342,0 19.3518389,0 C18.6854868,0 18.046157,0.264364522 17.5745476,0.735074997 L16.1473402,2.16101045 C16.0531391,2.25536689 16,2.38332394 16,2.51674524 C16,2.65016654 16.0531391,2.77812359 16.1473402,2.87248003 L21.1291303,7.85269165 C21.2236333,7.9470179 21.3514993,8 21.4849508,8 C21.6184024,8 21.7464193,7.9470179 21.8407714,7.85269165 L23.2658653,6.42474107 C23.7359651,5.9531702 24,5.31447176 24,4.648603 C24,3.98274935 23.7359651,3.34403581 23.2658653,2.87248003Z"
      fill="currentColor"
    ></path>
  </Icon>
)

Pencil.displayName = 'PencilIcon'

export default Pencil
