import { Icon } from '@chakra-ui/icon'

const TV = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.436 2.358c.358.33.563.796.564 1.284v12.414a1.75 1.75 0 01-1.887 1.745c-3.178-.255-5.683-.397-8.355-.439a.5.5 0 00-.508.5v1.246a.5.5 0 00.5.5H17a1.25 1.25 0 110 2.5H7a1.25 1.25 0 110-2.5h3.25a.5.5 0 00.5-.5v-1.247a.5.5 0 00-.508-.5c-2.672.039-5.177.184-8.352.439A1.75 1.75 0 010 16.056V3.642c.002-.488.207-.953.566-1.284A1.711 1.711 0 011.9 1.9a113.26 113.26 0 0020.21 0c.486-.039.967.127 1.326.458zM21.34 15.074a.5.5 0 00.159-.367V4.992a.5.5 0 00-.537-.5c-5.966.477-11.96.477-17.926 0a.5.5 0 00-.537.5v9.715a.5.5 0 00.537.5c5.966-.477 11.96-.477 17.926 0a.5.5 0 00.378-.133z"
      fill="currentColor"
    />
  </Icon>
)

TV.displayName = 'TVIcon'

export default TV
