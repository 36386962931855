import { useEffect, useState } from 'react'
import { arcadeApiClient } from '../apiClients'

export type SlackChannelType = {
  id: string
  isChannel: boolean
  isMember: boolean
  name: string
}

export function useSlackChannels() {
  const [loading, setLoading] = useState(true)
  const [channels, setChannels] = useState<SlackChannelType[]>([])
  const [error, setError] = useState()

  useEffect(loadChannels, [])

  function loadChannels() {
    arcadeApiClient
      .get(`/slack/channels`)
      .then(res => {
        setChannels(res.data.channels)
        setLoading(false)
      })
      .catch(setError)
  }

  return { channels, error, loading }
}
