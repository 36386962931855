import { Icon } from '@chakra-ui/icon'

const Tinder = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.333.436c.39-.385.984-.472 1.467-.214 7.216 3.824 10.6 12.303 9.221 17.82-.93 3.737-3.887 5.882-8.116 5.882a7.807 7.807 0 01-7.749-5.234 8.653 8.653 0 012.781-9.6.746.746 0 01.724-.119c.246.09.427.3.48.556.107.548.296 1.076.56 1.568a.249.249 0 00.437.005A10.2 10.2 0 009.1 1.9 1.251 1.251 0 019.333.436zm2.696 20.493a3.314 3.314 0 003.508-2.829 4.724 4.724 0 00-2.692-5.14.5.5 0 00-.611.713 2.3 2.3 0 01-.453 2.46 3.006 3.006 0 01-2.5 1.41.5.5 0 00-.607.575 3.406 3.406 0 003.355 2.811z"
      fill="currentColor"
    />
  </Icon>
)

Tinder.displayName = 'TinderIcon'

export default Tinder
