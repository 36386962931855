import { FC, PropsWithChildren, createContext, useState } from 'react'
import { useRouter } from 'next/router'
import { PlayerMeta } from '../types'

interface SpectatorViewContextProps {
  assumedUserFilter: string
  assumedUser?: PlayerMeta
  setAssumedUser?: (selectedOption: PlayerMeta) => void
  reset?: () => void
}

export const SpectatorViewContext = createContext(
  {} as SpectatorViewContextProps,
)

export const SpectatorViewProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const router = useRouter()
  const init = JSON.parse(localStorage.getItem('assumedUser') as string)
  const [user, setUser] = useState<PlayerMeta | undefined>(init)
  const filter = user ? `assumed_user_id=${user.id.toString()}` : ''

  const value = {
    assumedUser: user,
    assumedUserFilter: filter,
    setAssumedUser: selectedUser => {
      if (router.pathname === '/games/[id]') {
        router
          .push({
            pathname: '/games',
          })
          .catch(err => console.error(err))
      }
      setUser(selectedUser)
      localStorage.setItem('assumedUser', JSON.stringify(selectedUser))
    },
    reset: () => {
      if (router.pathname === '/games/[id]') {
        router
          .push({
            pathname: '/games',
          })
          .catch(err => console.error(err))
      }
      setUser(undefined)
      localStorage.removeItem('assumedUser')
    },
  }

  return (
    <SpectatorViewContext.Provider value={value}>
      {children}
    </SpectatorViewContext.Provider>
  )
}
