import { Transition } from '../useStateMachine'

export enum ApiRequestState {
  INITIAL,
  CANCELLED,
  LOADING,
  ERROR,
  SUCCESS,
}
export const networkRequestDigraph: Array<Transition<ApiRequestState>> = [
  [ApiRequestState.INITIAL, ApiRequestState.LOADING], // Send request
  [ApiRequestState.LOADING, ApiRequestState.CANCELLED], // Cancel request
  [ApiRequestState.LOADING, ApiRequestState.ERROR], // Request failed
  [ApiRequestState.LOADING, ApiRequestState.SUCCESS], // Request succeeded
  [ApiRequestState.CANCELLED, ApiRequestState.LOADING], // Resend request
  [ApiRequestState.ERROR, ApiRequestState.LOADING], // Resend request
  [ApiRequestState.SUCCESS, ApiRequestState.LOADING], // Resend request
]
