import { Icon } from '@chakra-ui/icon'

const ChevronLeftThin = props => (
  <Icon viewBox="0 0 16 16" {...props}>
    <path
      d="M11.354 1.646a.5.5 0 010 .708L5.707 8l5.647 5.646a.5.5 0 01-.708.708l-6-6a.5.5 0 010-.708l6-6a.5.5 0 01.708 0z"
      fill="currentColor"
      stroke="currentColor"
    />
  </Icon>
)

ChevronLeftThin.displayName = 'ChevronLeftThinIcon'

export default ChevronLeftThin
