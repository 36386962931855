import { Icon } from '@chakra-ui/icon'

const EyeCrossed = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.9 6.949a20.952 20.952 0 014.3 3.6c.741.823.744 2.072.007 2.898-2.562 2.816-6.795 5.8-11.051 5.8h-.3a11.26 11.26 0 01-4.31-.939L3.4 22.454a1 1 0 01-1.631-1.088.053.053 0 01.014-.01.062.062 0 00.012-.007l19.5-19.5a.737.737 0 00.173-.291l.018-.018A1 1 0 1122.9 2.954l-4 3.995zm-5.587 9.349a4.581 4.581 0 003-3.028 4.3 4.3 0 00-.201-3.061.252.252 0 00-.407-.067l-5.56 5.559a.253.253 0 00.068.407 4.3 4.3 0 003.1.19z"
      fill="currentColor"
    />
    <path
      d="M7.615 13.4a.244.244 0 00.061-.24A4.314 4.314 0 017.5 12 4.5 4.5 0 0112 7.5c.393.004.783.062 1.16.173.085.025.177 0 .24-.062l1.941-1.942a.254.254 0 00-.1-.421A10.413 10.413 0 0012 4.75C7.7 4.692 3.4 7.7.813 10.549a2.15 2.15 0 00-.007 2.9 21.211 21.211 0 003.438 3.03c.101.07.239.058.326-.029l3.045-3.05z"
      fill="currentColor"
    />
  </Icon>
)

EyeCrossed.displayName = 'EyeCrossedIcon'

export default EyeCrossed
