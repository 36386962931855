import { Icon } from '@chakra-ui/icon'

const MessageBubbleOutline = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 10.811C0 5.31 5.383.836 12 .836s12 4.473 12 9.973c0 5.5-5.383 9.975-12 9.975a14.243 14.243 0 01-3.772-.5l-5.981 2.784a1 1 0 01-1.316-1.355l2.126-4.244A9.01 9.01 0 010 10.811zm8.438 7.451a12.19 12.19 0 003.562.524c5.514 0 10-3.577 10-7.975s-4.486-7.975-10-7.975S2 6.411 2 10.811a7.214 7.214 0 002.925 5.628 1 1 0 01.269 1.228l-.869 1.735a.25.25 0 00.329.339l3.07-1.429a1 1 0 01.714-.05z"
      fill="currentColor"
    />
  </Icon>
)

MessageBubbleOutline.displayName = 'MessageBubbleOutlineIcon'

export default MessageBubbleOutline
