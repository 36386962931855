import { Icon } from '@chakra-ui/icon'

const Inbox = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.806 5.763l3.07 5.5c.075.152.117.318.124.487v6a1.5 1.5 0 01-1.5 1.5h-21a1.5 1.5 0 01-1.5-1.5v-6c.013-.169.057-.334.13-.487L3.2 5.759a1.955 1.955 0 011.7-1.005h14.206c.707.004 1.357.39 1.7 1.009zM5.048 6.75c-.09 0-.174.05-.218.129l-2.205 4a.251.251 0 00.219.371H6.5a1.5 1.5 0 011.5 1.5 1.5 1.5 0 001.5 1.5h5a1.5 1.5 0 001.5-1.5c0-.398.159-.779.439-1.061.28-.282.663-.44 1.061-.439h3.654a.25.25 0 00.218-.372l-2.241-4.016a.248.248 0 00-.218-.128L5.048 6.75z"
      fill="currentColor"
    />
  </Icon>
)

Inbox.displayName = 'InboxIcon'

export default Inbox
