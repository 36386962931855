import { Icon } from '@chakra-ui/icon'

const History = props => (
  <Icon {...props}>
    <svg
      width="34"
      height="35"
      viewBox="0 0 34 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 3.72949C8.03 3.72949 4 7.75949 4 12.7295H1L4.89 16.6195L4.96 16.7595L9 12.7295H6C6 8.85949 9.13 5.72949 13 5.72949C16.87 5.72949 20 8.85949 20 12.7295C20 16.5995 16.87 19.7295 13 19.7295C11.07 19.7295 9.32 18.9395 8.06 17.6695L6.64 19.0895C8.27 20.7195 10.51 21.7295 13 21.7295C17.97 21.7295 22 17.6995 22 12.7295C22 7.75949 17.97 3.72949 13 3.72949ZM12 8.72949V13.7295L16.28 16.2695L17 15.0595L13.5 12.9795V8.72949H12Z"
        fill="#E2E8F0"
      />
    </svg>
  </Icon>
)

History.displayName = 'HistoryIcon'

export default History
