import { parts, sizes } from './chakra-theme'

export const chromePrefillColorOverride = {
  transition: 'background-color 150ms ease -in -out 0s',
  transitionDelay: '5000s',
}
export const baseStyle = {
  field: {
    width: '100%',
    minWidth: 0,
    outline: 0,
    position: 'relative',
    appearance: 'none',
    transitionProperty: 'common',
    transitionDuration: 'normal',
    background: 'transparent',
    borderRadius: '99px',
    border: '1.5px solid',
    '&:-webkit-autofill': {
      ...chromePrefillColorOverride,
    },
    '&:-webkit-autofill:hover': {
      ...chromePrefillColorOverride,
      _hover: {
        border: '1.5px solid',
        borderColor: 'gray.500',
      },
    },
    '&:-webkit-autofill:focus': {
      ...chromePrefillColorOverride,
    },
    '&:-webkit-autofill:active': {
      ...chromePrefillColorOverride,
    },
    _blur: {
      borderColor: 'kirby.200',
    },
  },
  addon: {
    border: '2px solid',
    borderColor: 'transparent',
  },
}

export const variantChonky = (props): Record<string, any> => {
  return {
    field: {
      ...baseStyle.field,
      color: 'text.primary',
      borderColor: 'border.primary',
      _placeholder: {
        color: 'text.tertiary',
      },
      _hover: {
        borderColor: 'border.hover',
      },
      _focus: {
        border: '2px solid',
        borderColor: 'border.active',
      },
      _active: {
        border: '2px solid',
        borderColor: 'border.active',
      },
      _invalid: {
        border: '2px solid',
        borderColor: 'kirby.300',
        _hover: {
          borderColor: 'kirby.500',
        },
      },
      _disabled: {
        opacity: 0.5,
        cursor: 'not-allowed',
        color: 'blackAlpha.500',
        _hover: {
          border: 'solid 1.5px',
        },
      },
    },
    addon: {
      ...baseStyle.addon,
    },
  }
}
export const variantOutlineWhite = (props): Record<string, any> => {
  return {
    field: {
      ...baseStyle.field,
      color: 'text.primaryWhite',
      borderColor: 'cloud.300',
      _placeholder: {
        color: 'text.primaryWhite',
      },
      _hover: {
        borderColor: 'border.primaryWhite',
      },
      _focus: {
        border: '2px solid',
        borderColor: 'border.active',
      },
      _invalid: {
        border: '2px solid',
        borderColor: 'kirby.300',
        _hover: {
          borderColor: 'kirby.500',
        },
      },
      _disabled: {
        opacity: 0.5,
        cursor: 'not-allowed',
        _hover: {
          border: '1.5px solid',
        },
      },
    },
    addon: {
      ...baseStyle.addon,
    },
  }
}

export const variants = {
  'cb-base': variantChonky,
  'cb-chonky': variantChonky,
  'cb-outlineWhite': variantOutlineWhite,
}

export const defaultProps = {
  size: 'md',
  variant: 'base',
}

export default {
  parts,
  baseStyle,
  sizes,
  variants,
  defaultProps,
}
