import { Icon } from '@chakra-ui/icon'

const VideoPlayOutline = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 2h14a5.006 5.006 0 015 5v10a5.006 5.006 0 01-5 5H5a5.006 5.006 0 01-5-5V7a5.006 5.006 0 015-5zm14 18a3 3 0 003-3V7a3 3 0 00-3-3H5a3 3 0 00-3 3v10a3 3 0 003 3h14z"
      fill="currentColor"
    />
    <path
      d="M15.935 10.658l-5.764-2.882A1.5 1.5 0 008 9.118v5.764a1.5 1.5 0 002.171 1.342l5.764-2.882a1.5 1.5 0 000-2.684z"
      fill="currentColor"
    />
  </Icon>
)

VideoPlayOutline.displayName = 'VideoPlayOutlineIcon'

export default VideoPlayOutline
