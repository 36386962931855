import { Icon } from '@chakra-ui/icon'

const FlagAnother = props => (
  <Icon {...props}>
    <path
      d="M21 2H6.5a.75.75 0 00-.75.75v12c0 .414.336.75.75.75H21a1.752 1.752 0 001.75-1.75v-10A1.752 1.752 0 0021 2zM2.75 0a1.5 1.5 0 00-1.5 1.5v21a1.5 1.5 0 003 0v-21A1.5 1.5 0 002.75 0z"
      fill="currentColor"
    />
  </Icon>
)

FlagAnother.displayName = 'FlagAnotherIcon'

export default FlagAnother
