import useSWR from 'swr'
import { snakeCaseKeys } from '../utils/string'

import arcadeApiClient from '../apiClients/arcadeApiClient'

const fetcher = async url =>
  await arcadeApiClient.get(url).then(res => res.data)

export interface CurrentUser {
  id: number
  name: string
  firstName: string
  lastName: string
  teamName: string
  email: string
  personalEmail: string
  description: string
  birthday: string
  anniversary: string
  colorScheme: string
  jobTitle: string
  location: string
  phone: string
  personalPhone: string
  profileImage: string
  badgeImage: string
  isLocked: boolean
  needsToConnectGroupMe: boolean
  receiveEmails: boolean
  refetchCurrentUser: () => void
  updateCurrentUser: (changes: Partial<CurrentUser>) => void
}
export const useCurrentUser = (): CurrentUser | undefined => {
  const { data: currentUser, mutate: refetchCurrentUser } = useSWR(
    '/multi_platform/profile/info',
    fetcher,
  )

  const updateCurrentUser = (changes: Partial<CurrentUser>) => {
    return arcadeApiClient
      .put(`/multi_platform/profile/${currentUser.id}`, {
        user: snakeCaseKeys(changes),
      })
      .then(res => {
        refetchCurrentUser(res.data)
      })
  }

  if (!currentUser) return
  return {
    refetchCurrentUser,
    updateCurrentUser,
    ...currentUser,
    id: currentUser?.id?.toString(),
  }
}
