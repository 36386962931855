import { Icon } from '@chakra-ui/icon'

const RibbonCheckTwo = props => (
  <Icon {...props}>
    <path
      d="M23.366 19.766l-3-5.18a.25.25 0 00-.439.011 4.663 4.663 0 01-4.317 2.548.25.25 0 00-.191.078 4.505 4.505 0 01-.963.765.251.251 0 00-.086.341l2.942 4.978a1 1 0 001.8-.17l.832-2.288 2.392.405a1 1 0 001.031-1.488h-.001zM8.583 17.224a.25.25 0 00-.191-.078A4.666 4.666 0 014.074 14.6a.251.251 0 00-.439-.011l-3 5.181a1 1 0 001.032 1.487l2.391-.405.833 2.289a1 1 0 001.8.167l2.941-4.976a.251.251 0 00-.086-.341 4.552 4.552 0 01-.963-.767z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.01 14.72a3.17 3.17 0 01-3.197.78 3.169 3.169 0 01-5.626 0 3.17 3.17 0 01-3.979-3.981 3.169 3.169 0 010-5.625 3.169 3.169 0 013.978-3.975 3.17 3.17 0 015.627 0 3.17 3.17 0 013.978 3.978 3.169 3.169 0 010 5.625 3.17 3.17 0 01-.78 3.198zM8.177 9.059a.75.75 0 00.204.729l1.722 1.725a1.5 1.5 0 002.258-.151l3.732-4.874a.75.75 0 00-1.192-.912l-3.731 4.879-1.728-1.727a.75.75 0 00-1.265.33z"
      fill="currentColor"
    />
  </Icon>
)

RibbonCheckTwo.displayName = 'RibbonCheckTwoIcon'

export default RibbonCheckTwo
