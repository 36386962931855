import { Icon } from '@chakra-ui/icon'

const ThumbsDown = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.59 14.413a3 3 0 01-.46-2.66l.002-.008a.143.143 0 00.007-.037l1.9-6.357C3.069 2.006 4.934.393 7.734.393 8.158.393 9.02.386 9.729.38l1.059-.007c3.469 0 4.59.305 9.712 2.881v-.627a1.5 1.5 0 011.5-1.5h1.496a.5.5 0 01.5.5v14a.5.5 0 01-.5.5h-1.5a1.5 1.5 0 01-1.408-1h-2.055a1 1 0 00-.818.424l-4.89 6.928a2.712 2.712 0 01-4.839-2.253l.881-3.343a1 1 0 00-.967-1.256H3a3 3 0 01-2.41-1.214zm1.459-2.095l-.009.028h.001A1 1 0 003 13.627h4.909a3 3 0 012.9 3.766l-.882 3.343a.712.712 0 00.135.627.74.74 0 001.138-.039l4.884-6.928a3.006 3.006 0 012.452-1.27H20a.5.5 0 00.5-.5V5.8a.5.5 0 00-.275-.441c-5.964-3.021-6.376-3.021-10.551-2.975H9.61c-.557.007-1.175.013-1.876.013-1.886 0-3.016 1.052-3.776 3.521l-1.909 6.4z"
      fill="currentColor"
    />
  </Icon>
)

ThumbsDown.displayName = 'ThumbsDownIcon'

export default ThumbsDown
