import { Icon } from '@chakra-ui/icon'

const CautionCircle = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.464.96a11.718 11.718 0 00-8.17 3.507A11.328 11.328 0 00.96 12.679 11.308 11.308 0 0012.288 24h.205c6.411-.066 11.56-5.31 11.507-11.72A11.295 11.295 0 0012.464.96zM11.04 16.84a1.417 1.417 0 011.391-1.469h.026a1.466 1.466 0 011.462 1.412 1.416 1.416 0 01-1.391 1.468h-.026a1.468 1.468 0 01-1.462-1.41zm.48-9.64v5.76a.96.96 0 101.92 0V7.2a.96.96 0 10-1.92 0z"
      fill="currentColor"
    />
  </Icon>
)

CautionCircle.displayName = 'CautionCircleIcon'

export default CautionCircle
