import { Icon } from '@chakra-ui/icon'

const StarCircle = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 12C0 5.373 5.373 0 12 0c6.624.007 11.993 5.376 12 12 0 6.627-5.373 12-12 12S0 18.627 0 12zm2 0c0 5.523 4.477 10 10 10 5.52-.006 9.994-4.48 10-10 0-5.523-4.477-10-10-10S2 6.477 2 12z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.5 12a8.5 8.5 0 1117 0 8.5 8.5 0 01-17 0zm11.741 1.4l1.78-1.276a1 1 0 00-.582-1.813h-2.381a.25.25 0 01-.239-.179l-.862-2.869a1 1 0 00-1.914 0l-.862 2.869a.25.25 0 01-.239.179H7.561a1 1 0 00-.582 1.813l1.78 1.271a.251.251 0 01.092.282l-.82 2.456A1 1 0 009.58 17.25l2.27-1.7a.249.249 0 01.3 0l2.27 1.7a1 1 0 001.549-1.111l-.82-2.457a.251.251 0 01.092-.282z"
      fill="currentColor"
    />
  </Icon>
)

StarCircle.displayName = 'StarCircleIcon'

export default StarCircle
