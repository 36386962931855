import { Icon } from '@chakra-ui/icon'

const ArrowLongRight = props => (
  <Icon {...props}>
    <path
      d="M23.707 11.291l-4.778-4.778a1 1 0 00-1.707.707v3.028a.25.25 0 01-.25.25H1.5a1.5 1.5 0 000 3h15.472a.25.25 0 01.25.25v3.028a1 1 0 001.707.707l4.778-4.778a1 1 0 000-1.414z"
      fill="currentColor"
    />
  </Icon>
)

ArrowLongRight.displayName = 'ArrowLongRightIcon'

export default ArrowLongRight
