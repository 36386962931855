import { Icon } from '@chakra-ui/icon'

const Award = props => (
  <Icon viewBox="0 0 32 32" {...props}>
    <g fill="none">
      <path
        d="M22.6673 17.1986V29.3333L16.0007 25.3333L9.33398 29.3333V17.1973"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M16.0003 19.9997C21.155 19.9997 25.3337 15.821 25.3337 10.6663C25.3337 5.51168 21.155 1.33301 16.0003 1.33301C10.8457 1.33301 6.66699 5.51168 6.66699 10.6663C6.66699 15.821 10.8457 19.9997 16.0003 19.9997Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </g>
  </Icon>
)

Award.displayName = 'AwardIcon'

export default Award
