import { FC, PropsWithChildren } from 'react'
import { ChakraProvider, CSSReset } from '@chakra-ui/react'

import theme from '../../styles/theme'

export const CbThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <ChakraProvider theme={theme}>
      <CSSReset />
      {children}
    </ChakraProvider>
  )
}
