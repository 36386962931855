import { Icon } from '@chakra-ui/icon'

const Phone = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 .034H7a3 3 0 00-3 3v17.932a3 3 0 003 3h10a3 3 0 003-3V3.034a3 3 0 00-3-3zm-5 22.432a1 1 0 110-2 1 1 0 010 2zm5-3.5a1 1 0 001-1V3.534a1 1 0 00-1-1H7a1 1 0 00-1 1v14.432a1 1 0 001 1h10z"
      fill="currentColor"
    />
  </Icon>
)

Phone.displayName = 'PhoneIcon'

export default Phone
