import { Icon } from '@chakra-ui/icon'

const Circle = props => (
  <Icon {...props}>
    <path
      d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12z"
      fill="currentColor"
    />
  </Icon>
)

Circle.displayName = 'CircleIcon'

export default Circle
