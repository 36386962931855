import { Icon } from '@chakra-ui/icon'

const Pin = props => (
  <Icon {...props}>
    <path
      d="M12 11.875a5 5 0 100-10 5 5 0 000 10zM12 13.125c-.69 0-1.25.56-1.25 1.25v6.5a1.25 1.25 0 102.5 0v-6.5c0-.69-.56-1.25-1.25-1.25z"
      fill="currentColor"
    />
  </Icon>
)

Pin.displayName = 'PinIcon'

export default Pin
