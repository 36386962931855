import { Icon } from '@chakra-ui/icon'

const StarGroup = props => (
  <Icon {...props}>
    <path
      d="M15.713 12.767l-1.036-2.492a.493.493 0 01.092-.522l1.887-2.047a.633.633 0 00.15-.69.593.593 0 00-.574-.383h-2.13a.46.46 0 01-.415-.27L12.57 3.975A.643.643 0 0012 3.62a.643.643 0 00-.57.355l-1.118 2.389a.46.46 0 01-.416.27h-2.13a.593.593 0 00-.572.385.632.632 0 00.156.69l1.888 2.048c.13.138.166.344.092.522l-1.043 2.488a.675.675 0 00.166.748c.204.187.5.217.736.075l2.585-1.511a.447.447 0 01.453 0l2.585 1.51a.615.615 0 00.736-.074.675.675 0 00.165-.748zM22.927 15.838a.482.482 0 00.118-.526.452.452 0 00-.436-.293h-1.554a.46.46 0 01-.415-.27l-.82-1.755a.486.486 0 00-.435-.275.486.486 0 00-.434.275l-.823 1.755a.46.46 0 01-.415.27h-1.554a.451.451 0 00-.436.293.48.48 0 00.12.526l1.384 1.506c.13.139.166.345.093.522l-.765 1.82a.514.514 0 00.127.57.469.469 0 00.561.058l1.916-1.12a.446.446 0 01.453 0l1.915 1.12c.18.107.404.084.56-.058a.514.514 0 00.127-.57l-.763-1.82a.493.493 0 01.092-.522l1.384-1.506zM7.84 15.019H6.286a.46.46 0 01-.415-.27l-.82-1.755a.486.486 0 00-.435-.275.486.486 0 00-.434.275l-.823 1.755a.46.46 0 01-.415.27H1.39a.45.45 0 00-.436.293.48.48 0 00.12.526l1.384 1.506c.13.139.166.345.092.522l-.764 1.82a.514.514 0 00.127.57.469.469 0 00.56.058l1.916-1.117a.447.447 0 01.453 0l1.916 1.12c.18.108.404.084.56-.058a.514.514 0 00.127-.568L6.68 17.87a.493.493 0 01.093-.523l1.384-1.505a.482.482 0 00.123-.53.452.452 0 00-.441-.293z"
      fill="currentColor"
    />
  </Icon>
)

StarGroup.displayName = 'StarGroupIcon'

export default StarGroup
