import { Icon } from '@chakra-ui/icon'

const Quill = props => (
  <Icon {...props}>
    <path
      d="M23.366.019c-.166.045-1.858.521-4.152 1.416a.252.252 0 00-.147.154l-1.33 3.99a.248.248 0 01-.2.169.252.252 0 01-.241-.109l-1.62-2.423a.249.249 0 00-.319-.085A27.214 27.214 0 008.3 7.925a15.606 15.606 0 00-4.39 6.856 5.342 5.342 0 00.183 3.484.249.249 0 01-.05.281l-3.75 3.747a1 1 0 001.414 1.414L13.3 12.115a.5.5 0 01.707.707l-7.022 7.025a.25.25 0 00.154.426 6.37 6.37 0 002.08-.18c2.633-.831 5-2.346 6.856-4.39 5.112-5.111 7.8-14.666 7.907-15.069a.5.5 0 00-.616-.615z"
      fill="currentColor"
    />
  </Icon>
)

Quill.displayName = 'QuillIcon'

export default Quill
