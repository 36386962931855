import { FC, Fragment, StrictMode, useContext, useEffect } from 'react'
import Head from 'next/head'
import { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import Gleap from 'gleap'
import {
  Provider as RollbarProvider,
  ErrorBoundary as RollbarErrorBoundary,
} from '@rollbar/react'
import { SWRConfig } from 'swr'
import 'react-datepicker/dist/react-datepicker.css'
import 'stream-chat-react/dist/css/index.css'
import { CbFallbackUI } from 'ui/components/CbErrorBoundary'
import {
  maybeReloadOnNavigate,
  mergeAuth,
  monkeyPatchXMLHttpRequest,
} from 'utils'
import '@workplacearcade/styles/typography.css'
import { MPThemeContextProvider, SpectatorViewProvider } from '../providers'
import {
  AppContext,
  MPAppContextProvider,
} from '../providers/MPAppContextProvider'
import { loadTooltipIO } from '../helpers/setupTooltipIO'
import { useRouter } from 'next/router'
import { usePathname, useSearchParams } from 'next/navigation'
import { Routes } from '../types'
import { FeaturesProvider, RoleProvider } from 'providers'
import { AUTH_STORAGE_KEY } from 'ui/types'
import Script from 'next/script'

declare global {
  interface Window {
    mergeAuth: typeof mergeAuth
    prerenderReady: boolean
    Tooltip: any
    Appcues: any
    AppcuesSettings: {
      enableURLDetection: boolean
    }
  }
}

const rollbarConfig = {
  accessToken: process.env.ROLLBAR_ACCESS_TOKEN,
  enabled: process.env.ROLLBAR_ENABLED == 'true',
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: process.env.ROLLBAR_ENVIRONMENT,
    client: {
      javascript: {
        code_version: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
        guess_uncaught_frames: true,
        source_map_enabled: true,
      },
    },
  },
}

const MPAuthProvider = dynamic(
  async () => await import('../providers/MPAuthContextProvider'),
  { ssr: false },
)

const MainLayout = dynamic(
  async () => await import('../components/MainLayout/MainLayout'),
  { ssr: false },
)

const App: FC<AppProps> = ({ Component, pageProps }) => {
  const { title } = useContext(AppContext)
  const production = process.env.NODE_ENV === 'production'

  useEffect(() => {
    if (production) {
      Gleap.initialize('2fIWl1ctLszhV0uux40dJL7oFJkJG9JB')

      loadTooltipIO()
    }
  }, [])

  useEffect(monkeyPatchXMLHttpRequest, [])

  // Helper method to rapidly 'spoof' being logged in as a user, if necessary
  useEffect(() => {
    window.mergeAuth = mergeAuth
  }, [])

  useEffect(() => {
    if (window?.AppcuesSettings) {
      window.AppcuesSettings = {
        enableURLDetection: true,
      }
    }
  }, [])

  /* When switching logins via the login flow or an external link, reload any instances
     that did not initiate the change in LocalStorage (native behavior of the Web API)
     Navigate back to the root as the current page may not be valid for the new credentials
  */
  const reloadOnAuthChange = (e: StorageEvent) => {
    if (e.key !== AUTH_STORAGE_KEY) return

    window.location.href = '/'
  }

  useEffect(() => {
    window.addEventListener('storage', reloadOnAuthChange)

    return () => window.removeEventListener('storage', reloadOnAuthChange)
  }, [])

  const pathname = usePathname()
  const searchParams = useSearchParams()

  const suppressLayout = (() => {
    // Suppress for /guides/:id, but not /manage/guides/:id
    if (
      (!pathname?.match(/\/manage\/guides/) &&
        pathname?.match(/\/guides/) &&
        searchParams.get('id')) ||
      pathname?.match(/\/results/)
    )
      return true

    return false
  })()

  const SuppressableLayout = suppressLayout ? Fragment : MainLayout

  return (
    <>
      <Head>
        <meta name="robots" content="noindex" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, viewport-fit=cover"
        />
        <title>{title || 'Arcade'}</title>
        <link rel="icon" href="/images/favicon.ico" />
        {production && (
          <>
            <script src="https://hotglue.xyz/widget.js"></script>
          </>
        )}
      </Head>
      <Script
        src="https://fast.appcues.com/206255.js"
        // loads the script just before any next.js page is rendered or hydration occurs
        strategy="beforeInteractive"
      />
      <RollbarErrorBoundary
        /*
        Providing our custom CbErrorBoundary component as the UI for rollbar's error boundary.
        This allows the customer to easily reload the page.
        */
        fallbackUI={CbFallbackUI}
      >
        <MPAuthProvider>
          <SWRConfig
            value={{
              revalidateOnFocus: false,
              revalidateOnReconnect: false,
              fetcher: url => fetch(url).then(res => res.json()),
            }}
          >
            <SpectatorViewProvider>
              <FeaturesProvider>
                <RoleProvider>
                  <SuppressableLayout>
                    <Component {...pageProps} />
                  </SuppressableLayout>
                </RoleProvider>
              </FeaturesProvider>
            </SpectatorViewProvider>
          </SWRConfig>
        </MPAuthProvider>
      </RollbarErrorBoundary>
    </>
  )
}

const AppWithContext: FC<AppProps> = props => {
  const router = useRouter()

  useEffect(() => {
    router.events.on('routeChangeComplete', maybeReloadOnNavigate)

    return () => router.events.off('routeChangeComplete', maybeReloadOnNavigate)
  }, [])

  // For rendered cards, ignore all wrappers and context, just show the card
  if (router.pathname.startsWith(Routes.RenderedCards)) {
    return (
      <MPThemeContextProvider>
        <props.Component {...props.pageProps} />
      </MPThemeContextProvider>
    )
  }

  return (
    <RollbarProvider config={rollbarConfig}>
      <StrictMode>
        <MPThemeContextProvider>
          <MPAppContextProvider>
            <App {...props} />
          </MPAppContextProvider>
        </MPThemeContextProvider>
      </StrictMode>
    </RollbarProvider>
  )
}

export default AppWithContext
