import { Icon } from '@chakra-ui/icon'

const Clover = props => (
  <Icon {...props}>
    <path
      d="M22.987 13.736a.5.5 0 010-.723A3.263 3.263 0 0024 10.647c0-1.807-2.182-3.272-4.364-3.272a5.164 5.164 0 00-2.5 1.213.25.25 0 01-.35-.35A5.166 5.166 0 0018 5.738c0-2.181-1.466-4.363-3.273-4.363a3.259 3.259 0 00-2.365 1.013.5.5 0 01-.724 0 3.259 3.259 0 00-2.365-1.013C7.465 1.375 6 3.557 6 5.738a5.166 5.166 0 001.213 2.5.25.25 0 01-.35.35 5.164 5.164 0 00-2.5-1.213C2.182 7.375 0 8.84 0 10.647c0 .894.365 1.75 1.013 2.366a.5.5 0 010 .723A3.263 3.263 0 000 16.1c0 1.806 2.182 3.272 4.364 3.272 1.335 0 3.9-1.022 5.683-1.815a.5.5 0 01.7.457v3.358a1.25 1.25 0 102.5 0v-3.355a.5.5 0 01.7-.457c1.786.793 4.348 1.815 5.683 1.815 2.188 0 4.37-1.466 4.37-3.275 0-.894-.366-1.748-1.013-2.364z"
      fill="currentColor"
    />
  </Icon>
)

Clover.displayName = 'CloverIcon'

export default Clover
