import { Icon } from '@chakra-ui/icon'

const ToolWrench = props => (
  <Icon {...props}>
    <path
      d="M23.742 3.426a.5.5 0 00-.828-.2l-2.449 2.452a.5.5 0 01-.354.147H18.7a.5.5 0 01-.5-.5V3.911a.5.5 0 01.147-.353L20.8 1.1a.5.5 0 00-.19-.828 4.992 4.992 0 00-6.5 5.76.494.494 0 01-.128.458l-7.493 7.495a.5.5 0 01-.459.128 4.992 4.992 0 00-5.759 6.5.5.5 0 00.829.187l2.459-2.459a.5.5 0 01.351-.141h1.414a.5.5 0 01.5.5v1.414a.5.5 0 01-.146.353l-2.449 2.448a.5.5 0 00.2.828A5.04 5.04 0 005.016 24a4.994 4.994 0 004.872-6.025.5.5 0 01.128-.458l7.5-7.5a.5.5 0 01.458-.129 4.993 4.993 0 005.768-6.464v.002z"
      fill="currentColor"
    />
  </Icon>
)

ToolWrench.displayName = 'ToolWrenchIcon'

export default ToolWrench
