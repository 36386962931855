import { Icon } from '@chakra-ui/icon'

const RoundedRectangleOutline = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.249 0h11.502A6.256 6.256 0 0124 6.249v11.502A6.256 6.256 0 0117.749 24h-11.5A6.256 6.256 0 010 17.749v-11.5A6.256 6.256 0 016.249 0zm11.502 21.5a3.754 3.754 0 003.749-3.749V6.249A3.754 3.754 0 0017.749 2.5h-11.5A3.754 3.754 0 002.5 6.249v11.502A3.754 3.754 0 006.249 21.5h11.502z"
      fill="currentColor"
    />
  </Icon>
)

RoundedRectangleOutline.displayName = 'RoundedRectangleOutlineIcon'

export default RoundedRectangleOutline
