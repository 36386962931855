import { Icon } from '@chakra-ui/icon'

const FilesAlternate = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.941 3.607L18.87.554A1.912 1.912 0 0017.527 0H8.9A1.9 1.9 0 007 1.9V5H3.4a1.9 1.9 0 00-1.9 1.9v15.21A1.9 1.9 0 003.4 24h11.7a1.9 1.9 0 001.9-1.895V19h3.6a1.9 1.9 0 001.9-1.895V4.949c0-.504-.2-.988-.559-1.342zM15 21.5a.5.5 0 01-.5.5H4a.5.5 0 01-.5-.5v-14A.5.5 0 014 7h7.784a.5.5 0 01.35.143L14.85 9.8a.5.5 0 01.15.357V21.5zm2.25-4.5H20a.5.5 0 00.5-.5V5.157a.5.5 0 00-.15-.357l-2.743-2.683a.5.5 0 00-.351-.143L9.5 2a.5.5 0 00-.5.5v2.25c0 .138.112.25.25.25h2.778a1.9 1.9 0 011.342.554l3.072 3.055c.357.355.558.838.558 1.341v6.8c0 .138.112.25.25.25z"
      fill="currentColor"
    />
  </Icon>
)

FilesAlternate.displayName = 'FilesAlternateIcon'

export default FilesAlternate
