import { Icon } from '@chakra-ui/icon'

const RibbonCheckOne = props => (
  <Icon {...props}>
    <path
      d="M23.366 19.266l-2.4-4.138a.25.25 0 00-.424-.013c-1.22 1.838-3 3.236-5.075 3.985a.25.25 0 00-.131.363l1.975 3.343a1 1 0 001.8-.172l.832-2.287 2.392.405c.382.06.765-.103.988-.419.217-.318.234-.732.043-1.067zM3.471 15.09a.25.25 0 00-.425.012L.634 19.267a1 1 0 001.032 1.487l2.391-.405.833 2.288a1 1 0 001.8.168l1.98-3.351a.251.251 0 00-.13-.362 10.286 10.286 0 01-5.069-4.002z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.016.706a8.75 8.75 0 018.75 8.75 8.76 8.76 0 01-8.75 8.75 8.75 8.75 0 110-17.5zM8.47 10.788l1.73 1.725a1.5 1.5 0 002.254-.151l3.731-4.874a.75.75 0 00-1.191-.912l-3.736 4.879L9.53 9.728a.75.75 0 10-1.06 1.06z"
      fill="currentColor"
    />
  </Icon>
)

RibbonCheckOne.displayName = 'RibbonCheckOneIcon'

export default RibbonCheckOne
