import useSWR from 'swr'
import arcadeApiClient from '../apiClients/arcadeApiClient'

const metricFetcher = async (url: string): Promise<any> =>
  await arcadeApiClient.get(url).then(res => res.data.metrics)

export const useMetrics = () => {
  const { data: metricData, isValidating: isLoadingMetrics } = useSWR(
    'multi_platform/manage/games/valid_metrics',
    metricFetcher,
  )

  return { metricData, isLoadingMetrics }
}
