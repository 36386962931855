import { Icon } from '@chakra-ui/icon'

const ToolBolt = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.3 2.69l5.436 8.54a1.49 1.49 0 01-.01 1.61l-5.43 8.47a1.5 1.5 0 01-1.26.69H6.966a1.5 1.5 0 01-1.261-.69L.266 12.84a1.506 1.506 0 010-1.61l5.439-8.54A1.49 1.49 0 016.966 2h10.06c.515-.003.995.257 1.274.69zM6.956 12a5 5 0 1010 0 5 5 0 00-10 0z"
      fill="currentColor"
    />
  </Icon>
)

ToolBolt.displayName = 'ToolBoltIcon'

export default ToolBolt
