import { Icon } from '@chakra-ui/icon'

const Computer = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 .251h20a2 2 0 012 2v13.5a2 2 0 01-2 2h-7.651a.25.25 0 00-.248.281l.2 1.594a.252.252 0 01-.287.278A13.133 13.133 0 0012 19.751a13.01 13.01 0 00-2.015.154.25.25 0 01-.287-.279l.2-1.594a.248.248 0 00-.248-.281H2a2 2 0 01-2-2v-13.5a2 2 0 012-2zm0 15a.5.5 0 00.5.5h19a.5.5 0 00.5-.5v-12.5a.5.5 0 00-.5-.5h-19a.5.5 0 00-.5.5v12.5z"
      fill="currentColor"
    />
    <path
      d="M12 20.751c-2.667 0-5.5.876-5.5 2.5a.5.5 0 00.5.5h10a.5.5 0 00.5-.5c0-1.624-2.833-2.5-5.5-2.5z"
      fill="currentColor"
    />
  </Icon>
)

Computer.displayName = 'ComputerIcon'

export default Computer
