import { useContext, useState, useEffect, useCallback } from 'react'
import { arcadeApiClient } from 'apiClients'
import { AppContext } from 'providers'
import { Guide, Card, NewCard, GuideStatus, CardType, NewGuide } from 'types'
import { snakeCaseKeys } from './../utils/string'
import { debounce, omitBy, isNil } from 'lodash'
import { createNewCard } from 'helpers/createNewGuide'
import { useDisclosure } from '@chakra-ui/react'

export const sanitizeCard = (card: Card | NewCard) => {
  const filteredCard = omitBy(card, isNil)

  /* 
    If the guide creator has selected a quiz type, provided values and then swapped to a different card type
    There's a chance that the question values will still be there. This leads to an invalid card on the backend
    So we should strip those values out
  */
  if (filteredCard.format != CardType.Quiz && !!filteredCard.question) {
    delete filteredCard.question
  }

  return snakeCaseKeys(filteredCard)
}

export const useGuideBuilder = (
  initialCards: Card[] | NewCard[],
  initialGuide?: Guide,
) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { setToast } = useContext(AppContext)
  const [guide, setGuide] = useState<Guide | NewGuide>(initialGuide ?? {})
  const [isSaving, setIsSaving] = useState(false)
  const [activeCardIndex, setActiveCardIndex] = useState<number>(0)
  const [cards, setCards] = useState<Card[] | NewCard[]>(initialCards)
  const [editingCard, setEditingCard] = useState<Card | NewCard>(
    cards[activeCardIndex] ?? createNewCard(cards.length, CardType.Story),
  )

  useEffect(() => {
    if (cards[0]?.title) {
      setGuide(prev => ({ ...prev, title: cards[0].title }))
    }
  }, [cards[0]?.title])

  const getStatusLabel = (status: GuideStatus | undefined) => {
    switch (status) {
      case GuideStatus.Draft:
        return 'Draft'
      case GuideStatus.Published:
        return 'Published'
      case GuideStatus.Archived:
        return 'Archived'
      default:
        return 'Unsaved draft'
    }
  }

  const getNextAction = (status: GuideStatus) => {
    switch (status) {
      case GuideStatus.Draft:
        return 'publish'
      case GuideStatus.Published:
        return 'archive'
      case GuideStatus.Archived:
        return 'restore'
      default:
        return 'publish'
    }
  }

  const handleUpdateGuideStatus = async (action: string) => {
    let endpoint: string
    let successMessage: string
    let errorMessage: string
    let newStatus: GuideStatus

    switch (action) {
      case 'publish':
        endpoint = `/multi_platform/manage/guides/${guide.id}/publish`
        successMessage = 'Guide published successfully'
        errorMessage = 'Error publishing guide'
        newStatus = GuideStatus.Published
        break
      case 'archive':
        endpoint = `/multi_platform/manage/guides/${guide.id}/archive`
        successMessage = 'Guide archived successfully'
        errorMessage = 'Error archiving guide'
        newStatus = GuideStatus.Archived
        break
      case 'restore':
        endpoint = `/multi_platform/manage/guides/${guide.id}/restore`
        successMessage = 'Guide restored successfully'
        errorMessage = 'Error restoring guide'
        newStatus = GuideStatus.Published
        break
      case 'draft':
        endpoint = `/multi_platform/manage/guides/${guide.id}/unpublish`
        successMessage = 'Guide unpublished successfully'
        errorMessage = 'Error unpublishing guide'
        newStatus = GuideStatus.Draft
        break
      default:
        console.error('Invalid status:', action)
        return
    }

    setIsSaving(true)
    try {
      await arcadeApiClient.put(endpoint)
      setGuide(prevGuide => ({ ...prevGuide, status: newStatus }))
      setToast({
        status: 'success',
        description: successMessage,
      })
    } catch (error) {
      console.error(errorMessage, error)
      setToast({
        status: 'error',
        description: errorMessage,
      })
    } finally {
      setIsSaving(false)
    }
  }

  const handleSaveCard = async (card: Card | NewCard, guideId?: number) => {
    const sanitizedCard = sanitizeCard(card)

    setIsSaving(true)
    try {
      const response = sanitizedCard.hasOwnProperty('id')
        ? await arcadeApiClient.patch(
            `/multi_platform/manage/guides/${guide.id || guideId}/cards/${
              sanitizedCard.id
            }`,
            { card: sanitizedCard },
          )
        : await arcadeApiClient.post(
            `/multi_platform/manage/guides/${guide.id || guideId}/cards`,
            { card: sanitizedCard },
          )
      const newCard = response.data.card
      const updatedCards = [...cards]

      // Update or add the card based on if it's an existing card or a new card
      if (
        sanitizedCard.hasOwnProperty('id') ||
        sanitizedCard.format === CardType.Title
      ) {
        updatedCards[activeCardIndex] = newCard
      } else {
        updatedCards.push(newCard)
        // Set the active card to the new card's index value
        setActiveCardIndex(newCard.index)
      }

      setCards(updatedCards)

      setToast({
        status: 'success',
        description: 'Card saved successfully',
      })
      onClose()
    } catch (error) {
      console.error('Error saving card:', error)
      setToast({
        status: 'error',
        description: 'Error saving card',
      })
    } finally {
      setIsSaving(false)
    }
  }

  const handleSaveGuide = async (guideData: Guide | NewGuide) => {
    setIsSaving(true)
    try {
      const response = guide.hasOwnProperty('id')
        ? await arcadeApiClient.put(
            `/multi_platform/manage/guides/${guide.id}`,
            { guide: guideData },
          )
        : await arcadeApiClient.post('/multi_platform/manage/guides', {
            guide: guideData,
          })
      const newGuide = response.data.guide
      setGuide(newGuide)

      const updatedCards = [...cards]
      updatedCards[0] = {
        ...updatedCards[0],
        title: newGuide.title,
        format: CardType.Title,
        description: newGuide.description,
        index: 0,
      }

      await handleSaveCard(updatedCards[0], newGuide.id)

      setToast({
        status: 'success',
        description: 'Guide saved successfully',
      })
    } catch (error) {
      console.error('Error saving guide:', error.message)
      setToast({
        status: 'error',
        description: 'Error saving guide',
      })
    } finally {
      setIsSaving(false)
    }
  }

  const debouncedUpdateCardIndexes = useCallback(
    debounce(async (updatedCards: Card[] | NewCard[]) => {
      setIsSaving(true)
      try {
        const cardIds = updatedCards.map(card => card.id)
        await arcadeApiClient.put(
          `/multi_platform/manage/guides/${guide.id}/update_card_indexes`,
          { card_ids: cardIds },
        )

        setToast({
          status: 'success',
          description: 'Card order updated',
        })
      } catch (error) {
        console.error('Error updating card indexes:', error.message)
        setToast({
          status: 'error',
          description: 'Error updating card order',
        })
      } finally {
        setIsSaving(false)
      }
    }, 1000),
    [guide.id],
  )

  const shiftCard = (direction: 'left' | 'right') => {
    const newIndex =
      direction === 'left' ? activeCardIndex - 1 : activeCardIndex + 1

    if (newIndex >= 0 && newIndex < cards.length) {
      const newCards = [...cards]
      const [card] = newCards.splice(activeCardIndex, 1)
      newCards.splice(newIndex, 0, card)
      newCards.forEach((card, index) => {
        card.index = index
      })

      setCards(newCards)
      setActiveCardIndex(newIndex)
      debouncedUpdateCardIndexes(newCards)
    }
  }

  const handleRemoveCard = async () => {
    const removedCard = cards[activeCardIndex] as Card
    const updatedCards = cards.filter((_, index) => index !== activeCardIndex)
    setCards(updatedCards)
    setActiveCardIndex(prev => (prev > 0 ? prev - 1 : 0))
    if (!removedCard.hasOwnProperty('id')) return

    setIsSaving(true)
    try {
      await arcadeApiClient.delete(
        `/multi_platform/manage/guides/${guide.id}/cards/${removedCard.id}`,
      )
      debouncedUpdateCardIndexes(updatedCards)

      setToast({
        status: 'success',
        description: 'Card removed',
      })
    } catch (error) {
      console.error('Error deleting card:', error.message)
      setToast({
        status: 'error',
        description: 'Error removing card',
      })
    } finally {
      setIsSaving(false)
    }
  }

  const handleCardClick = (index: number) => setActiveCardIndex(index)

  const handleEditCard = () => {
    onOpen()
    setEditingCard(cards[activeCardIndex])
  }

  const handleAddCard = () => {
    onOpen()
    const newCard: NewCard = createNewCard(cards.length, CardType.Story)
    setEditingCard(newCard)
  }

  return {
    activeCardIndex,
    cards,
    editingCard,
    getNextAction,
    getStatusLabel,
    guide,
    handleAddCard,
    handleCardClick,
    handleEditCard,
    handleRemoveCard,
    handleSaveCard,
    handleSaveGuide,
    handleUpdateGuideStatus,
    isModalOpen: isOpen,
    isSaving,
    onModalClose: onClose,
    shiftCard,
  }
}
