import { Icon } from '@chakra-ui/icon'

const RoundedRectangleEmpty = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.25 0h19.5A2.252 2.252 0 0124 2.25v19.5A2.252 2.252 0 0121.75 24H2.25A2.252 2.252 0 010 21.75V2.25A2.252 2.252 0 012.25 0zm.25 21.5h19v-19h-19v19z"
      fill="currentColor"
    />
  </Icon>
)

RoundedRectangleEmpty.displayName = 'RoundedRectangleEmptyIcon'

export default RoundedRectangleEmpty
