import { Icon } from '@chakra-ui/icon'

const Calendar = props => (
  <Icon {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.75 3H21.5C22.6046 3 23.5 3.89543 23.5 5V22C23.5 23.1046 22.6046 24 21.5 24H2.5C1.39543 24 0.5 23.1046 0.5 22V5C0.5 3.89543 1.39543 3 2.5 3H4C4.27614 3 4.5 3.22386 4.5 3.5V5.75C4.5 6.16421 4.83579 6.5 5.25 6.5C5.66421 6.5 6 6.16421 6 5.75V1C6 0.447715 6.44772 0 7 0C7.55228 0 8 0.447715 8 1V2.751C8.00055 2.88868 8.11232 3 8.25 3H14.5C14.7761 3 15 3.22386 15 3.5V5.75C15 6.16421 15.3358 6.5 15.75 6.5C16.1642 6.5 16.5 6.16421 16.5 5.75V1C16.5 0.447715 16.9477 0 17.5 0C18.0523 0 18.5 0.447715 18.5 1V2.75C18.5 2.88807 18.6119 3 18.75 3ZM3 22H21C21.2761 22 21.5 21.7761 21.5 21.5V9.5C21.5 9.22386 21.2761 9 21 9H3C2.72386 9 2.5 9.22386 2.5 9.5V21.5C2.5 21.7761 2.72386 22 3 22Z"
    />
  </Icon>
)

Calendar.displayName = 'CalendarIcon'

export default Calendar
