import { Icon } from '@chakra-ui/icon'

const Restart = props => (
  <Icon {...props}>
    <path
      fill="currentColor"
      fill-opacity=".72"
      d="M12.567.258A10.82 10.82 0 0 0 2.818 7.4a.25.25 0 0 1-.271.163L.858 7.309a.51.51 0 0 0-.485.213.5.5 0 0 0-.021.53l2.679 4.7a.5.5 0 0 0 .786.107l3.77-3.746a.5.5 0 0 0-.279-.85l-1.715-.256a.25.25 0 0 1-.192-.35 8.259 8.259 0 1 1 7.866 11.59 1.25 1.25 0 0 0 .045 2.5h.047a10.75 10.75 0 0 0 10.414-11.142A10.75 10.75 0 0 0 12.567.26z"
    />
  </Icon>
)

Restart.displayName = 'RestartIcon'

export default Restart
