import { Icon } from '@chakra-ui/icon'

const VideoPlay = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.5 2h15A4.505 4.505 0 0124 6.5v11a4.505 4.505 0 01-4.5 4.5h-15A4.505 4.505 0 010 17.5v-11A4.505 4.505 0 014.5 2zm5.671 14.224l5.764-2.882a1.5 1.5 0 000-2.684l-5.764-2.882A1.5 1.5 0 008 9.118v5.764a1.5 1.5 0 002.171 1.342z"
      fill="currentColor"
    />
  </Icon>
)

VideoPlay.displayName = 'VideoPlayIcon'

export default VideoPlay
