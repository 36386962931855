import { Icon } from '@chakra-ui/icon'

const GeoPin = props => (
  <Icon {...props}>
    <svg
      width="24"
      height="20"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6667 7.00008C11.6667 10.2667 6.33333 15.6667 6.33333 15.6667C6.33333 15.6667 1 10.2667 1 7.00008C1 3.60008 3.73333 1.66675 6.33333 1.66675C8.93333 1.66675 11.6667 3.60008 11.6667 7.00008Z"
        stroke="#BABCD3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
        fill="none"
      />
      <path
        d="M6.33325 9.00008C7.43782 9.00008 8.33325 8.10465 8.33325 7.00008C8.33325 5.89551 7.43782 5.00008 6.33325 5.00008C5.22868 5.00008 4.33325 5.89551 4.33325 7.00008C4.33325 8.10465 5.22868 9.00008 6.33325 9.00008Z"
        stroke="#BABCD3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
        fill="none"
      />
    </svg>
  </Icon>
)

GeoPin.displayName = 'GeoPinIcon'

export default GeoPin
