import { Icon } from '@chakra-ui/icon'

const GiftTwo = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.469 11.375h-3.594a6.609 6.609 0 002.3-1.105 2.547 2.547 0 10-3.6-3.6 3.569 3.569 0 00-.6.985 3.54 3.54 0 00-.6-.985 2.547 2.547 0 00-3.6 3.6 6.6 6.6 0 002.3 1.105H6.469a1.752 1.752 0 00-1.75 1.75v1a1.764 1.764 0 001.75 1.75h4.5a.25.25 0 00.25-.25v-4.25h1.5v4.25c0 .138.112.25.25.25h4.5a1.763 1.763 0 001.75-1.75v-1a1.752 1.752 0 00-1.75-1.75zm-3.481-3.293a.547.547 0 11.78.768 5.88 5.88 0 01-1.34.566c.13-.467.318-.915.56-1.334zm-4.048-.01a.547.547 0 00-.77.778c.421.244.87.434 1.339.566a5.758 5.758 0 00-.569-1.344z"
      fill="currentColor"
    />
    <path
      d="M12.969 17.375a.25.25 0 00-.25.25v5c0 .138.112.25.25.25h2.569a1.756 1.756 0 001.745-1.626l.258-3.606a.252.252 0 00-.25-.268h-4.322zM11.219 17.625a.25.25 0 00-.25-.25H6.646a.25.25 0 00-.249.268l.258 3.606A1.756 1.756 0 008.4 22.875h2.569a.25.25 0 00.25-.25v-5zM11.969 4.625a1 1 0 001-1v-1.5a1 1 0 10-2 0v1.5a1 1 0 001 1zM6.122 7.036a1 1 0 000-1.414l-1.061-1.06a1 1 0 00-1.414 1.414l1.061 1.06a1 1 0 001.414 0zM2.692 11.875h-1.5a1 1 0 100 2h1.5a1 1 0 100-2zM20.353 4.562a1 1 0 00-1.414 0l-1.061 1.06a1 1 0 001.414 1.414l1.061-1.06a1 1 0 000-1.414zM22.808 11.875h-1.5a1 1 0 000 2h1.5a1 1 0 100-2z"
      fill="currentColor"
    />
  </Icon>
)

GiftTwo.displayName = 'GiftTwoIcon'

export default GiftTwo
