import { Icon } from '@chakra-ui/icon'

const Columns = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3,5v14h18V5H3z M8.33,17H5V7h3.33V17z M13.67,17h-3.33V7h3.33V17z M19,17h-3.33V7H19V17z"
      fill="currentColor"
    />
  </Icon>
)

Columns.displayName = 'Columns'

export default Columns
