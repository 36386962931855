import { Icon } from '@chakra-ui/icon'

const MenuSlim = props => (
  <Icon viewBox="0 0 24 16" {...props}>
    <path
      d="M1 8H23"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M1 1H23"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M1 15H23"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </Icon>
)

MenuSlim.displayName = 'MenuSlimIcon'

export default MenuSlim
