import { Icon } from '@chakra-ui/icon'

const LocationCursor = props => (
  <Icon {...props}>
    <path
      d="M23.633.368A1.242 1.242 0 0022.159.15L.66 11.649A1.25 1.25 0 001.25 14H10v8.75A1.25 1.25 0 0011.246 24c.46 0 .883-.255 1.1-.661l11.5-21.5a1.243 1.243 0 00-.213-1.471z"
      fill="currentColor"
    />
  </Icon>
)

LocationCursor.displayName = 'LocationCursorIcon'

export default LocationCursor
