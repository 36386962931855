import { Icon } from '@chakra-ui/icon'

const PinAlternate = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.646 1.677l3.637 3.637a1.5 1.5 0 01-.256 2.326l-5.472 3.099a6.783 6.783 0 01-.548 5.278 1.5 1.5 0 01-1.1.729 1.576 1.576 0 01-1.252-.427L7.647 10.31a1.5 1.5 0 01.3-2.355A6.32 6.32 0 0111.46 7.2a7.631 7.631 0 011.766.2l3.094-5.467a1.5 1.5 0 012.326-.256zm-2.185 4.747L17.875 4.3v.003a.5.5 0 10-.832-.555l-1.414 2.121a.5.5 0 10.832.555z"
      fill="currentColor"
    />
    <path
      d="M8.071 14.559l-6.5 6.5a1 1 0 000 1.414c.394.382 1.02.382 1.414 0l6.5-6.5a1 1 0 00-1.414-1.414z"
      fill="currentColor"
    />
  </Icon>
)

PinAlternate.displayName = 'PinAlternateIcon'

export default PinAlternate
