import { useEffect, useState } from 'react'
import { DateTime, DurationObjectUnits } from 'luxon'

const relativeFromGMTTime = (time: string): string => {
  return DateTime.fromISO(time).toRelative() as string
}

enum IntervalFrequency {
  Second = 1000,
  Minute = 60000,
  Hour = 3600000,
}

/* This hook accepts an ISO DateTime (such as comes straight from the DB)
   and returns a String of the relative time, updated every second, minute or hour
   as appropriate.  This hook could be slightly modified in the future to accept
   other timezone options/input strings 
*/

export const useRelativeTime = (time: string) => {
  const [timerFrequency, setTimerFrequency] = useState<IntervalFrequency>(
    IntervalFrequency.Second,
  )
  const [intervalId, setIntervalId] = useState<number>()
  const [relativeTime, setRelativeTime] = useState<string>(
    relativeFromGMTTime(time),
  )
  /* When timerFrequency changes:
       1. Clear previous interval, if exists
       2. Set a new interval
       3. Store that intervalId
       4. Return cleanup fn to remove interval on unmount
  */
  useEffect(() => {
    if (intervalId) clearInterval(intervalId)

    const id = window.setInterval(onTick, timerFrequency)

    setIntervalId(id)

    return () => clearInterval(intervalId)
  }, [timerFrequency])

  /* If input is more than an hour in future, when there are 59 minutes left, 
     onTick so interval will reset to minute. Clear timeout on unmount
  */
  useEffect(() => {
    const seconds = deltaFromNow()
    if (seconds > -3600) return

    // Number of seconds input is in the future - 59 minutes of seconds
    const interval = -seconds - 3540
    const timer = setTimeout(onTick, interval * 1000)

    return () => clearTimeout(timer)
  }, [])

  /* If input is more than a minute in future, when there are 59 seconds left, 
     onTick so interval will reset to second. Clear timeout on unmount
  */
  useEffect(() => {
    const seconds = deltaFromNow()
    if (seconds > -60) return

    // Number of seconds input is in the future - 59 seconds
    const interval = -seconds - 59
    const timer = setTimeout(onTick, interval * 1000)

    return () => clearTimeout(timer)
  }, [])

  const deltaFromNow = (units: keyof DurationObjectUnits = 'seconds') =>
    DateTime.now().diff(DateTime.fromISO(time), [units])[units]

  const newFrequency = (() => {
    const delta = Math.abs(deltaFromNow())

    if (delta < 60) return IntervalFrequency.Second
    if (delta < 3600) return IntervalFrequency.Minute

    return IntervalFrequency.Hour
  })()

  const onTick = () => {
    setRelativeTime(relativeFromGMTTime(time))
    setTimerFrequency(newFrequency)
  }

  return relativeTime
}
