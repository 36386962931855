import { Icon } from '@chakra-ui/icon'

const PlusCircle = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.75 12c0 6.49-5.26 11.75-11.75 11.75C5.514 23.742.258 18.486.25 12 .25 5.51 5.51.25 12 .25S23.75 5.51 23.75 12zm-2.5 0A9.25 9.25 0 0012 2.75 9.26 9.26 0 002.75 12a9.25 9.25 0 0018.5 0z"
      fill="currentColor"
    />
    <path
      d="M6.273 13.25H10.5a.25.25 0 01.25.25v4.227a1.25 1.25 0 102.5 0V13.5a.25.25 0 01.25-.25h4.227a1.25 1.25 0 100-2.5H13.5a.25.25 0 01-.25-.25V6.272a1.25 1.25 0 10-2.5 0V10.5a.25.25 0 01-.25.25H6.273a1.25 1.25 0 100 2.5z"
      fill="currentColor"
    />
  </Icon>
)

PlusCircle.displayName = 'PlusCircleIcon'

export default PlusCircle
