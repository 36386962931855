import { FC, createContext, PropsWithChildren } from 'react'
import { useBreakpointValue } from '@chakra-ui/react'

import { CbThemeProvider } from 'ui'

export interface ThemeContextProps {
  isMobile: boolean
}
export const MPThemeContext = createContext({} as ThemeContextProps)
const MPThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  const isMobile = useBreakpointValue({ base: true, md: false }) as boolean

  return (
    <MPThemeContext.Provider value={{ isMobile }}>
      {children}
    </MPThemeContext.Provider>
  )
}
export const MPThemeContextProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <CbThemeProvider>
      <MPThemeProvider>{children}</MPThemeProvider>
    </CbThemeProvider>
  )
}
