import { Icon } from '@chakra-ui/icon'

const CautionTriangle = props => (
  <Icon {...props} position="relative" top="0.5px">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.119 20L13.772 2.15a2 2 0 00-3.543 0L.881 20a2 2 0 001.772 2.928h18.694A2 2 0 0023.119 20zM11 8.423a1 1 0 112 0v6a1 1 0 11-2 0v-6zm1.022 11.51h.028A1.475 1.475 0 0013.5 18.4a1.527 1.527 0 00-1.524-1.467h-.028a1.476 1.476 0 00-1.448 1.53 1.528 1.528 0 001.522 1.47z"
      fill="currentColor"
    />
  </Icon>
)

CautionTriangle.displayName = 'CautionTriangleIcon'

export default CautionTriangle
