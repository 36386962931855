import { Icon } from '@chakra-ui/icon'

const Map = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 15.36c.003-2.65 2.24-4.797 5-4.8 2.76.003 4.997 2.15 5 4.8 0 3.044-4.457 7.357-4.646 7.54a.51.51 0 01-.708 0C18.457 22.717 14 18.404 14 15.36zm3.25 0c0 .928.784 1.68 1.75 1.68.966-.001 1.749-.753 1.75-1.68 0-.928-.784-1.68-1.75-1.68s-1.75.752-1.75 1.68z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 9.6c-.552 0-1-.43-1-.96l.009-5.461a.24.24 0 00-.137-.211.259.259 0 00-.258.019L9.214 6a.474.474 0 00-.209.39v12.293a.24.24 0 00.138.21.26.26 0 00.257-.018l2.691-1.85a1.027 1.027 0 011.374.237.935.935 0 01-.212 1.325l-4.375 3a1.55 1.55 0 01-1.771-.019L.6 16.882A1.429 1.429 0 010 15.73V1.44C0 .889.328.386.845.145a1.551 1.551 0 011.582.163l5.301 3.928c.175.132.42.136.6.011l5.8-3.978a1.551 1.551 0 011.745 0l6.499 4.456c.393.271.627.707.628 1.172V8.64c0 .53-.448.96-1 .96s-1-.43-1-.96V6.392a.474.474 0 00-.205-.388l-4.4-3.015a.259.259 0 00-.258-.018.24.24 0 00-.137.21V8.64c0 .53-.448.96-1 .96zm-8.138 9.225A.24.24 0 007 18.61V6.362a.474.474 0 00-.2-.384L2.4 2.714a.259.259 0 00-.262-.023.24.24 0 00-.138.215V15.25c0 .15.074.293.2.383l4.4 3.168a.258.258 0 00.262.023z"
      fill="currentColor"
    />
  </Icon>
)

Map.displayName = 'MapIcon'

export default Map
