import { get } from 'lodash'
import { AxiosResponse } from 'axios'

const KEY = 'clientFeatures'
export const FEATURE_HASH_CHANGED_EVENT_KEY = 'featureHashChanged'

export const storeAndCompareFeatureHash = (response: AxiosResponse) => {
  const hash = get(response, 'headers.x-features')
  const lastKnownHash = localStorage.getItem(KEY)

  /* If API doesn't send a value, do nothing.  Otherwise, comparing JS
     undefined to a potential serialized "undefined" will register
     as a change and refetch features after ever API response
  */
  if (hash && hash !== lastKnownHash) {
    localStorage.setItem(KEY, hash)
    window.dispatchEvent(new Event(FEATURE_HASH_CHANGED_EVENT_KEY))
  }

  return response
}

export const getFeatureHash = () => localStorage.getItem(KEY)
