import { Icon } from '@chakra-ui/icon'

const BriefCase = props => (
  <Icon {...props}>
    <svg
      width="24"
      height="20"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M5.12134 3.21214V0.666687H10.2122V3.21214"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="square"
          fill="none"
        />
        <path
          d="M5.75766 9.5758H0.666748V3.21216H14.6667V9.5758H9.57584"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="square"
          fill="none"
        />
        <path
          d="M14.0305 11.4848V14.6667H1.30322V11.4848"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="square"
          fill="none"
        />
        <path
          d="M9.57575 8.30305H5.75757V10.8485H9.57575V8.30305Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="square"
          fill="none"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </Icon>
)

BriefCase.displayName = 'BriefCaseIcon'

export default BriefCase
