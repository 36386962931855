import useSWR from 'swr'
import arcadeApiClient from '../apiClients/arcadeApiClient'

const fetcher = async (url: string): Promise<any> =>
  await arcadeApiClient.get(url).then(res => res.data.guides)

export const useGuides = () => {
  const { data: guides, isValidating: loading } = useSWR(
    '/multi_platform/guides/assignable',
    fetcher,
  )

  return { guides, loading }
}
