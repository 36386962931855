import { Icon } from '@chakra-ui/icon'

const ArrowDownLeft = props => (
  <Icon {...props}>
    <path
      d="M20 18.5H8.207a.25.25 0 01-.177-.427L21.06 5.06a1.5 1.5 0 00-2.12-2.123l-13.013 13a.25.25 0 01-.427-.177V4a1.5 1.5 0 10-3 0v15A2.5 2.5 0 005 21.5h15a1.5 1.5 0 000-3z"
      fill="currentColor"
    />
  </Icon>
)

ArrowDownLeft.displayName = 'ArrowDownLeftIcon'

export default ArrowDownLeft
