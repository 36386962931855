import { Icon } from '@chakra-ui/icon'

const Minus = props => (
  <Icon {...props}>
    <path
      d="M1.5 13.5h21a1.5 1.5 0 000-3h-21a1.5 1.5 0 000 3z"
      fill="currentColor"
    />
  </Icon>
)

Minus.displayName = 'MinusIcon'

export default Minus
