import { CardType, NewCard, NewGuide } from 'types'

export const createNewCard = (
  index: number = 0,
  format: CardType = CardType.Title,
): NewCard => ({
  index,
  format,
  title: '',
  description: undefined,
  mediaUrl: undefined,
})

export const createNewGuide = (): NewGuide => ({
  title: '',
  cards: [createNewCard()],
})
