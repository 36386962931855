import { Icon } from '@chakra-ui/icon'

const Dashboard = props => (
  <Icon {...props}>
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 13.7295H11V3.72949H3V13.7295ZM3 21.7295H11V15.7295H3V21.7295ZM13 21.7295H21V11.7295H13V21.7295ZM13 3.72949V9.72949H21V3.72949H13Z"
        fill="#E2E8F0"
      />
    </svg>
  </Icon>
)

Dashboard.displayName = 'DashboardIcon'

export default Dashboard
