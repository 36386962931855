import { Icon } from '@chakra-ui/icon'

const GiftOne = props => (
  <Icon {...props}>
    <path
      d="M13 12.48c0 .265.224.48.5.48H22c.966-.002 1.748-.753 1.75-1.68V9.36c-.001-.927-.784-1.679-1.75-1.68h-8.5a.49.49 0 00-.5.48v4.32zM3.75 12.96H11a.49.49 0 00.5-.48V8.16a.49.49 0 00-.5-.48H2c-.966.001-1.749.753-1.75 1.68v1.92c.002.927.784 1.678 1.75 1.68h1.75zM11.5 14.88a.49.49 0 00-.5-.48H2.75a.49.49 0 00-.5.48v7.44c.002.927.784 1.678 1.75 1.68h7a.49.49 0 00.5-.48v-8.64zM13.5 14.4a.49.49 0 00-.5.48v8.64c0 .265.224.48.5.48H20c.966-.002 1.748-.753 1.75-1.68v-7.44a.49.49 0 00-.5-.48H13.5z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.987 7.651a1.028 1.028 0 01-.944-.252.973.973 0 01-.267-.905c.445-1.75 1.167-3.977 1.924-4.704 1.155-1.11 3.028-1.11 4.184-.002a2.76 2.76 0 01.003 4.016c-.87.835-3.717 1.57-4.9 1.847zm3.063-4.759a.983.983 0 00-.933.256 8.373 8.373 0 00-.931 2.193c.8-.196 1.57-.497 2.284-.894a.895.895 0 00.266-.896.939.939 0 00-.686-.659zM9.8 1.79c.755.728 1.477 2.956 1.921 4.7a.972.972 0 01-.262.909 1.028 1.028 0 01-.944.252c-1.185-.277-4.032-1.012-4.9-1.847a2.76 2.76 0 01.002-4.016C6.773.68 8.645.681 9.8 1.79zM6.782 3.56a.893.893 0 00.248.887c.714.398 1.482.7 2.283.894a8.338 8.338 0 00-.931-2.193.984.984 0 00-.924-.237.938.938 0 00-.676.649z"
      fill="currentColor"
    />
  </Icon>
)

GiftOne.displayName = 'GiftOneIcon'

export default GiftOne
