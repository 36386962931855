import { Icon } from '@chakra-ui/icon'

const LayersBack = props => (
  <Icon {...props}>
    <path
      d="M0 2a2 2 0 012-2h13a2 2 0 012 2v13a2 2 0 01-2 2H2a2 2 0 01-2-2V2z"
      fill="currentColor"
    />
    <path
      d="M21.5 7h-2a1 1 0 100 2h2a.5.5 0 01.5.5v12a.5.5 0 01-.5.5h-12a.5.5 0 01-.5-.5v-2a1 1 0 10-2 0v2A2.5 2.5 0 009.5 24h12a2.5 2.5 0 002.5-2.5v-12A2.5 2.5 0 0021.5 7z"
      fill="currentColor"
    />
  </Icon>
)

LayersBack.displayName = 'LayersBackIcon'

export default LayersBack
