import { Icon } from '@chakra-ui/icon'

const HeartCalendar = props => (
  <Icon viewBox="0 0 64 64" {...props}>
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M61 23H3"
        stroke="url(#paint0_radial)"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M61 23H3"
        stroke="url(#paint1_radial)"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M56 9H8C5.23858 9 3 11.2386 3 14V52C3 54.7614 5.23858 57 8 57H56C58.7614 57 61 54.7614 61 52V14C61 11.2386 58.7614 9 56 9Z"
        stroke="url(#paint2_radial)"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M56 9H8C5.23858 9 3 11.2386 3 14V52C3 54.7614 5.23858 57 8 57H56C58.7614 57 61 54.7614 61 52V14C61 11.2386 58.7614 9 56 9Z"
        stroke="url(#paint3_radial)"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M17 3V15"
        stroke="url(#paint4_radial)"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M17 3V15"
        stroke="url(#paint5_radial)"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M47 3V15"
        stroke="url(#paint6_radial)"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M47 3V15"
        stroke="url(#paint7_radial)"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M40.4 32.578C39.8254 32.0141 39.1341 31.5832 38.3747 31.3158C37.6154 31.0483 36.8066 30.9509 36.0055 31.0302C35.2044 31.1096 34.4304 31.3638 33.7383 31.775C33.0462 32.1862 32.4528 32.7443 32 33.41C31.5472 32.7443 30.9538 32.1862 30.2617 31.775C29.5696 31.3638 28.7956 31.1096 27.9945 31.0302C27.1934 30.9509 26.3846 31.0483 25.6253 31.3158C24.8659 31.5832 24.1746 32.0141 23.6 32.578C22.5896 33.571 22.015 34.9247 22.0026 36.3413C21.9903 37.7579 22.5411 39.1215 23.534 40.132L23.6 40.198L32 48.5L40.4 40.2C41.4107 39.2072 41.9856 37.8537 41.9984 36.4371C42.0111 35.0204 41.4606 33.6568 40.468 32.646C40.445 32.623 40.423 32.6 40.4 32.578Z"
        stroke="url(#paint8_radial)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M40.4 32.578C39.8254 32.0141 39.1341 31.5832 38.3747 31.3158C37.6154 31.0483 36.8066 30.9509 36.0055 31.0302C35.2044 31.1096 34.4304 31.3638 33.7383 31.775C33.0462 32.1862 32.4528 32.7443 32 33.41C31.5472 32.7443 30.9538 32.1862 30.2617 31.775C29.5696 31.3638 28.7956 31.1096 27.9945 31.0302C27.1934 30.9509 26.3846 31.0483 25.6253 31.3158C24.8659 31.5832 24.1746 32.0141 23.6 32.578C22.5896 33.571 22.015 34.9247 22.0026 36.3413C21.9903 37.7579 22.5411 39.1215 23.534 40.132L23.6 40.198L32 48.5L40.4 40.2C41.4107 39.2072 41.9856 37.8537 41.9984 36.4371C42.0111 35.0204 41.4606 33.6568 40.468 32.646C40.445 32.623 40.423 32.6 40.4 32.578Z"
        stroke="url(#paint9_radial)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <defs>
        <radialGradient
          id="paint0_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-2.01923 23) rotate(1.54432) scale(63.0793 4.69458)"
        >
          <stop stopColor="#B182EA" />
          <stop offset="1" stopColor="#FF3364" />
        </radialGradient>
        <radialGradient
          id="paint1_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-0.585085 22.5642) rotate(1.59301) scale(57.9216 4.31446)"
        >
          <stop stopColor="#B182EA" />
          <stop offset="1" stopColor="#FF3364" />
        </radialGradient>
        <radialGradient
          id="paint2_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-2.01923 9) rotate(52.3049) scale(103.125 137.836)"
        >
          <stop stopColor="#B182EA" />
          <stop offset="1" stopColor="#FF3364" />
        </radialGradient>
        <radialGradient
          id="paint3_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-0.585085 -11.9171) rotate(53.1624) scale(96.5712 124.211)"
        >
          <stop stopColor="#B182EA" />
          <stop offset="1" stopColor="#FF3364" />
        </radialGradient>
        <radialGradient
          id="paint4_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(16.9135 3) rotate(86.9494) scale(20.4289 2.9991)"
        >
          <stop stopColor="#B182EA" />
          <stop offset="1" stopColor="#FF3364" />
        </radialGradient>
        <radialGradient
          id="paint5_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(16.9382 -2.22928) rotate(87.0425) scale(19.3482 2.67227)"
        >
          <stop stopColor="#B182EA" />
          <stop offset="1" stopColor="#FF3364" />
        </radialGradient>
        <radialGradient
          id="paint6_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(46.9135 3) rotate(86.9494) scale(20.4289 2.9991)"
        >
          <stop stopColor="#B182EA" />
          <stop offset="1" stopColor="#FF3364" />
        </radialGradient>
        <radialGradient
          id="paint7_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(46.9382 -2.22928) rotate(87.0425) scale(19.3482 2.67227)"
        >
          <stop stopColor="#B182EA" />
          <stop offset="1" stopColor="#FF3364" />
        </radialGradient>
        <radialGradient
          id="paint8_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(20.272 31.0034) rotate(53.8377) scale(36.8418 48.4858)"
        >
          <stop stopColor="#B182EA" />
          <stop offset="1" stopColor="#FF3364" />
        </radialGradient>
        <radialGradient
          id="paint9_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(20.7664 23.3789) rotate(54.6813) scale(34.5278 43.6585)"
        >
          <stop stopColor="#B182EA" />
          <stop offset="1" stopColor="#FF3364" />
        </radialGradient>
      </defs>
    </svg>
  </Icon>
)

HeartCalendar.displayName = 'HeartCalendarIcon'

export default HeartCalendar
