import { Icon } from '@chakra-ui/icon'

const MedalShiny = props => (
  <Icon {...props}>
    <path
      d="M10.178 4.862A.25.25 0 0010.4 5h3.217a.25.25 0 00.223-.138l2.26-4.5A.25.25 0 0015.879 0H8.141a.249.249 0 00-.223.362l2.26 4.5zM10.067 7.53a.252.252 0 00.154-.353L6.686.138A.25.25 0 006.462 0H6a1.071 1.071 0 00-.752.341L2.3 3.709a2 2 0 00-.052 2.572l3.43 4.262a.25.25 0 00.406-.023 7.016 7.016 0 013.983-2.99zM13.78 7.177a.248.248 0 00.154.353 7.024 7.024 0 013.982 2.989.25.25 0 00.406.023l3.43-4.261a2 2 0 00-.052-2.572L18.752.341A1.082 1.082 0 0018 0h-.6l-3.62 7.177z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.5 14.25a5.5 5.5 0 1111 0 5.5 5.5 0 01-11 0zm7.5 0a2 2 0 10-4 0 2 2 0 004 0z"
      fill="currentColor"
    />
    <path
      d="M11.4 21.22a.98.98 0 00-.4.78v1a1 1 0 102 0v-1a.98.98 0 00-1.6-.78zM16.4 19.143a1.021 1.021 0 000 1.414l.707.708a1 1 0 001.415-1.414l-.707-.708a1.046 1.046 0 00-1.415 0zM18.911 15.312a.98.98 0 00.785.408h1a1 1 0 100-2h-1a1 1 0 00-1 1c.004.216.08.424.215.592zM7.584 19.143a1.043 1.043 0 00-1.415 0l-.707.708a1 1 0 001.415 1.414l.707-.708c.38-.394.38-1.02 0-1.414zM5.011 14.038a.991.991 0 00-.724-.318h-1a1 1 0 100 2h1a.994.994 0 00.724-1.682z"
      fill="currentColor"
    />
  </Icon>
)

MedalShiny.displayName = 'MedalShinyIcon'

export default MedalShiny
