import { Icon } from '@chakra-ui/icon'

const Account = props => (
  <Icon viewBox="0 0 14 16" {...props}>
    <g>
      <path
        d="M8.3335 9.33333H5.66683C4.25283 9.33492 2.8972 9.89733 1.89735 10.8972C0.897497 11.897 0.335084 13.2527 0.333496 14.6667C0.333405 14.808 0.378237 14.9457 0.461519 15.0599C0.544801 15.1741 0.662223 15.2589 0.79683 15.302C2.82139 15.8258 4.90983 16.0608 7.00016 16C9.0905 16.0608 11.1789 15.8258 13.2035 15.302C13.3381 15.2589 13.4555 15.1741 13.5388 15.0599C13.6221 14.9457 13.6669 14.808 13.6668 14.6667C13.6652 13.2527 13.1028 11.897 12.103 10.8972C11.1031 9.89733 9.7475 9.33492 8.3335 9.33333Z"
        fill="currentColor"
      />
      <path
        d="M7.00016 8C9.06683 8 10.6668 5.68667 10.6668 3.69667C10.6668 2.7242 10.2805 1.79157 9.59289 1.10394C8.90526 0.416307 7.97262 0.0299988 7.00016 0.0299988C6.0277 0.0299988 5.09507 0.416307 4.40744 1.10394C3.7198 1.79157 3.3335 2.7242 3.3335 3.69667C3.3335 5.68667 4.9335 8 7.00016 8Z"
        fill="currentColor"
      />
    </g>
  </Icon>
)

Account.displayName = 'AccountIcon'

export default Account
