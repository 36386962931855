import { Icon } from '@chakra-ui/icon'

const MedalFirst = props => (
  <Icon {...props}>
    <path
      d="M9.708 4.843A.25.25 0 009.939 5h4.105a.25.25 0 00.232-.157l1.815-4.5A.249.249 0 0015.859 0H8.124a.25.25 0 00-.232.343l1.816 4.5zM10.086 9.224a.25.25 0 00.175-.337L6.738.156A.25.25 0 006.506 0H6a1.071 1.071 0 00-.752.341l-3.5 4a1 1 0 00-.1 1.178l3.779 6.222a.25.25 0 00.4.036 8.256 8.256 0 014.259-2.553zM22.354 5.519a1 1 0 00-.1-1.178l-3.5-4A1.057 1.057 0 0017.985 0h-.508a.251.251 0 00-.232.157l-3.507 8.734a.248.248 0 00.174.336 8.26 8.26 0 014.255 2.562.251.251 0 00.4-.036l3.787-6.234z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.242 17.25a6.75 6.75 0 016.75-6.75 6.758 6.758 0 016.75 6.75 6.75 6.75 0 01-13.5 0zm6.437 2h-.687a.75.75 0 000 1.5h3.062a.75.75 0 000-1.5h-.375a.25.25 0 01-.25-.25v-3.812a1.437 1.437 0 00-2.3-1.15l-1.65 1.237a.75.75 0 00.9 1.2l1.15-.862a.25.25 0 01.4.2V19a.25.25 0 01-.25.25z"
      fill="currentColor"
    />
  </Icon>
)

MedalFirst.displayName = 'MedalFirstIcon'

export default MedalFirst
