import { Icon } from '@chakra-ui/icon'

const Ticket = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.8 7.708a2.694 2.694 0 000 3.254c.302.411.254.982-.112 1.338L12.135 23.853a.5.5 0 01-.638.058 3.2 3.2 0 00-3.44-.126c-.408.248-.933.19-1.277-.142L.357 17.22a1.047 1.047 0 01-.143-1.276 3.126 3.126 0 00.447-1.614 3.2 3.2 0 00-.572-1.827.5.5 0 01.057-.638L11.7.311A1.023 1.023 0 0113.038.2a2.7 2.7 0 003.254 0c.411-.3.98-.253 1.337.11l6.058 6.061c.365.355.414.925.113 1.337zM11.17 19.6l8.271-8.275h.002a2.126 2.126 0 00-.002-3.008l-3.76-3.761a2.128 2.128 0 00-3.008 0L4.4 12.829a2.128 2.128 0 000 3.009L8.162 19.6a2.126 2.126 0 003.008 0z"
      fill="currentColor"
    />
    <path
      d="M14.708 7.034a.75.75 0 00-1.061 0L6.879 13.8a.751.751 0 101.061 1.064L14.708 8.1a.75.75 0 000-1.066zM14.2 11.345l-3.008 3.009a.75.75 0 101.06 1.06l3.008-3.008a.75.75 0 00-1.06-1.061z"
      fill="currentColor"
    />
  </Icon>
)

Ticket.displayName = 'TicketIcon'

export default Ticket
