import { Icon } from '@chakra-ui/icon'

const MapSearch = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.537 19.048a.982.982 0 011.336.215c.148.2.208.447.166.689a.92.92 0 01-.39.6L9.01 21.68a1.482 1.482 0 01-1.7-.017l-6.234-4.507A1.373 1.373 0 01.5 16.05V2.308c0-.53.314-1.014.81-1.246a1.483 1.483 0 011.516.157l5.08 3.778c.168.126.402.13.575.01l5.558-3.826a1.482 1.482 0 011.672 0l6.229 4.285c.377.261.6.68.602 1.128v4.609c0 .51-.43.923-.959.923-.529 0-.958-.414-.958-.923V7.068a.456.456 0 00-.188-.373l-4.216-2.897a.247.247 0 00-.248-.018.23.23 0 00-.131.202v5.249c0 .51-.43.923-.958.923-.53 0-.959-.413-.959-.923V3.979a.23.23 0 00-.131-.202.247.247 0 00-.247.018L9.33 6.692a.457.457 0 00-.2.376v11.818a.23.23 0 00.131.203c.08.038.175.032.247-.018l.03-.023zM2.55 3.51a.23.23 0 00-.132.206v11.87c0 .145.07.282.191.37l4.217 3.046c.073.052.17.06.25.021a.23.23 0 00.133-.206V7.04a.456.456 0 00-.191-.369L2.8 3.533a.247.247 0 00-.25-.022z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.22 21.501a.9.9 0 010 1.305v.002a.984.984 0 01-1.356 0l-2.295-2.21a.493.493 0 00-.583-.07c-2.29 1.3-5.227.758-6.856-1.266-1.628-2.024-1.44-4.9.44-6.71 1.88-1.811 4.866-1.993 6.967-.425 2.102 1.569 2.665 4.398 1.314 6.604a.449.449 0 00.074.561l2.294 2.21zm-8.191-8.332c-1.253.5-2.07 1.678-2.07 2.985 0 1.784 1.501 3.23 3.354 3.23 1.356 0 2.58-.787 3.098-1.994a3.145 3.145 0 00-.727-3.52 3.448 3.448 0 00-3.655-.701z"
      fill="currentColor"
    />
  </Icon>
)

MapSearch.displayName = 'MapSearchIcon'

export default MapSearch
