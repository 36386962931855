import { Icon } from '@chakra-ui/icon'

const Medal = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.043 14.043l.349-1.746a.807.807 0 00-.98-.982l-1.747.35-1.418-1.418 2.144-1.838A3.448 3.448 0 0016.5 6V2h2a1 1 0 100-2h-13a1 1 0 000 2h2v4a3.448 3.448 0 001.109 2.409l2.144 1.839-1.418 1.417-1.747-.35a.807.807 0 00-.98.981l.349 1.747L5 16a1 1 0 000 1.414l1.957 1.957-.349 1.747a.807.807 0 00.98.981l1.748-.35 1.956 1.957a1 1 0 001.415 0l1.957-1.958 1.748.35a.807.807 0 00.98-.981l-.349-1.747L19 17.415A1 1 0 0019 16l-1.957-1.957zM14.5 16.708a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0zM9.5 6c.036.334.18.647.411.891l1.676 1.436A.25.25 0 0012 8.138V2.25a.25.25 0 00-.25-.25h-2a.25.25 0 00-.25.25V6z"
      fill="currentColor"
    />
  </Icon>
)

Medal.displayName = 'MedalIcon'

export default Medal
