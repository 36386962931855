import { Icon } from '@chakra-ui/icon'

const ArrowRight = props => (
  <Icon {...props}>
    <path
      d="M23.987 11.52a2.275 2.275 0 00-.814-1.728L11.994.347A1.483 1.483 0 0010 .504c-.512.559-.47 1.407.094 1.918l8.637 7.296a.234.234 0 01.068.264.25.25 0 01-.233.156H1.452c-.795 0-1.44.619-1.44 1.382 0 .764.645 1.382 1.44 1.382h17.111a.25.25 0 01.233.156.235.235 0 01-.068.265l-8.637 7.296a1.353 1.353 0 00-.51 1.328c.096.499.47.907.973 1.063a1.483 1.483 0 001.437-.317l11.181-9.445a2.274 2.274 0 00.815-1.728z"
      fill="currentColor"
    />
  </Icon>
)

ArrowRight.displayName = 'ArrowRightIcon'

export default ArrowRight
